import React, { useState } from "react";

import { Grid, Box } from "@mui/material";

import InformacionGeneral from "./InformacionGeneral";
import BuscarPaciente from "./BuscarPaciente";
import CrearPaciente from "./CrearPaciente";

import TimelinePaciente from "./Timeline/TimelinePaciente";
import AgregarRegistrosAntiguos from "./AgregarRegistrosAntiguos/AgregarRegistros";

export default function InfoPaciente(props) {
  const {
    downMD,
    textoBusqueda,
    setTextoBusqueda,
    paciente,
    setPaciente,
    visibilidadElementos,
    registrosAntiguos,
    setRegistrosAntiguos,
    archivosAntiguos,
    agregarArchivoAntiguo,
    setArchivosAntiguos,
    readOnly,
  } = props;

  const [categorias, setCategorias] = useState({
    profesionales: { descripcion: "", estado: true },
    comercios: { descripcion: "", estado: true },
    pacientes: { descripcion: "", estado: true },
    apuntes: { descripcion: "", estado: true },
    resultados: { descripcion: "", estado: true },
  });

  const solicitarCambioPaciente = () => {
    setPaciente((prevState) => ({
      ...prevState,
      estado: { cargado: false, creando: false },
    }));
  };

  const solicitarCreacionPaciente = () => {
    setPaciente((prevState) => ({
      ...prevState,
      estado: { cargado: false, creando: true },
    }));
  };

  const solicitarCancelarCreacionPaciente = () => {
    setPaciente((prevState) => ({
      ...prevState,
      estado: { cargado: false, creando: false },
    }));
  };

  const solicitarAgregarRegistrosAntiguos = () => {
    setRegistrosAntiguos((prevState) => ({
      ...prevState,
      estado: { ingresando: true, enviando: false },
    }));
  };

  const solicitarCancelarAgregarRegistrosAntiguos = () => {
    setRegistrosAntiguos((prevState) => ({
      ...prevState,
      estado: { cargado: false, creando: false },
    }));
  };

  if (!readOnly) {
    if (!visibilidadElementos.timeline && downMD) {
      return null;
    }
  }

  return (
    <Box
      id="infoPacienteContenedor"
      padding={0}
      height="100%"
      overflow={"auto"}
    >
      {!paciente.estado.cargado ? (
        paciente.estado.creando ? (
          <Box id="crearPacienteContenedor" width={"100%"} height="100%">
            <CrearPaciente
              id="crearPaciente"
              setPaciente={setPaciente}
              solicitarCancelar={solicitarCancelarCreacionPaciente}
            />
          </Box>
        ) : (
          <Box id="buscarPacienteContenedor" width={"100%"} height={"100%"}>
            <BuscarPaciente
              id="buscarPaciente"
              paciente={paciente}
              setPaciente={setPaciente}
              solicitarCreacionPaciente={solicitarCreacionPaciente}
            />
          </Box>
        )
      ) : (
        <Grid
          container
          spacing={1}
          id="informacionGeneralContenedor"
          height={"100%"}
        >
          {!readOnly && registrosAntiguos.estado.ingresando ? (
            <AgregarRegistrosAntiguos
              registrosAntiguos={registrosAntiguos}
              setRegistrosAntiguos={setRegistrosAntiguos}
              archivosAntiguos={archivosAntiguos}
              setArchivosAntiguos={setArchivosAntiguos}
              agregarArchivoAntiguo={agregarArchivoAntiguo}
              solicitarCancelarAgregarRegistrosAntiguos={
                solicitarCancelarAgregarRegistrosAntiguos
              }
            />
          ) : (
            <React.Fragment>
              <Grid item id="gridItemInformacionGeneral" xs={12} md={4.5}>
                <InformacionGeneral
                  id="informacionGeneral"
                  textoBusqueda={textoBusqueda}
                  setTextoBusqueda={setTextoBusqueda}
                  solicitarCambioPaciente={solicitarCambioPaciente}
                  categorias={categorias}
                  setCategorias={setCategorias}
                  solicitarAgregarRegistrosAntiguos={
                    solicitarAgregarRegistrosAntiguos
                  }
                  readOnly={readOnly}
                />
              </Grid>
              <Grid
                item
                id="gridItemTimeline"
                xs={12}
                md={7.5}
                flexGrow={1}
                height="100%"
              >
                <TimelinePaciente
                  id="timeline"
                  paciente={paciente}
                  categorias={categorias}
                  textoBusqueda={textoBusqueda}
                />
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      )}
    </Box>
  );
}
