import React from "react";
import { Checkbox } from "@mui/material";

import theme from "../../../../../utils/theme";

export default function SeleccionCategoriasCheckbox(props) {
  const { itemKey, estado, categorias, setCategorias } = props;

  const handleChange = (event) => {
    let item = categorias[itemKey];
    item.estado = !item.estado;
    setCategorias({ ...categorias, [itemKey]: item });
  };

  return (
    <Checkbox
      size="small"
      checked={estado}
      onChange={handleChange}
      sx={{
        color: theme.palette.primary.main,
        "&.Mui-checked": { color: theme.palette.main },
      }}
    />
  );
}
