import React from "react";

import { Box, Radio, RadioGroup, FormControlLabel } from "@mui/material";

import PreguntaOpcionInformacionAdicional from "./PreguntaOpcionInformacionAdicional";

import { tipos_respuesta } from "../../../../../pages/ClinicFormPage";

export default function PreguntaOpciones(props) {
  const {
    inhabilitado,
    actualizarRespuesta,
    itemPregunta,
    indexProceso,
    indexAreaSalud,
    indexEspecialidad,
    indexSeccion,
    indexAreaPreguntas,
    indexPregunta,
  } = props;

  const handleOnChange = (event) => {
    const nuevoValor = event.target.value;
    actualizarRespuesta(
      indexProceso,
      indexAreaSalud,
      indexEspecialidad,
      indexSeccion,
      indexAreaPreguntas,
      indexPregunta,
      tipos_respuesta.opciones,
      nuevoValor
    );
  };

  return (
    <>
      <RadioGroup
        onChange={handleOnChange}
        value={itemPregunta.respuestaOpciones}
      >
        {itemPregunta.opciones.map((item, index) => {
          return (
            <Box key={index}>
              <FormControlLabel
                key={index}
                value={item.descripcion}
                control={
                  <Radio
                    size="small"
                    disabled={inhabilitado}
                    sx={{
                      paddingY: 0,
                      // Para que el botón de radio sea lo más pequeño posible
                      "&.MuiSvgIcon-root": {
                        fontSize: "1em",
                      },
                    }}
                  />
                }
                label={item.descripcion}
                componentsProps={{ typography: { fontSize: 12 } }}
              />
              {/* Aquí se debe renderizar el control de entrada para el texto
                adicional dependiendo de si la la opción seleccionada tiene
                información adicional */}
              {/* {item.informacionAdicional && valor === item.descripcion && ( */}
              {item.informacionAdicional &&
                itemPregunta.respuestaOpciones === item.descripcion && (
                  <PreguntaOpcionInformacionAdicional
                    inhabilitado={inhabilitado}
                    actualizarRespuesta={actualizarRespuesta}
                    itemPregunta={itemPregunta}
                    indexProceso={indexProceso}
                    indexAreaSalud={indexAreaSalud}
                    indexEspecialidad={indexEspecialidad}
                    indexSeccion={indexSeccion}
                    indexAreaPreguntas={indexAreaPreguntas}
                    indexPregunta={indexPregunta}
                  />
                )}
            </Box>
          );
        })}
      </RadioGroup>
    </>
  );
}
