import React from "react";

import useGlobalDataContext from "../../../hooks/useGlobalDataContext";

export default function Hexagono(props) {
  const {
    top,
    left,
    viewBoxTop,
    viewBoxLeft,
    viewBoxWidth,
    viewBoxHeight,
    ancho,
    altura,
    handleClick,
    texto,
    id,
    destino,
    proximamente,
    hexagonoActivo,
    hayHexagonoActivo,
    detectarHexagonoActivo,
    zIndex,
    opacidad,
  } = props;

  // Definición de colores
  const fillColorInhabilitado = "#bcdde6";
  const fillColorHabilitado = "#005064";
  const fillColorActivo = fillColorHabilitado; // "#007e97";
  const fillColorActivoHijo = "#407384";

  const fillColorHabilitadoProximamente = "#0096af";
  const textColorHabilitado = "#f2c936";
  const textColorHabilitadoProximamente = "#bcdde6";
  const textColorActivo = textColorHabilitado;
  const textColorActivoHijo = textColorHabilitadoProximamente; // "#ebebeb";

  var fillColor, textColor, cursor;

  // Aplicación de colores
  if (texto === null) {
    fillColor = fillColorInhabilitado;
  } else {
    if (proximamente) {
      fillColor = fillColorHabilitadoProximamente;
      textColor = textColorHabilitadoProximamente;
    } else {
      //if (hexagonoActivo.length > 0) {
      if (hayHexagonoActivo) {
        //if (hexagonoActivo.id === id) {
        if (detectarHexagonoActivo(id)) {
          fillColor = fillColorActivo;
          textColor = textColorActivo;
        } else {
          fillColor = fillColorActivoHijo;
          textColor = textColorActivoHijo;
        }
      } else {
        fillColor = fillColorHabilitado;
        textColor = textColorHabilitado;
      }
    }
  }

  if (destino === null) {
    cursor = "default";
  } else {
    cursor = "pointer";
  }

  const w1 = 239;
  const h1 = 209;

  //const xv0 = 1;
  const xv1 = 5;
  const xv2 = 46;
  const xv3 = 85;
  const xv4 = w1 - xv3;
  const xv5 = w1 - xv2;
  const xv6 = w1 - xv1;
  //const xv7 = w1 - xv0;
  const yh0 = 3;
  const yh1 = 17;
  const yh2 = 81;
  const yh3 = h1 - yh2;
  const xh4 = h1 - yh1;
  const xh5 = h1 - yh0;
  const r1 = 48;
  const r2 = 59;

  const d = `M ${xv3} ${yh0} 
    H ${xv4} 
    A ${r1} ${r1} 0 0 1 ${xv5} ${yh1} 
    L ${xv6} ${yh2} 
    A ${r2} ${r2} 0 0 1 ${xv6} ${yh3} 
    L ${xv5} ${xh4} 
    A ${r1} ${r1} 0 0 1 ${xv4} ${xh5} 
    H ${xv3} 
    A ${r1} ${r1} 0 0 1 ${xv2} ${xh4}
    L ${xv1} ${yh3} 
    A ${r2} ${r2} 0 0 1 ${xv1} ${yh2} 
    L ${xv2} ${yh1} 
    A ${r1} ${r1} 0 0 1 ${xv3} ${yh0}`;

  const localHandleClick = () => {
    handleClick(id, destino);
  };

  const globalData = useGlobalDataContext();

  const textos = {
    proximamente: globalData.terminosLocalizados.ETI194,
  };

  function fraccionarTexto(texto) {
    const lineas = [];
    const maximoCaracteresLinea = 14;
    if (texto !== null && texto !== undefined) {
      let palabras = texto.split(" ");
      let lineaActual = "";
      for (let palabra of palabras) {
        if (lineaActual.length + palabra.length + 1 <= maximoCaracteresLinea) {
          if (lineaActual !== "") {
            lineaActual += " ";
          }
          lineaActual += palabra;
        } else {
          lineas.push({ textoLinea: lineaActual, proximamente: false });
          lineaActual = palabra;
        }
      }
      if (lineaActual !== "") {
        lineas.push({ textoLinea: lineaActual, proximamente: false });
      }
    }
    return lineas;
  }

  let lineasTexto = fraccionarTexto(texto);

  if (proximamente) {
    lineasTexto.push({ textoLinea: textos.proximamente, proximamente: true });
  }

  const xTexto = "118";

  function CalcularPosicionesTexto(lineas) {
    const alturaLineaTexto = 30;
    const centroVertical = 126;
    const alturaBloque = alturaLineaTexto * lineas.length;
    const mediaAlturaBloque = alturaBloque / 2;
    const yPrimero = centroVertical - mediaAlturaBloque;
    for (let i = 0; i < lineas.length; i++) {
      lineas[i].y = (yPrimero + alturaLineaTexto * i).toString();
    }
  }

  if (lineasTexto.length > 0) {
    CalcularPosicionesTexto(lineasTexto);
  }

  const estiloHexagono = {
    position: "absolute",
    top: top,
    left: left,
    opacity: opacidad,
    zIndex: zIndex,
    transition:
      "top .8s ease-in-out, left .8s ease-in-out, opacity .8s ease-in-out",
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${ancho}px`}
      height={altura}
      viewBox={`${viewBoxLeft} ${viewBoxTop} ${viewBoxWidth} ${viewBoxHeight}`}
      style={estiloHexagono}
      cursor={cursor}
      onClick={localHandleClick}
      //onTransitionEnd={id === null ? null : handleTransitionEnd}
    >
      <path stroke="none" strokeWidth={0.5} fill={fillColor} d={d} />
      <text textAnchor="middle" fill={textColor} fontSize={21}>
        {lineasTexto.map((linea, index) => (
          <tspan
            key={index}
            x={xTexto}
            y={linea.y}
            fontStyle={linea.proximamente ? "italic" : "normal"}
            fontWeight={linea.proximamente ? "normal" : "bold"}
          >
            {linea.textoLinea}
          </tspan>
        ))}
      </text>
    </svg>
  );
}
