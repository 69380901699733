import React from "react";

import { useForm, Controller } from "react-hook-form";

import { Card, Typography, TextField, Stack, Button } from "@mui/material";

import theme from "../../../../utils/theme";

import useGlobalDataContext from "../../../../hooks/useGlobalDataContext";

export default function AgregarElemento(props) {
  const { tipo, titulo, close, edicion } = props;

  const { control, handleSubmit } = useForm({});

  const globalData = useGlobalDataContext();

  const cancelar = () => {
    close();
  };

  const agregarLocal = (data) => {
    data.Tipo = tipo;
    data.ParentKey = edicion(data);
  };

  return (
    <Card
      sx={{
        borderStyle: "solid",
        border: 2,
        borderColor: theme.palette.primary.main,
        p: 2,
        width: 400,
      }}
    >
      <form onSubmit={handleSubmit(agregarLocal)}>
        <Stack spacing={1}>
          {/* <Typography variant="h5">Agregar {titulo}</Typography> */}
          <Typography variant="h5">
            {globalData.terminosLocalizados.ETI146} {titulo}
          </Typography>
          <Controller
            name="Descripcion"
            control={control}
            rules={{
              required: {
                value: true,
              },
            }}
            render={({ field }) => (
              <TextField
                type="text"
                size="small"
                name="Descripcion"
                //placeholder="Descripción"
                placeholder={globalData.terminosLocalizados.ETI099}
                // ref={inputReference}
                autoFocus
                {...field}
              />
            )}
          ></Controller>
          <Stack direction={"row"} spacing={1} justifyContent="flex-end">
            <Button variant="CommandBar2" type="submit">
              {/* Agregar */}
              {globalData.terminosLocalizados.COM037}
            </Button>
            <Button variant="CommandBar2" onClick={cancelar}>
              {/* Cancelar */}
              {globalData.terminosLocalizados.COM004}
            </Button>
          </Stack>
        </Stack>
      </form>
    </Card>
  );
}
