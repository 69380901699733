import React from "react";
import { Search } from "@mui/icons-material";
import { Box } from "@mui/material";

import theme from "../../utils/theme";

import useGlobalDataContext from "../../hooks/useGlobalDataContext";

export default function SearchBar() {
  const globalData = useGlobalDataContext();

  return (
    <Box width="100%">
      <Box id="search" width={"100%"} display="flex">
        <Box flexGrow={1}>
          <input
            className="inputSearch"
            type="text"
            id="SearchText"
            placeholder={
              globalData.loadingTerminosLocalizados
                ? ""
                : globalData.terminosLocalizados.ETI030
            }
            width="95%"
          />
        </Box>
        <Search
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            borderRadius: "0 25px 25px 0",
            width: 60,
            height: 30,
          }}
          viewBox="0 0 24 24"
        />
      </Box>
    </Box>
  );
}
