import React, { useState } from "react";

import { TextField } from "@mui/material";

import useGlobalDataContext from "../../../../../hooks/useGlobalDataContext";

import { tipos_respuesta } from "../../../../../pages/ClinicFormPage";

export default function PreguntaOpcionInformacionAdicional(props) {
  const {
    inhabilitado,
    actualizarRespuesta,
    itemPregunta,
    indexProceso,
    indexAreaSalud,
    indexEspecialidad,
    indexSeccion,
    indexAreaPreguntas,
    indexPregunta,
  } = props;

  const [itemState, setItemState] = useState(itemPregunta);

  const globalData = useGlobalDataContext();

  // Se deberían cargar desde el servidor en función del idioma del usuario
  const opcionesEtiquetaInformacionAdicional =
    globalData.terminosLocalizados.ETI129;

  const handleOnBlur = (event) => {
    const nuevoValor = event.target.value;
    setItemState((prevState) => ({
      ...prevState,
      respuestaOpcionesTextoAdicional: nuevoValor,
    }));
    actualizarRespuesta(
      indexProceso,
      indexAreaSalud,
      indexEspecialidad,
      indexSeccion,
      indexAreaPreguntas,
      indexPregunta,
      tipos_respuesta.opcionesInformacionAdicional,
      nuevoValor
    );
  };

  return (
    <TextField
      id="el-text-field"
      multiline
      rows="3"
      label={opcionesEtiquetaInformacionAdicional}
      defaultValue={itemState.respuestaOpcionesTextoAdicional}
      inputProps={{ style: { fontSize: 12, lineHeight: "14px" } }}
      InputProps={{ style: { padding: "8px" } }}
      onBlur={handleOnBlur}
      disabled={inhabilitado}
      sx={{ marginY: "4px" }}
    />
  );
}
