import React, { useState } from "react";

import { Grid, Button, Box } from "@mui/material";

import useGlobalDataContext from "../../../hooks/useGlobalDataContext";

import ModalContainer from "../../Autenticados/ModalContainer";

import CambiarPassword from "./CambiarPassword";

export default function Seguridad() {
  const [isOpenCambiarPassword, setIsOpenCambiarPassword] = useState(false);

  const globalData = useGlobalDataContext();

  console.log(globalData);

  const textos = {
    etiquetaPasswordAnterior: globalData.terminosLocalizados.ETI156,
    etiquetaPasswordNueva: globalData.terminosLocalizados.ETI157,
    etiquetaPasswordNuevaConfirmacion: globalData.terminosLocalizados.ETI158,
    textoEditar: globalData.terminosLocalizados.COM040,
    textoGuardar: globalData.terminosLocalizados.COM041,
    textoCancelar: globalData.terminosLocalizados.COM004,
    textoCambiarPassword: globalData.terminosLocalizados.COM051,
  };

  const handleOpenCambiarPassword = () => {
    setIsOpenCambiarPassword(true);
  };

  const handleCloseCambiarPassword = () => {
    setIsOpenCambiarPassword(false);
  };

  return (
    <Box>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={1}
        maxWidth="700px"
        margin={1}
        width="100%"
      >
        <Grid item md={6} width="100%">
          <Button onClick={handleOpenCambiarPassword} sx={{ width: "100%" }}>
            {textos.textoCambiarPassword}
          </Button>
        </Grid>
      </Grid>
      <ModalContainer
        isOpenModal={isOpenCambiarPassword}
        closeModal={handleCloseCambiarPassword}
      >
        <CambiarPassword close={handleCloseCambiarPassword}></CambiarPassword>
      </ModalContainer>
    </Box>
  );
}
