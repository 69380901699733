import React, { useRef } from "react";

import { Box, Stack, Grid, Button, Slide } from "@mui/material";

import CabeceraFormulario from "./CabeceraFormulario";
import Preguntas from "./Preguntas/Preguntas";
import MuestraPaciente from "./MuestraPaciente";
import OtrasEntradas from "./OtrasEntradas";
import Loading from "../../../Feedback/Loading";
import Logo_Beemedics from "../../../../assets/img/Logo_BeeMedics_TRN.png";

import useGlobalDataContext from "../../../../hooks/useGlobalDataContext";

import theme from "../../../../utils/theme";

export const acciones_respuesta = {
  fecha: "FECHA",
  multimedia: "MULTIMEDIA",
  opciones: "OPCIONES",
  opcionesInformacionAdicional: "OPCIONES_INFORMACION_ADICIONAL",
  textoPleno: "TEXTO_PLENO",
  unidadMedida: "UNIDAD_MEDIDA",
  unidadMedidaValoracion: "UNIDAD_MEDIDA_VALORACION",
  compartido: "COMPARTIDO",
  prescripcion: "PRESCRIPCION",
  prescripcionTest: "PRESCRIPCION_TEST",
  notasProfesional: "NOTAS_PROFESIONAL",
  archivosAdjuntos: "ARCHIVOS_ADJUNTOS",
  usuariosEtiquetados: "USUARIOS_ETIQUETADOS",
  compartidoPrescripcion: "COMPARTIDO_PRESCRIPCION",
  compartidoPrescripcionTest: "COMPARTIDO_PRESCRIPCION_TEST",
  compartidoNotasProfesional: "COMPARTIDO_NOTAS_PROFESIONAL",
  compartidoArchivosAdjuntos: "COMPARTIDO_ARCHIVOS_ADJUNTOS",
};

export const uso_barra_titulo = {
  prescripcion: acciones_respuesta.compartidoPrescripcion,
  prescripcionTest: acciones_respuesta.compartidoPrescripcionTest,
  notasProfesional: acciones_respuesta.compartidoNotasProfesional,
  archivosAdjuntos: acciones_respuesta.compartidoArchivosAdjuntos,
  usuariosEtiquetados: acciones_respuesta.usuariosEtiquetados,
  pregunta: "PREGUNTA",
};

export default function Formulario(props) {
  const {
    paciente,
    publicar,
    publicando,
    setPublicando,
    downMD,
    visibilidadElementos,
    slideTimeout,
    slideDirection,
    formularioClinico,
    setFormularioClinico,
    loading,
    actualizarRespuesta,
    actualizarOtrasEntradas,
  } = props;

  const containerRef = useRef(null);

  const globalData = useGlobalDataContext();

  const tituloFormulario = globalData.terminosLocalizados.ETI091;
  const raizTituloPublicacion = globalData.terminosLocalizados.ETI092;
  const preposicionTituloPublicacion = globalData.terminosLocalizados.OCN004;
  var tituloPublicacion = raizTituloPublicacion;
  if (paciente.estado.cargado) {
    tituloPublicacion = `${raizTituloPublicacion} ${preposicionTituloPublicacion} ${paciente.nombre}`;
  }

  const textoComandoPublicar = globalData.terminosLocalizados.COM036;

  const handlePublicar = () => {
    setPublicando(true);
    publicar();
  };

  if (
    !visibilidadElementos.formulario &&
    !visibilidadElementos.prescripciones &&
    !visibilidadElementos.muestraPaciente &&
    downMD
  ) {
    return null;
  }

  //console.log(formularioClinico);

  return (
    <Stack
      id="stackFormularioClinico"
      spacing={1}
      display={"flex"}
      sx={{
        height: "100%",
      }}
      ref={containerRef}
    >
      {/* Ingreso de valores */}
      {(visibilidadElementos.formulario ||
        visibilidadElementos.prescripciones ||
        !downMD) && (
        <Box
          id="ingresoContenedor"
          display="flex"
          flexDirection={"column"}
          overflow={"hidden"}
          sx={{
            [theme.breakpoints.down("sm")]: { height: "auto" },
            [theme.breakpoints.down("md")]: { height: "100%" },
          }}
          // Es necesario establecer también flex-srink y flex-basic.
          // Se recomienda utilizar esta versión corta.
          flex="1 1 0"
        >
          <Box width={"100%"} id="cabecera">
            <CabeceraFormulario titulo={tituloFormulario}>
              {/* Aquí se debería embeber el control para editar el formulario clínico */}
            </CabeceraFormulario>
          </Box>
          <Grid
            container
            id="ingresoContenido"
            height="inherit"
            flexGrow="1"
            display="flex"
            paddingY={0.5}
            overflow="auto"
            paddingBottom={0}
          >
            {(visibilidadElementos.formulario || !downMD) && (
              <Slide
                in={visibilidadElementos.formulario || !downMD}
                timeout={slideTimeout}
                container={containerRef.current}
                direction={slideDirection}
              >
                <Grid
                  item
                  id="preguntasContenedor"
                  xs={12}
                  md={6}
                  height="100%"
                >
                  {loading ? (
                    <Box
                      id="loading"
                      height={"100%"}
                      display="flex"
                      alignItems={"center"}
                      justifyContent="center"
                    >
                      <Loading />
                    </Box>
                  ) : (
                    <Preguntas
                      id="preguntas"
                      formularioClinico={formularioClinico}
                      inhabilitado={!paciente.estado.cargado}
                      actualizarRespuesta={actualizarRespuesta}
                    />
                  )}
                </Grid>
              </Slide>
            )}
            {(visibilidadElementos.prescripciones || !downMD) && (
              <Slide
                in={visibilidadElementos.prescripciones || !downMD}
                timeout={slideTimeout}
                container={containerRef.current}
                direction={slideDirection}
              >
                <Grid item id="prescripciones" xs={12} md={6} maxHeight="100%">
                  <OtrasEntradas
                    id="otrasEntradas"
                    inhabilitado={!paciente.estado.cargado}
                    formularioClinico={formularioClinico}
                    setFormularioClinico={setFormularioClinico}
                    actualizarOtrasEntradas={actualizarOtrasEntradas}
                  ></OtrasEntradas>
                </Grid>
              </Slide>
            )}
          </Grid>
        </Box>
      )}
      {(visibilidadElementos.muestraPaciente || !downMD) && (
        <Slide
          in={visibilidadElementos.muestraPaciente || !downMD}
          timeout={slideTimeout}
          container={containerRef.current}
          direction={slideDirection}
        >
          <Box
            id="muestraContenedor"
            display="flex"
            flexDirection={"column"}
            overflow={"hidden"}
            sx={{
              [theme.breakpoints.down("sm")]: { height: "auto" },
              [theme.breakpoints.down("md")]: { height: "100%" },
            }}
            // Es necesario establecer también flex-srink y flex-basic.
            // Se recomienda utilizar esta versión corta.
            flex="1 1 0"
          >
            <CabeceraFormulario id="cabecera" titulo={tituloPublicacion}>
              {/* Aquí se debería embeber el control para marcar la publicación como "vital" */}
            </CabeceraFormulario>
            <Grid
              container
              id="muestraContenido"
              height="inherit"
              flexGrow={1}
              display="flex"
              overflow={"auto"}
            >
              <Grid item id="preguntasContenedor" xs={12} height="100%">
                <MuestraPaciente
                  id="muestra"
                  formularioClinico={formularioClinico}
                />
              </Grid>
            </Grid>
          </Box>
        </Slide>
      )}
      <Box
        id="footBar"
        flexGrow={0}
        flexShrink={1}
        flexBasis={0}
        height="44px"
        display="flex"
        justifyContent={"space-between"}
        alignItems="center"
      >
        <Box height="100%">
          <Box
            id="logoContainer"
            component={"img"}
            src={Logo_Beemedics}
            alt="BeeMedics"
            height={"100%"}
          ></Box>
        </Box>
        <Stack direction={"row"} spacing={1}>
          {publicando && (
            <Box display={"flex"} justifyContent="center">
              <Loading size={25} />
            </Box>
          )}
          <Button disabled={!paciente.estado.cargado} onClick={handlePublicar}>
            {textoComandoPublicar}
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
}
