import React from "react";
import { useForm, Controller } from "react-hook-form";

import { TextField, Card, Typography, Stack, Button } from "@mui/material";

import useAuth from "../../../auth/useAuth";
import ErrorSummary from "../../Forms/ErrorSummary";
import useGlobalDataContext from "../../../hooks/useGlobalDataContext";

import { API_URL } from "../../../utils/constants";
import useToastContext from "../../../hooks/useToastContext";
import { Severities } from "../../../contexts/ToastContext";

export default function CambiarPassword(props) {
  const { close } = props;

  const auth = useAuth();
  const addToast = useToastContext();

  const globalData = useGlobalDataContext();

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({});

  // En esta estructura se tienen los nombres de los campos en el idioma del usuario
  // Se recibirán del servidor cuando esté implementado el multi-lenguaje.
  const descripcionCampos = {
    PasswordAnterior: globalData.terminosLocalizados.ETI156,
    PasswordNueva: globalData.terminosLocalizados.ETI157,
    PasswordNuevaConfirmar: globalData.terminosLocalizados.ETI158,
  };

  const cancelar = () => {
    close();
  };

  const cambiarPassword = async (data) => {
    const request = {
      Token: auth.user.token,
      PasswordAnterior: data.PasswordAnterior,
      PasswordNueva: data.PasswordNueva,
    };
    const url = `${API_URL()}/users/changePassword`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(request),
    };
    try {
      const res = await fetch(url, options);
      if (res.ok) {
        const json = await res.json();
        if (json.resultadoOK) {
          addToast(globalData.terminosLocalizados.AVI034, Severities.info);
          close();
        } else {
          json.errores.forEach((item) => {
            addToast(item, Severities.error);
          });
        }
      } else {
        addToast(globalData.terminosLocalizados.AVI004, Severities.error);
      }
    } catch (err) {
      addToast(globalData.terminosLocalizados.AVI005, Severities.error);
    }
  };

  return (
    <Card
      sx={{
        p: 2,
        width: 400,
        borderRadius: "10px",
      }}
    >
      <form onSubmit={handleSubmit(cambiarPassword)}>
        <Stack spacing={1}>
          <Typography variant="h5">
            {globalData.terminosLocalizados.COM051}
          </Typography>
          <Controller
            name="PasswordAnterior"
            control={control}
            rules={{
              required: {
                value: true,
              },
              maxLength: {
                value: 40,
              },
            }}
            render={({ field }) => (
              <TextField
                type="password"
                size="small"
                name="Usuario"
                label={descripcionCampos.PasswordAnterior}
                {...field}
              />
            )}
          ></Controller>
          <Controller
            name="PasswordNueva"
            control={control}
            rules={{
              required: {
                value: true,
              },
              maxLength: {
                value: 40,
              },
            }}
            render={({ field }) => (
              <TextField
                type="password"
                size="small"
                name="PasswordNueva"
                label={descripcionCampos.PasswordNueva}
                {...field}
              />
            )}
          ></Controller>
          <Controller
            name="PasswordNuevaConfirmar"
            control={control}
            rules={{
              required: {
                value: true,
              },
              maxLength: {
                value: 40,
              },
              // Esto es equivalente a una función que devuelve "true" si sin iguales
              // o devuelve el texto si no son iguales.
              validate: (value) =>
                value === getValues("PasswordNueva") ||
                globalData.terminosLocalizados.AVI035,
            }}
            render={({ field }) => (
              <TextField
                type="password"
                size="small"
                name="PasswordNuevaConfirmar"
                label={descripcionCampos.PasswordNuevaConfirmar}
                {...field}
              />
            )}
          ></Controller>
          <Stack direction={"row"} spacing={1} justifyContent="flex-end">
            <Button variant="CommandBar2" type="submit">
              {globalData.terminosLocalizados.COM003}
            </Button>
            <Button variant="CommandBar2" onClick={cancelar}>
              {globalData.terminosLocalizados.COM004}
            </Button>
          </Stack>
          <ErrorSummary
            errors={errors}
            descripciones={descripcionCampos}
          ></ErrorSummary>
        </Stack>
      </form>
    </Card>
  );
}
