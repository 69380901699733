import React, { useState, useEffect } from "react";

import { Box, Button } from "@mui/material";

import useGlobalDataContext from "../../../../../hooks/useGlobalDataContext";
import uuid from "react-uuid";

import { tipos_respuesta } from "../../../../../pages/ClinicFormPage";

export default function PreguntaMultimedia(props) {
  const {
    inhabilitado,
    actualizarRespuesta,
    itemPregunta,
    indexProceso,
    indexAreaSalud,
    indexEspecialidad,
    indexSeccion,
    indexAreaPreguntas,
    indexPregunta,
  } = props;

  const [itemState, setItemState] = useState(itemPregunta);

  useEffect(() => {
    setItemState(itemPregunta);
  }, [itemPregunta]);

  const handleOnChange = (event) => {
    if (event.target.files.lenght === 0) {
      return;
    }
    const archivoSeleccionado = event.target.files[0];

    const nuevoValor = {
      guid: uuid(),
      archivo: archivoSeleccionado,
      preview: URL.createObjectURL(archivoSeleccionado),
      estado: { cargado: false },
    };

    setItemState((prevState) => ({
      ...prevState,
      respuestaMultimedia: nuevoValor,
    }));

    actualizarRespuesta(
      indexProceso,
      indexAreaSalud,
      indexEspecialidad,
      indexSeccion,
      indexAreaPreguntas,
      indexPregunta,
      tipos_respuesta.multimedia,
      nuevoValor
    );
  };

  const handleClickPreview = (event) => {
    // Las dos líneas a continuación son para evitar que después de ejecutar
    // el código del evento onClick en el preview se ejecute el código del evento
    // onCange del botón de selección.
    // Por ahora se omite, porque no se está utilizando un visor del archivo multimedia.
    // event.preventDefault();
    // event.stopPropagation();
    // Aquí se debería ejecutar el código para abrir un visor del archivo multimedia.
    // Por ahora se omite, porque el visor que tenemos está diseñado para
    // los archivos adjuntos de la timeline.
  };

  const globalData = useGlobalDataContext();

  return (
    <Box>
      <Button
        variant="contained"
        component="label"
        disabled={inhabilitado}
        sx={{
          width: "100%",
          fontSize: "12px",
          //opacity: "0.5",
          textTransform: "none",
          "& .MuiButtonBase-root": {
            display: "flex",
          },
        }}
      >
        {/* Seleccionar archivo multimedia */}
        {itemState.respuestaMultimedia !== null &&
          itemState.respuestaMultimedia.archivo !== null && (
            <Box
              id="boxImagen"
              height={"30px"}
              // display="flex"
              // justifyContent={"space-around"}
            >
              <img
                height={"100%"}
                src={itemState.respuestaMultimedia.preview}
                alt="preview"
                onClick={handleClickPreview}
              />
            </Box>
          )}
        <Box id="boxTexto" flexGrow={1} textAlign={"center"}>
          {globalData.terminosLocalizados.COM048}
        </Box>
        <input type="file" hidden onChange={handleOnChange} />
      </Button>
    </Box>
  );
}
