import React, { useCallback, useState } from "react";

import { Typography } from "@mui/material";

import BoxPregunta from "./BoxPregunta";

import { API_URL } from "../../../../utils/constants";

import useAuth from "../../../../auth/useAuth";
import useToastContext from "../../../../hooks/useToastContext";
import { Severities } from "../../../../contexts/ToastContext";
import useGlobalDataContext from "../../../../hooks/useGlobalDataContext";

import { const_TipoOrdenableFormularioClinico } from "../../../../utils/constants";

export default function BoxAreaPreguntas(props) {
  const {
    itemAreasPreguntas,
    indexAreasPreguntas,
    indexUltimo,
    guid,
    handleQuitarPregunta,
    handleSubirPregunta,
    handleBajarPregunta,
    areasPreguntasHorizontal,
  } = props;

  // const sxIconBox = {
  //   width: 36,
  //   minWidth: 36,
  // };

  const [visible, setVisible] = useState(itemAreasPreguntas.visible);

  const auth = useAuth();
  const addToast = useToastContext();
  const globalData = useGlobalDataContext();

  // Cambiar la visibilidad de un área de preguntas en el formulario clínico
  const cambiarVisibilidad = useCallback(
    async (guid, visible) => {
      const url = `${API_URL()}/EditarFormularioClinico/setAreaPreguntasVisibilidad`;
      let datos = {
        Token: auth.user.token,
        GuidAreaPreguntas: guid,
        Visible: !visible,
      };
      try {
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(datos),
        };
        const res = await fetch(url, options);
        if (res.ok) {
          const json = await res.json();
          if (json.resultadoOK) {
            setVisible(json.visible);
          } else {
            json.errores.forEach((item) => {
              addToast(item, Severities.error);
            });
          }
        } else {
          //addToast("Error de servicio.", Severities.error);
          addToast(globalData.terminosLocalizados.AVI004, Severities.error);
        }
      } catch (err) {
        //addToast("Error de conexión", Severities.error);
        addToast(globalData.terminosLocalizados.AVI005, Severities.error);
      }
    },
    [
      addToast,
      auth.user.token,
      globalData.terminosLocalizados.AVI004,
      globalData.terminosLocalizados.AVI005,
    ]
  );

  return (
    <BoxPregunta
      guid={guid}
      tipo={const_TipoOrdenableFormularioClinico.AREA_PREGUNTAS}
      subible={indexAreasPreguntas !== 0}
      bajable={indexAreasPreguntas < indexUltimo}
      ocultable={true}
      handleQuitarPregunta={handleQuitarPregunta}
      handleSubirPregunta={handleSubirPregunta}
      handleBajarPregunta={handleBajarPregunta}
      cambiarVisibilidad={cambiarVisibilidad}
      areasPreguntasHorizontal={areasPreguntasHorizontal}
      visible={visible}
    >
      <Typography sx={{ fontWeight: "bold" }}>
        {itemAreasPreguntas.descripcion}
      </Typography>
    </BoxPregunta>
  );
}
