import React from "react";

import Header from "../components/Autenticados/Header";
import EditClinicFormPage from "./EditClinicFormPage";
import ClinicFormPage from "./ClinicFormPage";
import DashboardPage from "./DashboardPage";
import ArchivoPage from "./ArchivoPage";
import MyAccountPage from "./MyAccountPage";
import NotFoundPage from "./NotFoundPage";

import TestHexagonos from "./TestHexagonos";

import { const_Paginas } from "../utils/constants";

export default function ContentPage() {
  const [currentPage, setCurrentPage] = React.useState(const_Paginas.DASHBOARD);
  return (
    <>
      <Header setCurrentPage={setCurrentPage} />
      <Content currentPage={currentPage} setCurrentPage={setCurrentPage} />
    </>
  );
}

const Content = (props) => {
  const { currentPage, setCurrentPage } = props;
  //console.log(`Content - currentPage=${currentPage}`);
  switch (currentPage) {
    case const_Paginas.EDIT_CLINIC_FORM:
      return <EditClinicFormPage />;
    case const_Paginas.CLINIC_FORM:
      return <ClinicFormPage />;
    case const_Paginas.DASHBOARD:
      return <DashboardPage setCurrentPage={setCurrentPage} />;
    case const_Paginas.ARCHIVO:
      return <ArchivoPage />;
    case const_Paginas.MY_ACCOUNT:
      return <MyAccountPage />;
    // case const_Paginas.TEST_HEXAGONOS:
    //   return <TestHexagonos />;
    default:
      return <NotFoundPage />;
  }
};
