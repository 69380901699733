import React from "react";

import { Typography, TextField, Box, Stack } from "@mui/material";

import BoxPregunta from "./BoxPregunta";

import {
  const_TipoOrdenableFormularioClinico,
  const_TipoFactorValoracion,
} from "../../../../utils/constants";

import useGlobalDataContext from "../../../../hooks/useGlobalDataContext";

export default function EdicionUnidadMedida(props) {
  const {
    itemPreguntas,
    handleQuitarPregunta,
    handleSubirPregunta,
    handleBajarPregunta,
  } = props;

  const globalData = useGlobalDataContext();

  // Obtener desde el servidor según el idioma del usuario
  const detalleFactorValoracion = {
    Menor: globalData.terminosLocalizados.VAL001,
    MenorIgual: globalData.terminosLocalizados.VAL002,
    Igual: globalData.terminosLocalizados.VAL003,
    MayorIgual: globalData.terminosLocalizados.VAL004,
    Mayor: globalData.terminosLocalizados.VAL005,
    Rango: globalData.terminosLocalizados.VAL006,
    RangoConjuncion: globalData.terminosLocalizados.OCN001,
  };

  return (
    <Box>
      <BoxPregunta
        guid={itemPreguntas.guid}
        tipo={const_TipoOrdenableFormularioClinico.UNIDAD_MEDIDA}
        suprimible
        handleQuitarPregunta={handleQuitarPregunta}
        handleSubirPregunta={handleSubirPregunta}
        handleBajarPregunta={handleBajarPregunta}
      >
        <TextField
          type="text"
          name={itemPreguntas.descripcion}
          placeholder={itemPreguntas.descripcion}
          sx={{
            width: "50%",
            "& .MuiInputBase-input": {
              paddingY: 0.2,
              paddingX: 1,
            },
          }}
        ></TextField>
        <Typography sx={{ marginLeft: 1 }}>
          {itemPreguntas.unidadMedida}
        </Typography>
      </BoxPregunta>
      <Box>
        {itemPreguntas.valoraciones.map((itemValoracion, indexValoracion) => {
          return (
            <BoxPregunta
              key={indexValoracion}
              guid={itemValoracion.guid}
              tipo={const_TipoOrdenableFormularioClinico.VALORACION}
              suprimible
              handleQuitarPregunta={handleQuitarPregunta}
            >
              <RenderValoracion
                itemValoracion={itemValoracion}
                detalleFactorValoracion={detalleFactorValoracion}
              />
            </BoxPregunta>
          );
        })}
      </Box>
    </Box>
  );
}

function RenderValoracion(props) {
  const { itemValoracion, detalleFactorValoracion } = props;
  let textoValoracion = "";
  switch (itemValoracion.codigoComparacion) {
    case const_TipoFactorValoracion.MENOR:
      textoValoracion = `${detalleFactorValoracion.Menor} ${itemValoracion.valorReferencia}`;
      return (
        <RenderItemValoracion
          texto={itemValoracion.valoracion}
          valoracion={textoValoracion}
        />
      );
    case const_TipoFactorValoracion.MENOR_IGUAL:
      textoValoracion = `${detalleFactorValoracion.MenorIgual} ${itemValoracion.valorReferencia}`;
      return (
        <RenderItemValoracion
          texto={itemValoracion.valoracion}
          valoracion={textoValoracion}
        />
      );
    case const_TipoFactorValoracion.IGUAL:
      textoValoracion = `${detalleFactorValoracion.Igual} ${itemValoracion.valorReferencia}`;
      return (
        <RenderItemValoracion
          texto={itemValoracion.valoracion}
          valoracion={textoValoracion}
        />
      );
    case const_TipoFactorValoracion.MAYOR_IGUAL:
      textoValoracion = `${detalleFactorValoracion.MayorIgual} ${itemValoracion.valorReferencia}`;
      return (
        <RenderItemValoracion
          texto={itemValoracion.valoracion}
          valoracion={textoValoracion}
        />
      );
    case const_TipoFactorValoracion.MAYOR:
      textoValoracion = `${detalleFactorValoracion.Mayor} ${itemValoracion.valorReferencia}`;
      return (
        <RenderItemValoracion
          texto={itemValoracion.valoracion}
          valoracion={textoValoracion}
        />
      );
    case const_TipoFactorValoracion.RANGO:
      textoValoracion = `${detalleFactorValoracion.Rango} ${itemValoracion.valorReferencia} ${detalleFactorValoracion.RangoConjuncion} ${itemValoracion.valorReferenciaSuperior}`;
      return (
        <RenderItemValoracion
          texto={itemValoracion.valoracion}
          valoracion={textoValoracion}
        />
      );
    default:
      return null;
  }
}

function RenderItemValoracion(props) {
  const { texto, valoracion } = props;
  return (
    <Stack direction="row" spacing={3} width="100%" paddingLeft={2}>
      <Box width={"50px"}>
        <Typography fontSize={12}>{texto}:</Typography>
      </Box>
      <Box>
        <Typography fontSize={12}>{valoracion}</Typography>
      </Box>
    </Stack>
  );
}
