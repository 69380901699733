import React from "react";
import { Box, Typography } from "@mui/material";

import theme from "../../../../../utils/theme";
import useGlobalDataContext from "../../../../../hooks/useGlobalDataContext";

export default function ClaveColores() {
  const codigosColores = {
    comercios: { descripcion: "", color: theme.palette.timeline.comercios },
    resultados: { descripcion: "", color: theme.palette.timeline.resultados },
    apuntesProfesional: {
      descripcion: "",
      color: theme.palette.timeline.apuntesProfesional,
    },
    prescripciones: {
      descripcion: "",
      color: theme.palette.timeline.prescripciones,
    },
    prescripcionesTest: {
      descripcion: "",
      color: theme.palette.timeline.prescripcionesTest,
    },
    notasProfesional: {
      descripcion: "",
      color: theme.palette.timeline.notasProfesional,
    },
    notasPaciente: {
      descripcion: "",
      color: theme.palette.timeline.notasPaciente,
    },
    portatiles: {
      descripcion: "",
      color: theme.palette.timeline.portatiles,
    },
    registrosAntiguos: {
      descripcion: "",
      color: theme.palette.timeline.registrosAntiguos,
    },
  };

  const globalData = useGlobalDataContext();

  // Se debería obtener desde el servidor en función del idioma del usuario
  //const titulo = "Código de colores";
  const titulo = globalData.terminosLocalizados.ETI090;

  // Las descripciones de las códigos de colores se deberían obtener desde el servidor
  codigosColores.comercios.descripcion = globalData.terminosLocalizados.ETI136;
  codigosColores.resultados.descripcion = globalData.terminosLocalizados.ETI137;
  codigosColores.apuntesProfesional.descripcion =
    globalData.terminosLocalizados.ETI138;
  codigosColores.prescripciones.descripcion =
    globalData.terminosLocalizados.ETI139;
  codigosColores.prescripcionesTest.descripcion =
    globalData.terminosLocalizados.ETI154;
  codigosColores.notasProfesional.descripcion =
    globalData.terminosLocalizados.ETI140;
  codigosColores.notasPaciente.descripcion =
    globalData.terminosLocalizados.ETI141;
  codigosColores.portatiles.descripcion = globalData.terminosLocalizados.ETI142;
  codigosColores.registrosAntiguos.descripcion =
    globalData.terminosLocalizados.ETI170;

  return (
    <Box
      padding={1}
      borderRadius={2}
      width={"100%"}
      bgcolor={theme.palette.terciary.main}
    >
      <Typography
        sx={{ fontSize: 12, fontWeight: "bold", textAlign: "center" }}
      >
        {titulo}
      </Typography>
      <Box marginTop={1.2}>
        {Object.values(codigosColores).map((item, index) => {
          return (
            <Box key={index} height={20} display={"flex"}>
              <Box backgroundColor={item.color} width={15} height={15}></Box>
              <Box marginLeft={1.5}>
                <Typography fontSize={12}>{item.descripcion}</Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
