import React from "react";

import {
  Card,
  Typography,
  Stack,
  Button,
  Box,
  Divider,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import uuid from "react-uuid";

import Interrogacion from "../../../../assets/img/Interrogacion.png";

import theme from "../../../../utils/theme";

import useGlobalDataContext from "../../../../hooks/useGlobalDataContext";

export default function AdjuntarArchivos(props) {
  const { close, formularioClinico, setFormularioClinico } = props;

  const globalData = useGlobalDataContext();

  // Deberían traerse desde el servidor en función del idioma del usuario
  // const textoComandoSeleccionar = "Seleccionar Archivo";
  // const textoComandoSubir = "Subir Archivos";
  const textoComandoSeleccionar = globalData.terminosLocalizados.COM052;
  //const textoComandoAdjuntar = globalData.terminosLocalizados.ETI149;
  const textoComandoLimpiar = globalData.terminosLocalizados.COM014;
  const textoComandoCerrar = globalData.terminosLocalizados.COM011;

  const handleSeleccionar = (event) => {
    setFormularioClinico((previous) => ({
      ...previous,
      archivosAdjuntos: {
        ...previous.archivosAdjuntos,
        archivos: [
          ...previous.archivosAdjuntos.archivos,
          ...Array.from(event.target.files).map((file) => ({
            archivo: file,
            guid: uuid(),
          })),
        ],
      },
    }));
  };

  const handleLimpiar = () => {
    setFormularioClinico((previous) => ({
      ...previous,
      archivosAdjuntos: {
        ...previous.archivosAdjuntos,
        archivos: [],
      },
    }));
  };

  const handleCerrar = () => {
    close();
  };

  const handleBorrar = (index) => {
    console.log(index);
    setFormularioClinico((previous) => ({
      ...previous,
      archivosAdjuntos: {
        ...previous.archivosAdjuntos,
        archivos: previous.archivosAdjuntos.archivos.filter(
          (item, itemIndex) => itemIndex !== index
        ),
      },
    }));
  };

  return (
    <Card sx={{ p: 2, width: 700, borderRadius: "10px" }}>
      <Stack spacing={2}>
        <Box>
          <Typography variant="h6">
            {globalData.terminosLocalizados.ETI149}
          </Typography>
        </Box>
        <Divider sx={{ marginBlockStart: 0 }} />
        <Box
          display={"flex"}
          flexDirection="row"
          justifyContent={"space-between"}
        >
          <Button
            variant="CommandBar4"
            component="label"
            sx={{
              bgcolor: theme.palette.secondary.main,
              color: theme.palette.secondary.contrastText,
              ":hover": { bgcolor: theme.palette.secondary.dark },
              ":focus": { bgcolor: theme.palette.secondary.dark },
            }}
          >
            {textoComandoSeleccionar}
            <input type="file" multiple hidden onChange={handleSeleccionar} />
          </Button>
          <Button variant="CommandBar4" onClick={handleLimpiar}>
            {textoComandoLimpiar}
          </Button>
          <Button variant="CommandBar4" onClick={handleCerrar}>
            {textoComandoCerrar}
          </Button>
        </Box>
        <ImageList
          sx={{
            width: "100%",
            height: 400,
          }}
          cols={3}
        >
          {formularioClinico.archivosAdjuntos.archivos.map((item, index) => {
            let isImageFile = item.archivo.type.split("/")[0] === "image";
            return (
              <ImageListItem key={index} cols={1}>
                {isImageFile && (
                  <img
                    src={URL.createObjectURL(item.archivo)}
                    alt={`file preview ${index}`}
                    loading="lazy"
                  />
                )}
                {!isImageFile && (
                  <img
                    src={Interrogacion}
                    alt={`file preview ${index}`}
                    loading="lazy"
                  />
                )}
                <ImageListItemBar
                  title={item.name}
                  actionIcon={
                    <IconButton
                      sx={{ color: "rgba(255,255,255,1)" }}
                      onClick={() => handleBorrar(index)}
                    >
                      <CloseIcon />
                    </IconButton>
                  }
                ></ImageListItemBar>
              </ImageListItem>
            );
          })}
        </ImageList>
      </Stack>
    </Card>
  );
}
