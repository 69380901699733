import React from "react";
import { Modal } from "@mui/material";

export default function ModalContainer(props) {
  const { isOpenModal, closeModal, children } = props;

  return (
    <Modal
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      aria-labelledby="transtition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpenModal}
      onClose={closeModal}
      closeAfterTransition
      //hideBackdrop={hideBackdrop}
    >
      {children}
    </Modal>
  );
}
