import React, { useState, useCallback } from "react";

import {
  Modal,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
} from "@mui/material";

import { styled } from "@mui/material/styles";

import useAuth from "../../auth/useAuth";
import useGlobalDataContext from "../../hooks/useGlobalDataContext";
import useToastContext from "../../hooks/useToastContext";
import { Severities } from "../../contexts/ToastContext";

import { const_TipoCuentaAdicional } from "../../utils/constants";

import ModalContainer from "./ModalContainer";
import AgregarCuentaAdicional from "./AgregarCuentaAdicional";

import theme from "../../utils/theme";
import { API_URL } from "../../utils/constants";

export default function SeleccionarCuenta(props) {
  const { openCuentas, handleCloseCuentas } = props;

  const [cuentasSelectedIndex, setCuentasSelectedIndex] = useState(0);
  const [isOpenAddAccount, setIsOpenAddAccount] = useState(false);

  const auth = useAuth();
  const addToast = useToastContext();
  const globalData = useGlobalDataContext();

  // Como es de los primeros componentes que se renderizan después de que el usuario se autentica
  // sucede que a veces se llega a este componente y aún no se terminaron de cargar los términos
  // localizados. Para evitar errores, los que se utilizan en este componente se inicializan
  // en blanco y se cargan los valores definitivos mediante un efecto que se dispara
  // cuanto los términos localizados terminaron de cargarse.
  var textos = React.useMemo(
    () => ({
      cuentaAgregada: "",
      cuentaProfesional: "",
      cuentaInstitucion: "",
      cuentaGeneral: "",
      AVI004: "",
      AVI005: "",
    }),
    []
  );

  React.useEffect(() => {
    if (!globalData.loadingTerminosLocalizados) {
      textos.cuentaAgregada = globalData.terminosLocalizados.AVI038;
      textos.cuentaProfesional = globalData.terminosLocalizados.ETI185;
      textos.cuentaInstitucion = globalData.terminosLocalizados.ETI186;
      textos.cuentaGeneral = globalData.terminosLocalizados.ETI187;
      textos.AVI004 = globalData.terminosLocalizados.AVI004;
      textos.AVI005 = globalData.terminosLocalizados.AVI005;
    }
  }, [globalData, textos]);

  // Función para seleccionar una cuenta
  const handleCuentasClick = (event, index) => {
    setCuentasSelectedIndex(index);
    auth.activarCuenta(index);
    handleCloseCuentas();
  };

  // Función para abrir la lista de cuentas
  const openAddAccount = (event) => {
    setIsOpenAddAccount(true);
    handleCloseCuentas();
  };

  const closeAddAccount = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    setIsOpenAddAccount(false);
  };

  const agregarCuentaAdicional = useCallback(
    async (formValue) => {
      // Aquí se debería preguntar si hay datos o validar
      //if (!datos) return;
      //event.preventDefault();
      let url = "";
      let datos = "";
      switch (formValue.TipoCuenta) {
        case const_TipoCuentaAdicional.PROFESIONAL:
          url = `${API_URL()}/Users/addCuentaAdicionalProfesional`;
          datos = {
            Token: auth.user.token,
            Profesion: formValue.Profesion,
            Nombre: formValue.Nombre,
            Apellido: formValue.Apellido,
            Email: formValue.Email,
            Telefono: formValue.Telefono,
            CodigoPaisDocumentoIdentidad:
              formValue.CodigoPaisDocumentoProfesional,
            TipoDocumentoIdentidad: formValue.TipoDocumentoProfesional,
            NumeroDocumentoIdentidad: formValue.NumeroDocumentoProfesional,
            LugarTrabajo: formValue.LugarTrabajo,
          };
          break;
        case const_TipoCuentaAdicional.INSTITUCION:
          url = `${API_URL()}/Users/addCuentaAdicionalInstitucion`;
          datos = {
            Token: auth.user.token,
            Nombre: formValue.Nombre,
            Email: formValue.Email,
            Telefono: formValue.Telefono,
            CodigoPais: formValue.CodigoPaisInstitucion,
            Provincia: formValue.Provincia,
            Ciudad: formValue.Ciudad,
            Direccion: formValue.Direccion,
            PaginaWeb: formValue.PaginaWeb,
          };
          break;
        default:
          alert(formValue.TipoCuenta);
      }
      try {
        datos.Token = auth.user.token;
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(datos),
        };
        const res = await fetch(url, options);
        if (res.ok) {
          const json = await res.json();
          if (json.resultadoOK) {
            auth.setAdditionalAccounts(json.cuentasAdicionales);
            addToast(textos.cuentaAgregada, Severities.info);
            closeAddAccount();
          } else {
            json.errores.forEach((item) => {
              addToast(item, Severities.error);
            });
          }
        } else {
          addToast(textos.AVI004, Severities.error);
        }
      } catch (err) {
        addToast(textos.AVI005, Severities.error);
      }
    },
    [auth, addToast, textos.cuentaAgregada, textos.AVI004, textos.AVI005]
  );

  if (globalData.loadingTerminosLocalizados) {
    return null;
  }

  return (
    <>
      <Modal
        hideBackdrop
        open={openCuentas}
        onClose={handleCloseCuentas}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <>
          <List
            variant="menu"
            sx={{
              position: "absolute",
              top: "50px",
              right: "140px",
              width: "300px",
              boxShadow: 24,
              pt: 1,
              px: 1,
              pb: 1,
            }}
          >
            {auth.user.cuentasAdicionales.lista.map((item, index) => {
              let saludo = "";
              if (!globalData.loadingTerminosLocalizados) {
                if (
                  item.codigoCategoria === const_TipoCuentaAdicional.PROFESIONAL
                ) {
                  saludo = `${globalData.terminosLocalizados.ETI128} ${item.detalle}`;
                } else {
                  saludo = `${globalData.terminosLocalizados.ETI127}, ${item.detalle}`;
                }
              }
              return (
                <React.Fragment key={item.guid}>
                  <ItemCuentaAdicional
                    item={item}
                    index={index}
                    selected={cuentasSelectedIndex === index}
                    handleSelect={handleCuentasClick}
                    saludo={saludo}
                    textos={textos}
                  />
                </React.Fragment>
              );
            })}
            <ListItem
              variant="menu"
              button={true}
              onClick={openAddAccount}
              sx={{
                borderRadius: "6px",
              }}
            >
              <ListItemAvatar>
                <Avatar
                  alt="+"
                  // Apunto a una imagen que no existe para que utilice el 'alt'
                  src="."
                />
              </ListItemAvatar>
              <ListItemTextDynamicStyled
                primaryTypographyProps={{
                  color: theme.palette.primary.contrastText,
                  fontSize: 16,
                }}
                primary="Add Account"
                onClick={openAddAccount}
              ></ListItemTextDynamicStyled>
            </ListItem>
          </List>
        </>
      </Modal>
      <ModalContainer
        isOpenModal={isOpenAddAccount}
        closeModal={closeAddAccount}
      >
        <AgregarCuentaAdicional
          close={closeAddAccount}
          agregar={agregarCuentaAdicional}
        ></AgregarCuentaAdicional>
      </ModalContainer>
    </>
  );
}

function ItemCuentaAdicional(props) {
  const { item, index, selected, handleSelect, saludo, textos } = props;

  var detalleCategoria = "";
  switch (item.codigoCategoria) {
    case const_TipoCuentaAdicional.PROFESIONAL:
      detalleCategoria = textos.cuentaProfesional;
      break;
    case const_TipoCuentaAdicional.INSTITUCION:
      detalleCategoria = textos.cuentaInstitucion;
      break;
    default:
      detalleCategoria = textos.cuentaGeneral;
      break;
  }

  return (
    <React.Fragment key={item.guid}>
      <ListItem
        variant="menu"
        button={true}
        selected={selected}
        onClick={(event) => handleSelect(event, index)}
        sx={{
          borderRadius: "6px",
        }}
      >
        <ListItemAvatar>
          <Avatar alt={item.detalle} src={item.avatarURL} />
        </ListItemAvatar>
        <ListItemTextDynamicStyled
          selected={selected}
          primaryTypographyProps={{
            color: theme.palette.primary.contrastText,
            fontSize: 16,
          }}
          primary={detalleCategoria}
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color={theme.palette.primary.contrastText}
              >
                {saludo}
              </Typography>
            </React.Fragment>
          }
        ></ListItemTextDynamicStyled>
      </ListItem>
    </React.Fragment>
  );
}

const ListItemTextDynamicStyled = styled(ListItemText, {
  shouldForwardProp: (prop) => prop !== "selected",
})(({ selected, theme }) => ({
  ...(selected && {
    "& .MuiListItemText-primary": {
      color: theme.palette.secondary.contrastText,
      fontWeight: 600,
    },
  }),
}));
