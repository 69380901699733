import React, { useCallback, useEffect, useState } from "react";
import { Box, TextField } from "@mui/material";
import BarraTituloOtrasEntradas from "./BarraTituloOtrasEntradas";

import { tipos_respuesta } from "../../../../pages/ClinicFormPage";

const debounce = (func, delay) => {
  let timeout;
  return (...args) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

// De uso exclusivo para prescripción, prescripción de test y notas profesionales.
// Para preguntas de texto pleno se utiliza otro componente.
export default function EntradaArea(props) {
  const {
    titulo,
    rows,
    uso,
    inhabilitado,
    formularioClinico,
    actualizarOtrasEntradas,
  } = props;

  const obtenerValor = () => {
    switch (uso) {
      case tipos_respuesta.prescripcion:
        return formularioClinico.prescripcion.texto;
      case tipos_respuesta.prescripcionTest:
        return formularioClinico.prescripcionTest.texto;
      case tipos_respuesta.notasProfesional:
        return formularioClinico.notasProfesionales.texto;
      default:
        return "";
    }
  };

  //const [valor, setValor] = useState(obtenerValor());
  const [valor, setValor] = useState("");

  useEffect(() => {
    setValor(obtenerValor());
  }, [uso, formularioClinico]);

  // const handleOnChange = (event) => {
  //   setValor(event.target.value);
  // };

  const handleOnChange = (event) => {
    const nuevoValor = event.target.value;
    //console.log(nuevoValor);
    var tipoRespuesta;
    switch (uso) {
      case tipos_respuesta.prescripcion:
        tipoRespuesta = tipos_respuesta.prescripcion;
        break;
      case tipos_respuesta.prescripcionTest:
        tipoRespuesta = tipos_respuesta.prescripcionTest;
        break;
      case tipos_respuesta.notasProfesional:
        tipoRespuesta = tipos_respuesta.notasProfesional;
        break;
      case tipos_respuesta.archivosAdjuntos:
        tipoRespuesta = tipos_respuesta.archivosAdjuntos;
        break;
      case tipos_respuesta.usuariosEtiquetados:
        tipoRespuesta = tipos_respuesta.usuariosEtiquetados;
        break;
      default:
        throw new Error(
          `EntradaArea - handleOnBlur: el uso ${uso} no está implementado.`
        );
    }
    setValor(nuevoValor);
    //actualizarOtrasEntradas(tipoRespuesta, nuevoValor);
    debounceActualizarOtrasEntradas(tipoRespuesta, nuevoValor);
  };

  const debounceActualizarOtrasEntradas = useCallback(
    debounce((campo, valor) => {
      actualizarOtrasEntradas(campo, valor);
    }, 300),
    []
  );

  var usoCompartido = establecerUsoCompartido(uso);

  function establecerUsoCompartido(uso) {
    switch (uso) {
      case tipos_respuesta.prescripcion:
        return tipos_respuesta.compartidoPrescripcion;
      case tipos_respuesta.prescripcionTest:
        return tipos_respuesta.compartidoPrescripcionTest;
      case tipos_respuesta.notasProfesional:
        return tipos_respuesta.compartidoNotasProfesional;
      default:
        throw new Error(`EntradaArea: el uso ${uso} no está implementado.`);
    }
  }

  return (
    <Box
      overflow={"hidden"}
      height={"inherit"}
      display="flex"
      flexDirection={"column"}
    >
      <BarraTituloOtrasEntradas
        texto={titulo}
        uso={usoCompartido}
        inhabilitado={inhabilitado}
        formularioClinico={formularioClinico}
        actualizarOtrasEntradas={actualizarOtrasEntradas}
      />
      <TextField
        height="100%"
        multiline
        rows={rows}
        value={valor}
        inputProps={{ style: { fontSize: 12, lineHeight: "13px" } }}
        InputProps={{ style: { padding: "8px" } }}
        onChange={handleOnChange}
        //onBlur={handleOnBlur}
        //disabled={inhabilitado}
      />
    </Box>
  );
}
