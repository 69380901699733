import React from "react";

import { Box } from "@mui/material";

import EdicionListaEspecialidades from "./EdicionListaEspecialidades";

export default function EdicionListaAreasSalud(props) {
  const {
    areasSalud,
    handleQuitarPregunta,
    handleSubirPregunta,
    handleBajarPregunta,
    cambiarAlineacionAreasPreguntas,
  } = props;
  return (
    <Box>
      {areasSalud.map((itemAreasSalud, indexAreasSalud) => {
        return (
          <EdicionListaEspecialidades
            especialidades={itemAreasSalud.especialidades}
            descripcionAreaSalud={itemAreasSalud.descripcion}
            handleQuitarPregunta={handleQuitarPregunta}
            handleSubirPregunta={handleSubirPregunta}
            handleBajarPregunta={handleBajarPregunta}
            cambiarAlineacionAreasPreguntas={cambiarAlineacionAreasPreguntas}
          />
        );
      })}
    </Box>
  );
}
