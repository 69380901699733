import React, { useEffect, useState } from "react";
import getUserLocale from "get-user-locale";

import { Box, Grid } from "@mui/material";

import SearchAnonimo from "../components/Anonimo/SearchAnonimo";
import Hello from "../components/Anonimo/Hello";

import { API_URL } from "../utils/constants";

import Loading from "../components/Feedback/Loading";
import useToastContext from "../hooks/useToastContext";
import { Severities } from "../contexts/ToastContext";

export default function HomePage(props) {
  const { userInfo, setUserInfo } = props;

  const [terminos, setTerminos] = useState({});
  const [loadingTerminos, setLoadingTerminos] = useState(true);

  const codigoIdioma = getUserLocale();

  const urlObtenerTerminosLocalizados = `${API_URL()}/Internacionalizacion/getAlgunosTerminosLocalizados`;

  const addToast = useToastContext();

  // Obtener lista de términos localizados
  useEffect(() => {
    (async () => {
      const request = {
        Idioma: codigoIdioma.slice(0, 2),
        Codigos: [
          "AVI001",
          "AVI002",
          "AVI003",
          "AVI004",
          "AVI005",
          "AVI006",
          "AVI007",
          "AVI008",
          "AVI009",
          "AVI010",
          "AVI011",
          "AVI037",
          "COM001",
          "COM002",
          "COM003",
          "COM004",
          "COM034",
          "ETI001",
          "ETI002",
          "ETI003",
          "ETI004",
          "ETI005",
          "ETI006",
          "ETI007",
          "ETI008",
          "ETI120",
          "ETI121",
          "ETI122",
        ],
      };
      try {
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(request),
        };
        const res = await fetch(urlObtenerTerminosLocalizados, options);
        if (res.ok) {
          const json = await res.json();
          if (json.resultadoOK) {
            const objetoTerminos = {};
            json.terminosLocalizados.forEach((item, i) => {
              objetoTerminos[item.codigoTermino] = item.texto;
            });
            //console.log(objetoTerminos);
            setTerminos(objetoTerminos);
          } else {
            json.errores.forEach((item) => {
              addToast(item, Severities.error);
            });
          }
          setLoadingTerminos(false);
        } else {
          addToast("Error de servicio.", Severities.error);
          setLoadingTerminos(false);
        }
        return;
      } catch (err) {
        addToast("Error de conexión", Severities.error);
      }
    })();
  }, [addToast, urlObtenerTerminosLocalizados, codigoIdioma]);

  if (loadingTerminos) {
    return <Loading />;
  }

  return (
    <Box
      id="homeContainer"
      // El atributo de la imagen de fondo se tuvo que mantener
      // en una clase CSS, que ahora está definida en App.css.
      className="home"
      display="flex"
      flexDirection={"row"}
      height="100vh"
      width="100vw"
      padding="2%"
    >
      <Grid id="gridContainer" container>
        <Grid id="gridSearch" item md={7} xs={12}>
          <SearchAnonimo terminos={terminos} />
        </Grid>
        <Grid
          id="gridHello"
          item
          md={5}
          xs={12}
          display={"flex"}
          flexDirection="column"
          alignItems={"center"}
        >
          <Hello
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            codigoIdioma={codigoIdioma}
            terminos={terminos}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
