import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";

import {
  Grid,
  Button,
  Stack,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";

import useAuth from "../../../auth/useAuth";
import ErrorSummary from "../../Forms/ErrorSummary";
import useGlobalDataContext from "../../../hooks/useGlobalDataContext";
import Loading from "../../Feedback/Loading";
import { API_URL } from "../../../utils/constants";

import useToastContext from "../../../hooks/useToastContext";
import { Severities } from "../../../contexts/ToastContext";

const anchoBotones = "150px";

export default function Configuracion() {
  const [modoEdicion, setModoEdicion] = useState(false);

  const auth = useAuth();
  const addToast = useToastContext();
  const globalData = useGlobalDataContext();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      CodigoIdioma: auth.user.codigoIdioma ? auth.user.codigoIdioma : "",
    },
  });

  // En esta estructura se tienen los nombres de los campos en el idioma del usuario
  // Se recibirán del servidor cuando esté implementado el multi-lenguaje.
  const descripcionCampos = {
    CodigoIdiomaPreferido: globalData.terminosLocalizados.ETI119, // "Idioma Preferido",
  };

  const actualizarConfiguracionCuenta = async (data) => {
    const request = {
      Token: auth.user.token,
      Guid: auth.user.guid,
    };
    request.NuevosDatosConfiguracion = {
      CodigoIdioma: data.CodigoIdioma,
    };
    const url = `${API_URL()}/users/putConfiguracion`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(request),
    };
    try {
      const res = await fetch(url, options);
      if (res.ok) {
        const json = await res.json();
        if (json.resultadoOK) {
          //auth.updateUser(json.usuario);
          auth.updateConfiguracion(json.datosConfiguracion);
        } else {
          json.errores.forEach((item) => {
            addToast(item, Severities.error);
          });
        }
      } else {
        addToast(globalData.terminosLocalizados.AVI004, Severities.error);
      }
    } catch (err) {
      addToast(globalData.terminosLocalizados.AVI005, Severities.error);
    }
  };

  const handleEditar = () => {
    setModoEdicion(true);
  };

  const handleGuardar = (data) => {
    actualizarConfiguracionCuenta(data);
    setModoEdicion(false);
  };

  const handleCancelar = () => {
    setModoEdicion(false);
  };

  return (
    <form onSubmit={handleSubmit(handleGuardar)}>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={1}
        maxWidth="700px"
        margin={1}
        width="100%"
      >
        <RenderIdioma control={control} modoEdicion={modoEdicion} />
        <Grid item md={12}>
          <RenderCommandBar
            modoEdicion={modoEdicion}
            handleEditar={handleEditar}
            handleCancelar={handleCancelar}
          />
        </Grid>
        <Grid item md={12}>
          <ErrorSummary
            errors={errors}
            descripciones={descripcionCampos}
          ></ErrorSummary>
        </Grid>
      </Grid>
    </form>
  );
}

function RenderIdioma(props) {
  const { control, modoEdicion } = props;

  const globalData = useGlobalDataContext();

  const textos = {
    tituloIdioma: globalData.terminosLocalizados.ETI118,
    etiquetaIdioma: globalData.terminosLocalizados.ETI119,
    textoEditar: globalData.terminosLocalizados.COM040,
    textoGuardar: globalData.terminosLocalizados.COM041,
    textoCancelar: globalData.terminosLocalizados.COM004,
  };

  return (
    <>
      <Grid item md={12} width="100%">
        <Typography variant="h7" fontStyle="italic">
          {textos.tituloIdioma}
        </Typography>
      </Grid>
      <Grid item md={12} width="100%">
        <FormControl size="small">
          <InputLabel>{textos.etiquetaIdioma}</InputLabel>
          <Controller
            name="CodigoIdioma"
            control={control}
            render={({ field }) => (
              <Select
                // A los <Select> no se les aplica "disabled" porque le cambia el color al contenido
                inputProps={{
                  readOnly: !modoEdicion,
                  //disabled: !modoEdicion
                }}
                label={textos.etiquetaIdioma}
                MenuProps={{
                  PaperProps: { sx: { maxHeight: 190, width: "" } },
                }}
                {...field}
              >
                {globalData.loadingIdiomas ? (
                  <Loading />
                ) : (
                  globalData.idiomas.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.codigo}>
                        {item.nombre}
                      </MenuItem>
                    );
                  })
                )}
              </Select>
            )}
          ></Controller>
        </FormControl>
      </Grid>
    </>
  );
}

function RenderCommandBar(props) {
  const { modoEdicion, handleEditar, handleCancelar } = props;

  const globalData = useGlobalDataContext();

  const textos = {
    textoEditar: globalData.terminosLocalizados.COM040,
    textoGuardar: globalData.terminosLocalizados.COM041,
    textoCancelar: globalData.terminosLocalizados.COM004,
  };

  return (
    <Stack direction={"row"} spacing={1} justifyContent="flex-start">
      {!modoEdicion && (
        <Button
          variant="CommandBar3"
          onClick={handleEditar}
          sx={{
            width: anchoBotones,
          }}
        >
          {textos.textoEditar}
        </Button>
      )}
      {modoEdicion && (
        <Button
          variant="CommandBar3"
          type="submit"
          sx={{
            width: anchoBotones,
          }}
        >
          {textos.textoGuardar}
        </Button>
      )}
      {modoEdicion && (
        <Button
          variant="CommandBar3"
          onClick={handleCancelar}
          sx={{
            width: anchoBotones,
          }}
        >
          {textos.textoCancelar}
        </Button>
      )}
    </Stack>
  );
}
