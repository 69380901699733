import React from "react";

import { SvgIcon } from "@mui/material";

export default function StackHorizontal() {
  return (
    <SvgIcon>
      <rect x="3" y="3" width="8" height="18" rx="2" />
      <rect x="12" y="3" width="8" height="18" rx="2" />
    </SvgIcon>
  );
}
