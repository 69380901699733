import React from "react";

import { Box, TextField } from "@mui/material";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider, DatePicker } from "@mui/lab";

import { tipos_respuesta } from "../../../../../pages/ClinicFormPage";

export default function PreguntaFecha(props) {
  const {
    inhabilitado,
    actualizarRespuesta,
    itemPregunta,
    indexProceso,
    indexAreaSalud,
    indexEspecialidad,
    indexSeccion,
    indexAreaPreguntas,
    indexPregunta,
  } = props;

  // Registra la respuesta en la entrada de la lista de respuestas correspondiente al guid.
  const handleOnChange = (nuevoValor) => {
    actualizarRespuesta(
      indexProceso,
      indexAreaSalud,
      indexEspecialidad,
      indexSeccion,
      indexAreaPreguntas,
      indexPregunta,
      tipos_respuesta.fecha,
      nuevoValor
    );
  };

  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          value={itemPregunta.respuestaFecha}
          onChange={(nuevoValor) => {
            handleOnChange(nuevoValor);
          }}
          inputFormat="dd/MM/yyyy"
          renderInput={(params) => <TextField size="small" {...params} />}
          disabled={inhabilitado}
        />
      </LocalizationProvider>
    </Box>
  );
}
