import React, { useState, useCallback } from "react";
import Logo_Beemedics from "../../assets/img/Logo_BeeMedics.png";
//import "./Signup.scss";

import { Box, Button, Stack } from "@mui/material";
import useToastContext from "../../hooks/useToastContext";
import { Severities } from "../../contexts/ToastContext";

import ModalContainer from "../Autenticados/ModalContainer";
import SignupForm from "./SignupForm";

import { API_URL } from "../../utils/constants";

export default function Signup(props) {
  const { terminos } = props;

  const [isOpenSignupForm, setIsOpenSignupForm] = useState(false);

  const addToast = useToastContext();

  // Función para procesar el click en las listas
  const openSignupForm = (event) => {
    setIsOpenSignupForm(true);
  };

  const closeSignupForm = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    setIsOpenSignupForm(false);
  };

  const agregarUsuario = useCallback(
    async (data) => {
      // Aquí se debería preguntar si hay datos o validar
      //if (!datos) return;
      //event.preventDefault();
      let url = `${API_URL()}/Users/signUp`;
      let urlActivacion = `${window.location.href}ActivationPage`;
      let request = {
        Usuario: data.Usuario,
        Email: data.Email,
        Telefono: data.Telefono,
        Password: data.Password,
        ConfirmarPassword: data.ConfirmarPassword,
        UrlOrigen: urlActivacion,
      };
      try {
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(request),
        };
        const res = await fetch(url, options);
        if (res.ok) {
          const json = await res.json();
          if (json.resultadoOK) {
            //addToast("Usuario agregado", Severities.info);
            addToast(terminos.AVI006, Severities.info);
            closeSignupForm();
          } else {
            json.errores.forEach((item) => {
              addToast(item, Severities.error);
            });
          }
        } else {
          addToast(terminos.AVI004, Severities.error);
        }
      } catch (err) {
        addToast(terminos.AVI005, Severities.error);
      }
    },
    [addToast, terminos.AVI004, terminos.AVI005, terminos.AVI006]
  );

  return (
    <Box
      id="signUpContainer"
      flexGrow={0}
      fontSize="14px"
      color={"rgb(0,103,122)"}
      fontWeight="bold"
      sx={{ verticalAlign: "middle", wordWrap: "normal" }}
    >
      <Stack spacing={"5px"}>
        <Box id="adviceContainer">
          <span
            style={{
              verticalAlign: "sub",
            }}
          >
            {terminos.ETI122}
          </span>
          <img
            style={{
              height: "24px",
              marginRight: "5px",
              verticalAlign: "middle",
            }}
            src={Logo_Beemedics}
            alt="BeeMedics"
          ></img>
        </Box>
        <Box
          id="commandContainer"
          textAlign={"center"}
          display={"flex"}
          justifyContent="l"
        >
          <Button variant="CommandBar1" onClick={openSignupForm}>
            {terminos.COM002}
          </Button>
        </Box>
      </Stack>
      <ModalContainer
        id="signupModal"
        isOpenModal={isOpenSignupForm}
        closeModal={closeSignupForm}
      >
        <SignupForm
          close={closeSignupForm}
          agregar={agregarUsuario}
          terminos={terminos}
        ></SignupForm>
      </ModalContainer>
    </Box>
  );
}
