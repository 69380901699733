import React, { useState } from "react";

import { TextField, InputAdornment } from "@mui/material";

import { const_TipoFactorValoracion } from "../../../../../utils/constants";

import useGlobalDataContext from "../../../../../hooks/useGlobalDataContext";

import { tipos_respuesta } from "../../../../../pages/ClinicFormPage";

export default function PreguntaUnidadMedida(props) {
  const {
    inhabilitado,
    actualizarRespuesta,
    itemPregunta,
    indexProceso,
    indexAreaSalud,
    indexEspecialidad,
    indexSeccion,
    indexAreaPreguntas,
    indexPregunta,
  } = props;

  const [itemState, setItemState] = useState(itemPregunta);

  const globalData = useGlobalDataContext();

  // Se recibirán del servidor cuando esté implementado el multi-lenguaje.
  const detalleFactorValoracion = {
    Menor: globalData.terminosLocalizados.VAL001,
    MenorIgual: globalData.terminosLocalizados.VAL002,
    Igual: globalData.terminosLocalizados.VAL003,
    MayorIgual: globalData.terminosLocalizados.VAL004,
    Mayor: globalData.terminosLocalizados.VAL005,
    Rango: globalData.terminosLocalizados.VAL006,
    RangoConjuncion: globalData.terminosLocalizados.OCN001,
  };

  function valorarRespuestaUnidadMedida(valor, valoraciones) {
    let valoracion = { resultado: "", referencia: "" };
    valoraciones.every((item) => {
      switch (item.codigoComparacion) {
        case const_TipoFactorValoracion.MENOR:
          if (valor < item.valorReferencia) {
            valoracion.resultado = item.valoracion;
            valoracion.referencia = `${detalleFactorValoracion.Menor} ${item.valorReferencia}`;
            return false;
          }
          break;
        case const_TipoFactorValoracion.MENOR_IGUAL:
          if (valor <= item.valorReferencia) {
            valoracion.resultado = item.valoracion;
            valoracion.referencia = `${detalleFactorValoracion.MenorIgual} ${item.valorReferencia}`;
            return false;
          }
          break;
        case const_TipoFactorValoracion.IGUAL:
          if ((valor = item.valorReferencia)) {
            valoracion.resultado = item.valoracion;
            valoracion.referencia = `${detalleFactorValoracion.Igual} ${item.valorReferencia}`;
            return false;
          }
          break;
        case const_TipoFactorValoracion.MAYOR_IGUAL:
          if (valor >= item.valorReferencia) {
            valoracion.resultado = item.valoracion;
            valoracion.referencia = `${detalleFactorValoracion.MayorIgual} ${item.valorReferencia}`;
            return false;
          }
          break;
        case const_TipoFactorValoracion.MAYOR:
          if (valor > item.valorReferencia) {
            valoracion.resultado = item.valoracion;
            valoracion.referencia = `${detalleFactorValoracion.Mayor} ${item.valorReferencia}`;
            return false;
          }
          break;
        case const_TipoFactorValoracion.RANGO:
          if (
            valor >= item.valorReferencia &&
            valor <= item.valorReferenciaSuperior
          ) {
            valoracion.resultado = item.valoracion;
            valoracion.referencia = `${detalleFactorValoracion.Rango} ${item.valorReferencia} ${detalleFactorValoracion.RangoConjuncion} ${item.valorReferenciaSuperior}`;
            return false;
          }
          break;
        default:
          return null;
      }
      return true;
    });
    return valoracion;
  }

  // Registra la respuesta en la entrada de la lista de respuestas correspondiente al guid.
  const handleOnBlur = (event) => {
    const respuesta = {
      valor: itemState.respuestaUnidadMedida,
      valoracion: valorarRespuestaUnidadMedida(
        itemState.respuestaUnidadMedida,
        itemPregunta.valoraciones
      ),
    };
    actualizarRespuesta(
      indexProceso,
      indexAreaSalud,
      indexEspecialidad,
      indexSeccion,
      indexAreaPreguntas,
      indexPregunta,
      tipos_respuesta.unidadMedida,
      respuesta
    );
  };

  const handleOnChange = (event) => {
    setItemState((prevState) => ({
      ...prevState,
      respuestaUnidadMedida: event.target.value,
    }));
  };

  React.useEffect(() => {
    setItemState(itemPregunta);
  }, [itemPregunta]);

  return (
    <TextField
      size="small"
      value={itemState.respuestaUnidadMedida}
      inputProps={{ style: { fontSize: 12, lineHeight: "14px" } }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {itemPregunta.unidadMedida}
          </InputAdornment>
        ),
      }}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      disabled={inhabilitado}
    />
  );
}
