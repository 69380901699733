import React from "react";

import { Box, Typography } from "@mui/material";

import EdicionListaPreguntas from "./EdicionListaPreguntas";

import BoxAreaPreguntas from "./BoxAreaPreguntas";

export default function EdicionListaAreasPreguntas(props) {
  const {
    areasPreguntas,
    handleQuitarPregunta,
    handleSubirPregunta,
    handleBajarPregunta,
    areasPreguntasHorizontal,
  } = props;
  return (
    <Box>
      {areasPreguntas.map((itemAreasPreguntas, indexAreasPreguntas) => {
        const indexUltimo = areasPreguntas.length - 1;
        return (
          <Box sx={{ marginRight: 0 }}>
            <BoxAreaPreguntas
              itemAreasPreguntas={itemAreasPreguntas}
              indexAreasPreguntas={indexAreasPreguntas}
              indexUltimo={indexUltimo}
              guid={itemAreasPreguntas.guid}
              handleQuitarPregunta={handleQuitarPregunta}
              handleSubirPregunta={handleSubirPregunta}
              handleBajarPregunta={handleBajarPregunta}
              areasPreguntasHorizontal={areasPreguntasHorizontal}
            >
              <Typography sx={{ fontWeight: "bold" }}>
                {itemAreasPreguntas.descripcion}
              </Typography>
            </BoxAreaPreguntas>
            <EdicionListaPreguntas
              preguntas={itemAreasPreguntas.preguntas}
              handleQuitarPregunta={handleQuitarPregunta}
              handleSubirPregunta={handleSubirPregunta}
              handleBajarPregunta={handleBajarPregunta}
            />
          </Box>
        );
      })}
    </Box>
  );
}
