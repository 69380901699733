import React, { useEffect, useState } from "react";

import { Box, Typography, Stack, Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import Fondo from "../assets/img/Background-claro.png";
import Loading from "../components/Feedback/Loading";
import theme from "../utils/theme";
import { API_URL } from "../utils/constants";
import useToastContext from "../hooks/useToastContext";
import { Severities } from "../contexts/ToastContext";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function ActivationPage(props) {
  const { token } = props;

  const [activando, setActivando] = useState(true);
  const [errorActivacion, setErrorActivacion] = useState(false);

  // let query = useQuery();
  // let token = query.get("codigo");

  const addToast = useToastContext();

  const textoActivando = "Su cuenta está siendo activada...";
  const textoActivado = "Su cuenta ha sido activada.";
  const textoError = "No se ha podido activar la cuenta.";
  const textoContinuar = "Continuar en BeeMedics";

  const navigate = useNavigate();

  const continuar = () => {
    navigate("/");
  };

  // Activar el usuario correspondiente al token recibido
  useEffect(() => {
    (async () => {
      let url = `${API_URL()}/Users/activate`;
      const request = {
        Token: token,
      };
      try {
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(request),
        };
        const res = await fetch(url, options);
        if (res.ok) {
          const json = await res.json();
          if (json.resultadoOK) {
          } else {
            json.errores.forEach((item) => {
              addToast(item, Severities.error);
            });
            setErrorActivacion(true);
          }
        } else {
          addToast("Error de servicio.", Severities.error);
          setErrorActivacion(true);
        }
        setActivando(false);
        return;
      } catch (err) {
        setActivando(false);
        setErrorActivacion(true);
        addToast("Error de conexión", Severities.error);
      }
    })();
  }, [addToast, token]);

  return (
    <Box
      id="MainBox"
      width={"100%"}
      height="100%"
      display={"flex"}
      flexDirection="column"
      justifyContent="center"
      alignItems={"center"}
      sx={{
        // No logro que funcione la propiedad correspondiente
        backgroundImage: `url(${Fondo})`,
      }}
    >
      <Stack spacing={2}>
        {activando && (
          <Box justifyContent={"center"} color={theme.palette.primary.main}>
            <Typography fontWeight={"bold"}>{textoActivando}</Typography>
          </Box>
        )}
        {activando && <Loading />}
        {!(activando || errorActivacion) && (
          <Box justifyContent={"center"} color={theme.palette.primary.main}>
            <Typography fontWeight={"bold"}>{textoActivado}</Typography>
          </Box>
        )}
        {errorActivacion && (
          <Box justifyContent={"center"} color={theme.palette.error.main}>
            <Typography fontWeight={"bold"}>{textoError}</Typography>
          </Box>
        )}
        {!activando && <Button onClick={continuar}>{textoContinuar}</Button>}
      </Stack>
    </Box>
  );
}
