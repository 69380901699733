import React from "react";
import {
  Grid,
  Stack,
  Box,
  Typography,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider, DatePicker } from "@mui/lab";

import ArchivoAntiguo from "./ArchivoAntiguo";

import { API_URL } from "../../../../../utils/constants";
import useAuth from "../../../../../auth/useAuth";

import useToastContext from "../../../../../hooks/useToastContext";
import { Severities } from "../../../../../contexts/ToastContext";

import theme from "../../../../../utils/theme";
import useGlobalDataContext from "../../../../../hooks/useGlobalDataContext";

import { const_TiposMimeAceptados } from "../../../../../utils/constants";

import { tipoEstadoArchivo } from "../../../../../utils/constants";

export default function AgregarRegistros(props) {
  const {
    registrosAntiguos,
    setRegistrosAntiguos,
    archivosAntiguos,
    setArchivosAntiguos,
    agregarArchivoAntiguo,
    solicitarCancelarAgregarRegistrosAntiguos,
  } = props;

  const globalData = useGlobalDataContext();
  const auth = useAuth();
  const addToast = useToastContext();

  const textos = {
    titulo: globalData.terminosLocalizados.ETI166,
    cancelar: globalData.terminosLocalizados.COM004,
    publicar: globalData.terminosLocalizados.COM036,
    fecha: globalData.terminosLocalizados.ETI168,
    usarFechaActual: globalData.terminosLocalizados.ETI169,
    agregar: globalData.terminosLocalizados.COM037,
    avisos: {
      sinFecha: globalData.terminosLocalizados.AVI039, // Debe ingresar la fecha
      sinArchivos: globalData.terminosLocalizados.AVI040, // Debe cargar al menos un archivo
    },
  };

  const handlePublicar = () => {
    subirArchivos();
  };

  const handleCancelar = () => {
    solicitarCancelarAgregarRegistrosAntiguos();
  };

  const handleOnChangeFecha = (nuevoValor) => {
    setRegistrosAntiguos((previous) => ({ ...previous, fecha: nuevoValor }));
  };

  const handleAgregar = () => {
    agregarArchivoAntiguo();
  };

  const handleOnChangeUsarFechaActual = (event) => {
    const flagUsarFechaActual = event.target.checked;
    const current = new Date();
    setRegistrosAntiguos((previous) => ({
      ...previous,
      usarFechaActual: flagUsarFechaActual,
      fecha: flagUsarFechaActual ? current.toISOString() : previous.fecha,
    }));
  };

  const actualizarSeleccion = async (event, guidArchivo) => {
    console.log(event);
    const archivoSeleccionado = event.target.files[0];

    // En caso de que se quieran validar los tipos de contenido MIME
    if (const_TiposMimeAceptados.includes(archivoSeleccionado.type)) {
      //console.log("Tipo MIME aceptado");
    } else {
      //console.log("Tipo MIME no aceptado");
    }

    setArchivosAntiguos(
      archivosAntiguos.map((item) =>
        item.guid === guidArchivo
          ? {
              ...item,
              archivo: archivoSeleccionado,
              preview: URL.createObjectURL(archivoSeleccionado),
              estado: { cargado: true },
            }
          : item
      )
    );
  };

  const cancelarSeleccion = (guidArchivo) => {
    setArchivosAntiguos(
      archivosAntiguos.map((item) =>
        item.guid === guidArchivo
          ? {
              ...item,
              archivo: null,
              src: null,
              estado: { cargado: false },
            }
          : item
      )
    );
  };

  const actualizarNombre = (event, guidArchivo) => {
    setArchivosAntiguos(
      archivosAntiguos.map((item) =>
        item.guid === guidArchivo
          ? {
              ...item,
              nombre: event.target.value,
            }
          : item
      )
    );
  };

  const actualizarNota = (event, guidArchivo) => {
    setArchivosAntiguos(
      archivosAntiguos.map((item) =>
        item.guid === guidArchivo
          ? {
              ...item,
              nota: event.target.value,
            }
          : item
      )
    );
  };

  const actualizarEstadoArchivo = (guidArchivo, tipoEstado) => {
    setArchivosAntiguos(
      archivosAntiguos.map((item) =>
        item.guid === guidArchivo
          ? {
              ...item,
              estado: {
                ...item.estado,
                // Como es true/false mutuamente exclusivo se pueden definir así.
                // Si no fueran mutuamente exclusivos se debería implementar un switch.
                subiendo: tipoEstado === tipoEstadoArchivo.SUBIENDO,
                subido: tipoEstado === tipoEstadoArchivo.SUBIDO,
                error: tipoEstado === tipoEstadoArchivo.ERROR,
              },
            }
          : item
      )
    );
    if (tipoEstado === tipoEstadoArchivo.SUBIDO) {
      setRegistrosAntiguos((previous) => ({
        ...previous,
        estado: {
          ...previous.estado,
          cantidadArchivosEnviados:
            previous.estado.cantidadArchivosEnviados + 1,
        },
      }));
    }
  };

  const cancelarArchivo = (event, guidArchivo) => {
    setArchivosAntiguos(
      archivosAntiguos.filter((item) => {
        return item.guid !== guidArchivo;
      })
    );
  };

  const subirArchivos = () => {
    if (registrosAntiguos.fecha === null) {
      //addToast("Debe ingresar la fecha", Severities.error);
      addToast(textos.avisos.sinFecha, Severities.error);
      return;
    }
    var cantidadArchivosEnviar = 0;
    archivosAntiguos.forEach((item) => {
      if (item.estado.cargado) {
        cantidadArchivosEnviar += 1;
      }
    });
    if (cantidadArchivosEnviar === 0) {
      //addToast("Debe cargar al menos un archivo", Severities.error);
      addToast(textos.avisos.sinArchivos, Severities.error);
      return;
    }
    setRegistrosAntiguos((previous) => ({
      ...previous,
      estado: {
        ...previous.estado,
        cantidadArchivosEnviar: cantidadArchivosEnviar,
        cantidadArchivosEnviados: 0,
        enviandoArchivos: true,
      },
    }));
    archivosAntiguos.forEach((item) => {
      if (item.estado.cargado) {
        subirArchivo(item);
      }
    });
  };

  async function subirArchivo(item) {
    const url = `${API_URL()}/FormularioClinico/addArchivoAntiguoPublicacion`;
    const request = crearRequestSubirArchivo(item);
    try {
      const options = {
        method: "POST",
        body: request,
      };
      actualizarEstadoArchivo(item.guid, tipoEstadoArchivo.SUBIENDO);
      const res = await fetch(url, options);
      if (res.ok) {
        const json = await res.json();
        if (json.resultadoOK) {
          actualizarEstadoArchivo(item.guid, tipoEstadoArchivo.SUBIDO);
        } else {
          actualizarEstadoArchivo(item.guid, tipoEstadoArchivo.ERROR);
        }
      } else {
        actualizarEstadoArchivo(item.guid, tipoEstadoArchivo.ERROR);
      }
    } catch (err) {
      actualizarEstadoArchivo(item.guid, tipoEstadoArchivo.ERROR);
    }
  }

  const crearRequestSubirArchivo = (item) => {
    const request = new FormData();
    request.append("Token", auth.user.token);
    request.append("Guid", item.guid);
    request.append("File", item.archivo);
    request.append("NombreAsignado", item.nombre);
    request.append("Nota", item.nota);
    return request;
  };

  // * Se debería tener una lista de archivos (en el estado de registrosAntiguos)
  // * Al arrastrar un archivo se debería intentar agregarlo a la lista
  // * Al seleccionar un archivo mediante el comando, se debería intentar agregar a la lista
  // * Al intentar agregar a la lista se debería verificar que la ruta no esté duplicada
  // * Al intentar agregar un registro a la lista se debería verificar el tipo de archivo
  // * Por cada elemento de la lista se debería renderizar el componente de registro atiguo
  // * En el componente de registro antiguo se debería permitir cargar el nombre  y las notas
  // * En el componente de registro antiguo se debería permitir eliminar el archivo
  // * En el componente de registro antiguo, si no hay un archivo, se debería permitir seleccionarlo
  // * El comando de agregar otro registro debería agregar un registro antiguo (sin archivo) a la lista
  // * El componente de registro antiguo debería permitir eliminar el registro
  // * El comando de publicar debería revisar que no haya registros sin archivo, sin nombre o sin
  // notas (Ricardo confirmará si se permitirá agregar registros sin notas)
  // * El comando de publicar, si todos los registros son válidos, debería enviarlos a la timeline
  // del paciente.
  // * Al intentar cancelar se debería verificar que no haya registros, y si hay registros, advertir
  // al usuario y pedirle confirmación.

  return (
    <Stack
      id="stackAgregarRegistros"
      spacing={1}
      padding={1}
      borderRadius={2}
      width={"100%"}
      height="100%"
      bgcolor={theme.palette.terciary.main}
      // Para restaurar el margen -8px
      marginTop="8px"
      marginLeft="8px"
    >
      <Box item id="titulo" xs={12}>
        <Typography variant="h5" color={theme.palette.primary.main}>
          {textos.titulo}
        </Typography>
      </Box>
      <Grid container>
        <Grid item id="fecha" xs={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label={textos.fecha}
              value={registrosAntiguos.fecha}
              onChange={(nuevoValor) => {
                handleOnChangeFecha(nuevoValor);
              }}
              inputFormat="dd/MM/yyyy"
              renderInput={(params) => (
                <TextField id="fecha" size="small" {...params} />
              )}
              disabled={registrosAntiguos.usarFechaActual}
            />
          </LocalizationProvider>
        </Grid>
        <Grid
          item
          id="usarFechaActual"
          xs={6}
          display="flex"
          flexDirection={"row"}
          justifyContent="flex-end"
        >
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                onChange={handleOnChangeUsarFechaActual}
                checked={registrosAntiguos.usarFechaActual}
              />
            }
            label={
              <Typography color={theme.palette.primary.main}>
                {textos.usarFechaActual}
              </Typography>
            }
          />
        </Grid>
      </Grid>
      <Box
        id="contenedorListaBoton"
        flexGrow={1}
        // No sé por qué aquí se tienen que restar 120px.
        // Para mí tenían que ser 76px (la alura que ocupan las dos
        // líneas de botones), pero con 120px es como funciona,
        // incluso con responsive.
        maxHeight="calc(100% - 120px)"
      >
        {/* <Dropzone
            border="solid"
            width="100%"
            onDrop={handleDrop}
            noClick
            noKeyboard
          > */}
        <Stack
          id="listaArchivos"
          flexGrow={1}
          spacing={1}
          overflow="auto"
          maxHeight={"calc(100% - 36px)"}
        >
          {archivosAntiguos.map((item, index) => {
            return (
              <ArchivoAntiguo
                item={item}
                index={index}
                actualizarSeleccion={actualizarSeleccion}
                cancelarSeleccion={cancelarSeleccion}
                actualizarNombre={actualizarNombre}
                actualizarNota={actualizarNota}
                cancelarArchivo={cancelarArchivo}
              />
            );
          })}
        </Stack>
        {/* </Dropzone> */}
        <Box marginTop={1}>
          <Button variant="CommandBar2" onClick={handleAgregar} bottom={0}>
            {textos.agregar}
          </Button>
        </Box>
      </Box>
      <Box>
        <Grid container>
          <Grid item flexGrow={1}>
            <Button variant="CommandBar2" onClick={handleCancelar}>
              {textos.cancelar}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="destacado"
              onClick={handlePublicar}
              sx={{ bgcolor: theme.palette.secondary.main }}
            >
              {textos.publicar}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
}
