import React from "react";

import { TextField } from "@mui/material";

import BoxPregunta from "./BoxPregunta";

import { const_TipoOrdenableFormularioClinico } from "../../../../utils/constants";

import useGlobalDataContext from "../../../../hooks/useGlobalDataContext";

export default function EdicionFecha(props) {
  const {
    itemPreguntas,
    handleQuitarPregunta,
    handleSubirPregunta,
    handleBajarPregunta,
  } = props;

  const globalData = useGlobalDataContext();

  return (
    <BoxPregunta
      guid={itemPreguntas.guid}
      tipo={const_TipoOrdenableFormularioClinico.FECHA}
      suprimible
      handleQuitarPregunta={handleQuitarPregunta}
      handleSubirPregunta={handleSubirPregunta}
      handleBajarPregunta={handleBajarPregunta}
    >
      <TextField
        type="text"
        name={itemPreguntas.descripcion}
        // placeholder="Fecha"
        placeholder={globalData.terminosLocalizados.TVC004}
        sx={{ width: "50%" }}
      ></TextField>
    </BoxPregunta>
  );
}
