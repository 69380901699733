import React from "react";
import { Box, Typography, FormControlLabel, Input } from "@mui/material";

import SeleccionCategoriasCheckbox from "./SeleccionCategoriasCheckbox";

import useGlobalDataContext from "../../../../../hooks/useGlobalDataContext";

import theme from "../../../../../utils/theme";

function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

export default function SeleccionCategorias(props) {
  const { textoBusqueda, setTextoBusqueda, categorias, setCategorias } = props;

  const globalData = useGlobalDataContext();

  // Se debería obtener desde el servidor en función del idioma del usuario
  //const titulo = "Ver por categoría";
  const titulo = globalData.terminosLocalizados.ETI130;

  // Se debería obtener desde el servidor en función del idioma del usuario
  //const busquedaPlaceholder = "Ingrese su búsqueda";
  const busquedaPlaceholder = globalData.terminosLocalizados.ETI089;

  // Las descripciones de las categorías se deberían obtener desde el servidor
  categorias.profesionales.descripcion = globalData.terminosLocalizados.ETI131;
  categorias.comercios.descripcion = globalData.terminosLocalizados.ETI132;
  categorias.pacientes.descripcion = globalData.terminosLocalizados.ETI133;
  categorias.apuntes.descripcion = globalData.terminosLocalizados.ETI134;
  categorias.resultados.descripcion = globalData.terminosLocalizados.ETI135;

  const handleChangeBusqueda = (event) => {
    setTextoBusqueda(event.target.value);
  };

  return (
    <Box
      id="seleccionCategorias"
      padding={1}
      borderRadius={2}
      width={"100%"}
      bgcolor={theme.palette.terciary.main}
    >
      <Typography
        sx={{ fontSize: 12, fontWeight: "bold", textAlign: "center" }}
      >
        {titulo}
      </Typography>
      <Box marginTop={0.8}>
        {Object.values(categorias).map((item, index) => {
          const itemKey = getKeyByValue(categorias, item);
          return (
            <Box key={index} height={24}>
              <FormControlLabel
                fontSize={12}
                control={
                  <SeleccionCategoriasCheckbox
                    itemKey={itemKey}
                    estado={item.estado}
                    categorias={categorias}
                    setCategorias={setCategorias}
                  />
                }
                label={
                  <Typography fontSize={12}>{item.descripcion}</Typography>
                }
              />
            </Box>
          );
        })}
      </Box>
      <Input
        type="text"
        variant="TimelineSearch"
        name="textoBusqueda"
        value={textoBusqueda}
        placeholder={busquedaPlaceholder}
        onChange={handleChangeBusqueda}
        disableUnderline={true}
        sx={{
          marginTop: 1,
          width: "100%",
        }}
      />
    </Box>
  );
}
