import React from "react";

import { Box, FormControlLabel, Checkbox } from "@mui/material";

import BoxPregunta from "./BoxPregunta";
import { const_TipoOrdenableFormularioClinico } from "../../../../utils/constants";

export default function EdicionOpciones(props) {
  const {
    opciones,
    handleQuitarPregunta,
    handleSubirPregunta,
    handleBajarPregunta,
  } = props;
  return (
    <Box>
      {opciones.map((itemOpcion, indexOpcion) => {
        const indexUltimo = opciones.length - 1;
        return (
          <BoxPregunta
            guid={itemOpcion.guid}
            tipo={const_TipoOrdenableFormularioClinico.OPCION}
            subible={indexOpcion !== 0}
            bajable={indexOpcion < indexUltimo}
            suprimible
            handleQuitarPregunta={handleQuitarPregunta}
            handleSubirPregunta={handleSubirPregunta}
            handleBajarPregunta={handleBajarPregunta}
          >
            <FormControlLabel
              control={<Checkbox />}
              label={itemOpcion.descripcion}
            />
          </BoxPregunta>
        );
      })}
    </Box>
  );
}
