import React, { useState } from "react";

import { TextField } from "@mui/material";

import { tipos_respuesta } from "../../../../../pages/ClinicFormPage";

export default function PreguntaTextoPleno(props) {
  const {
    inhabilitado,
    actualizarRespuesta,
    itemPregunta,
    indexProceso,
    indexAreaSalud,
    indexEspecialidad,
    indexSeccion,
    indexAreaPreguntas,
    indexPregunta,
  } = props;

  const [itemState, setItemState] = useState(itemPregunta);

  React.useEffect(() => {
    //console.log(itemPregunta);
    setItemState(itemPregunta);
  }, [itemPregunta]);

  React.useEffect(() => {
    //console.log(itemState);
  }, [itemState]);

  // Registra la respuesta en la entrada de la lista de respuestas correspondiente al guid.
  const handleOnBlur = (event) => {
    actualizarRespuesta(
      indexProceso,
      indexAreaSalud,
      indexEspecialidad,
      indexSeccion,
      indexAreaPreguntas,
      indexPregunta,
      tipos_respuesta.textoPleno,
      event.target.value
    );
  };

  const handleChange = (event) => {
    setItemState((prevState) => ({
      ...prevState,
      respuestaTextoPleno: event.target.value,
    }));
  };

  return (
    <TextField
      id="el-text-field"
      multiline
      rows="3"
      value={itemState.respuestaTextoPleno}
      inputProps={{ style: { fontSize: 12, lineHeight: "14px" } }}
      InputProps={{ style: { padding: "8px" } }}
      onBlur={handleOnBlur}
      onChange={handleChange}
      disabled={inhabilitado}
    />
  );
}
