import React, { useState } from "react";
import { Box, Button } from "@mui/material";

import BarraTituloOtrasEntradas from "./BarraTituloOtrasEntradas";
import { uso_barra_titulo } from "./Formulario";
import ModalContainer from "../../../Autenticados/ModalContainer";
import AdjuntarArchivos from "./AdjuntarArchivos";

export default function ArchivosAdjuntos(props) {
  const {
    titulo,
    inhabilitado,
    formularioClinico,
    setFormularioClinico,
    actualizarOtrasEntradas,
  } = props;
  const [isOpenModal, setIsOpenModal] = useState(false);

  const openModal = () => {
    setIsOpenModal(true);
  };

  const closeModal = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    setIsOpenModal(false);
  };

  const cantidadArchivosAdjuntos =
    formularioClinico.archivosAdjuntos.archivos.length;

  return (
    <Box>
      <BarraTituloOtrasEntradas
        texto={titulo}
        uso={uso_barra_titulo.archivosAdjuntos}
        inhabilitado={inhabilitado}
        formularioClinico={formularioClinico}
        actualizarOtrasEntradas={actualizarOtrasEntradas}
      />
      <Button
        variant="base"
        component="label"
        onClick={openModal}
        sx={{ padding: 0, width: "100%" }}
        disabled={inhabilitado}
      >
        {cantidadArchivosAdjuntos > 0 && `(${cantidadArchivosAdjuntos})`} +
      </Button>
      <ModalContainer isOpenModal={isOpenModal} closeModal={closeModal}>
        <AdjuntarArchivos
          close={closeModal}
          formularioClinico={formularioClinico}
          setFormularioClinico={setFormularioClinico}
        />
      </ModalContainer>
    </Box>
  );
}
