import React from "react";
import { Box, Typography } from "@mui/material";

export default function LineaCabeceraPaciente(props) {
  const { etiqueta, valor } = props;
  return (
    <Box display={"flex"}>
      <Typography fontSize={12} marginRight={0.5}>
        {etiqueta}:
      </Typography>
      <Typography fontSize={12}>{valor}</Typography>
    </Box>
  );
}
