//import React, { useRef, useEffect, useState } from "react";
import React, { useState, useCallback, Fragment } from "react";
import getUserLocale from "get-user-locale";
import { List, ListItemButton, ListItemText, Fab, Box } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import UpdateIcon from "@mui/icons-material/Edit";

import CajaListaSeleccion from "./CajaListaSeleccion";
import ModalContainer from "../../../Autenticados/ModalContainer";
import AgregarElemento from "./AgregarElemento";
import CambiarElemento from "./CambiarElemento";
import EliminarElemento from "./EliminarElemento";

import theme from "../../../../utils/theme";
import useToastContext from "../../../../hooks/useToastContext";

import { API_URL } from "../../../../utils/constants";
import useAuth from "../../../../auth/useAuth";
import { Severities } from "../../../../contexts/ToastContext";

import { const_TipoSeleccionEdicionFormularioClinico } from "../../../../utils/constants";
import useGlobalDataContext from "../../../../hooks/useGlobalDataContext";

export default function ListaSeleccion(props) {
  const {
    tipo,
    titulo,
    datos,
    seleccionado,
    seleccionar,
    padreId,
    setElementos,
    administrarCambioElementoRepositorio,
  } = props;

  const auth = useAuth();

  const addToast = useToastContext();
  const globalData = useGlobalDataContext();

  const codigoIdiomaLocal = getUserLocale();

  const [isOpenModalAgregar, setIsOpenModalAgregar] = useState(false);
  const [isOpenModalCambiar, setIsOpenModalCambiar] = useState(false);
  const [isOpenModalEliminar, setIsOpenModalEliminar] = useState(false);

  const [idSeleccionado, setIdSeleccionado] = useState();
  const [descripcionSeleccionado, setDescripcionSeleccionado] = useState();

  const openModalAgregar = () => {
    setIsOpenModalAgregar(true);
  };
  const closeModalAgregar = () => {
    setIsOpenModalAgregar(false);
  };

  const openModalCambiar = () => {
    setIsOpenModalCambiar(true);
  };
  const closeModalCambiar = () => {
    setIsOpenModalCambiar(false);
  };

  const openModalEliminar = () => {
    setIsOpenModalEliminar(true);
  };
  const closeModalEliminar = () => {
    setIsOpenModalEliminar(false);
  };

  const agregarElemento = useCallback(
    async (formValue) => {
      const url = `${API_URL()}/EditarFormularioClinico/addElemento`;
      let datos = {
        ...formValue,
        Token: auth.user.token,
        ParentId: padreId,
        CodigoIdioma: auth.user.codigoIdioma
          ? auth.user.codigoIdioma
          : codigoIdiomaLocal,
      };
      try {
        datos.Token = auth.user.token;
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(datos),
        };
        const res = await fetch(url, options);
        if (res.ok) {
          const json = await res.json();
          if (json.resultadoOK) {
            setElementos(json.elementos);
            closeModalAgregar();
          } else {
            json.errores.forEach((item) => {
              addToast(item, Severities.error);
            });
          }
        } else {
          //addToast("Error de servicio.", Severities.error);
          addToast(globalData.terminosLocalizados.AVI004, Severities.error);
        }
      } catch (err) {
        //addToast("Error de conexión", Severities.error);
        addToast(globalData.terminosLocalizados.AVI005, Severities.error);
      }
    },
    [
      auth,
      addToast,
      padreId,
      setElementos,
      globalData.terminosLocalizados.AVI004,
      globalData.terminosLocalizados.AVI005,
    ]
  );

  const cambiarElemento = useCallback(
    async (formValue) => {
      const url = `${API_URL()}/EditarFormularioClinico/putElemento`;
      let datos = {
        Token: auth.user.token,
        Tipo: formValue.Tipo,
        GuidCuentaAdicional: auth.cuentaActiva.guid,
        ParentId: padreId,
        Id: formValue.Id,
        DescripcionAnterior: formValue.DescripcionAnterior,
        DescripcionPosterior: formValue.Descripcion,
        CodigoIdioma: auth.user.codigoIdioma
          ? auth.user.codigoIdioma
          : codigoIdiomaLocal,
      };
      if (datos.ParentId === undefined) {
        datos.ParentId = 0;
      }
      try {
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(datos),
        };
        const res = await fetch(url, options);
        if (res.ok) {
          const json = await res.json();
          if (json.resultadoOK) {
            setElementos(json.elementos);
            setDescripcionSeleccionado(datos.DescripcionPosterior);
            closeModalCambiar();
            administrarCambioElementoRepositorio(
              formValue.Tipo,
              formValue.Id,
              datos.DescripcionPosterior
            );
          } else {
            json.errores.forEach((item) => {
              addToast(item, Severities.error);
            });
          }
        } else {
          addToast("Error de servicio.", Severities.error);
        }
      } catch (err) {
        addToast("Error de conexión", Severities.error);
      }
    },
    [
      auth,
      addToast,
      padreId,
      setElementos,
      administrarCambioElementoRepositorio,
    ]
  );

  const eliminarElemento = useCallback(
    async (formValue) => {
      const url = `${API_URL()}/EditarFormularioClinico/deleteElemento`;
      let datos = {
        Token: auth.user.token,
        Tipo: formValue.Tipo,
        GuidCuentaAdicional: auth.cuentaActiva.guid,
        ParentId: padreId,
        Id: formValue.Id,
        Descripcion: formValue.Descripcion,
        CodigoIdioma: auth.user.codigoIdioma
          ? auth.user.codigoIdioma
          : codigoIdiomaLocal,
      };
      if (datos.ParentId === undefined) {
        datos.ParentId = 0;
      }
      try {
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(datos),
        };
        const res = await fetch(url, options);
        if (res.ok) {
          const json = await res.json();
          if (json.resultadoOK) {
            setElementos(json.elementos);
            seleccionar(0);
            closeModalEliminar();
          } else {
            json.errores.forEach((item) => {
              addToast(item, Severities.error);
            });
          }
        } else {
          addToast("Error de servicio.", Severities.error);
        }
      } catch (err) {
        addToast("Error de conexión", Severities.error);
      }
    },
    [auth, addToast, padreId, setElementos, seleccionar]
  );

  const handleListItemClick = (id, descripcion) => {
    seleccionar(id);
    setIdSeleccionado(id);
    setDescripcionSeleccionado(descripcion);
  };

  const openAgregarElemento = (event) => {
    setIsOpenModalAgregar(true);
  };
  const closeAgregarElemento = () => {
    setIsOpenModalAgregar(false);
  };

  const openCambiarElemento = (event) => {
    setIsOpenModalCambiar(true);
  };
  const closeCambiarElemento = () => {
    setIsOpenModalCambiar(false);
  };

  const openEliminarElemento = (event) => {
    setIsOpenModalEliminar(true);
  };
  const closeEliminarElemento = () => {
    setIsOpenModalEliminar(false);
  };

  const elementos = datos;

  return (
    <CajaListaSeleccion titulo={titulo} tipo={tipo}>
      <List
        id="lista-seleccion-list"
        component="nav"
        aria-label="main malbox folders"
        dense
        sx={{ p: 0, maxHeight: "100%", overflow: "auto", height: "100%" }}
      >
        {elementos.map((item) => {
          return (
            <ListItemButton
              key={item.id}
              id="lista-seleccion-list-item-button"
              selected={seleccionado === item.id}
              onClick={(event) =>
                handleListItemClick(item.id, item.descripcion)
              }
            >
              <ListItemText id="lista-seleccion-list-item-text">
                {item.descripcion}
              </ListItemText>
            </ListItemButton>
          );
        })}
      </List>
      {(tipo === const_TipoSeleccionEdicionFormularioClinico.PROCESO ||
        padreId > 0) &&
        tipo !== const_TipoSeleccionEdicionFormularioClinico.TIPO_VARIABLE && (
          <Box sx={{ position: "absolute", right: 3, bottom: 3 }}>
            <Box>
              <Fab
                sx={{
                  color: theme.palette.primary.contrastText,
                  zIndex: 1,
                  width: 36, // Es lo mínimo que se puede establecer sin que se deforme
                  height: 36,
                }}
                onClick={openModalAgregar}
              >
                <AddIcon></AddIcon>
              </Fab>
              <ModalContainer
                isOpenModal={isOpenModalAgregar}
                closeModal={closeModalAgregar}
              >
                <AgregarElemento
                  tipo={tipo}
                  titulo={titulo}
                  open={openAgregarElemento}
                  close={closeAgregarElemento}
                  edicion={agregarElemento}
                />
              </ModalContainer>
            </Box>
            {seleccionado > "0" && (
              <Fragment>
                <Box>
                  <Fab
                    sx={{
                      color: theme.palette.primary.contrastText,
                      zIndex: 1,
                      width: 36, // Es lo mínimo que se puede establecer sin que se deforme
                      height: 36,
                    }}
                    onClick={openModalCambiar}
                  >
                    <UpdateIcon></UpdateIcon>
                  </Fab>
                  <ModalContainer
                    isOpenModal={isOpenModalCambiar}
                    closeModal={closeModalCambiar}
                  >
                    <CambiarElemento
                      tipo={tipo}
                      titulo={titulo}
                      open={openCambiarElemento}
                      close={closeCambiarElemento}
                      edicion={cambiarElemento}
                      id={idSeleccionado}
                      descripcion={descripcionSeleccionado}
                    />
                  </ModalContainer>
                </Box>
                <Box>
                  <Fab
                    sx={{
                      color: theme.palette.primary.contrastText,
                      zIndex: 1,
                      width: 36, // Es lo mínimo que se puede establecer sin que se deforme
                      height: 36,
                    }}
                    onClick={openModalEliminar}
                  >
                    <DeleteIcon></DeleteIcon>
                  </Fab>
                  <ModalContainer
                    isOpenModal={isOpenModalEliminar}
                    closeModal={closeModalEliminar}
                  >
                    <EliminarElemento
                      tipo={tipo}
                      titulo={titulo}
                      open={openEliminarElemento}
                      close={closeEliminarElemento}
                      id={idSeleccionado}
                      descripcion={descripcionSeleccionado}
                      edicion={eliminarElemento}
                    />
                  </ModalContainer>
                </Box>
              </Fragment>
            )}
          </Box>
        )}
    </CajaListaSeleccion>
  );
}
