import React from "react";

import { Select, MenuItem, InputLabel } from "@mui/material";
import { Controller } from "react-hook-form";

import useGlobalDataContext from "../../hooks/useGlobalDataContext";
import Loading from "../Feedback/Loading";

export default function SelectorPaises(props) {
  const { control, name, label } = props;

  const globalData = useGlobalDataContext();

  // No estoy usando este componente porque no logro que funcion lo del {...field}
  // que se recibe por render props en origen.

  return (
    <>
      <InputLabel>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field }) => (
          <Select
            // A los <Select> no se les aplica "disabled" porque le cambia el color al contenido
            inputProps={
              {
                //readOnly: !modoEdicion,
                //disabled: !modoEdicion
              }
            }
            label={label}
            MenuProps={{
              PaperProps: { sx: { maxHeight: 190, width: "" } },
            }}
            //onChange={onChange}
            {...field}
          >
            {globalData.loadingPaises ? (
              <Loading />
            ) : (
              globalData.paises.map((item, index) => {
                return (
                  <MenuItem key={index} value={item.codigo}>
                    {item.nombre}
                  </MenuItem>
                );
              })
            )}
          </Select>
        )}
      ></Controller>
    </>
  );
}
