import React from "react";
import { Box, Typography } from "@mui/material";
import theme from "../../utils/theme";

export default function MensajeError(props) {
  const { texto } = props;
  return (
    <Box
      width={"100%"}
      bgcolor={theme.palette.error.main}
      padding={1}
      borderRadius={3}
    >
      <Typography color={theme.palette.error.contrastText}>{texto}</Typography>
    </Box>
  );
}
