import React from "react";

import { Box, Typography } from "@mui/material";

import BoxPregunta from "./BoxPregunta";

import EdicionOpciones from "./EdicionOpciones";
import EdicionTextoPleno from "./EdicionTextoPleno";
import EdicionFecha from "./EdicionFecha";
import EdicionMultimedia from "./EdicionMultimedia";
import EdicionUnidadMedida from "./EdicionUnidadMedida";

import { const_TipoOrdenableFormularioClinico } from "../../../../utils/constants";

export default function EdicionListaPreguntas(props) {
  const {
    preguntas,
    handleQuitarPregunta,
    handleSubirPregunta,
    handleBajarPregunta,
  } = props;

  const indexUltimo = preguntas.length - 1;

  return (
    <Box>
      {preguntas.map((itemPreguntas, indexPreguntas) => {
        return (
          <>
            <BoxPregunta
              guid={itemPreguntas.guid}
              tipo={const_TipoOrdenableFormularioClinico.PREGUNTA}
              subible={indexPreguntas !== 0}
              bajable={indexPreguntas < indexUltimo}
              handleQuitarPregunta={handleQuitarPregunta}
              handleSubirPregunta={handleSubirPregunta}
              handleBajarPregunta={handleBajarPregunta}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  fontStyle: "italic",
                }}
              >
                {itemPreguntas.descripcion}:
              </Typography>
            </BoxPregunta>
            {itemPreguntas.unidadMedida != null && (
              <EdicionUnidadMedida
                itemPreguntas={itemPreguntas}
                handleQuitarPregunta={handleQuitarPregunta}
                handleSubirPregunta={handleSubirPregunta}
                handleBajarPregunta={handleBajarPregunta}
              />
            )}
            <EdicionOpciones
              opciones={itemPreguntas.opciones}
              handleQuitarPregunta={handleQuitarPregunta}
              handleSubirPregunta={handleSubirPregunta}
              handleBajarPregunta={handleBajarPregunta}
            />
            {itemPreguntas.textoPleno && (
              <EdicionTextoPleno
                itemPreguntas={itemPreguntas}
                handleQuitarPregunta={handleQuitarPregunta}
                handleSubirPregunta={handleSubirPregunta}
                handleBajarPregunta={handleBajarPregunta}
              />
            )}
            {itemPreguntas.fecha && (
              <EdicionFecha
                itemPreguntas={itemPreguntas}
                handleQuitarPregunta={handleQuitarPregunta}
                handleSubirPregunta={handleSubirPregunta}
                handleBajarPregunta={handleBajarPregunta}
              />
            )}
            {itemPreguntas.multimedia && (
              <EdicionMultimedia
                itemPreguntas={itemPreguntas}
                handleQuitarPregunta={handleQuitarPregunta}
                handleSubirPregunta={handleSubirPregunta}
                handleBajarPregunta={handleBajarPregunta}
              />
            )}
          </>
        );
      })}
    </Box>
  );
}
