import React from "react";

import { Box, Fab } from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import ArrowCircleDown from "@mui/icons-material/ArrowCircleDown";
import ArrowCircleUp from "@mui/icons-material/ArrowCircleUp";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import StackVertical from "../../../Feedback/Svg/StackVertical";
import StackHorizontal from "../../../Feedback/Svg/StackHorizontal";

import theme from "../../../../utils/theme";

export default function BoxPregunta(props) {
  const {
    guid,
    tipo,
    subible,
    bajable,
    suprimible,
    ocultable,
    alineable,
    handleQuitarPregunta,
    handleSubirPregunta,
    handleBajarPregunta,
    cambiarVisibilidad,
    cambiarAlineacionAreasPreguntas,
    areasPreguntasHorizontal,
    visible,
    children,
  } = props;

  const sxIconBox = {
    width: 36,
    minWidth: 36,
  };

  const sxFab = {
    color: theme.palette.primary.contrastText,
    zIndex: 1,
    width: 36, // Es lo mínimo que se puede establecer sin que se deforme
    height: 36,
    right: 0,
    "&.MuiFab-root:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  };

  function handleCambiarAlineacion(guid, areasPreguntasHorizontal) {
    cambiarAlineacionAreasPreguntas(guid, areasPreguntasHorizontal);
  }

  function handleCambiarVisibilidad(guid, visible) {
    cambiarVisibilidad(guid, visible);
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "&:hover": {
          backgroundColor: "divider",
        },
      }}
    >
      {children}
      <Box
        sx={{
          marginLeft: "auto", // Para que todo el bloque de íconos quede recostado a la derecha
          display: "flex",
          flexGrow: 0,
        }}
      >
        {alineable && (
          <Box sx={sxIconBox}>
            <Fab
              sx={sxFab}
              onClick={() =>
                handleCambiarAlineacion(guid, areasPreguntasHorizontal)
              }
            >
              {areasPreguntasHorizontal ? (
                <StackHorizontal />
              ) : (
                <StackVertical />
              )}
            </Fab>
          </Box>
        )}
        {ocultable && (
          <Box sx={sxIconBox}>
            <Fab
              sx={sxFab}
              disabled={areasPreguntasHorizontal}
              onClick={() => handleCambiarVisibilidad(guid, visible)}
            >
              {visible ? <Visibility /> : <VisibilityOff />}
            </Fab>
          </Box>
        )}
        <Box sx={sxIconBox}>
          <Fab
            sx={sxFab}
            onClick={() => handleSubirPregunta(guid, tipo)}
            disabled={!subible}
          >
            <ArrowCircleUp />
          </Fab>
        </Box>
        <Box sx={sxIconBox}>
          <Fab
            sx={sxFab}
            onClick={() => handleBajarPregunta(guid, tipo)}
            disabled={!bajable}
          >
            <ArrowCircleDown />
          </Fab>
        </Box>
        <Box sx={sxIconBox}>
          <Fab
            sx={sxFab}
            onClick={() => handleQuitarPregunta(guid, tipo)}
            disabled={!suprimible}
          >
            <DeleteIcon />
          </Fab>
        </Box>
      </Box>
    </Box>
  );
}
