import React from "react";
import {
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Checkbox,
} from "@mui/material";
import CajaListaSeleccion from "./CajaListaSeleccion";

export default function ListaSeleccionPropiedades(props) {
  const { titulo, datos, seleccionar, seleccionados } = props;

  const handleListItemClick = (id) => {
    seleccionar(id);
  };

  return (
    <CajaListaSeleccion titulo={titulo}>
      <List
        id="lista-seleccion-list"
        component="nav"
        aria-label="main malbox folders"
        dense
        sx={{ p: 0, maxHeight: "100%", overflow: "auto" }}
      >
        {datos.map((item) => {
          const labelId = `checkbox-list-label-${item.id}`;
          return (
            <ListItemButton
              key={item.id}
              id="lista-seleccion-list-item-button"
              role={undefined}
              onClick={() => handleListItemClick(item.id, "")}
              dense
            >
              <ListItemIcon sx={{ minWidth: 0 }}>
                <Checkbox
                  edge="start"
                  checked={seleccionados.indexOf(item.id) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={item.nombre} />
            </ListItemButton>
          );
        })}
      </List>
    </CajaListaSeleccion>
  );
}
