import React from "react";
import { useForm, Controller } from "react-hook-form";

import {
  TextField,
  Card,
  Typography,
  Stack,
  Button,
  Grid,
} from "@mui/material";

import ErrorSummary from "../Forms/ErrorSummary";

//import theme from "../../utils/theme";

export default function SignupForm(props) {
  const { close, agregar, terminos } = props;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  // En esta estructura se tienen los nombres de los campos en el idioma del usuario
  const descripcionCampos = {
    Usuario: terminos.ETI003,
    Email: terminos.ETI004,
    Telefono: terminos.ETI005,
    Password: terminos.ETI006,
    ConfirmarPassword: terminos.ETI007,
  };

  const cancelar = () => {
    close();
  };

  const agregarLocal = (data) => {
    agregar(data);
  };

  return (
    <Card
      id="signUpContainer"
      sx={{
        p: 2,
        //width: "min(400px,100vw)",
        width: "clamp(150px,100vw,400px)",
        borderRadius: "10px",
      }}
    >
      <form onSubmit={handleSubmit(agregarLocal)}>
        <Stack id="signUpStack" spacing={1}>
          <Typography variant="h5">{terminos.ETI008}</Typography>
          <Controller
            name="Usuario"
            control={control}
            rules={{
              required: {
                value: true,
              },
              maxLength: {
                value: 40,
              },
            }}
            render={({ field }) => (
              <TextField
                type="text"
                size="small"
                name="Usuario"
                label={descripcionCampos.Usuario}
                {...field}
              />
            )}
          ></Controller>
          <Controller
            name="Email"
            control={control}
            rules={{
              required: {
                value: true,
              },
              pattern: {
                value:
                  /^([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/, ///^(\[[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.)|(())([a-zA-Z]{2,4}|[0-9]{1,3})$/,
              },
              maxLength: {
                value: 255,
              },
            }}
            render={({ field }) => (
              <TextField
                type="text"
                size="small"
                name="Email"
                label={descripcionCampos.Email}
                sx={{ width: "100%" }}
                {...field}
              />
            )}
          ></Controller>
          <Controller
            name="Telefono"
            control={control}
            rules={{
              maxLength: {
                value: 40,
              },
            }}
            render={({ field }) => (
              <TextField
                type="text"
                size="small"
                name="Telefono"
                label={descripcionCampos.Telefono}
                {...field}
              />
            )}
          ></Controller>
          <Controller
            name="Password"
            control={control}
            rules={{
              maxLength: {
                value: 24,
              },
            }}
            render={({ field }) => (
              <TextField
                type="password"
                size="small"
                name="Password"
                label={descripcionCampos.Password}
                {...field}
              />
            )}
          ></Controller>
          <Controller
            name="ConfirmarPassword"
            control={control}
            rules={{
              maxLength: {
                value: 24,
              },
            }}
            render={({ field }) => (
              <TextField
                type="password"
                size="small"
                name="ConfirmarPassword"
                label={descripcionCampos.ConfirmarPassword}
                {...field}
              />
            )}
          ></Controller>
          <Grid
            container
            id="commandBarGrid"
            justifyContent={"flex-end"}
            spacing={0.5}
            width="100%"
          >
            <Grid item>
              <Button variant="CommandBar2" type="submit">
                {terminos.COM003}
              </Button>
            </Grid>
            <Grid item>
              <Button variant="CommandBar2" onClick={cancelar}>
                {terminos.COM004}
              </Button>
            </Grid>
          </Grid>
          <ErrorSummary
            errors={errors}
            descripciones={descripcionCampos}
            textoRequerido={terminos.AVI007}
            textoNoValido={terminos.AVI008}
          ></ErrorSummary>
        </Stack>
      </form>
    </Card>
  );
}
