import React, { useCallback, useEffect, useState, createContext } from "react";
import { Box } from "@mui/material";

const ToastContext = createContext();

export default ToastContext;

export const Severities = { info: 1, alert: 2, error: 3 };

export function ToastContextProvider({ children }) {
  const [toasts, setToasts] = useState([{ text: "", severity: "" }]);

  useEffect(() => {
    if (toasts.length > 0) {
      const timer = setTimeout(
        () => setToasts((toasts) => toasts.slice(1)),
        4000
      );
      return () => clearTimeout(timer);
    }
  }, [toasts]);

  const addToast = useCallback(
    function (text, severity) {
      const newToast = { text: text, severity: severity };
      setToasts((toasts) => [...toasts, newToast]);
    },
    [setToasts]
  );

  return (
    <ToastContext.Provider value={addToast}>
      {children}
      <Box
        sx={{
          position: "fixed",
          bottom: "1rem",
          left: "1rem",
        }}
        className="toasts-wrapper"
      >
        {toasts.map((toast) => {
          switch (toast.severity) {
            case Severities.info:
              return (
                <Box
                  sx={{
                    padding: "0.5rem 1rem",
                    color: "white",
                    backgroundColor: "green",
                    marginBottom: "0.5rem",
                  }}
                  key={toast.text}
                >
                  {toast.text}
                </Box>
              );
            case Severities.alert:
              return (
                <Box
                  sx={{
                    padding: "0.5rem 1rem",
                    color: "white",
                    backgroundColor: "yellow",
                    marginBottom: "0.5rem",
                  }}
                  key={toast.text}
                >
                  {toast.text}
                </Box>
              );
            case Severities.error:
              return (
                <Box
                  sx={{
                    padding: "0.5rem 1rem",
                    color: "white",
                    backgroundColor: "red",
                    marginBottom: "0.5rem",
                  }}
                  key={toast.text}
                >
                  {toast.text}
                </Box>
              );
            default:
              return null;
          }
        })}
      </Box>
    </ToastContext.Provider>
  );
}
