import React, { useEffect, useState, createContext } from "react";
import getUserLocale from "get-user-locale";

import { API_URL } from "../utils/constants";
import useAuth from "../auth/useAuth";
import useToastContext from "../hooks/useToastContext";

const GlobalDataContext = createContext();

export default GlobalDataContext;

export const Severities = { info: 1, alert: 2, error: 3 };

export function GlobalDataContextProvider({ children }) {
  const [paises, setPaises] = useState(null);
  const [loadingPaises, setLoadingPaises] = useState(true);

  const [idiomas, setIdiomas] = useState(null);
  const [loadingIdiomas, setLoadingIdiomas] = useState(true);

  const [terminosLocalizados, setTerminosLocalizados] = useState(null);
  const [loadingTerminosLocalizados, setLoadingTerminosLocalizados] =
    useState(true);

  const contextValue = {
    paises,
    loadingPaises,
    idiomas,
    loadingIdiomas,
    terminosLocalizados,
    loadingTerminosLocalizados,
  };

  const auth = useAuth();

  const addToast = useToastContext();

  // Obtener los países
  useEffect(() => {
    (async () => {
      try {
        const url = `${API_URL()}/GlobalData/getPaises`;
        let datos = {
          GuidCuentaAdicional: "",
        };
        //
        // *** ATENCIÓN ***
        // No puedo asignar la cuenta adicional activa porque no logro que tenga valor
        // antes de ejecutar este código (es probable que todavía no estén cargados
        // los datos de autenticación).
        // *****************
        // if (auth.activeAddicionalAccount !== "undefined") {
        //   datos.GuidCuentaAdicional = auth.activeAddicionalAccount.guid;
        // }
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(datos),
        };
        const res = await fetch(url, options);
        if (res.ok) {
          const json = await res.json();
          if (json.resultadoOK) {
            setPaises(json.lista);
            setLoadingPaises(false);
          } else {
            json.errores.forEach((item) => {
              addToast(item, Severities.error);
            });
          }
        } else {
        }
      } catch (err) {}
    })();
  }, [loadingPaises, addToast, setPaises]);

  // Obtener los idiomas
  useEffect(() => {
    (async () => {
      try {
        const url = `${API_URL()}/GlobalData/getIdiomas`;
        let datos = {
          GuidCuentaAdicional: "",
        };
        //
        // *** ATENCIÓN ***
        // No puedo asignar la cuenta adicional activa porque no logro que tenga valor
        // antes de ejecutar este código (es probable que todavía no estén cargados
        // los datos de autenticación).
        // *****************
        // if (auth.activeAddicionalAccount !== "undefined") {
        //   datos.GuidCuentaAdicional = auth.activeAddicionalAccount.guid;
        // }
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(datos),
        };
        const res = await fetch(url, options);
        if (res.ok) {
          const json = await res.json();
          if (json.resultadoOK) {
            setIdiomas(json.lista);
            setLoadingIdiomas(false);
          } else {
            json.errores.forEach((item) => {
              addToast(item, Severities.error);
            });
          }
        } else {
        }
      } catch (err) {}
    })();
  }, [loadingIdiomas, addToast, setIdiomas]);

  // Obtener términos localizados
  useEffect(() => {
    (async () => {
      if (auth.user === null) {
        return;
      }
      try {
        setLoadingTerminosLocalizados(true);
        const url = `${API_URL()}/Internacionalizacion/getTerminosLocalizados`;

        const codigoIdiomaLocal = getUserLocale();
        const codigoIdiomaDefecto = codigoIdiomaLocal.slice(0, 2);
        const codigoIdioma =
          auth.user.codigoIdioma !== null
            ? auth.user.codigoIdioma
            : codigoIdiomaDefecto;

        let request = {
          Token: auth.user.token,
          Idioma: codigoIdioma,
        };
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(request),
        };
        const res = await fetch(url, options);
        if (res.ok) {
          const json = await res.json();
          if (json.resultadoOK) {
            const objetoTerminos = {};
            json.terminosLocalizados.forEach((item, i) => {
              objetoTerminos[item.codigoTermino] = item.texto;
            });
            setTerminosLocalizados(objetoTerminos);
            setLoadingTerminosLocalizados(false);
          } else {
            json.errores.forEach((item) => {
              addToast(item, Severities.error);
            });
          }
        } else {
        }
      } catch (err) {}
    })();
  }, [addToast, setTerminosLocalizados, auth.user]);

  return (
    <GlobalDataContext.Provider value={contextValue}>
      {children}
    </GlobalDataContext.Provider>
  );
}
