import React, { useState } from "react";
import { Box, Checkbox, Typography } from "@mui/material";

import { uso_barra_titulo } from "./Formulario";
import { tipos_respuesta } from "../../../../pages/ClinicFormPage";

export default function BarraTituloOtrasEntradas(props) {
  const {
    texto,
    uso,
    omitirCompartir,
    inhabilitado,
    formularioClinico,
    actualizarOtrasEntradas,
  } = props;

  const [compartido, setCompartido] = useState(false);

  // Establecer el estado inicial según el uso recibido
  useState(() => {
    switch (uso) {
      case tipos_respuesta.compartidoPrescripcion:
        setCompartido(formularioClinico.prescripcion.compartido);
        break;
      case tipos_respuesta.compartidoPrescripcionTest:
        setCompartido(formularioClinico.prescripcionTest.compartido);
        break;
      case tipos_respuesta.compartidoNotasProfesional:
        setCompartido(formularioClinico.notasProfesionales.compartido);
        break;
      case tipos_respuesta.compartidoArchivosAdjuntos:
        setCompartido(formularioClinico.archivosAdjuntos.compartido);
        break;
      default:
        setCompartido(false);
    }
  }, [formularioClinico, uso]);

  const handleOnChange = (event) => {
    const nuevoValor = event.target.checked;
    setCompartido(nuevoValor);
    var tipoRespuesta;
    switch (uso) {
      case uso_barra_titulo.prescripcion:
        tipoRespuesta = tipos_respuesta.compartidoPrescripcion;
        break;
      case uso_barra_titulo.prescripcionTest:
        tipoRespuesta = tipos_respuesta.compartidoPrescripcionTest;
        break;
      case uso_barra_titulo.notasProfesional:
        tipoRespuesta = tipos_respuesta.compartidoNotasProfesional;
        break;
      case uso_barra_titulo.archivosAdjuntos:
        tipoRespuesta = tipos_respuesta.compartidoArchivosAdjuntos;
        break;
      default:
        throw new Error(
          `handleOnChange estado global: El tipo de uso "${uso}" no está implementada`
        );
    }
    actualizarOtrasEntradas(tipoRespuesta, nuevoValor);
  };

  return (
    <Box
      height={"20px"}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems="end"
      width="100%"
    >
      <Box flexGrow={1} width={"calc(100% - 20px)"}>
        <Typography
          fontSize={11}
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {texto}
        </Typography>
      </Box>
      {!omitirCompartir && (
        <Checkbox
          size="small"
          checked={compartido}
          onChange={handleOnChange}
          disabled={inhabilitado}
          sx={{
            padding: 0,
            // Para que el checkbox sea lo más pequeño posible
            "&.MuiSvgIcon-root": { fontSize: "1em" },
          }}
        />
      )}
    </Box>
  );
}
