import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import {
  Grid,
  Button,
  Stack,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";

import useAuth from "../../../auth/useAuth";
import ErrorSummary from "../../Forms/ErrorSummary";
import useGlobalDataContext from "../../../hooks/useGlobalDataContext";
import Loading from "../../Feedback/Loading";
import { API_URL } from "../../../utils/constants";

import useToastContext from "../../../hooks/useToastContext";
import { Severities } from "../../../contexts/ToastContext";

const anchoBotones = "150px";

export default function InformacionInstitucion() {
  const [modoEdicion, setModoEdicion] = useState(false);

  const auth = useAuth();
  const globalData = useGlobalDataContext();
  const addToast = useToastContext();

  // En esta estructura se tienen los nombres de los campos en el idioma del usuario
  // Se recibirán del servidor cuando esté implementado el multi-lenguaje.
  const descripcionCampos = {
    Nombre: globalData.terminosLocalizados.ETI108,
    Email: globalData.terminosLocalizados.ETI085,
    Telefono: globalData.terminosLocalizados.ETI005,
    CodigoPais: globalData.terminosLocalizados.ETI071,
    Provincia: globalData.terminosLocalizados.ETI113,
    Ciudad: globalData.terminosLocalizados.ETI114,
    Direccion: globalData.terminosLocalizados.ETI086,
    PaginaWeb: globalData.terminosLocalizados.ETI115,
  };

  const textos = {
    tituloInformacionPersonal: globalData.terminosLocalizados.ETI111,
    tituloInformacionContacto: globalData.terminosLocalizados.ETI082,
    etiquetaNombre: globalData.terminosLocalizados.ETI108,
    etiquetaEmail: globalData.terminosLocalizados.ETI085,
    etiquetaTelefono: globalData.terminosLocalizados.ETI005,
    etiquetaCodigoPais: globalData.terminosLocalizados.ETI112,
    etiquetaProvincia: globalData.terminosLocalizados.ETI113,
    etiquetaCiudad: globalData.terminosLocalizados.ETI114,
    etiquetaDireccion: globalData.terminosLocalizados.ETI086,
    etiquetaPaginaWeb: globalData.terminosLocalizados.ETI115,
    textoEditar: globalData.terminosLocalizados.COM040,
    textoGuardar: globalData.terminosLocalizados.COM041,
    textoCancelar: globalData.terminosLocalizados.COM004,
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      Nombre: "",
      Email: "",
      Telefono: "",
      CodigoPais: "",
      Provincia: "",
      Ciudad: "",
      Direccion: "",
      PaginaWeb: "",
    },
  });

  // Obtener información de la cuenta adicional de profesional
  useEffect(() => {
    (async () => {
      try {
        const request = {
          Token: auth.user.token,
          Guid: auth.cuentaActiva.guid,
        };
        const url = `${API_URL()}/Users/getCuentaInstitucion`;
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(request),
        };
        const res = await fetch(url, options);
        if (res.ok) {
          const json = await res.json();
          //console.log(json);
          if (json.resultadoOK) {
            reset({
              Nombre: json.datos.nombre,
              Email: json.datos.email,
              Telefono: json.datos.telefono,
              CodigoPais: json.datos.codigoPais,
              Provincia: json.datos.provincia,
              Ciudad: json.datos.ciudad,
              Direccion: json.datos.direccion,
              PaginaWeb: json.datos.paginaWeb,
            });
          } else {
            json.errores.forEach((item) => {
              addToast(item, Severities.error);
            });
          }
        } else {
        }
      } catch (err) {
        addToast(globalData.terminosLocalizados.AVI005, Severities.error);
      }
    })();
    // eslint-disable-next-line
  }, [addToast, auth.cuentaActiva.guid, auth.user.token]);

  const actualizarInformacionInstitucion = async (data) => {
    const request = {
      Token: auth.user.token,
      Guid: auth.cuentaActiva.guid,
    };
    request.Datos = {
      Nombre: data.Nombre,
      Email: data.Email,
      Telefono: data.Telefono,
      CodigoPais: data.CodigoPais,
      Provincia: data.Provincia,
      Ciudad: data.Ciudad,
      Direccion: data.Direccion,
      PaginaWeb: data.PaginaWeb,
    };
    const url = `${API_URL()}/users/putCuentaInstitucion`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(request),
    };
    try {
      const res = await fetch(url, options);
      if (res.ok) {
        const json = await res.json();
        if (json.resultadoOK) {
        } else {
          json.errores.forEach((item) => {
            addToast(item, Severities.error);
          });
        }
      } else {
        addToast(globalData.terminosLocalizados.AVI004, Severities.error);
      }
    } catch (err) {
      addToast(globalData.terminosLocalizados.AVI005, Severities.error);
    }
  };

  const handleEditar = () => {
    setModoEdicion(true);
  };

  const handleGuardar = (data) => {
    actualizarInformacionInstitucion(data);
    setModoEdicion(false);
  };

  const handleCancelar = () => {
    setModoEdicion(false);
  };

  return (
    <form onSubmit={handleSubmit(handleGuardar)}>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={1}
        maxWidth="700px"
        margin={1}
        width="100%"
      >
        <RenderDatosInstitucion
          control={control}
          inputProps={{ readOnly: !modoEdicion, disabled: !modoEdicion }}
          selectInputProps={{ readOnly: !modoEdicion }}
          textos={textos}
          modoEdicion={modoEdicion}
        />
        <Grid item md={12}>
          <RenderCommandBar
            modoEdicion={modoEdicion}
            handleEditar={handleEditar}
            handleCancelar={handleCancelar}
          />
        </Grid>
        <Grid item md={12}>
          <ErrorSummary
            errors={errors}
            descripciones={descripcionCampos}
          ></ErrorSummary>
        </Grid>
      </Grid>
      {/* </Box> */}
    </form>
  );
}

function RenderDatosInstitucion(props) {
  const {
    control,
    // Estas propiedades son para todos los campos excepto los de tipo <Select>
    inputProps,
    // Si hubiera algún campo de tipo <Select> se le debería aplicar estas propiedades
    //selectInputProps,
    textos,
    modoEdicion,
  } = props;

  const globalData = useGlobalDataContext();

  return (
    <>
      <Grid item md={12} width="100%">
        <Typography variant="h7" fontStyle="italic">
          {textos.tituloInformacionPersonal}
        </Typography>
      </Grid>
      <Grid item md={12}>
        <Controller
          name="Nombre"
          control={control}
          rules={{
            maxLength: {
              value: 40,
            },
          }}
          render={({ field }) => (
            <TextField
              type="text"
              size="small"
              name={textos.etiquetaNombre}
              label={textos.etiquetaNombre}
              inputProps={inputProps}
              {...field}
            />
          )}
        ></Controller>
      </Grid>
      <Grid item md={12}>
        <Controller
          name="PaginaWeb"
          control={control}
          rules={{
            maxLength: {
              value: 40,
            },
          }}
          render={({ field }) => (
            <TextField
              type="text"
              name={textos.etiquetaPaginaWeb}
              label={textos.etiquetaPaginaWeb}
              size="small"
              inputProps={inputProps}
              {...field}
            />
          )}
        ></Controller>
      </Grid>
      <Grid item md={12} width="100%">
        <Typography variant="h7" fontStyle="italic">
          {textos.tituloInformacionContacto}
        </Typography>
      </Grid>
      <Grid item md={12}>
        <Controller
          name="Email"
          control={control}
          rules={{
            required: {
              value: true,
            },
            pattern: {
              value:
                // Este es el patern original, con \. para indicar el punto en la columna 28,
                // pero emite una advertencia diciendo que es escape es innecesario.
                // Lo quito, pero dejo el original en comentarios, por las dudas.
                // /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/,
                /^([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/,
            },
          }}
          render={({ field }) => (
            <TextField
              type="text"
              name={textos.etiquetaEmail}
              label={textos.etiquetaEmail}
              size="small"
              inputProps={inputProps}
              {...field}
            />
          )}
        ></Controller>
      </Grid>
      <Grid item md={12}>
        <Controller
          name="Telefono"
          control={control}
          rules={{
            maxLength: {
              value: 40,
            },
          }}
          render={({ field }) => (
            <TextField
              type="text"
              name={textos.etiquetaTelefono}
              label={textos.etiquetaTelefono}
              size="small"
              inputProps={inputProps}
              {...field}
            />
          )}
        ></Controller>
      </Grid>
      <Grid item md={12} width="100%">
        <FormControl size="small">
          <InputLabel>{textos.etiquetaCodigoPais}</InputLabel>
          <Controller
            name="CodigoPais"
            control={control}
            render={({ field }) => (
              <Select
                // A los <Select> no se les aplica "disabled" porque le cambia el color al contenido
                inputProps={{
                  readOnly: !modoEdicion,
                  //disabled: !modoEdicion
                }}
                label={textos.etiquetaCodigoPais}
                MenuProps={{
                  PaperProps: { sx: { maxHeight: 190, width: "" } },
                }}
                {...field}
              >
                {globalData.loadingPaises ? (
                  <Loading />
                ) : (
                  globalData.paises.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.codigo}>
                        {item.nombre}
                      </MenuItem>
                    );
                  })
                )}
              </Select>
            )}
          ></Controller>
        </FormControl>
      </Grid>
      <Grid item md={6}>
        <Controller
          name="Provincia"
          control={control}
          rules={{
            maxLength: {
              value: 40,
            },
          }}
          render={({ field }) => (
            <TextField
              type="text"
              name={textos.etiquetaProvincia}
              label={textos.etiquetaProvincia}
              size="small"
              inputProps={inputProps}
              {...field}
            />
          )}
        ></Controller>
      </Grid>
      <Grid item md={6}>
        <Controller
          name="Ciudad"
          control={control}
          rules={{
            maxLength: {
              value: 40,
            },
          }}
          render={({ field }) => (
            <TextField
              type="text"
              name={textos.etiquetaCiudad}
              label={textos.etiquetaCiudad}
              size="small"
              inputProps={inputProps}
              {...field}
            />
          )}
        ></Controller>
      </Grid>
      <Grid item md={12}>
        <Controller
          name="Direccion"
          control={control}
          rules={{
            maxLength: {
              value: 40,
            },
          }}
          render={({ field }) => (
            <TextField
              type="text"
              name={textos.etiquetaDireccion}
              label={textos.etiquetaDireccion}
              size="small"
              inputProps={inputProps}
              {...field}
            />
          )}
        ></Controller>
      </Grid>
    </>
  );
}

function RenderCommandBar(props) {
  const { modoEdicion, handleEditar, handleCancelar } = props;

  const globalData = useGlobalDataContext();

  const textos = {
    textoEditar: globalData.terminosLocalizados.COM040,
    textoGuardar: globalData.terminosLocalizados.COM041,
    textoCancelar: globalData.terminosLocalizados.COM004,
  };

  return (
    <Stack direction={"row"} spacing={1} justifyContent="flex-start">
      {!modoEdicion && (
        <Button
          variant="CommandBar3"
          onClick={handleEditar}
          sx={{
            width: anchoBotones,
          }}
        >
          {textos.textoEditar}
        </Button>
      )}
      {modoEdicion && (
        <Button
          variant="CommandBar3"
          type="submit"
          sx={{
            width: anchoBotones,
          }}
        >
          {textos.textoGuardar}
        </Button>
      )}
      {modoEdicion && (
        <Button
          variant="CommandBar3"
          onClick={handleCancelar}
          sx={{
            width: anchoBotones,
          }}
        >
          {textos.textoCancelar}
        </Button>
      )}
    </Stack>
  );
}
