import React, { useEffect, useState, useRef, useCallback } from "react";

import {
  Grid,
  Box,
  Button,
  useMediaQuery,
  Typography,
  Slide,
} from "@mui/material";

import uuid from "react-uuid";

import Formulario from "../components/Datos/FormularioClinico/Formulario/Formulario";
import InfoPaciente from "../components/Datos/FormularioClinico/InfoPaciente/InfoPaciente";
import { API_URL } from "../utils/constants";
import useAuth from "../auth/useAuth";
import useToastContext from "../hooks/useToastContext";
import { Severities } from "../contexts/ToastContext";
import useGlobalDataContext from "../hooks/useGlobalDataContext";
import theme from "../utils/theme";
import { getScrollbarWidth } from "../utils/generalUtils";
import Loading from "../components/Feedback/Loading";

import { tipoEstadoArchivo } from "../utils/constants";

export const tipos_respuesta = {
  fecha: "FECHA",
  multimedia: "MULTIMEDIA",
  opciones: "OPCIONES",
  opcionesInformacionAdicional: "OPCIONES_INFORMACION_ADICIONAL",
  textoPleno: "TEXTO_PLENO",
  unidadMedida: "UNIDAD_MEDIDA",
  unidadMedidaValoracion: "UNIDAD_MEDIDA_VALORACION",
  compartido: "COMPARTIDO",
  prescripcion: "PRESCRIPCION",
  prescripcionTest: "PRESCRIPCION_TEST",
  notasProfesional: "NOTAS_PROFESIONAL",
  archivosAdjuntos: "ARCHIVOS_ADJUNTOS",
  usuariosEtiquetados: "USUARIOS_ETIQUETADOS",
  compartidoPrescripcion: "COMPARTIDO_PRESCRIPCION",
  compartidoPrescripcionTest: "COMPARTIDO_PRESCRIPCION_TEST",
  compartidoNotasProfesional: "COMPARTIDO_NOTAS_PROFESIONAL",
  compartidoArchivosAdjuntos: "COMPARTIDO_ARCHIVOS_ADJUNTOS",
};

export default function ClinicFormPage() {
  const [paciente, setPaciente] = useState({
    estado: {
      cargado: false,
      creando: false,
    },
  });

  const containerRef = useRef(null);

  const [formularioClinico, setFormularioClinico] = useState(null);
  const [loadingFormulario, setLoadingFormulario] = useState(true);

  const [publicando, setPublicando] = useState(false);

  const [textoBusqueda, setTextoBusqueda] = useState("");

  const [registrosAntiguos, setRegistrosAntiguos] = useState({
    fecha: null,
    usarFechaActual: false,
    estado: {
      ingresando: false,
      enviandoArchivos: false,
      cantidadArchivosEnviar: null,
      cantidadArchivosEnviados: null,
      archivosEnviados: false,
      enviando: false,
      enviado: false,
    },
  });

  const [archivosAntiguos, setArchivosAntiguos] = useState([
    {
      guid: uuid(),
      archivo: null,
      preview: null,
      nombre: "",
      nota: "",
      contador: 0,
      estado: { cargado: false },
    },
  ]);

  // Por defecto, visible únicamente la timeline
  const [visibilidadElementos, setVisibilidadElementos] = useState({
    timeline: true,
    formulario: false,
    prescripciones: false,
    muestraPaciente: false,
  });

  // Por defecto, visible únicamente la navegación hacia la derecha del formulario
  // y hacia la muestra de paciente
  const [visibilidadNavegacion, setVisibilidadNavegacion] = useState({
    timeline: false,
    formulario: { izquierda: false, derecha: true },
    prescripciones: { izquierda: false, derecha: false },
    muestraPaciente: false,
  });

  const [slideDirection, setSlideDirection] = useState("down");

  const downMD = useMediaQuery(theme.breakpoints.down("md"));

  const auth = useAuth();
  const addToast = useToastContext();
  const globalData = useGlobalDataContext();

  const urlFormularioClinico = `${API_URL()}/FormularioClinico/getFormularioClinico`;

  const textos = {
    publicacionAgregada: globalData.terminosLocalizados.AVI027,
    registrosAntiguosAgregados: globalData.terminosLocalizados.AVI036,
    navegacion: {
      timeline: globalData.terminosLocalizados.ETI161,
      formulario: "Formulario Clínico",
      prescripciones: globalData.terminosLocalizados.ETI163,
      muestraPaciente: globalData.terminosLocalizados.ETI164,
    },
  };

  const actualizarEstadoElementos = (
    timeline,
    formulario,
    prescripciones,
    muestraPaciente
  ) => {
    setVisibilidadElementos({
      timeline: timeline,
      formulario: formulario,
      prescripciones: prescripciones,
      muestraPaciente: muestraPaciente,
    });
    setVisibilidadNavegacion({
      timeline: formulario,
      formulario: { izquierda: prescripciones, derecha: timeline },
      prescripciones: { izquierda: muestraPaciente, derecha: formulario },
      muestraPaciente: prescripciones,
    });
  };

  const handleClickNavegacion = (event) => {
    var flagTimeline = event.target.dataset.elemento === "timeline";
    var flagFormulario = event.target.dataset.elemento === "formulario";
    var FlagPrescripciones = event.target.dataset.elemento === "prescripciones";
    var FlagMuestraPaciente =
      event.target.dataset.elemento === "muestraPaciente";
    actualizarEstadoElementos(
      flagTimeline,
      flagFormulario,
      FlagPrescripciones,
      FlagMuestraPaciente
    );
    switch (event.target.dataset.tipo) {
      case "up":
        setSlideDirection("down");
        break;
      case "left":
        setSlideDirection("right");
        break;
      case "right":
        setSlideDirection("left");
        break;
      default:
        // Por defecto, que se desplace desde arriba
        setSlideDirection("down");
    }
  };

  const publicar = () => {
    if (validarPublicacion()) {
      iniciarPublicacion();
      subirArchivosRespuestaMultimedia();
      subirArchivosAdjuntos();
    } else {
      addToast(globalData.terminosLocalizados.AVI043, Severities.error);
      setPublicando(false);
    }
  };

  const validarPublicacion = () => {
    if (formularioClinico.archivosAdjuntos.archivos.length !== 0) {
      return true;
    }
    var valido = false;
    formularioClinico.procesos.forEach((item) => {
      if (valido) {
        return;
      }
      item.areasSalud.forEach((item) => {
        item.especialidades.forEach((item) => {
          if (valido) {
            return;
          }
          item.secciones.forEach((item) => {
            item.areasPreguntas.forEach((item) => {
              if (valido) {
                return;
              }
              item.preguntas.forEach((item) => {
                if (valido) {
                  return;
                }
                if (
                  (item.multimedia &&
                    item.respuestaMultimedia.archivo !== null) ||
                  item.respuestaFecha !== null ||
                  item.respuestaOpciones !== "" ||
                  item.respuestaOpcionesTextoAdicional !== "" ||
                  item.respuestaTextoPleno !== "" ||
                  item.respuestaUnidadMedidaValoracion !== ""
                ) {
                  valido = true;
                }
                if (item.respuestaMultimedia !== null) {
                  if (item.respuestaMultimedia.archivo !== null) {
                    valido = true;
                  }
                }
              });
            });
          });
        });
      });
    });
    return valido;
  };

  // Establece que se inicia la publicación.
  // Se enciende el flag "enviar", que indica al hook de envío que debe
  // monitorear el estado del envío de los archivos adjuntos
  // y las respuestas multimedia para ver si ya puede
  // comenzar a enviar la publicación.
  const iniciarPublicacion = () => {
    setFormularioClinico((previous) => ({
      ...previous,
      estado: {
        ...previous.estado,
        enviar: true,
      },
    }));
  };

  // ------------ Respuestas Multimedia --------------

  const subirArchivosRespuestaMultimedia = () => {
    var cantidadEnviar = 0;
    // Calcular la cantidad de archivos a enviar
    formularioClinico.procesos.forEach((item) => {
      item.areasSalud.forEach((item) => {
        item.especialidades.forEach((item) => {
          item.secciones.forEach((item) => {
            item.areasPreguntas.forEach((item) => {
              item.preguntas.forEach((item) => {
                if (item.respuestaMultimedia !== null) {
                  if (item.respuestaMultimedia.archivo !== null) {
                    cantidadEnviar += 1;
                  }
                }
              });
            });
          });
        });
      });
    });
    // Actualizar el estado
    setFormularioClinico((previous) => ({
      ...previous,
      estado: {
        ...previous.estado,
        cantidadRespuestasMultimediaEnviar: cantidadEnviar,
        cantidadRespuestasMultimediaEnviadas: 0,
        enviandoRespuestasMultimedia: true,
      },
    }));
    // Enviar los archivos
    formularioClinico.procesos.forEach((item) => {
      item.areasSalud.forEach((item) => {
        item.especialidades.forEach((item) => {
          item.secciones.forEach((item) => {
            item.areasPreguntas.forEach((item) => {
              item.preguntas.forEach((item) => {
                if (item.respuestaMultimedia !== null) {
                  if (item.respuestaMultimedia.archivo !== null) {
                    subirArchivoRespuestaMultimedia(item);
                  }
                }
              });
            });
          });
        });
      });
    });
  };

  async function subirArchivoRespuestaMultimedia(item) {
    const url = `${API_URL()}/FormularioClinico/addRespuestaMultimedia`;
    const request = crearRequestSubirArchivoRespuestaMultimedia(item);
    try {
      const options = {
        method: "POST",
        body: request,
      };
      actualizarEstadoArchivoRespuestaMultimedia(
        item.guid,
        tipoEstadoArchivo.SUBIENDO
      );
      const res = await fetch(url, options);
      if (res.ok) {
        const json = await res.json();
        if (json.resultadoOK) {
          actualizarEstadoArchivoRespuestaMultimedia(
            item.guid,
            tipoEstadoArchivo.SUBIDO
          );
        } else {
          json.errores.forEach((item) => {
            addToast(item, Severities.error);
          });
          actualizarEstadoArchivoRespuestaMultimedia(
            item.guid,
            tipoEstadoArchivo.ERROR
          );
        }
      } else {
        addToast(globalData.terminosLocalizados.AVI004, Severities.error);
        actualizarEstadoArchivoRespuestaMultimedia(
          item.guid,
          tipoEstadoArchivo.ERROR
        );
      }
    } catch (err) {
      addToast(globalData.terminosLocalizados.AVI005, Severities.error);
      actualizarEstadoArchivoRespuestaMultimedia(
        item.guid,
        tipoEstadoArchivo.ERROR
      );
    }
  }

  const crearRequestSubirArchivoRespuestaMultimedia = (item) => {
    const request = new FormData();
    request.append("Token", auth.user.token);
    request.append("Guid", item.respuestaMultimedia.guid);
    request.append("File", item.respuestaMultimedia.archivo);
    return request;
  };

  const actualizarEstadoArchivoRespuestaMultimedia = (
    guidArchivo,
    tipoEstado
  ) => {
    setFormularioClinico((previous) => {
      const nuevo = { ...previous };
      nuevo.procesos.map((item) => {
        item.areasSalud.map((item) => {
          item.especialidades.map((item) => {
            item.secciones.map((item) => {
              item.areasPreguntas.map((item) => {
                item.preguntas.map((item) => {
                  if (item.respuestaMultimedia !== null) {
                    return item.respuestaMultimedia.guid === guidArchivo
                      ? {
                          ...item,
                          estado: {
                            ...item.estado,
                            subiendo: tipoEstado === tipoEstadoArchivo.SUBIENDO,
                            subido: tipoEstado === tipoEstadoArchivo.SUBIDO,
                            error: tipoEstado === tipoEstadoArchivo.ERROR,
                          },
                        }
                      : item;
                  } else {
                    return item;
                  }
                });
                return item;
              });
              return item;
            });
            return item;
          });
          return item;
        });
        return item;
      });
      return nuevo;
    });
    if (tipoEstado === tipoEstadoArchivo.SUBIDO) {
      setFormularioClinico((previous) => ({
        ...previous,
        estado: {
          ...previous.estado,
          cantidadRespuestasMultimediaEnviadas:
            previous.estado.cantidadRespuestasMultimediaEnviadas + 1,
        },
      }));
    }
  };

  // ------------ Archivos Adjuntos --------------

  const subirArchivosAdjuntos = () => {
    const cantidadEnviar = formularioClinico.archivosAdjuntos.archivos.length;
    // Actualizar el estado
    setFormularioClinico((previous) => ({
      ...previous,
      estado: {
        ...previous.estado,
        cantidadArchivosAdjuntosEnviar: cantidadEnviar,
        cantidadArchivosAdjuntosEnviadas: 0,
        enviarArchivosAdjuntos: true,
      },
    }));
    // Enviar los archivos
    formularioClinico.archivosAdjuntos.archivos.forEach((item) => {
      subirArchivoAdjunto(item);
    });
  };

  async function subirArchivoAdjunto(item) {
    const url = `${API_URL()}/FormularioClinico/addArchivoPublicacion`;
    const request = crearRequestSubirArchivoAdjunto(item);
    try {
      const options = {
        method: "POST",
        body: request,
      };
      actualizarEstadoArchivoAdjunto(item.guid, tipoEstadoArchivo.SUBIENDO);
      const res = await fetch(url, options);
      if (res.ok) {
        const json = await res.json();
        if (json.resultadoOK) {
          actualizarEstadoArchivoAdjunto(item.guid, tipoEstadoArchivo.SUBIDO);
        } else {
          json.errores.forEach((item) => {
            addToast(item, Severities.error);
          });
          actualizarEstadoArchivoAdjunto(item.guid, tipoEstadoArchivo.ERROR);
        }
      } else {
        addToast(globalData.terminosLocalizados.AVI004, Severities.error);
        actualizarEstadoArchivoAdjunto(item.guid, tipoEstadoArchivo.ERROR);
      }
    } catch (err) {
      addToast(globalData.terminosLocalizados.AVI005, Severities.error);
      actualizarEstadoArchivoAdjunto(item.guid, tipoEstadoArchivo.ERROR);
    }
  }

  const crearRequestSubirArchivoAdjunto = (item) => {
    const request = new FormData();
    request.append("Token", auth.user.token);
    request.append("Guid", item.guid);
    request.append("File", item.archivo);
    return request;
  };

  const actualizarEstadoArchivoAdjunto = (guidArchivo, tipoEstado) => {
    setFormularioClinico((previous) => {
      const nuevo = { ...previous };
      nuevo.archivosAdjuntos.archivos.map((item) => {
        return item.guid === guidArchivo
          ? {
              ...item,
              estado: {
                ...item.estado,
                subiendo: tipoEstado === tipoEstadoArchivo.SUBIENDO,
                subido: tipoEstado === tipoEstadoArchivo.SUBIDO,
                error: tipoEstado === tipoEstadoArchivo.ERROR,
              },
            }
          : item;
      });
      return nuevo;
    });
    if (tipoEstado === tipoEstadoArchivo.SUBIDO) {
      setFormularioClinico((previous) => ({
        ...previous,
        estado: {
          ...previous.estado,
          cantidadArchivosAdjuntosEnviados:
            previous.estado.cantidadArchivosAdjuntosEnviados + 1,
        },
      }));
    }
  };

  // --------------- Refrescar la timeline --------------

  // Refrescar la timeline del usuario.
  // Se utiliza luego de agregar una nueva publicación.
  const refrescarTimeline = useCallback(async () => {
    if (!paciente.estado.cargado) {
      return;
    }
    const url = `${API_URL()}/FormularioClinico/getTimeline`;
    const request = {
      Token: auth.user.token,
      GuidCuentaAdicional: auth.cuentaActiva.guid,
      CodigoPaisDocumento: paciente.paisDocumento,
      TipoDocumento: paciente.tipoDocumento,
      numeroDocumento: paciente.numeroDocumento,
    };
    try {
      const options = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(request),
      };
      const res = await fetch(url, options);
      if (res.ok) {
        const json = await res.json();
        if (json.resultadoOK) {
          const timeline = json.timeline;
          setPaciente((previous) => {
            return {
              ...previous,
              timeline: timeline,
            };
          });
        } else {
          json.errores.forEach((item) => {
            addToast(item, Severities.error);
          });
        }
      } else {
        addToast(globalData.terminosLocalizados.AVI004, Severities.error);
      }
      return;
    } catch (err) {
      addToast(globalData.terminosLocalizados.AVI005, Severities.error);
    }
  }, [
    addToast,
    auth.cuentaActiva.guid,
    auth.user.token,
    globalData.terminosLocalizados.AVI004,
    globalData.terminosLocalizados.AVI005,
    paciente.numeroDocumento,
    paciente.paisDocumento,
    paciente.tipoDocumento,
    paciente.estado.cargado,
  ]);

  // ------------ Publicación --------------

  const iniciarEnvioPublicacion = () => {
    setFormularioClinico((previous) => ({
      ...previous,
      estado: {
        ...previous.estado,
        enviando: true,
      },
    }));
  };

  const extraerRespuestas = useCallback(() => {
    let respuestas = [];
    for (let itemProceso of formularioClinico.procesos) {
      for (let itemAreaSalud of itemProceso.areasSalud) {
        for (let itemEspecialidad of itemAreaSalud.especialidades) {
          for (let itemSeccion of itemEspecialidad.secciones) {
            for (let itemAreaPreguntas of itemSeccion.areasPreguntas) {
              for (let itemPregunta of itemAreaPreguntas.preguntas) {
                const respuesta = {
                  PreguntaGuid: itemPregunta.guid,
                  CompartirRespuestaPaciente: itemPregunta.respuestaCompartida,
                  Pregunta: itemPregunta.descripcion,
                  AreaPregunta: itemAreaPreguntas.descripcion,
                  Seccion: itemSeccion.descripcion,
                  Especialidad: itemEspecialidad.descripcion,
                  AreaSalud: itemAreaSalud.descripcion,
                  Proceso: itemProceso.descripcion,
                  UnidadMedida: itemPregunta.unidadMedida,
                  RespuestaUnidadMedida: itemPregunta.respuestaUnidadMedida,
                  RespuestaUnidadMedidaValoracion:
                    itemPregunta.respuestaUnidadMedidaValoracion,
                  RespuestaUnidadMedidaValoracionReferencia:
                    itemPregunta.respuestaUnidadMedidaValoracionReferencia,
                  RespuestaTextoPleno: itemPregunta.respuestaTextoPleno,
                  RespuestaOpcion: itemPregunta.respuestaOpciones,
                  RespuestaOpcionTextoAdicional:
                    itemPregunta.respuestaOpcionesTextoAdicional,
                  RespuestaFecha: itemPregunta.respuestaFecha,
                  //RespuestaMultimedia: null,
                  GuidArchivoRespuestaMultimedia: null,
                };
                if (itemPregunta.respuestaMultimedia !== null) {
                  respuesta.GuidArchivoRespuestaMultimedia =
                    itemPregunta.respuestaMultimedia.guid;
                }
                respuestas.push(respuesta);
              }
            }
          }
        }
      }
    }
    return respuestas;
  }, [formularioClinico]);

  const extraerArchivosAdjuntos = useCallback(() => {
    return formularioClinico.archivosAdjuntos.archivos.map((item) => item.guid);
  }, [formularioClinico]);

  // Crear un "request" para una nueva publicación estándar
  const crearRequestPublicar = useCallback(() => {
    const respuesta = {
      Token: auth.user.token,
      GuidCuentaAdicional: auth.cuentaActiva.guid,
      PaisDocumentoPaciente: paciente.paisDocumento,
      TipoDocumentoPaciente: paciente.tipoDocumento,
      DocumentoPaciente: paciente.numeroDocumento,
      Prescripcion: {
        Texto: formularioClinico.prescripcion.texto,
        CompartidoPaciente: formularioClinico.prescripcion.compartido,
      },
      TestPrescripcion: {
        Texto: formularioClinico.prescripcionTest.texto,
        CompartidoPaciente: formularioClinico.prescripcionTest.compartido,
      },
      NotasProfesionales: {
        Texto: formularioClinico.notasProfesionales.texto,
        CompartidoPaciente: formularioClinico.notasProfesionales.compartido,
      },
      Respuestas: extraerRespuestas(),
      GuidArchivosAdjuntos: extraerArchivosAdjuntos(),
    };
    return respuesta;
  }, [
    formularioClinico,
    auth.cuentaActiva.guid,
    auth.user.token,
    paciente.numeroDocumento,
    paciente.paisDocumento,
    paciente.tipoDocumento,
    extraerRespuestas,
    extraerArchivosAdjuntos,
  ]);

  // useEffect(() => {
  //   console.log(formularioClinico);
  // }, [formularioClinico]);

  // Complementa el formulario al inicio.
  // También se llama después de terminar una publicación, para limpiar las preguntas.
  const complementarFormulario = useCallback((formulario) => {
    try {
      const procesos = formulario.procesos.map((itemProceso) => {
        const areasSalud = itemProceso.areasSalud.map((itemAreaSalud) => {
          const especialidades = itemAreaSalud.especialidades.map(
            (itemEspecialidad) => {
              const secciones = itemEspecialidad.secciones.map(
                (itemSeccion) => {
                  const areasPreguntas = itemSeccion.areasPreguntas.map(
                    (itemAreaPreguntas) => {
                      const preguntas = itemAreaPreguntas.preguntas.map(
                        (itemPregunta) => {
                          const respuesta = {
                            ...itemPregunta,
                            guidProceso: itemProceso.guid,
                            guidAreaSalud: itemAreaSalud.guid,
                            guidEspecialidad: itemEspecialidad.guid,
                            guidSeccion: itemSeccion.guid,
                            guidAreaPreguntas: itemAreaPreguntas.guid,
                            respuestaCompartida: false,
                            respuestaFecha: null,
                            respuestaMultimedia: {
                              archivo: null,
                              preview: null,
                              estado: { cargado: false },
                            },
                            respuestaOpciones: "",
                            respuestaOpcionesTextoAdicional: "",
                            respuestaUnidadMedida: "",
                            respuestaUnidadMedidaValoracion: "",
                            respuestaTextoPleno: "",
                          };
                          return respuesta;
                        }
                      );
                      return {
                        ...itemAreaPreguntas,
                        contadorCompartidas: 0,
                        preguntas: preguntas,
                      };
                    }
                  );
                  return {
                    ...itemSeccion,
                    contadorCompartidas: 0,
                    areasPreguntas: areasPreguntas,
                  };
                }
              );
              return {
                ...itemEspecialidad,
                contadorCompartidas: 0,
                secciones: secciones,
              };
            }
          );
          return {
            ...itemAreaSalud,
            contadorCompartidas: 0,
            especialidades: especialidades,
          };
        });
        return {
          ...itemProceso,
          contadorCompartidas: 0,
          areasSalud: areasSalud,
        };
      });
      return {
        archivosAdjuntos: { archivos: [], compartido: false },
        etiquetasUsuario: [],
        prescripcion: { texto: "", compartido: false },
        prescripcionTest: { texto: "", compartido: false },
        notasProfesionales: { texto: "", compartido: false },
        estado: {
          enviar: false,
          enviando: false,
          enviado: false,
          enviandoArchivosAdjuntos: false,
          cantidadArchivosAdjuntosEnviar: null,
          cantidadArchivosAdjuntosEnviados: null,
          archivosAdjuntosEnviados: false,
          enviandoRespuestasMultimedia: false,
          cantidadRespuestasMultimediaEnviar: null,
          cantidadRespuestasMultimediaEnviadas: null,
          respuestasMultimediaEnviadas: false,
        },
        procesos: procesos,
      };
    } catch (err) {
      console.log(err);
    }
  }, []);

  const finalizarEnvioPublicacion = useCallback(() => {
    setFormularioClinico(complementarFormulario(formularioClinico));
  }, [complementarFormulario, formularioClinico]);

  // Agregar una publicación una vez que se han subido todos los archivos.
  useEffect(() => {
    (async () => {
      if (formularioClinico === null) {
        return;
      }
      // Si no hay orden de enviar se suspende el envío
      if (!formularioClinico.estado.enviar) {
        return;
      }
      // Si ya se está enviando no enviar de nuevo
      if (formularioClinico.estado.enviando) {
        return;
      }
      // Si todavía no se enviaron todos los archivos adjuntos hay que esperar
      if (formularioClinico.estado.cantidadArchivosAdjuntosEnviar > 0) {
        if (
          !(
            formularioClinico.estado.cantidadArchivosAdjuntosEnviados ===
            formularioClinico.estado.cantidadArchivosAdjuntosEnviar
          )
        ) {
          return;
        }
      }
      // Si todavía no se enviaron todas las respuestas multimedia hay que esperar
      if (formularioClinico.estado.cantidadRespuestasMultimediaEnviar > 0) {
        if (
          !(
            formularioClinico.estado.cantidadRespuestasMultimediaEnviadas ===
            formularioClinico.estado.cantidadRespuestasMultimediaEnviar
          )
        ) {
          return;
        }
      }
      // Si la cantidad de archivos adjuntos a enviar es NULL entonces todavía
      // no hay orden de publicar
      if (formularioClinico.estado.cantidadArchivosAdjuntosEnviar === null) {
        return;
      }
      // Si la cantidad de respuestas multimedia a enviar es NULL entonces todavía
      // no hay orden de publicar
      if (
        formularioClinico.estado.cantidadRespuestasMultimediaEnviar === null
      ) {
        return;
      }
      iniciarEnvioPublicacion();
      const url = `${API_URL()}/FormularioClinico/addPublicacion`;
      const request = crearRequestPublicar();
      try {
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(request),
        };
        const res = await fetch(url, options);
        if (res.ok) {
          const json = await res.json();
          if (json.resultadoOK) {
            refrescarTimeline();
            setPublicando(false);
            addToast(textos.publicacionAgregada, Severities.info);
            // Falta limpiar los controles de entrada
            //limpiarControlesEntrada();
            //closeAddAccount();
          } else {
            json.errores.forEach((item) => {
              addToast(item, Severities.error);
            });
          }
          setPublicando(false);
          finalizarEnvioPublicacion();
        } else {
          addToast(globalData.terminosLocalizados.AVI004, Severities.error);
          setPublicando(false);
          finalizarEnvioPublicacion();
        }
      } catch (err) {
        addToast(globalData.terminosLocalizados.AVI005, Severities.error);
        finalizarEnvioPublicacion();
      }
    })();
  }, [
    //formularioClinico.estado.cantidadRespuestasMultimediaEnviadas,
    //formularioClinico.estado.cantidadArchivosAdjuntosEnviados,
    addToast,
    crearRequestPublicar,
    globalData.terminosLocalizados.AVI004,
    globalData.terminosLocalizados.AVI005,
    refrescarTimeline,
    textos.publicacionAgregada,
    //formularioClinico.estado.cantidadArchivosAdjuntosEnviar,
    //formularioClinico.estado.enviandoArchivosAdjuntos,
    //formularioClinico.estado.cantidadRespuestasMultimediaEnviar,
    //formularioClinico.estado.enviandoRespuestasMultimedia,
    //formularioClinico.estado.enviando,
    //formularioClinico.estado.enviar,
    //publicacion,
    //finalizarEnvioRegistrosAntiguos,
    //initialItemArchivosAntiguos,
    formularioClinico,
    finalizarEnvioPublicacion,
  ]);

  // ----------- Registros Antiguos ------------

  const iniciarEnvioRegistrosAntiguos = () => {
    setRegistrosAntiguos((previous) => ({
      ...previous,
      estado: {
        ...previous.estado,
        enviando: true,
      },
    }));
  };

  // Crear un "request" para una publicación de registros antiguos
  const crearRequestPublicarRegistrosAntiguos = useCallback(() => {
    const request = {
      Token: auth.user.token,
      GuidCuentaAdicional: auth.cuentaActiva.guid,
      PaisDocumentoPaciente: paciente.paisDocumento,
      TipoDocumentoPaciente: paciente.tipoDocumento,
      DocumentoPaciente: paciente.numeroDocumento,
      FechaRegistro: registrosAntiguos.fecha,
      ArchivosAdjuntos: [],
    };
    archivosAntiguos.forEach((item) => {
      if (item.estado.cargado) {
        request.ArchivosAdjuntos.push({ guid: item.guid });
      }
    });
    return request;
  }, [
    archivosAntiguos,
    auth.cuentaActiva.guid,
    auth.user.token,
    paciente.numeroDocumento,
    paciente.paisDocumento,
    paciente.tipoDocumento,
    registrosAntiguos.fecha,
  ]);

  // Agregar una publicación de registros antiguos
  // una vez que se han subido todos los archivos.
  useEffect(() => {
    (async () => {
      const finalizarEnvioRegistrosAntiguos = () => {
        setRegistrosAntiguos((previous) => ({
          ...previous,
          fecha: null,
          usarFechaActual: false,
          estado: {
            ...previous.estado,
            ingresando: false,
            enviandoArchivos: false,
            cantidadArchivosEnviar: null,
            cantidadArchivosEnviados: null,
            archivosEnviados: false,
            enviando: false,
          },
        }));
        archivosAntiguos.map((item) => URL.revokeObjectURL(item.preview));
        setArchivosAntiguos([
          {
            guid: uuid(),
            archivo: null,
            preview: null,
            nombre: "",
            nota: "",
            contador: 0,
            estado: { cargado: false },
          },
        ]);
      };
      if (registrosAntiguos.estado.enviando) {
        return;
      }
      if (!registrosAntiguos.estado.enviandoArchivos) {
        return;
      }
      if (
        !(
          registrosAntiguos.estado.cantidadArchivosEnviados ===
          registrosAntiguos.estado.cantidadArchivosEnviar
        )
      ) {
        return;
      }
      if (registrosAntiguos.estado.cantidadArchivosEnviar === null) {
        return;
      }
      iniciarEnvioRegistrosAntiguos();
      const url = `${API_URL()}/FormularioClinico/addPublicacionAntigua`;
      const request = crearRequestPublicarRegistrosAntiguos();
      try {
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(request),
        };
        const res = await fetch(url, options);
        if (res.ok) {
          const json = await res.json();
          if (json.resultadoOK) {
            refrescarTimeline();
            setPublicando(false);
            addToast(textos.registrosAntiguosAgregados, Severities.info);
            // Falta limpiar los controles de entrada
            //closeAddAccount();
          } else {
            json.errores.forEach((item) => {
              addToast(item, Severities.error);
            });
          }
          finalizarEnvioRegistrosAntiguos();
        } else {
          addToast(globalData.terminosLocalizados.AVI004, Severities.error);
          finalizarEnvioRegistrosAntiguos();
        }
      } catch (err) {
        addToast(globalData.terminosLocalizados.AVI005, Severities.error);
        finalizarEnvioRegistrosAntiguos();
      }
    })();
  }, [
    registrosAntiguos.estado.cantidadArchivosEnviados,
    addToast,
    crearRequestPublicarRegistrosAntiguos,
    globalData.terminosLocalizados.AVI004,
    globalData.terminosLocalizados.AVI005,
    refrescarTimeline,
    textos.registrosAntiguosAgregados,
    registrosAntiguos.estado.cantidadArchivosEnviar,
    registrosAntiguos.estado.enviandoArchivos,
    registrosAntiguos.estado.enviando,
    archivosAntiguos,
  ]);

  const agregarArchivoAntiguo = () => {
    setArchivosAntiguos((previous) => [
      ...previous,
      {
        guid: uuid(),
        archivo: null,
        preview: null,
        nombre: "",
        nota: "",
        contador: 0,
        estado: { cargado: false },
      },
    ]);
  };

  // ------------------- Obtener el formulario --------------------

  // Obtener el formulario (primera vez)
  // Se está ejecutando también después de agregar preguntas, entre tanto se busca
  // la forma de actualizar el formulario una vez recibida la respuesta (que ya trae
  // el nuevo contenido del formulario)
  useEffect(() => {
    (async () => {
      const request = {
        Token: auth.user.token,
        GuidCuentaAdicional: auth.cuentaActiva.guid,
      };
      try {
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(request),
        };
        const res = await fetch(urlFormularioClinico, options);
        if (res.ok) {
          const json = await res.json();
          if (json.resultadoOK) {
            let formulario = json.formularioClinico;
            let formularioComplementado = complementarFormulario(formulario);
            //setFormularioClinico(complementarFormulario(formulario));
            //console.log(formularioComplementado);
            setFormularioClinico(formularioComplementado);
          } else {
            json.errores.forEach((item) => {
              addToast(item, Severities.error);
            });
          }
          setLoadingFormulario(false);
        } else {
          addToast(globalData.terminosLocalizados.AVI004, Severities.error);
          setLoadingFormulario(false);
        }
        return;
      } catch (err) {
        addToast(globalData.terminosLocalizados.AVI005, Severities.error);
      }
    })();
  }, [
    addToast,
    auth.cuentaActiva.guid,
    auth.user.token,
    urlFormularioClinico,
    setFormularioClinico,
    globalData.terminosLocalizados.AVI004,
    globalData.terminosLocalizados.AVI005,
    complementarFormulario,
  ]);

  // ---------------------- Actualización de datos en la publicación ----------------------

  // Actualizar una respuesta
  const actualizarRespuesta = (
    indexProceso,
    indexAreaSalud,
    indexEspecialidad,
    indexSeccion,
    indexAreaPreguntas,
    indexPregunta,
    tipoRespuesta,
    respuesta
  ) => {
    setFormularioClinico((prevState) => {
      const nuevoEstado = { ...prevState };
      const pregunta =
        nuevoEstado.procesos[indexProceso].areasSalud[indexAreaSalud]
          .especialidades[indexEspecialidad].secciones[indexSeccion]
          .areasPreguntas[indexAreaPreguntas].preguntas[indexPregunta];

      switch (tipoRespuesta) {
        case tipos_respuesta.fecha:
          pregunta.respuestaFecha = respuesta;
          console.log(respuesta);
          break;
        case tipos_respuesta.opciones:
          pregunta.respuestaOpciones = respuesta;
          pregunta.respuestaOpcionesTextoAdicional = "";
          break;
        case tipos_respuesta.opcionesInformacionAdicional:
          pregunta.respuestaOpcionesTextoAdicional = respuesta;
          break;
        case tipos_respuesta.textoPleno:
          pregunta.respuestaTextoPleno = respuesta;
          break;
        case tipos_respuesta.unidadMedida:
          pregunta.respuestaUnidadMedida = respuesta.valor;
          pregunta.respuestaUnidadMedidaValoracion =
            respuesta.valoracion.resultado;
          pregunta.respuestaUnidadMedidaValoracionReferencia =
            respuesta.valoracion.referencia;
          break;
        case tipos_respuesta.multimedia:
          pregunta.respuestaMultimedia = respuesta;
          break;
        case tipos_respuesta.compartido:
          if (pregunta.respuestaCompartida !== respuesta) {
            pregunta.respuestaCompartida = respuesta;
            if (respuesta) {
              nuevoEstado.procesos[indexProceso].contadorCompartidas += 1;
              nuevoEstado.procesos[indexProceso].areasSalud[
                indexAreaSalud
              ].contadorCompartidas += 1;
              nuevoEstado.procesos[indexProceso].areasSalud[
                indexAreaSalud
              ].especialidades[indexEspecialidad].contadorCompartidas += 1;
              nuevoEstado.procesos[indexProceso].areasSalud[
                indexAreaSalud
              ].especialidades[indexEspecialidad].secciones[
                indexSeccion
              ].contadorCompartidas += 1;
              nuevoEstado.procesos[indexProceso].areasSalud[
                indexAreaSalud
              ].especialidades[indexEspecialidad].secciones[
                indexSeccion
              ].areasPreguntas[indexAreaPreguntas].contadorCompartidas += 1;
            } else {
              nuevoEstado.procesos[indexProceso].contadorCompartidas -= 1;
              nuevoEstado.procesos[indexProceso].areasSalud[
                indexAreaSalud
              ].contadorCompartidas -= 1;
              nuevoEstado.procesos[indexProceso].areasSalud[
                indexAreaSalud
              ].especialidades[indexEspecialidad].contadorCompartidas -= 1;
              nuevoEstado.procesos[indexProceso].areasSalud[
                indexAreaSalud
              ].especialidades[indexEspecialidad].secciones[
                indexSeccion
              ].contadorCompartidas -= 1;
              nuevoEstado.procesos[indexProceso].areasSalud[
                indexAreaSalud
              ].especialidades[indexEspecialidad].secciones[
                indexSeccion
              ].areasPreguntas[indexAreaPreguntas].contadorCompartidas -= 1;
            }
          }
          break;
        case tipos_respuesta.compartidoPrescripcion:
          pregunta.compartidoPrescripcion = respuesta;
          break;
        case tipos_respuesta.compartidoPrescripcionTest:
          pregunta.compartidoPrescripcionTest = respuesta;
          break;
        case tipos_respuesta.compartidoNotasProfesional:
          pregunta.compartidoNotasProfesional = respuesta;
          break;
        case tipos_respuesta.compartidoArchivosAdjuntos:
          pregunta.compartidoArchivosAdjuntos = respuesta;
          break;
        default:
          throw new Error(
            `actualizarRespuesta: El tipo de respuesta "${tipoRespuesta}" no está implementada`
          );
      }
      return nuevoEstado;
    });
  };

  // Actualizar otras entradas
  const actualizarOtrasEntradas = useCallback((tipoRespuesta, respuesta) => {
    setFormularioClinico((prevState) => {
      const nuevoEstado = { ...prevState };
      switch (tipoRespuesta) {
        case tipos_respuesta.prescripcion:
          nuevoEstado.prescripcion.texto = respuesta;
          break;
        case tipos_respuesta.prescripcionTest:
          nuevoEstado.prescripcionTest.texto = respuesta;
          break;
        case tipos_respuesta.notasProfesional:
          nuevoEstado.notasProfesionales.texto = respuesta;
          break;
        case tipos_respuesta.archivosAdjuntos:
          nuevoEstado.archivosAdjuntos.texto = respuesta;
          break;
        case tipos_respuesta.usuariosEtiquetados:
          nuevoEstado.usuariosEtiquetados = respuesta;
          break;
        case tipos_respuesta.compartidoPrescripcion:
          nuevoEstado.prescripcion.compartido = respuesta;
          break;
        case tipos_respuesta.compartidoPrescripcionTest:
          nuevoEstado.prescripcionTest.compartido = respuesta;
          break;
        case tipos_respuesta.compartidoNotasProfesional:
          nuevoEstado.notasProfesionales.compartido = respuesta;
          break;
        case tipos_respuesta.compartidoArchivosAdjuntos:
          nuevoEstado.archivosAdjuntos.compartido = respuesta;
          break;
        default:
          throw new Error(
            `actualizarOtrasEntradas: El tipo de respuesta "${tipoRespuesta}" no está implementada`
          );
      }
      return nuevoEstado;
    });
  }, []);

  const slideTimeout = 500;

  if (formularioClinico === null) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      {downMD &&
        paciente.estado.cargado &&
        !registrosAntiguos.estado.ingresando && (
          <RenderNavegacionSuperior
            handleClickNavegacion={handleClickNavegacion}
            textos={textos}
            visibilidadElementos={visibilidadElementos}
          />
        )}
      {!registrosAntiguos.estado.ingresando && (
        <RenderNavegacionLateral
          visibilidadNavegacion={visibilidadNavegacion}
          downMD={downMD}
          handleClickNavegacion={handleClickNavegacion}
          textos={textos}
          paciente={paciente}
        />
      )}
      <Grid
        container
        spacing={1}
        id="gridContenedorPrincipal"
        padding={1}
        display={"flex"}
        flexDirection={"row"}
        flexGrow={1}
        width={"100vw"}
        height={"calc(100vh - 50px)"}
        // Los grid tienen un margen de -8px por defecto, que es necesario anular
        marginTop={0}
        overflow="hidden"
        ref={containerRef}
        // Para que al presentarse en una ventana más angosta
        // los botones de navegación no cubran el contenido que hay detrás.
        // Los botones de navegación tienen un ancho definido de 18px,
        // pero hay algunos márgenes negativo de -8px definido para los grid de MUI.
        // En teoría, el padding debería ser de 10px, pero dejo dos pixeles más.
        sx={{
          [theme.breakpoints.down("md")]: {
            paddingLeft: "22px",
            //paddingRight: paddingRightValue,
            paddingRight: `${12 + getScrollbarWidth()}px`,
          },
        }}
      >
        {(visibilidadElementos.timeline || !downMD) && (
          <Slide
            in={visibilidadElementos.timeline || !downMD}
            timeout={slideTimeout}
            container={containerRef.current}
            direction={slideDirection}
          >
            <Grid id="gridInfoPaciente" item xs={12} md={6} height="100%">
              <InfoPaciente
                //id="infoPaciente"
                downMD={downMD}
                visibilidadElementos={visibilidadElementos}
                textoBusqueda={textoBusqueda}
                setTextoBusqueda={setTextoBusqueda}
                paciente={paciente}
                setPaciente={setPaciente}
                pacienteFijo={false}
                registrosAntiguos={registrosAntiguos}
                setRegistrosAntiguos={setRegistrosAntiguos}
                archivosAntiguos={archivosAntiguos}
                agregarArchivoAntiguo={agregarArchivoAntiguo}
                setArchivosAntiguos={setArchivosAntiguos}
              ></InfoPaciente>
            </Grid>
          </Slide>
        )}
        <Grid
          item
          id="formulario-clinico-contenedor"
          xs={12}
          md={6}
          height="100%"
        >
          <Formulario
            downMD={downMD}
            visibilidadElementos={visibilidadElementos}
            paciente={paciente}
            publicar={publicar}
            publicando={publicando}
            setPublicando={setPublicando}
            slideTimeout={slideTimeout}
            slideDirection={slideDirection}
            formularioClinico={formularioClinico}
            setFormularioClinico={setFormularioClinico}
            loading={loadingFormulario}
            actualizarRespuesta={actualizarRespuesta}
            actualizarOtrasEntradas={actualizarOtrasEntradas}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

function RenderNavegacionSuperior(props) {
  const { handleClickNavegacion, textos, visibilidadElementos } = props;
  return (
    <React.Fragment>
      <Box
        id="navegacion-superior"
        paddingTop={1}
        display="flex"
        width={"100%"}
        data-tipo="up"
      >
        {!visibilidadElementos.timeline && (
          <Button
            id="navegacionSuperiorTimeline"
            variant="navegation-top"
            onClick={handleClickNavegacion}
            sx={{ flex: "1 0 0" }}
            data-elemento="timeline"
            data-tipo="up"
          >
            <RenderTextoNavegacionSuperior
              texto={textos.navegacion.timeline}
              elemento="timeline"
            />
          </Button>
        )}
        {!visibilidadElementos.formulario && (
          <Button
            id="navegacionSuperiorFormulario"
            variant="navegation-top"
            onClick={handleClickNavegacion}
            sx={{ flex: "1 0 0" }}
            data-elemento="formulario"
            data-tipo="up"
          >
            <RenderTextoNavegacionSuperior
              texto={textos.navegacion.formulario}
              elemento="formulario"
            />
          </Button>
        )}
        {!visibilidadElementos.prescripciones && (
          <Button
            id="navegacionSuperiorPrescripciones"
            variant="navegation-top"
            onClick={handleClickNavegacion}
            sx={{ flex: "1 0 0" }}
            data-elemento="prescripciones"
            data-tipo="up"
          >
            <RenderTextoNavegacionSuperior
              texto={textos.navegacion.prescripciones}
              elemento="prescripciones"
            />
          </Button>
        )}
        {!visibilidadElementos.muestraPaciente && (
          <Button
            id="navegacionSuperiorMuestraPaciente"
            variant="navegation-top"
            onClick={handleClickNavegacion}
            sx={{ flex: "1 0 0" }}
            data-elemento="muestraPaciente"
            data-tipo="up"
          >
            <RenderTextoNavegacionSuperior
              texto={textos.navegacion.muestraPaciente}
              elemento="muestraPaciente"
            />
          </Button>
        )}
      </Box>
    </React.Fragment>
  );
}

function RenderTextoNavegacionSuperior(props) {
  const { texto, elemento } = props;
  return (
    //Se utiliza una tipografía para poder aplicar la elipsis
    <Typography
      textOverflow="ellipsis"
      overflow="hidden"
      whiteSpace="nowrap"
      // Las tipografías utilizan un tipo de letra diferente a los botones.
      // Se hereda el tamaño para que utilice el de los botones.
      fontSize={"inherit"}
      // Se deben definir también aquí, porque si hace click sobre el texto (este componente)
      // en el evento del click devuelve lo que haya aquí.
      data-elemento={elemento}
      data-tipo="up"
    >
      {texto}
    </Typography>
  );
}

function RenderNavegacionLateral(props) {
  const {
    visibilidadNavegacion,
    downMD,
    handleClickNavegacion,
    textos,
    paciente,
  } = props;
  return (
    <React.Fragment>
      {visibilidadNavegacion.timeline && downMD && (
        <Button
          variant="navegation-left"
          data-elemento="timeline"
          data-tipo="left"
          onClick={handleClickNavegacion}
          sx={{
            bottom: "40px",
          }}
        >
          {textos.navegacion.timeline}
        </Button>
      )}
      {/* Navegación a formulario */}
      {/* Si el paciente no está cargado no mostrar los botones de navegación al formulario */}
      {visibilidadNavegacion.formulario.izquierda &&
        paciente.estado.cargado &&
        downMD && (
          <Button
            variant="navegation-left"
            data-elemento="formulario"
            data-tipo="left"
            onClick={handleClickNavegacion}
            sx={{
              bottom: "40px",
            }}
          >
            {textos.navegacion.formulario}
          </Button>
        )}
      {visibilidadNavegacion.formulario.derecha &&
        paciente.estado.cargado &&
        downMD && (
          <Button
            variant="navegation-right"
            data-elemento="formulario"
            data-tipo="right"
            onClick={handleClickNavegacion}
            sx={{
              top: "60px",
            }}
          >
            {textos.navegacion.formulario}
          </Button>
        )}
      {/* Navegación a prescripciones */}
      {visibilidadNavegacion.prescripciones.izquierda && downMD && (
        <Button
          variant="navegation-left"
          data-elemento="prescripciones"
          data-tipo="left"
          onClick={handleClickNavegacion}
          sx={{
            bottom: "40px",
          }}
        >
          {textos.navegacion.prescripciones}
        </Button>
      )}
      {visibilidadNavegacion.prescripciones.derecha && downMD && (
        <Button
          variant="navegation-right"
          data-elemento="prescripciones"
          data-tipo="right"
          onClick={handleClickNavegacion}
          sx={{
            top: "60px",
          }}
        >
          {textos.navegacion.prescripciones}
        </Button>
      )}
      {/* Navegación a muestra de paciente */}
      {visibilidadNavegacion.muestraPaciente && downMD && (
        <Button
          variant="navegation-right"
          data-elemento="muestraPaciente"
          data-tipo="right"
          onClick={handleClickNavegacion}
          sx={{
            top: "60px",
          }}
        >
          {textos.navegacion.muestraPaciente}
        </Button>
      )}
    </React.Fragment>
  );
}
