import React from "react";

import { useForm, Controller } from "react-hook-form";

import { Card, Typography, TextField, Stack, Button } from "@mui/material";

import ErrorSummary from "../../../Forms/ErrorSummary";
import theme from "../../../../utils/theme";

import useGlobalDataContext from "../../../../hooks/useGlobalDataContext";

export default function CambiarElemento(props) {
  const { tipo, titulo, close, edicion, id, descripcion } = props;

  const globalData = useGlobalDataContext();

  const descripcionAnterior = descripcion;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { Descripcion: descripcion } });

  // En esta estructura se tienen los nombres de los campos en el idioma del usuario
  // Se recibirán del servidor cuando esté implementado el multi-lenguaje.
  const descripcionCampos = {
    Elemento: "Elemento",
  };

  const cancelar = () => {
    close();
  };

  const cambiarLocal = (data) => {
    data.Tipo = tipo;
    data.Id = id;
    data.DescripcionAnterior = descripcionAnterior;
    data.ParentKey = edicion(data);
  };

  return (
    <Card
      sx={{
        borderStyle: "solid",
        border: 2,
        borderColor: theme.palette.primary.main,
        p: 2,
        width: 400,
      }}
    >
      <form onSubmit={handleSubmit(cambiarLocal)}>
        <Stack spacing={1}>
          <Typography variant="h5">
            {globalData.terminosLocalizados.ETI147} {titulo}
          </Typography>
          <Controller
            name="Descripcion"
            control={control}
            rules={{
              required: {
                value: true,
              },
            }}
            render={({ field }) => (
              <TextField
                type="text"
                size="small"
                name="Descripcion"
                placeholder="Descripción"
                autoFocus
                {...field}
              />
            )}
          ></Controller>
          <Stack direction={"row"} spacing={1} justifyContent="flex-end">
            <Button variant="CommandBar2" type="submit">
              {/* Cambiar */}
              {globalData.terminosLocalizados.COM038}
            </Button>
            <Button variant="CommandBar2" onClick={cancelar}>
              {/* Cancelar */}
              {globalData.terminosLocalizados.COM004}
            </Button>
          </Stack>
          <ErrorSummary
            errors={errors}
            descripciones={descripcionCampos}
          ></ErrorSummary>
        </Stack>
      </form>
    </Card>
  );
}
