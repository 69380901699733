import React from "react";
import { Box, Typography } from "@mui/material";

export function TextoNivel1(props) {
  const { texto, estilo, peso } = props;
  return <Texto texto={texto} nivel={1} estilo={estilo} peso={peso} />;
}

export function TextoNivel2(props) {
  const { texto, estilo, peso } = props;
  return <Texto texto={texto} nivel={2} estilo={estilo} peso={peso} />;
}

export function TextoNivel3(props) {
  const { texto, estilo, peso } = props;
  return <Texto texto={texto} nivel={3} estilo={estilo} peso={peso} />;
}

export function TextoNivel4(props) {
  const { texto, estilo, peso } = props;
  return <Texto texto={texto} nivel={4} estilo={estilo} peso={peso} />;
}

export function Texto(props) {
  const { texto, nivel, estilo, peso } = props;
  var fontSize;
  switch (nivel) {
    case 1:
      fontSize = 10;
      break;
    case 2:
      fontSize = 11;
      break;
    case 3:
      fontSize = 12;
      break;
    case 4:
      fontSize = 14;
      break;
    default:
      fontSize = 10;
  }
  return (
    <Box
      display={"flex"}
      height={"inherit"}
      alignItems={"center"}
      sx={{ width: "inherit", maxWidth: "inherit" }}
    >
      <Typography
        fontSize={fontSize}
        fontStyle={estilo}
        fontWeight={peso}
        textOverflow="ellipsis"
        overflow={"hidden"}
        whiteSpace="nowrap"
      >
        {texto}
      </Typography>
    </Box>
  );
}
