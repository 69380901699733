import React, { useState } from "react";
import { Box } from "@mui/material";
import { Timeline } from "@mui/lab";

import CabeceraPaciente from "./CabeceraPaciente";
import LineaTimeline from "./LineaTimeline";
import theme from "../../../../../utils/theme";
import { const_TipoCuentaAdicional } from "../../../../../utils/constants";
import ElementoTimelineAbierto from "./ElementoTimelineAbierto";
import { const_tipoPublicacion } from "../../../../../utils/constants";

export default function TimelinePaciente(props) {
  const { paciente, categorias, textoBusqueda } = props;
  const [elementosAbiertos, setElementosAbiertos] = useState([]);

  const cambiarEstadoElemento = (guid) => {
    const elementos = [...elementosAbiertos];
    const index = elementos.indexOf(guid);
    if (index === -1) {
      elementos.push(guid);
    } else {
      elementos.splice(index, 1);
    }
    setElementosAbiertos(elementos);
  };

  return (
    <Box display="flex" flexDirection={"column"} sx={{ height: "100%" }}>
      <Box>
        <CabeceraPaciente paciente={paciente}></CabeceraPaciente>
      </Box>
      <Box flexGrow={1} overflow={"auto"} width={"100%"} minWidth={"100%"}>
        <Timeline sx={{ paddingLeft: 0, paddingRight: 0 }}>
          {paciente.timeline.map((item, index) => {
            // Filtrar por categorías
            if (item.categoriaAutor === const_TipoCuentaAdicional.PROFESIONAL) {
              if (!categorias.profesionales.estado) {
                return null;
              }
            }
            if (item.categoriaAutor === const_TipoCuentaAdicional.INSTITUCION) {
              if (!categorias.comercios.estado) {
                return null;
              }
            }
            if (item.publicacionPaciente) {
              if (!categorias.pacientes.estado) {
                return null;
              }
            }
            if (item.tipoPublicacion === const_tipoPublicacion.CITA) {
              if (!categorias.apuntes.estado) {
                return null;
              }
            }
            if (item.tipoPublicacion === const_tipoPublicacion.RESULTADO) {
              if (!categorias.resultados.estado) {
                return null;
              }
            }
            // Filtrar por búsqueda
            // Por ahora solo se busca en el texto de la prescripción, porque las respuestas se obtienen
            // recién cuando se abre la publicación. Para buscdar en las respuestas sería necesario
            // que desde el inicio se carguen todas las publicciones co todas las respuestas.
            if (textoBusqueda !== "") {
              if (
                item.prescripcion
                  .toUpperCase()
                  .indexOf(textoBusqueda.toUpperCase()) === -1
              ) {
                return null;
              }
            }
            var sxBox = {
              bgcolor: theme.palette.timeline.resultados,
            };
            if (item.publicacionPaciente) {
              sxBox.bgcolor = theme.palette.timeline.notasPaciente;
            } else {
              switch (item.tipoPublicacion) {
                case const_tipoPublicacion.PRESCRIPCION:
                  sxBox.bgcolor = theme.palette.timeline.prescripciones;
                  break;
                case const_tipoPublicacion.PRESCRIPCION_TEST:
                  sxBox.bgcolor = theme.palette.timeline.prescripcionesTest;
                  break;
                case const_tipoPublicacion.CONSULTA:
                  sxBox.bgcolor = theme.palette.timeline.apuntesProfesional;
                  break;
                case const_tipoPublicacion.RESULTADO:
                  sxBox.bgcolor = theme.palette.timeline.resultados;
                  break;
                case const_tipoPublicacion.REGISTRO_ANTIGUO:
                  sxBox.bgcolor = theme.palette.timeline.registrosAntiguos;
                  break;
                default:
              }
            }
            var sxAutor = { color: theme.palette.primary.contrastText };
            if (item.publicacionPaciente) {
              sxAutor.color = "#000";
            }
            if (elementosAbiertos.indexOf(item.guid) === -1) {
              return (
                <LineaTimeline
                  key={index}
                  item={item}
                  elementosAbiertos={elementosAbiertos}
                  setElementosAbiertos={setElementosAbiertos}
                  cambiarEstado={cambiarEstadoElemento}
                  sxBox={sxBox}
                  sxAutor={sxAutor}
                ></LineaTimeline>
              );
            } else {
              return (
                <ElementoTimelineAbierto
                  key={index}
                  guid={item.guid}
                  tipoPublicacion={item.tipoPublicacion}
                  cambiarEstado={cambiarEstadoElemento}
                  sxBox={sxBox}
                  sxAutor={sxAutor}
                ></ElementoTimelineAbierto>
              );
            }
          })}
        </Timeline>
      </Box>
    </Box>
  );
}
