import React from "react";

import { SvgIcon } from "@mui/material";

export default function StackVertical() {
  return (
    <SvgIcon>
      <rect
        x="3"
        y="3"
        width="18"
        height="8"
        rx="2"
        //ry="2"
        //style="fill-opacity:0.1;"
      />
      <rect x="3" y="12" width="18" height="8" rx="2" />
    </SvgIcon>
  );
}
