import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import "./App.css";

import { ThemeProvider, CssBaseline } from "@mui/material";

// import "overlayscrollbars/styles/overlayscrollbars.css";
// import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

//import Home from './components/Anonimo/Home'
import "@fontsource/roboto";
//import AppRouter from "./_routers/AppRouter";
import AppRouter from "./_routers/AppRouter";
import AuthProvider from "./auth/AuthProvider";
import { GlobalDataContextProvider } from "./contexts/GlobalDataContext";
import { ToastContextProvider } from "./contexts/ToastContext";

import theme from "./utils/theme";

//import UserDataContext from "./contexts/UserDataContext";

function App() {
  //const [userName, setUserName] = React.useState("");
  //const userNameValue = { userName, setUserName };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        {/* <UserDataContext.Provider value={userNameValue}> */}
        <AuthProvider>
          <GlobalDataContextProvider>
            <ToastContextProvider>
              {/* <OverlayScrollbarsComponent
                options={{ scrollbars: { color: "#000" } }}
              > */}
              <Router>
                <AppRouter />
              </Router>
              {/* </OverlayScrollbarsComponent> */}
            </ToastContextProvider>
          </GlobalDataContextProvider>
        </AuthProvider>
        {/* </UserDataContext.Provider> */}
      </CssBaseline>
    </ThemeProvider>
  );
}

export default App;
