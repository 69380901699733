import React, { useState } from "react";
import {
  Box,
  Typography,
  Switch,
  Grid,
  Stack,
  ButtonBase,
} from "@mui/material";
import { styled } from "@mui/system";

import { Buffer } from "buffer";

import { API_URL } from "../../../../../utils/constants";
import useAuth from "../../../../../auth/useAuth";

import ComandoEstadoElemento from "./ComandoEstadoElemento";
import Loading from "../../../../Feedback/Loading";
import MensajeError from "../../../../Feedback/MensajeError";

import ModalContainer from "../../../../Autenticados/ModalContainer";
import ArchivoMultimedia from "./ArchivoMultimedia";

import { const_tipoPublicacion } from "../../../../../utils/constants";

import theme from "../../../../../utils/theme";
import useFetch from "../../../../../hooks/useFetch";

import useGlobalDataContext from "../../../../../hooks/useGlobalDataContext";

export const tipoMultimedia = {
  ARCHIVO_ADJUNTO: "ARCHIVO_ADJUNTO",
  RESPUESTA_MULTIMEDIA: "RESPUESTA_MULTIMEDIA",
  REGISTRO_ANTIGUO: "REGISTRO_ANTIGUO",
};

export default function ElementoTimelineAbierto(props) {
  const { guid, tipoPublicacion, cambiarEstado, sxBox, sxAutor } = props;

  const [vistaPaciente, setVistaPaciente] = useState(false);

  const auth = useAuth();
  const globalData = useGlobalDataContext();

  // Se debería obtener desde el servidor según el idioma del usuario
  const textos = {
    vistaPaciente: globalData.terminosLocalizados.ETI145,
    nota: globalData.terminosLocalizados.ETI167,
    archivosAdjuntos: globalData.terminosLocalizados.ETI096,
  };

  const url = `${API_URL()}/FormularioClinico/getPublicacion`;
  const request = { Token: auth.user.token, Guid: guid };
  const options = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  };

  const respuesta = useFetch(url, options);

  if (tipoPublicacion === const_tipoPublicacion.REGISTRO_ANTIGUO) {
    sxAutor.color = theme.palette.timeline.apuntesProfesional;
    sxBox.color = theme.palette.timeline.apuntesProfesional;
  }

  // Se presenta aquí el "loading" porque si se intenta presentar dentro de la caja de la publiación
  // o bien no se presenta, o sale recostado siempre a la izquierda o a la derecha (no centrado)
  if (respuesta.loading) {
    return <Loading />;
  }
  if (respuesta.error) {
    return (
      <Box paddingX={1}>
        <MensajeError texto={respuesta.error} />
      </Box>
    );
  }
  let item = null;
  if (!(respuesta.loading || !respuesta.result)) {
    item = respuesta.result.publicacion;
  }

  const fuentes = {
    general: 12,
    chica: 8,
    proceso: 12,
    seccion: 11,
    areaPreguntas: 11,
    pregunta: 10,
    respuesta: 10,
    prescripcion: 10,
    archivosAdjuntos: 12,
  };

  const cambiarVista = (event) => {
    setVistaPaciente(event.target.checked);
  };

  return (
    <Box padding={1}>
      <Box
        width={"100%"}
        display="flex"
        paddingLeft={0}
        paddingY={1}
        borderRadius="10px"
        color={theme.palette.primary.contrastText}
        sx={sxBox}
      >
        {respuesta.loading || !respuesta.result ? (
          // En realidad esto no se está presentando. Se está haciendo más arriba. Ver allí los motivos.
          <Loading size="10" />
        ) : (
          <Box display={"flex"} width="100%">
            <Box
              width={"20px"}
              minWidth="20px"
              display={"flex"}
              alignItems="center"
              overflow={"clip"}
              marginRight={1}
              color={theme.palette.timeline.iconos}
            >
              <ComandoEstadoElemento
                item={item}
                cambiarEstado={cambiarEstado}
              />
            </Box>
            <Box width={"100%"} paddingRight={4}>
              <Box display="flex" justifyContent={"space-between"}>
                <Typography
                  fontSize={fuentes.general}
                  fontWeight="bold"
                  sx={sxAutor}
                >
                  {item.autor}
                </Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography fontSize={fuentes.general}>
                    {textos.vistaPaciente}
                  </Typography>
                  <AntSwitch checked={vistaPaciente} onChange={cambiarVista} />
                </Stack>
              </Box>
              <Typography fontSize={fuentes.chica}>{item.fecha}</Typography>
              <RenderPrescripcion publicacion={item} fuentes={fuentes} />
              <RenderProcesos
                procesos={item.procesos}
                fuentes={fuentes}
                vistaPaciente={vistaPaciente}
              />
              <RenderArchivosAdjuntos
                archivos={item.archivosAdjuntos}
                tipoPublicacion={tipoPublicacion}
                fuentes={fuentes}
                textos={textos}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

function RenderPrescripcion(props) {
  const { publicacion, fuentes } = props;
  if (!publicacion.prescripcion) {
    return null;
  }
  return (
    <Box width={"100%"} marginY={1}>
      <Typography
        fontSize={fuentes.prescripcion}
        sx={{ wordWrap: "break-word" }}
      >
        {publicacion.prescripcion}
      </Typography>
    </Box>
  );
}

function RenderProcesos(props) {
  const { procesos, fuentes, vistaPaciente } = props;
  if (!procesos.length > 0) {
    return null;
  }
  return procesos.map((proceso) => {
    return (
      <RenderProceso
        key={proceso.guid}
        proceso={proceso}
        fuentes={fuentes}
        vistaPaciente={vistaPaciente}
      />
    );
  });
}

function RenderProceso(props) {
  const { proceso, fuentes, vistaPaciente } = props;
  if (vistaPaciente && !proceso.contenidoCompartido) {
    return null;
  }
  return (
    <Box width={"100%"}>
      <Typography fontSize={fuentes.proceso}>{proceso.descripcion}</Typography>
      {proceso.areasSalud.map((areaSalud) => {
        return (
          <RenderAreaSalud
            areaSalud={areaSalud}
            fuentes={fuentes}
            vistaPaciente={vistaPaciente}
          />
        );
      })}
    </Box>
  );
}

function RenderAreaSalud(props) {
  const { areaSalud, fuentes, vistaPaciente } = props;
  if (vistaPaciente && !areaSalud.contenidoCompartido) {
    return null;
  }
  return (
    <Box width={"100%"}>
      {areaSalud.especialidades.map((especialidad) => {
        return (
          <RenderEspecialidad
            especialidad={especialidad}
            fuentes={fuentes}
            vistaPaciente={vistaPaciente}
          />
        );
      })}
    </Box>
  );
}

function RenderEspecialidad(props) {
  const { especialidad, fuentes, vistaPaciente } = props;
  if (vistaPaciente && !especialidad.contenidoCompartido) {
    return null;
  }
  return (
    <Box width={"100%"}>
      {especialidad.secciones.map((seccion) => {
        return (
          <RenderSeccion
            seccion={seccion}
            fuentes={fuentes}
            vistaPaciente={vistaPaciente}
          />
        );
      })}
    </Box>
  );
}

function RenderSeccion(props) {
  const { seccion, fuentes, vistaPaciente } = props;
  if (vistaPaciente && !seccion.contenidoCompartido) {
    return null;
  }
  return (
    <Box width={"100%"} paddingLeft={0.5}>
      <Typography fontSize={fuentes.seccion} fontStyle={"italic"}>
        {seccion.descripcion}
      </Typography>
      {seccion.areasPreguntas.map((areaPreguntas) => {
        return (
          <RenderAreaPreguntas
            areaPreguntas={areaPreguntas}
            fuentes={fuentes}
            vistaPaciente={vistaPaciente}
          />
        );
      })}
    </Box>
  );
}

function RenderAreaPreguntas(props) {
  const { areaPreguntas, fuentes, vistaPaciente } = props;
  if (vistaPaciente && !areaPreguntas.contenidoCompartido) {
    return null;
  }
  return (
    <Box width={"100%"} paddingLeft={0.5}>
      <Typography fontSize={fuentes.areaPreguntas}>
        {areaPreguntas.descripcion}
      </Typography>
      {areaPreguntas.preguntas.map((pregunta) => {
        return (
          <RenderPregunta
            pregunta={pregunta}
            fuentes={fuentes}
            vistaPaciente={vistaPaciente}
          />
        );
      })}
    </Box>
  );
}

function RenderPregunta(props) {
  const { pregunta, fuentes, vistaPaciente } = props;
  if (vistaPaciente && !pregunta.compartirRespuestaPaciente) {
    return null;
  }
  return (
    <Box width={"100%"} paddingLeft={0.5}>
      <Typography fontSize={fuentes.pregunta}>{pregunta.pregunta}</Typography>
      <Box width="100%" paddingLeft={0.5}>
        <RenderUnidadMedida pregunta={pregunta} fuentes={fuentes} />
        <RenderFecha pregunta={pregunta} fuentes={fuentes} />
        <RenderOpcion pregunta={pregunta} fuentes={fuentes} />
        <RenderTextoPleno pregunta={pregunta} fuentes={fuentes} />
        <RenderMultimedia pregunta={pregunta} fuentes={fuentes} />
      </Box>
    </Box>
  );
}

function RenderUnidadMedida(props) {
  const { pregunta, fuentes } = props;
  if (!pregunta.respuestaUnidadMedida) {
    return null;
  }
  console.log(pregunta.valoracionRespuesta);
  return (
    <Stack id="unidadMedida" width={"100%"} direction="row" spacing={1}>
      <Typography fontSize={fuentes.respuesta}>
        {pregunta.respuestaUnidadMedida} {pregunta.unidadMedida}
      </Typography>
      {pregunta.valoracionRespuesta !== null && (
        <Typography fontSize={fuentes.respuesta}>
          ({pregunta.valoracionRespuesta})
        </Typography>
      )}
    </Stack>
  );
}

function RenderFecha(props) {
  const { pregunta, fuentes } = props;
  if (!pregunta.respuestaFecha) {
    return null;
  }
  return (
    <Box id="fecha" width={"100%"}>
      <Typography fontSize={fuentes.respuesta}>
        {pregunta.respuestaFecha}
      </Typography>
    </Box>
  );
}

function RenderOpcion(props) {
  const { pregunta, fuentes } = props;
  if (!pregunta.respuestaOpcion) {
    return null;
  }
  return (
    <Box id="opcion" display={"flex"} width={"100%"}>
      <Typography fontSize={fuentes.respuesta}>
        {pregunta.respuestaOpcion}
      </Typography>
      {pregunta.respuestaOpcionTextoAdicional && (
        <Box id="opcionInterior" marginLeft={1}>
          <Typography fontSize={fuentes.respuesta} whiteSpace="pre-wrap">
            {pregunta.respuestaOpcionTextoAdicional}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

function RenderTextoPleno(props) {
  const { pregunta, fuentes } = props;
  if (!pregunta.respuestaTextoPleno) {
    return null;
  }
  return (
    <Box id="textoPleno" width={"100%"}>
      <Typography fontSize={fuentes.respuesta} whiteSpace="pre-wrap">
        {pregunta.respuestaTextoPleno}
      </Typography>
    </Box>
  );
}

function RenderMultimedia(props) {
  const { pregunta, fuentes } = props;
  const [isOpenModal, setIsOpenModal] = useState(false);
  if (!pregunta.respuestaMultimedia) {
    return null;
  }
  const byteArray = Buffer.from(
    pregunta.respuestaMultimedia.contenido,
    "base64"
  );
  const blob = new Blob([byteArray], { type: "image/jpeg" });
  //console.log(byteArray);
  const imageUrl = URL.createObjectURL(blob);

  // Funciones para procesar la apertura del archivo
  const openModal = (event) => {
    setIsOpenModal(true);
  };

  const closeModal = () => {
    setIsOpenModal(false);
  };

  return (
    <>
      <ButtonBase
        id="multimediaButtonBase"
        onClick={openModal}
        sx={{ width: "100%", color: theme.palette.primary.main }}
      >
        <Grid
          id="multimediaGuidContainer"
          container
          width={"100%"}
          height={"content"}
          bgcolor={theme.palette.timeline.registrosAntiguosDark}
          paddingY={1}
        >
          <Grid
            id="multimediaGridItemImagen"
            item
            xs={12}
            xs1={2}
            // Se establece la misma altura del contenido, porque no pude determinar
            // a qué se debe que sea más alto que el contenido.
            //height={"30px"}
            //height={"content"}
          >
            <img
              height={"30px"}
              width={"30px"}
              src={imageUrl}
              type="image/jpeg"
              alt="preview"
            />
          </Grid>
          <Grid
            id="multimediaGridItemNombre"
            item
            xs={12}
            xs1={10}
            justifyContent={"center"}
          >
            <Typography fontSize={fuentes.chica} lineHeight={"30px"}>
              {pregunta.respuestaMultimedia.nombreOriginal}
            </Typography>
          </Grid>
        </Grid>
      </ButtonBase>
      <ModalContainer
        id="modalArchivoMultimedia"
        isOpenModal={isOpenModal}
        closeModal={closeModal}
      >
        <>
          <ArchivoMultimedia
            archivo={pregunta.respuestaMultimedia}
            tipo={tipoMultimedia.RESPUESTA_MULTIMEDIA}
            close={closeModal}
          ></ArchivoMultimedia>
        </>
      </ModalContainer>
    </>
  );
}

function RenderArchivosAdjuntos(props) {
  const { archivos, tipoPublicacion, fuentes, textos } = props;
  if (!archivos.length > 0) {
    return null;
  }
  return (
    <Stack spacing={1} mt={1}>
      <Typography fontSize={fuentes.archivosAdjuntos}>
        {textos.archivosAdjuntos}
      </Typography>
      {archivos.map((itemArchivo, index) => {
        return (
          <RenderArchivo
            archivo={itemArchivo}
            index={index}
            fuentes={fuentes}
            tituloNota={textos.nota}
            tipoPublicacion={tipoPublicacion}
          />
        );
      })}
    </Stack>
  );
}

function RenderArchivo(props) {
  const {
    archivo,
    //index,
    fuentes,
    tituloNota,
    tipoPublicacion,
  } = props;
  const [isOpenModal, setIsOpenModal] = useState(false);

  const byteArray = Buffer.from(archivo.contenido, "base64");
  const blob = new Blob([byteArray], { type: "image/jpeg" });
  console.log(byteArray);
  const imageUrl = URL.createObjectURL(blob);

  // Funciones para procesar la apertura del archivo
  const openModal = (event) => {
    setIsOpenModal(true);
  };

  const closeModal = () => {
    setIsOpenModal(false);
  };

  return (
    <>
      <Stack spacing={1}>
        {/* {index !== 0 && <Divider></Divider>} */}
        {archivo.nombreAsignado != null && (
          <Box>
            <Typography
              fontSize={fuentes.chica}
              color={theme.palette.primary.main}
              fontWeight={"bold"}
              fontStyle={"italic"}
            >
              {archivo.nombreAsignado}
            </Typography>
          </Box>
        )}
        <ButtonBase
          onClick={openModal}
          sx={{ color: theme.palette.primary.main }}
        >
          <Grid
            container
            width={"100%"}
            paddingY={1}
            height={"content"}
            bgcolor={theme.palette.timeline.registrosAntiguosDark}
          >
            <Grid
              item
              xs={12}
              xs1={2}
              // Se establece la misma altura del contenido, porque no pude determinar
              // a qué se debe que sea más alto que el contenido.
              height={"30px"}
            >
              <img
                height={"30px"}
                width={"30px"}
                src={imageUrl}
                type="image/jpeg"
                alt="preview"
              />
            </Grid>
            <Grid item xs={12} xs1={10} justifyContent={"center"}>
              <Typography fontSize={fuentes.chica} lineHeight={"30px"}>
                {archivo.nombreOriginal}
              </Typography>
            </Grid>
          </Grid>
        </ButtonBase>
        {archivo.nota != null && (
          <>
            <Box>
              <Typography
                fontSize={fuentes.chica}
                fontStyle={"italic"}
                fontWeight={"bold"}
                color={theme.palette.primary.contrastText}
              >
                {tituloNota}
              </Typography>
            </Box>
            <Box>
              <Typography fontSize={fuentes.chica}>{archivo.nota}</Typography>
            </Box>
          </>
        )}
      </Stack>
      <ModalContainer
        id="modalArchivoMultimedia"
        isOpenModal={isOpenModal}
        closeModal={closeModal}
      >
        <ArchivoMultimedia
          archivo={archivo}
          tipo={
            tipoPublicacion === tipoPublicacion.REGISTRO_ANTIGUO
              ? tipoMultimedia.REGISTRO_ANTIGUO
              : tipoMultimedia.ARCHIVO_ADJUNTO
          }
          close={closeModal}
          tipoPublicacion={tipoPublicacion}
        ></ArchivoMultimedia>
      </ModalContainer>
    </>
  );
}

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));
