import theme from "../../../../utils/theme";
import { const_TipoOrdenableFormularioClinico } from "../../../../utils/constants";
import { Button } from "@mui/material";

export default function BotonMoverProceso(props) {
  const { guid, habilitado, derecha, handleSubir, handleBajar, children } =
    props;
  const sxValue = {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.primary.contrastText,
    },
    borderRadius: "30px",
  };
  if (derecha === "true") {
    sxValue.paddingLeft = 2;
    sxValue.marginLeft = "auto";
  } else {
    sxValue.paddingRight = 2;
  }

  function handleMover() {
    if (derecha === "true") {
      handleBajar(guid, const_TipoOrdenableFormularioClinico.PROCESO);
    } else {
      handleSubir(guid, const_TipoOrdenableFormularioClinico.PROCESO);
    }
  }

  return (
    <Button sx={sxValue} disabled={!habilitado} onClick={() => handleMover()}>
      {children}
    </Button>
  );
}
