import React, { useState, useCallback } from "react";
import useAuth from "../../auth/useAuth";
import { FormGroup, Button, Box, Link, Stack } from "@mui/material";

import Loading from "../Feedback/Loading";
import { API_URL } from "../../utils/constants";
import Interrogacion from "../../assets/img/Interrogacion.png";

import ModalContainer from "../Autenticados/ModalContainer";
import ForgotPassword from "./ForgotPassword";

export default function Login(props) {
  const { terminos } = props;

  const [loginInfo, setLoginInfo] = useState({
    Username: "",
    Password: "",
  });

  const [isSendingLogin, setIsSendingLogin] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loginFailMessage, setLoginFailMessage] = useState(null);

  const [isOpenForgotPassword, setIsOpenForgotPassword] = useState(false);

  const auth = useAuth();

  // El envío del request de autenticación se hace aquí
  // porque no encontré forma de hacerlo desde el proveedor de autorización.
  // Una vez recibido el OK se envía el usuario nuevo al proveedor de autorización
  // para que lo registre, porque es el proveedor de autorización el encargado
  // de la persistencia del usuario autenticado (en localStorage, sessionStorage, etc.)
  const sendLoginRequest = useCallback(async () => {
    if (isSendingLogin) return;
    setIsSendingLogin(true);
    try {
      const username = loginInfo.Username;
      const password = loginInfo.Password;
      const options = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username, password }),
      };
      const url = `${API_URL()}/Users/authenticate`;
      const res = await fetch(url, options);
      const json = await res.json();
      if (res.ok) {
        const respuesta = json;
        if (respuesta.autenticado) {
          const user = respuesta.usuario;
          auth.setLoggedIn(user);
        } else {
          setLoginError(true);
          setLoginFailMessage(terminos.AVI003);
          setIsSendingLogin(false);
        }
      } else {
        setLoginFailMessage(terminos.AVI004);
        setLoginError(true);
        setIsSendingLogin(false);
      }
    } catch (err) {
      setLoginFailMessage(terminos.AVI005);
      setLoginError(true);
      setIsSendingLogin(false);
    }
  }, [auth, isSendingLogin, loginInfo.Username, loginInfo.Password, terminos]);

  const onChange = (event) => {
    setLoginInfo({
      ...loginInfo,
      [event.target.name]: event.target.value,
    });
    setLoginError(false);
    setLoginFailMessage(null);
  };

  // Función para procesar la recuperación de contraseña
  const openForgotPassword = (event) => {
    setIsOpenForgotPassword(true);
  };

  const closeForgotPassword = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    setIsOpenForgotPassword(false);
  };

  return (
    <form
      id="loginForm"
      style={{
        flexGrow: "0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
      onChange={onChange}
    >
      <Stack spacing={"5px"}>
        <FormGroup>
          <input
            className="inputText"
            name="Username"
            type="text"
            id="Username"
            placeholder={terminos.ETI001}
          ></input>
        </FormGroup>
        <FormGroup>
          <input
            className="inputText"
            name="Password"
            type="password"
            id="Password"
            placeholder={terminos.ETI002}
          ></input>
        </FormGroup>
        <FormGroup
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box id="commandBar">
            <Box id="loginContainer" display={"flex"} flexDirection="row">
              <Button
                variant="CommandBar1"
                disabled={isSendingLogin}
                onClick={sendLoginRequest}
              >
                {terminos.COM001}
              </Button>
              {isSendingLogin && (
                <Box paddingTop={0} marginLeft={0.5}>
                  <Loading size={20} />
                </Box>
              )}
            </Box>
            <Box
              id="loginErrorContainer"
              color="red"
              fontWeight={"bold"}
              fontSize="12px"
            >
              {loginError && <span>{loginFailMessage}</span>}
            </Box>
          </Box>
          <Box
            flexGrow={0}
            display="flex"
            flexDirection={"column"}
            fontSize="10px"
          >
            <Box id="remaindMeContainer">
              <label>
                <input
                  style={{
                    verticalAlign: "middle",
                    position: "relative",
                    bottom: "1px",
                  }}
                  type="checkbox"
                ></input>
                {terminos.ETI120}
              </label>
            </Box>
            <Box id="forgotPassContainer">
              <label style={{ display: "block" }}>
                <img
                  style={{
                    verticalAlign: "middle",
                    position: "relative",
                    bottom: "1px",
                    height: "16px",
                    marginLeft: "3px",
                  }}
                  src={Interrogacion}
                  alt="?"
                ></img>
                <Link
                  color="rgb(113,113,117)"
                  underline="hover"
                  onClick={openForgotPassword}
                >
                  {terminos.ETI121}
                </Link>
              </label>
            </Box>
          </Box>
        </FormGroup>
      </Stack>
      <ModalContainer
        id="modalForgotPass"
        isOpenModal={isOpenForgotPassword}
        closeModal={closeForgotPassword}
      >
        <ForgotPassword
          close={closeForgotPassword}
          terminos={terminos}
        ></ForgotPassword>
      </ModalContainer>
    </form>
  );
}
