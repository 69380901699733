import { Box } from "@mui/material";
import { ErrorMessage } from "@hookform/error-message";
import ErrorMessageContainer from "./ErrorMessageContainer";

import useGlobalDataContext from "../../hooks/useGlobalDataContext";

// Para los casos en que los términos localizados aún no están cargados
// (por ejemplo, al registrar un nuevo usuario) existe la posibilidad
// de recibir los textos correspondientes a "requerido" y "no válido"
// por parámetros. Será responsabilidad del componente de origen
// proporcionar estos términos según la lógica de la aplicación.

export default function ErrorSummary({
  errors,
  descripciones,
  textoRequerido,
  textoNoValido,
}) {
  const globalData = useGlobalDataContext();

  // Esta estructura contendrá los errores traducidos al lenguaje del usuario.
  let detalleErrores = {};

  // Comprobar si globalData.terminosLocalizados está cargado
  if (globalData.terminosLocalizados) {
    detalleErrores = {
      requerido: globalData.terminosLocalizados.AVI007, // "es requerido",
      contenido: globalData.terminosLocalizados.AVI008, //s "no es válido",
    };
  } else {
    detalleErrores = {
      requerido: textoRequerido,
      contenido: textoNoValido,
    };
  }

  if (Object.keys(errors).length === 0) {
    return null;
  }

  return (
    <Box id="errorSummaryContainer">
      {Object.entries(errors).map((item) => {
        const fieldName = item[0];
        const { type, message } = item[1];
        var texto = "";
        if (message) {
          texto = message;
        } else {
          let field = descripciones[fieldName];
          if (field === undefined) {
            field = fieldName;
          }
          switch (type) {
            case "required":
              texto = `${field} ${detalleErrores.requerido}`;
              break;
            case "maxLength":
            case "minLenght":
            case "pattern":
              texto = `${field} ${detalleErrores.contenido}`;
              break;
            default:
              texto = field;
          }
        }
        return (
          <ErrorMessage
            errors={errors}
            name={fieldName}
            message={texto}
            as={ErrorMessageContainer}
            key={fieldName}
          />
        );
      })}
    </Box>
  );
}
