import React from "react";

import { Box } from "@mui/material";

import EdicionListaSecciones from "./EdicionListaSecciones";

export default function EdicionListaEspecialidades(props) {
  const {
    especialidades,
    descripcionAreaSalud,
    handleQuitarPregunta,
    handleSubirPregunta,
    handleBajarPregunta,
    cambiarAlineacionAreasPreguntas,
  } = props;
  return (
    <Box>
      {especialidades.map((itemEspecialidades, indexEspecialidades) => {
        return (
          <EdicionListaSecciones
            secciones={itemEspecialidades.secciones}
            guidEspecialidad={itemEspecialidades.guid}
            descripcionAreaSalud={descripcionAreaSalud}
            descripcionEspecialidad={itemEspecialidades.descripcion}
            handleQuitarPregunta={handleQuitarPregunta}
            handleSubirPregunta={handleSubirPregunta}
            handleBajarPregunta={handleBajarPregunta}
            cambiarAlineacionAreasPreguntas={cambiarAlineacionAreasPreguntas}
          />
        );
      })}
    </Box>
  );
}
