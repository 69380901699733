import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";

import {
  Grid,
  Button,
  Stack,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";

import useAuth from "../../../auth/useAuth";
import DatosUsuario from "../Comunes/DatosUsuario";
import ErrorSummary from "../../Forms/ErrorSummary";
import useGlobalDataContext from "../../../hooks/useGlobalDataContext";
import Loading from "../../Feedback/Loading";
import {
  API_URL,
  const_TipoDocumentoIdentidad,
} from "../../../utils/constants";

import useToastContext from "../../../hooks/useToastContext";
import { Severities } from "../../../contexts/ToastContext";

const anchoBotones = "150px";

export default function InformacionPersonal() {
  const [modoEdicion, setModoEdicion] = useState(false);

  const auth = useAuth();
  const globalData = useGlobalDataContext();
  const addToast = useToastContext();

  // En esta estructura se tienen los nombres de los campos en el idioma del usuario
  // Se recibirán del servidor cuando esté implementado el multi-lenguaje.
  const descripcionCampos = {
    CodigoPaisDocumentoIdentidad: globalData.terminosLocalizados.ETI071,
    TipoDocumentoIdentidad: globalData.terminosLocalizados.ETI072,
    NumeroDocumentoIdentidad: globalData.terminosLocalizados.ETI073,
    PrimerNombre: globalData.terminosLocalizados.ETI009,
    SegundoNombre: globalData.terminosLocalizados.ETI010,
    PrimerApellido: globalData.terminosLocalizados.ETI011,
    SegundoApellido: globalData.terminosLocalizados.ETI012,
    FechaNacimiento: globalData.terminosLocalizados.ETI080,
    LugarNacimiento: globalData.terminosLocalizados.ETI081,
    TelefonoCelular: globalData.terminosLocalizados.ETI083,
    TelefonoFijo: globalData.terminosLocalizados.ETI084,
    Email: globalData.terminosLocalizados.ETI085,
    Direccion: globalData.terminosLocalizados.ETI086,
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      PrimerNombre: auth.user.primerNombre ? auth.user.primerNombre : "",
      SegundoNombre: auth.user.segundoNombre ? auth.user.segundoApellido : "",
      PrimerApellido: auth.user.primerApellido ? auth.user.primerApellido : "",
      SegundoApellido: auth.user.segundoApellido
        ? auth.user.segundoApellido
        : "",
      FechaNacimiento: auth.user.fechaNacimiento
        ? auth.user.fechaNacimiento
        : "",
      LugarNacimiento: auth.user.lugarNacimiento
        ? auth.user.lugarNacimiento
        : "",
      Email: auth.user.email ? auth.user.email : "",
      TelefonoCelular: auth.user.telefonoCelular
        ? auth.user.telefonoCelular
        : "",
      TelefonoFijo: auth.user.telefonoFijo ? auth.user.telefonoFijo : "",
      Direccion: auth.user.direccion ? auth.user.direccion : "",
      CodigoPaisDocumento: auth.user.codigoPaisDocumento
        ? auth.user.codigoPaisDocumento
        : "",
      TipoDocumento: auth.user.tipoDocumento ? auth.user.tipoDocumento : "",
      NumeroDocumento: auth.user.numeroDocumento
        ? auth.user.numeroDocumento
        : "",
    },
  });

  const actualizarInformacionPersonal = async (data) => {
    const request = {
      Token: auth.user.token,
      Guid: auth.user.guid,
    };
    request.NuevosDatosUsuario = {
      PrimerNombre: data.PrimerNombre,
      SegundoNombre: data.SegundoNombre,
      PrimerApellido: data.PrimerApellido,
      SegundoApellido: data.SegundoApellido,
      FechaNacimiento: data.FechaNacimiento,
      LugarNacimiento: data.LugarNacimiento,
      Email: data.Email,
      TelefonoCelular: data.TelefonoCelular,
      TelefonoFijo: data.TelefonoFijo,
      Direccion: data.Direccion,
      CodigoPaisDocumento: data.CodigoPaisDocumento,
      TipoDocumento: data.TipoDocumento,
      NumeroDocumento: data.NumeroDocumento,
    };
    const url = `${API_URL()}/users/putUsuario`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(request),
    };
    try {
      const res = await fetch(url, options);
      if (res.ok) {
        const json = await res.json();
        if (json.resultadoOK) {
          auth.updateUser(json.usuario);
        } else {
          json.errores.forEach((item) => {
            addToast(item, Severities.error);
          });
        }
      } else {
        addToast(globalData.terminosLocalizados.AVI004, Severities.error);
      }
    } catch (err) {
      addToast(globalData.terminosLocalizados.AVI005, Severities.error);
    }
  };

  const handleEditar = () => {
    setModoEdicion(true);
  };

  const handleGuardar = (data) => {
    actualizarInformacionPersonal(data);
    setModoEdicion(false);
  };

  const handleCancelar = () => {
    setModoEdicion(false);
  };

  return (
    <form onSubmit={handleSubmit(handleGuardar)}>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={1}
        maxWidth="700px"
        margin={1}
        width="100%"
      >
        <RenderIdentificacionUsuario
          control={control}
          modoEdicion={modoEdicion}
        />
        <DatosUsuario
          control={control}
          inputProps={{ readOnly: !modoEdicion, disabled: !modoEdicion }}
          selectInputProps={{ readOnly: !modoEdicion }}
        />
        <Grid item md={12}>
          <RenderCommandBar
            modoEdicion={modoEdicion}
            handleEditar={handleEditar}
            handleCancelar={handleCancelar}
          />
        </Grid>
        <Grid item md={12}>
          <ErrorSummary
            errors={errors}
            descripciones={descripcionCampos}
          ></ErrorSummary>
        </Grid>
      </Grid>
    </form>
  );
}

function RenderIdentificacionUsuario(props) {
  const { control, modoEdicion } = props;

  const globalData = useGlobalDataContext();

  // Se deberían obtener desde el servidor en función del idioma del usuario
  const textos = {
    tituloIdentificacion: globalData.terminosLocalizados.ETI106,
    etiquetaCodigoPaisDocumento: globalData.terminosLocalizados.ETI076,
    etiquetaTipoDocumento: globalData.terminosLocalizados.ETI077,
    etiquetaNumeroDocumento: globalData.terminosLocalizados.ETI078,
  };

  // Se debería generalizar, porque se utiliza en varios componentes.
  const descripcionTipoDocumentoIdentidad = {
    CedulaIdentidad: globalData.terminosLocalizados.ETI068,
    Pasaporte: globalData.terminosLocalizados.ETI069,
    DNI: globalData.terminosLocalizados.ETI070,
  };

  return (
    <>
      <Grid item md={12} width="100%">
        <Typography variant="h7" fontStyle="italic">
          {textos.tituloIdentificacion}
        </Typography>
      </Grid>
      <Grid item md={12} width="100%">
        <FormControl size="small">
          <InputLabel>{textos.etiquetaCodigoPaisDocumento}</InputLabel>
          <Controller
            name="CodigoPaisDocumento"
            control={control}
            render={({ field }) => (
              <Select
                // A los <Select> no se les aplica "disabled" porque le cambia el color al contenido
                inputProps={{
                  readOnly: !modoEdicion,
                  //disabled: !modoEdicion
                }}
                label={textos.etiquetaCodigoPaisDocumento}
                MenuProps={{
                  PaperProps: { sx: { maxHeight: 190, width: "" } },
                }}
                {...field}
              >
                {globalData.loadingPaises ? (
                  <Loading />
                ) : (
                  globalData.paises.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.codigo}>
                        {item.nombre}
                      </MenuItem>
                    );
                  })
                )}
              </Select>
            )}
          ></Controller>
        </FormControl>
      </Grid>
      <Grid item md={6} width="100%">
        <FormControl size="small">
          <InputLabel>{textos.etiquetaTipoDocumento}</InputLabel>
          <Controller
            name="TipoDocumento"
            control={control}
            rules={{
              maxLength: {
                value: 40,
              },
            }}
            render={({ field }) => (
              <Select
                // A los <Select> no se les aplica "disabled" porque le cambia el color al contenido
                inputProps={{
                  readOnly: !modoEdicion,
                  //disabled: !modoEdicion
                }}
                label={textos.etiquetaTipoDocumento}
                {...field}
              >
                <MenuItem value={const_TipoDocumentoIdentidad.CEDULA_IDENTIDAD}>
                  {descripcionTipoDocumentoIdentidad.CedulaIdentidad}
                </MenuItem>
                <MenuItem value={const_TipoDocumentoIdentidad.PASAPORTE}>
                  {descripcionTipoDocumentoIdentidad.Pasaporte}
                </MenuItem>
                <MenuItem value={const_TipoDocumentoIdentidad.DNI}>
                  {descripcionTipoDocumentoIdentidad.DNI}
                </MenuItem>
              </Select>
            )}
          ></Controller>
        </FormControl>
      </Grid>
      <Grid item md={6} width="100%">
        <Controller
          name="NumeroDocumento"
          control={control}
          rules={{
            required: {
              value: true,
            },
            maxLength: {
              value: 40,
            },
          }}
          render={({ field }) => (
            <TextField
              type="text"
              name={textos.etiquetaNumeroDocumento}
              label={textos.etiquetaNumeroDocumento}
              size="small"
              inputProps={{ readOnly: !modoEdicion, disabled: !modoEdicion }}
              {...field}
            />
          )}
        ></Controller>
      </Grid>
    </>
  );
}

function RenderCommandBar(props) {
  const { modoEdicion, handleEditar, handleCancelar } = props;

  const globalData = useGlobalDataContext();

  // Se deberían obtener desde el servidor en función del idioma del usuario
  const textos = {
    textoEditar: globalData.terminosLocalizados.COM040,
    textoGuardar: globalData.terminosLocalizados.COM041,
    textoCancelar: globalData.terminosLocalizados.COM004,
  };

  return (
    <Stack direction={"row"} spacing={1} justifyContent="flex-start">
      {!modoEdicion && (
        <Button
          variant="CommandBar3"
          onClick={handleEditar}
          sx={{
            width: anchoBotones,
          }}
        >
          {textos.textoEditar}
        </Button>
      )}
      {modoEdicion && (
        <Button
          variant="CommandBar3"
          type="submit"
          sx={{
            width: anchoBotones,
          }}
        >
          {textos.textoGuardar}
        </Button>
      )}
      {modoEdicion && (
        <Button
          variant="CommandBar3"
          onClick={handleCancelar}
          sx={{
            width: anchoBotones,
          }}
        >
          {textos.textoCancelar}
        </Button>
      )}
    </Stack>
  );
}
