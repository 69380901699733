import { createTheme } from "@mui/material/styles";

// Definición de la paleta de colores y breakpoints personalizados
let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      xs1: 320,
      xs2: 380,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      // Personalizado: para la timeline, en la
      // selección de caregorías y código de colores.
      //mine: 3000,
    },
  },
  palette: {
    primary: {
      main: "#00677A",
      contrastText: "#fff",
      background: "#fff",
    },
    secondary: {
      main: "#F2C640",
      contrastText: "#00677A",
    },
    terciary: { main: "#E2EEF1", dark: "#005063", contrastText: "#fff" },
    cuaternary: {
      main: "#E2EEF1",
      dark: "#B5DBEB",
      ultraDark: "#A3D3E6",
      ultraDarkPlus: "#81C2DC",
      contrastText: "#00677A",
    },
    infoBox: { main: "#ADDBE2", contrastText: "#000" },
    timeline: {
      comercios: "#7DA544",
      resultados: "#228E85",
      apuntesProfesional: "#1f6eaa",
      prescripciones: "#D59F4B",
      prescripcionesTest: "#F488EA",
      notasProfesional: "#156235",
      notasPaciente: "#1D82A4",
      portatiles: "#840F5B",
      registrosAntiguos: "#F1C335",
      registrosAntiguosDark: "#D9B63A",
      iconos: "#5b5b5f",
      search: { main: "#587A8D", contrastText: "#FFF" },
    },
  },
});

//console.log(theme);

// Aplicación de la paleta de colores y otros estilos a los componentes
theme = createTheme(theme, {
  components: {
    // Esto no funciona. Lo aplico a través del CSS
    // MuiBox: {
    //   styleOverrides: {
    //     root: { borderStyle: "solid", boxSizing: "border-box" },
    //   },
    // },
    MuiPaper: {},
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
    // Esto se agrega porque el DataGrid de MUI utiliza FormControl para el ícono de los filtros
    // y como en la regla anterior establecimos 100%, entonces el ícono ocupa el 100% del formulario
    // de filtros y no se ve el resto de los campos.
    MuiDataGrid: {
      styleOverrides: {
        filterFormDeleteIcon: {
          width: "20px",
        },
      },
    },
    MuiFormGroup: {
      styleOverrides: {
        root: {
          //marginTop: 5,
          //marginBottom: 5,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          paddingLeft: 10,
          paddingRight: 10,
        },
      },
    },
    MuiMenuList: {
      styleOverrides: {
        root: {
          paddingLeft: 10,
          paddingRight: 10,
        },
      },
    },
    MuiMenuItem: {
      variants: [
        {
          // Esta variante es para utilizarse en los menúes desplegables
          // sin afectar otros elementos del mismo componente
          props: { variant: "menu" },
          style: {
            color: theme.palette.primary.contrastText,
            "&:hover": {
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.secondary.contrastText,
            },
          },
        },
      ],
    },
    MuiList: {
      variants: [
        {
          props: { variant: "menu" },
          style: {
            backgroundColor: theme.palette.primary.main,
            paddingLeft: 8,
            paddingRight: 8,
          },
        },
      ],
    },
    MuiListItem: {
      variants: [
        {
          // Esta variante es para utilizarse en los menúes desplegables
          // sin afectar otros elementos del mismo componente
          props: { variant: "menu" },
          style: {
            "&.Mui-selected": {
              backgroundColor: theme.palette.secondary.main,
            },
            "&:hover": {
              backgroundColor: theme.palette.primary.dark,
            },
            "&.Mui-selected:hover": {
              backgroundColor: theme.palette.secondary.dark,
            },
          },
        },
      ],
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: theme.palette.secondary.main,
          },
          "&:hover": {
            backgroundColor: theme.palette.secondary.dark,
          },
          "&.Mui-selected:hover": {
            backgroundColor: theme.palette.secondary.dark,
          },
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.primary.main,
          "&:hover": {
            backgroundColor: theme.palette.secondary.main,
          },
        },
      },
    },
    MuiLink: {
      variants: [
        {
          // Esta variante es para utilizarse en los menúes desplegables
          // sin afectar otros elementos del mismo componente
          props: { variant: "menu" },
          style: {
            color: "inherit",
          },
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          borderRadius: "5px",
          //borderStyle: "solid",
          //borderWidth: "1px",
          //borderColor: theme.palette.primary.main,
          //textAlign: "center",
          "&:hover": {
            backgroundColor: theme.palette.primary.light,
          },
          "&:focus": {
            backgroundColor: theme.palette.primary.light,
            outlineStyle: "none",
          },
        },
      },
      variants: [
        {
          props: { variant: "icon" },
          style: {
            backgroundColor: "transparent",
            color: theme.palette.primary.main,
            "&:hover": {
              backgroundColor: "transparent",
              color: theme.palette.secondary.dark,
            },
            "&:focus": {
              backgroundColor: "transparent",
              color: theme.palette.secondary.dark,
            },
          },
        },
        // Estas variantes deberían cambiarse por "commandBar1", "commandBar2", etc.
        // o por "cb1", "cb2", etc. (al estilo de los header "h1", "h2", etc.)
        {
          //props: { variant: "command" },
          props: { variant: "CommandBar1" },
          style: { height: "20px", minWidth: "70px", paddingTop: "8px" },
        },
        {
          //props: { variant: "alto" },
          props: { variant: "CommandBar2" },
          style: { height: "30px", width: "120px", minWidth: "120px" },
        },
        {
          //props: { variant: "extra-alto" },
          props: { variant: "CommandBar3" },
          style: { height: "40px", width: "120px", minWidth: "120px" },
        },
        {
          //props: { variant: "extra-alto" },
          props: { variant: "CommandBar4" },
          style: { height: "40px", width: "210px", minWidth: "210px" },
        },
        {
          props: { variant: "destacado" },
          style: {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            fontWeight: "bold",
            height: "30px",
            width: "120px",
            "&:hover": {
              backgroundColor: theme.palette.secondary.dark,
            },
          },
        },
        {
          props: { variant: "navegation-top" },
          style: {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            height: "22px",
            textTransform: "none",
            borderRadius: "15px 15px 0 0",
            "&:hover": {
              // En los botones de navegación que no cambie el color del fondo
              backgroundColor: theme.palette.secondary.main,
            },
            "&:focus": {
              // En los botones de navegación que no cambie el color del fondo
              backgroundColor: theme.palette.secondary.main,
            },
          },
        },
        {
          props: { variant: "navegation-left" },
          style: {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            height: "18px",
            textTransform: "none",
            // Para que se posiciona en la extrema izquierda del contenedor
            position: "fixed",
            // Para que se posiciona en la extrema izquierda del contenedor
            left: "0px",
            transform: "rotate(-90deg)",
            transformOrigin: "left top",
            borderRadius: "0 0 15px 15px",
            "&:hover": {
              // En los botones de navegación que no cambie el color del fondo
              backgroundColor: theme.palette.secondary.main,
            },
            "&:focus": {
              // En los botones de navegación que no cambie el color del fondo
              backgroundColor: theme.palette.secondary.main,
            },
          },
        },
        {
          props: { variant: "navegation-right" },
          style: {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            height: "18px",
            textTransform: "none",
            // Para que se posiciona en la extrema derecha del contenedor
            position: "fixed",
            // Para que se posiciona en la extrema derecha del contenedor
            right: "0px",
            transform: "rotate(-90deg)",
            transformOrigin: "right bottom",
            borderRadius: "15px 15px 0 0",
            "&:hover": {
              // En los botones de navegación que no cambie el color del fondo
              backgroundColor: theme.palette.secondary.main,
            },
            "&:focus": {
              // En los botones de navegación que no cambie el color del fondo
              backgroundColor: theme.palette.secondary.main,
            },
          },
        },
      ],
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          //backgroundColor: "#00677A",
          // padding: "3px 0px 3px 3px",
          //paddingTop: 0,
          //paddingLeft: "14px",
          //paddingTop: "0px",
        },
      },
      variants: [
        {
          props: { variant: "TimelineSearch" },
          style: {
            backgroundColor: theme.palette.timeline.search.main,
            color: theme.palette.timeline.search.contrastText,
            padding: "0px 10px 0px 10px",
            borderRadius: "7px",
            fontSize: 12,
          },
        },
      ],
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
        },
        arrow: {
          color: theme.palette.secondary.main,
        },
      },
    },
    //Esto no sé cómo funciona, se supone que es para customizar las barras de desplazamiento
    // Solo funcionó parcialmente aplicándolo a un componente en particular mediante la propiedad "sx"
    // (por ejemplo, funcionó el cambio de colores, pero no funcionó el cambio del ancho de la barra)
    MuiCssBaseline: {
      styleOverrides: {
        "@global": {
          "&::-webkit-scrollbar": {
            width: "5px",
          },
          "&::-webkit-scrollbar-track": {
            background: theme.palette.secondary.main,
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.primary.main,
            outline: "1px solid slategrey",
          },
        },
      },
    },
    tab: {
      "& .MuiBox-root": {
        padding: "0px",
      },
    },
  },
  // Esto lo saco porque no anda.
  // Se supone que modificando aquí, se aplica la propiedad definida
  // para todas las instancias del componente.
  // props: {
  //   MuiInputBase: {
  //     size: "small",
  //   },
  // },
});

//console.log(theme);

export default theme;
