import { React } from "react";

import { Box } from "@mui/material";

import useAuth from "../auth/useAuth";

import HomePage from "../pages/HomePage";
import ContentPage from "../pages/ContentPage";
import ActivationPage from "../pages/ActivationPage";
import { useLocation } from "react-router-dom";

export default function AppRouter() {
  const auth = useAuth();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activationCode = queryParams.get("codigo");
  const isLogged = auth.isLogged();

  //console.log(auth.isLogged());
  return (
    <Box
      id="app-router-box"
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* {auth.isLogged() ? <ContentPage /> : <HomePage />} */}
      {isLogged ? (
        <ContentPage />
      ) : activationCode ? (
        <ActivationPage token={activationCode} />
      ) : (
        <HomePage />
      )}
    </Box>
  );
}
