import React from "react";

import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import EdicionListaAreasPreguntas from "./EdicionListaAreasPreguntas";
import BoxSeccion from "./BoxSeccion";
import { const_TipoOrdenableFormularioClinico } from "../../../../utils/constants";
import theme from "../../../../utils/theme";

import useGlobalDataContext from "../../../../hooks/useGlobalDataContext";

export default function EdicionListaSecciones(props) {
  const {
    secciones,
    guidEspecialidad,
    descripcionAreaSalud,
    descripcionEspecialidad,
    handleQuitarPregunta,
    handleSubirPregunta,
    handleBajarPregunta,
    cambiarAlineacionAreasPreguntas,
  } = props;

  const globalData = useGlobalDataContext();

  return (
    <Box>
      {secciones.map((itemSecciones, indexSecciones) => {
        const ariacontrols = `panel-${guidEspecialidad}-content`;
        const id = `seccionpanel-${guidEspecialidad}-header`;
        const indexUltimo = secciones.length - 1;
        return (
          <Accordion id="Accordion-Id" sx={{ padding: 1 }}>
            <BoxSeccion
              itemSecciones={itemSecciones}
              indexSecciones={indexSecciones}
              indexUltimo={indexUltimo}
              guid={itemSecciones.guid}
              tipo={const_TipoOrdenableFormularioClinico.SECCION}
              subible={indexSecciones !== 0}
              bajable={indexSecciones < indexUltimo}
              handleQuitarPregunta={handleQuitarPregunta}
              handleSubirPregunta={handleSubirPregunta}
              handleBajarPregunta={handleBajarPregunta}
              cambiarAlineacionAreasPreguntas={cambiarAlineacionAreasPreguntas}
            >
              <Box
                sx={{
                  display: "flex",
                  flexGrow: 2,
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexGrow: 2,
                    alignItems: "center",
                    marginRight: 2,
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon
                        sx={{
                          color: theme.palette.primary.contrastText,
                        }}
                      />
                    }
                    aria-controls={ariacontrols}
                    id={id}
                    sx={{
                      height: "48px",
                      color: theme.palette.primary.contrastText,
                      backgroundColor: theme.palette.primary.dark,
                      borderRadius: "30px",
                      flexGrow: 2,
                      "&.Mui-expanded": {
                        // Se sobrescribe la altura mínima para que respeta la definida
                        // para el elemento contraído.
                        minHeight: "48px",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography>{itemSecciones.descripcion}</Typography>
                    </Box>
                    <Box
                      sx={{
                        marginLeft: "auto",
                        marginRight: 4,
                        color: theme.palette.secondary.main,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography sx={{ fontSize: 12 }}>
                        {/* Área de salud: {descripcionAreaSalud} */}
                        {globalData.terminosLocalizados.ETI103}:{" "}
                        {descripcionAreaSalud}
                      </Typography>
                      <Typography sx={{ fontSize: 12 }}>
                        {/* Especialidad: {descripcionEspecialidad} */}
                        {globalData.terminosLocalizados.ETI104}:{" "}
                        {descripcionEspecialidad}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                </Box>
              </Box>
            </BoxSeccion>
            <AccordionDetails id="AccordionDetails-id" sx={{ paddingRight: 0 }}>
              <EdicionListaAreasPreguntas
                areasPreguntas={itemSecciones.areasPreguntas}
                handleQuitarPregunta={handleQuitarPregunta}
                handleSubirPregunta={handleSubirPregunta}
                handleBajarPregunta={handleBajarPregunta}
                areasPreguntasHorizontal={
                  itemSecciones.areasPreguntasHorizontal
                }
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
}
