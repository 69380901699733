import React from "react";

import { useForm } from "react-hook-form";

import {
  Card,
  Typography,
  Stack,
  Button,
  //TextField,
} from "@mui/material";

import ErrorSummary from "../../../Forms/ErrorSummary";
import theme from "../../../../utils/theme";

import useGlobalDataContext from "../../../../hooks/useGlobalDataContext";

export default function EliminarElemento(props) {
  const { tipo, titulo, close, edicion, id, descripcion } = props;

  const globalData = useGlobalDataContext();

  const {
    handleSubmit,
    formState: { errors },
  } = useForm({});

  // En esta estructura se tienen los nombres de los campos en el idioma del usuario
  // Se recibirán del servidor cuando esté implementado el multi-lenguaje.
  const descripcionCampos = {
    Elemento: "Elemento",
  };

  const cancelar = () => {
    close();
  };

  const eliminarLocal = (data) => {
    data.Tipo = tipo;
    data.Id = id;
    data.Descripcion = descripcion;
    data.ParentKey = edicion(data);
  };

  return (
    <Card
      sx={{
        borderStyle: "solid",
        border: 2,
        borderColor: theme.palette.primary.main,
        p: 2,
        width: 400,
      }}
    >
      <form onSubmit={handleSubmit(eliminarLocal)}>
        <Stack spacing={1}>
          <Typography variant="h5" sx={{ marginBottom: 1 }}>
            {/* Eliminar {titulo} */}
            {globalData.terminosLocalizados.ETI148} {titulo}
          </Typography>
          <Typography>{descripcion}</Typography>
          <Stack direction={"row"} spacing={1} justifyContent="flex-end">
            <Button variant="CommandBar2" type="submit">
              {/* Eliminar */}
              {globalData.terminosLocalizados.COM039}
            </Button>
            <Button variant="CommandBar2" onClick={cancelar}>
              {/* Cancelar */}
              {globalData.terminosLocalizados.COM004}
            </Button>
          </Stack>
          <ErrorSummary
            errors={errors}
            descripciones={descripcionCampos}
          ></ErrorSummary>
        </Stack>
      </form>
    </Card>
  );
}
