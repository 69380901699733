import React from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  Typography,
  FormControl,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Button,
  Grid,
} from "@mui/material";

import {
  API_URL,
  const_TipoDocumentoIdentidad,
} from "../../../../utils/constants";

import useAuth from "../../../../auth/useAuth";
import useToastContext from "../../../../hooks/useToastContext";
import { Severities } from "../../../../contexts/ToastContext";
import useGlobalDataContext from "../../../../hooks/useGlobalDataContext";

import ErrorSummary from "../../../Forms/ErrorSummary";
import Loading from "../../../Feedback/Loading";

import theme from "../../../../utils/theme";

export default function BuscarPaciente(props) {
  const { setPaciente, solicitarCreacionPaciente } = props;

  const auth = useAuth();
  const addToast = useToastContext();
  const globalData = useGlobalDataContext();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      CodigoPaisDocumento: "UY",
      TipoDocumento: "CI",
    },
  });

  // Se debería obtener desde el servidor en función del idioma del usuario
  const titulo = globalData.terminosLocalizados.ETI088; // Buscar Paciente

  // En esta estructura se tienen los nombres de los campos en el idioma del usuario
  // Se recibirán del servidor cuando esté implementado el multi-lenguaje.
  const descripcionCampos = {
    CodigoPaisDocumentoIdentidad: globalData.terminosLocalizados.ETI071,
    TipoDocumentoIdentidad: globalData.terminosLocalizados.ETI072,
    NumeroDocumentoIdentidad: globalData.terminosLocalizados.ETI073,
  };

  // Se deberían obtener desde el servidor en función del idioma del usuario
  const textos = {
    tituloIdentificacion: globalData.terminosLocalizados.ETI106,
    etiquetaCodigoPaisDocumento: globalData.terminosLocalizados.ETI076,
    etiquetaTipoDocumento: globalData.terminosLocalizados.ETI077,
    etiquetaNumeroDocumento: globalData.terminosLocalizados.ETI078,
    textoEditar: globalData.terminosLocalizados.COM040,
    textoGuardar: globalData.terminosLocalizados.COM041,
    textoCancelar: globalData.terminosLocalizados.COM004,
  };

  // Se recibirán del servidor cuando esté implementado el multi-lenguaje.
  // Se debería generalizar y obtenerlo en algún punto en común, porque
  // se utiliza en diferentes lugares.
  const descripcionTipoDocumentoIdentidad = {
    CedulaIdentidad: globalData.terminosLocalizados.ETI068,
    Pasaporte: globalData.terminosLocalizados.ETI069,
    DNI: globalData.terminosLocalizados.ETI070,
  };

  // Se recibirá del servidor cuando esté implementado el multi-lenguaje.
  const textoBuscar = globalData.terminosLocalizados.COM034;
  const textoCrear = globalData.terminosLocalizados.COM031;

  const buscarLocal = (data) => {
    buscarPaciente(data);
  };

  const handleCrearTimeline = () => {
    solicitarCreacionPaciente();
  };

  const buscarPaciente = async (data) => {
    const request = {
      Token: auth.user.token,
      GuidCuentaAdicional: auth.cuentaActiva.guid,
      CodigoPaisDocumento: data.CodigoPaisDocumento,
      TipoDocumento: data.TipoDocumento,
      NumeroDocumento: data.NumeroDocumento,
    };
    const url = `${API_URL()}/FormularioClinico/getPaciente`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(request),
    };
    try {
      const res = await fetch(url, options);
      if (res.ok) {
        const json = await res.json();
        if (json.resultadoOK) {
          setPaciente((prevState) => ({
            ...prevState,
            estado: { cargado: true, creando: false },
            paisDocumento: data.CodigoPaisDocumento,
            tipoDocumento: data.TipoDocumento,
            numeroDocumento: data.NumeroDocumento,
            nombre: json.nombre,
            nombreCompleto: json.nombreCompleto,
            edad: json.edad,
            ultimaVisita: json.ultimaVisita,
            timeline: json.timeline,
          }));
        } else {
          json.errores.forEach((item) => {
            addToast(item, Severities.error);
          });
        }
      } else {
        //addToast("Error de servicio.", Severities.error);
        addToast(globalData.terminosLocalizados.AVI004, Severities.error);
      }
    } catch (err) {
      //addToast("Error de conexión", Severities.error);
      addToast(globalData.terminosLocalizados.AVI005, Severities.error);
    }
  };

  return (
    <Box
      id="buscar-paciente-contenido"
      height={"100%"}
      minWidth="210px"
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Box>
        <Box
          bgcolor={theme.palette.terciary.main}
          padding={1}
          borderRadius={2}
          width={226}
        >
          <form onSubmit={handleSubmit(buscarLocal)}>
            <Grid container rowSpacing={1}>
              <Grid item md={12}>
                <Typography variant="h6">{titulo}</Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl size="small">
                  <InputLabel>{textos.etiquetaCodigoPaisDocumento}</InputLabel>
                  <Controller
                    name="CodigoPaisDocumento"
                    control={control}
                    render={({ field }) => (
                      <Select
                        // A los <Select> no se les aplica "disabled" porque le cambia el color al contenido
                        inputProps={
                          {
                            //readOnly: !modoEdicion,
                            //disabled: !modoEdicion
                          }
                        }
                        label={textos.etiquetaCodigoPaisDocumento}
                        MenuProps={{
                          PaperProps: { sx: { maxHeight: 190, width: "" } },
                        }}
                        {...field}
                      >
                        {globalData.loadingPaises ? (
                          <Loading />
                        ) : (
                          globalData.paises.map((item, index) => {
                            return (
                              <MenuItem key={index} value={item.codigo}>
                                {item.nombre}
                              </MenuItem>
                            );
                          })
                        )}
                      </Select>
                    )}
                  ></Controller>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl size="small">
                  <InputLabel
                  //inputProps={{ readOnly: !modoEdicion, disabled: !modoEdicion }}
                  >
                    {textos.etiquetaTipoDocumento}
                  </InputLabel>
                  <Controller
                    name="TipoDocumento"
                    control={control}
                    render={({ field }) => (
                      <Select
                        // A los <Select> no se les aplica "disabled" porque le cambia el color al contenido
                        inputProps={
                          {
                            //readOnly: !modoEdicion,
                            //disabled: !modoEdicion
                          }
                        }
                        label={textos.etiquetaTipoDocumento}
                        {...field}
                      >
                        <MenuItem
                          value={const_TipoDocumentoIdentidad.CEDULA_IDENTIDAD}
                        >
                          {descripcionTipoDocumentoIdentidad.CedulaIdentidad}
                        </MenuItem>
                        <MenuItem
                          value={const_TipoDocumentoIdentidad.PASAPORTE}
                        >
                          {descripcionTipoDocumentoIdentidad.Pasaporte}
                        </MenuItem>
                        <MenuItem value={const_TipoDocumentoIdentidad.DNI}>
                          {descripcionTipoDocumentoIdentidad.DNI}
                        </MenuItem>
                      </Select>
                    )}
                  ></Controller>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="NumeroDocumento"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                    },
                    maxLength: {
                      value: 12,
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      type="text"
                      size="small"
                      label={textos.etiquetaNumeroDocumento}
                      {...field}
                    />
                  )}
                ></Controller>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="CommandBar2"
                  type="submit"
                  sx={{ width: "100%" }}
                >
                  {textoBuscar}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="CommandBar2"
                  sx={{
                    width: "100%",
                  }}
                  onClick={handleCrearTimeline}
                >
                  {textoCrear}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <ErrorSummary
                  errors={errors}
                  descripciones={descripcionCampos}
                ></ErrorSummary>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </Box>
  );
}
