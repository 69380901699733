import React from "react";
import { Box, Card } from "@mui/material";
import theme from "../../../../utils/theme";

export default function CajaListaSeleccion(props) {
  const { titulo, children } = props;
  return (
    <Card
      id="lista-seleccion-card"
      sx={{
        width: 160,
        minWidth: 160,
        maxWidth: 160,
        bgcolor: "background.paper",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: theme.palette.primary.main,
        borderRadius: "3%",
        marginLeft: 1,
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          textAlign: "center",
          height: 22,
        }}
      >
        {titulo}
      </Box>
      {children}
    </Card>
  );
}
