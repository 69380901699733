import React from "react";
import { Box } from "@mui/material";

import Hexagono from "./Hexagono";

export default function GrillaHexagonos(props) {
  const {
    valoresGrilla,
    handleClick,
    repositorioContenido,
    calcularLeft,
    hexagonoActivo,
    hayHexagonoActivo,
    detectarHexagonoActivo,
  } = props;

  if (valoresGrilla === null || valoresGrilla === undefined) {
    return null;
  }

  const columnaPar = (columna) => {
    return columna % 2 === 0;
  };

  const calcularTop = (fila, columna) => {
    let top = fila * valoresGrilla.hexagonoAltura; //+

    if (columnaPar(columna)) {
      top += valoresGrilla.hexagonoAltura / 2;
    }
    return top;
  };

  const calcularLeftLocal = (columna) => {
    return calcularLeft(
      columna,
      valoresGrilla.hexagonoAncho,
      valoresGrilla.separacionHorizontal
    );
  };

  const generarKey = (fila, columna) => {
    return `X${fila}${columna}`;
  };

  let grilla = [];

  // Por hora
  let columnasAdicionales = 0;
  let filasAdicionales = 0;

  // Primera capa de hexágonos
  for (
    let numeroColumna = -1;
    numeroColumna < valoresGrilla.cantidadColumnas + 2 + columnasAdicionales;
    numeroColumna++
  ) {
    const valorLeft = calcularLeftLocal(numeroColumna);

    for (
      let numeroFila = -1;
      numeroFila < valoresGrilla.cantidadFilas + 1 + filasAdicionales;
      numeroFila++
    ) {
      const valorTop = calcularTop(numeroFila, numeroColumna);

      // Por defecto, para hexágonos sin contenido, la clave es la fila y la columna.
      // Si recibe contenido, más adelante, se asigna el identificador del contenido.
      var key = generarKey(numeroFila, numeroColumna);
      var texto = null;
      var destino = null;
      var id = null;
      var proximamente = false;
      var zIndex = 0;

      let top = valorTop;
      let left = valorLeft;
      let opacidad = 1;

      grilla.push(
        <Hexagono
          key={key}
          top={top}
          left={left}
          viewBoxTop={0}
          viewBoxLeft={0}
          viewBoxWidth={valoresGrilla.hexagonoAnchoOriginal}
          viewBoxHeight={valoresGrilla.hexagonoAlturaOriginal}
          ancho={valoresGrilla.hexagonoAncho}
          altura={valoresGrilla.hexagonoAltura}
          handleClick={handleClick}
          texto={texto}
          id={id}
          destino={destino}
          proximamente={proximamente}
          hexagonoActivo={hexagonoActivo}
          hayHexagonoActivo={hayHexagonoActivo}
          detectarHexagonoActivo={detectarHexagonoActivo}
          fila={numeroFila}
          columna={numeroColumna}
          zIndex={zIndex}
          opacidad={opacidad}
        />
      );
    }
  }

  // Segunda capa de hexágonos
  var itemContenido = null;
  var itemContenidoIndexActual = -1;

  for (
    let numeroColumna = -1;
    numeroColumna < valoresGrilla.cantidadColumnas + 2 + columnasAdicionales;
    numeroColumna++
  ) {
    const valorLeft = calcularLeftLocal(numeroColumna);

    for (
      let numeroFila = -1;
      numeroFila < valoresGrilla.cantidadFilas + 1 + filasAdicionales;
      numeroFila++
    ) {
      const valorTop = calcularTop(numeroFila, numeroColumna);

      // Determinar si en este hexágono corresponde incluir contenido
      let habilitadoContenido = false;
      if (valoresGrilla.orientacion === "H") {
        if (numeroColumna >= 0) {
          if (columnaPar(numeroColumna)) {
            habilitadoContenido = numeroFila >= 0 && numeroFila < 2;
          } else {
            habilitadoContenido = numeroFila >= 0 && numeroFila < 3;
          }
        }
      } else {
        if (numeroFila >= 0) {
          habilitadoContenido = numeroColumna >= 0 && numeroColumna <= 2;
        }
      }

      let flagGenerar = false;
      if (habilitadoContenido) {
        if (itemContenidoIndexActual < repositorioContenido.length - 1) {
          itemContenidoIndexActual += 1;
          itemContenido = repositorioContenido[itemContenidoIndexActual];
          key = itemContenido.id;
          texto = itemContenido.texto;
          id = itemContenido.id;
          proximamente = itemContenido.proximamente;
          destino = itemContenido.destino;
          flagGenerar = true;
        }
      }

      if (flagGenerar) {
        let top = valorTop;
        let left = valorLeft;
        let zIndex = 1;
        let opacidad = 1;

        if (hayHexagonoActivo()) {
          // Hay hexágono activo
          if (hexagonoActivo[0].id === id) {
            // Este es el hexágono activo de este nivel (el primer nivel)
            // Se mueve al centro de la flor
            top = calcularTop(1, 1);
            left = calcularLeftLocal(1);
            // Se establece un zIndex superior
            //zIndex = hexagonoActivo.length * 2;
            zIndex = 2;
            if (hexagonoActivo.length === 1) {
              // Si hay solo un nivel de hexágonos activos
              // este es el hexágono activo que se tiene que mostrar
              opacidad = 1;
            } else {
              // Si no, este hexágono se tienen que ocultar
              opacidad = 0;
            }
          } else {
            // Este no es el hexágono activo de este nivl
            opacidad = 0;
          }
        } else {
          // No hay hexágono activo: este nivel se tiene que mostrar
          opacidad = 1;
        }

        grilla.push(
          <Hexagono
            key={key}
            top={top}
            left={left}
            viewBoxTop={0}
            viewBoxLeft={0}
            viewBoxWidth={valoresGrilla.hexagonoAnchoOriginal}
            viewBoxHeight={valoresGrilla.hexagonoAlturaOriginal}
            ancho={valoresGrilla.hexagonoAncho}
            altura={valoresGrilla.hexagonoAltura}
            handleClick={handleClick}
            texto={texto}
            id={id}
            destino={destino}
            proximamente={proximamente}
            hexagonoActivo={hexagonoActivo}
            hayHexagonoActivo={hayHexagonoActivo}
            detectarHexagonoActivo={detectarHexagonoActivo}
            fila={numeroFila}
            columna={numeroColumna}
            zIndex={zIndex}
            opacidad={opacidad}
          />
        );
      }
    }
  }

  // Capas subsiguientes
  if (hayHexagonoActivo()) {
    const buscarRecursivo = (array, propiedad, valor) => {
      for (const elemento of array) {
        if (elemento[propiedad] === valor) {
          return elemento;
        } else if (
          elemento.destino !== null &&
          typeof elemento.destino === "object"
        ) {
          const resultadoRecursivo = buscarRecursivo(
            elemento.destino,
            propiedad,
            valor
          );
          if (resultadoRecursivo) {
            return resultadoRecursivo;
          }
        }
      }
      return null;
    };
    for (let index = 0; index < hexagonoActivo.length; index++) {
      const idNivel = hexagonoActivo[index].id;
      const itemContenidoActivo = buscarRecursivo(
        repositorioContenido,
        "id",
        idNivel
      );
      if (
        itemContenidoActivo !== undefined &&
        Array.isArray(itemContenidoActivo.destino)
      ) {
        let item = null;
        let ubicaciones = [];
        const agregarUbicacion = (columna, fila, elemento) => {
          ubicaciones.push({
            columna: columna,
            fila: fila,
            texto: elemento.texto,
            destino: elemento.destino,
            id: elemento.id,
            proximamente: elemento.proximamente,
          });
        };
        const cantidadElementos = itemContenidoActivo.destino.length;
        let columnaDestino = null;

        let filaDestino = null;
        if (cantidadElementos >= 1) {
          // Ubicar el primer elemento
          item = itemContenidoActivo.destino[0];
          if (
            cantidadElementos === 1 ||
            cantidadElementos === 2 ||
            cantidadElementos === 4
          ) {
            columnaDestino = 2;
            filaDestino = 0;
          } else {
            // Para el resto, incluso si son más de seis
            columnaDestino = 1;
            filaDestino = 0;
          }
          agregarUbicacion(columnaDestino, filaDestino, item);
        }
        if (cantidadElementos >= 2) {
          // Ubicar el segundo elemento
          item = itemContenidoActivo.destino[1];
          if (
            cantidadElementos === 2 ||
            cantidadElementos === 3 ||
            cantidadElementos === 4
          ) {
            columnaDestino = 2;
            filaDestino = 1;
          } else {
            // Para el resto, incluso si son más de seis
            columnaDestino = 2;
            filaDestino = 0;
          }
          agregarUbicacion(columnaDestino, filaDestino, item);
        }
        if (cantidadElementos >= 3) {
          // Ubicar el tercer elemento
          item = itemContenidoActivo.destino[2];
          if (cantidadElementos === 3 || cantidadElementos === 4) {
            columnaDestino = 0;
            filaDestino = 1;
          } else {
            columnaDestino = 2;
            filaDestino = 1;
          }
          agregarUbicacion(columnaDestino, filaDestino, item);
        }
        if (cantidadElementos >= 4) {
          // Ubicar el cuarto elemento
          item = itemContenidoActivo.destino[3];
          if (cantidadElementos === 4) {
            columnaDestino = 0;
            filaDestino = 0;
          } else {
            if (cantidadElementos === 5) {
              columnaDestino = 0;
              filaDestino = 1;
            } else {
              columnaDestino = 1;
              filaDestino = 2;
            }
          }
          agregarUbicacion(columnaDestino, filaDestino, item);
        }
        if (cantidadElementos >= 5) {
          // Ubicar el quinto elemento
          item = itemContenidoActivo.destino[4];
          if (cantidadElementos === 5) {
            columnaDestino = 0;
            filaDestino = 0;
          } else {
            columnaDestino = 0;
            filaDestino = 1;
          }
          agregarUbicacion(columnaDestino, filaDestino, item);
        }
        if (cantidadElementos >= 6) {
          // Ubicar el sexto elemento
          item = itemContenidoActivo.destino[5];
          columnaDestino = 0;
          filaDestino = 0;
          agregarUbicacion(columnaDestino, filaDestino, item);
        }
        if (cantidadElementos > 6) {
          if (valoresGrilla.orientacion === "H") {
            // La grilla se despliega horizontalmente: tres filas como máximo
            let contadorColumna = 3; // El resto de hexágonos se coloca a partir de la 3ra. columna
            let contadorFila = 0;
            for (let index = 6; index < cantidadElementos; index++) {
              item = itemContenidoActivo.destino[index];
              agregarUbicacion(contadorColumna, contadorFila, item);
              contadorFila += 1;
              if (
                (columnaPar(contadorColumna) && contadorFila > 1) ||
                contadorFila > 2
              ) {
                // Si se superaron las dos filas de una columna par o las tres de una columna impar
                // se pasa a la siguiente columna
                contadorColumna += 1;
                contadorFila = 0;
              }
            }
          } else {
            // La grilla se despliega verticalmente: tres columnas como máximo
            let contadorColumna = 0;
            let contadorFila = 2;
            //let nuevaFila = 0;
            for (let index = 6; index < cantidadElementos; index++) {
              item = itemContenidoActivo.destino[index];
              agregarUbicacion(contadorColumna, contadorFila, item);
              contadorColumna += 1;
              if (contadorColumna > 2) {
                contadorColumna = 0;
                contadorFila += 1;
              }
            }
          }
        }
        let indexUbicacion = 0;
        itemContenidoActivo.destino.forEach((itemContenido) => {
          const itemUbicacion = ubicaciones[indexUbicacion];

          let valorLeft = calcularLeftLocal(itemUbicacion.columna);
          let valorTop = calcularTop(itemUbicacion.fila, itemUbicacion.columna);

          key = itemContenido.id;
          texto = itemContenido.texto;
          id = itemContenido.id;
          proximamente = itemContenido.proximamente;
          destino = itemContenido.destino;
          let opacidad = 1;

          if (index !== hexagonoActivo.length - 1) {
            // Este no es el último nivel
            if (hexagonoActivo[index + 1].id === itemContenido.id) {
              // Este es el hexágono activo del siguiente nivel:
              // Se debe colocar en el centro de la flor
              valorLeft = calcularLeftLocal(1);
              valorTop = calcularTop(1, 1);
              if (hexagonoActivo.length === index + 2) {
                // El siguiente nivel es el nivel superior
                opacidad = 1;
              } else {
                opacidad = 0;
              }
            } else {
              opacidad = 0;
            }
          } else {
            // Este es el último nivel: el contenido debe estar visible
            opacidad = 1;
          }

          zIndex = (index + 1) * 2 + 1;
          //console.log(`texto=${texto} index=${index} zIndex=${zIndex}`);

          grilla.push(
            <Hexagono
              key={key}
              top={valorTop}
              left={valorLeft}
              viewBoxTop={0}
              viewBoxLeft={0}
              viewBoxWidth={valoresGrilla.hexagonoAnchoOriginal}
              viewBoxHeight={valoresGrilla.hexagonoAlturaOriginal}
              ancho={valoresGrilla.hexagonoAncho}
              altura={valoresGrilla.hexagonoAltura}
              handleClick={handleClick}
              texto={texto}
              id={id}
              destino={destino}
              proximamente={proximamente}
              hexagonoActivo={hexagonoActivo}
              hayHexagonoActivo={hayHexagonoActivo}
              detectarHexagonoActivo={detectarHexagonoActivo}
              fila={itemUbicacion.fila}
              columna={itemUbicacion.columna}
              zIndex={zIndex}
              opacidad={opacidad}
            />
          );
          indexUbicacion++;
        });
      }
    }
  }

  return (
    <Box id="grilla" width="100%" height="100%" position={"relative"}>
      {grilla}
    </Box>
  );
}
