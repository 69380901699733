import { createContext, useState, useEffect } from "react";

import { API_URL, const_TipoCuentaAdicional } from "../utils/constants";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  // Si se quiere persistencia entre sesiones o luego de refrescar el navegador
  // hay que quitar los comentarios a estas tres líneas
  // y comentar o quitar la línea que está después.
  // const [user, setUser] = useState(
  //   JSON.parse(sessionStorage.getItem(const_CurrentUser)) || null
  // );
  const [user, setUser] = useState(null);

  // Si se quiere persistencia entre sesiones o luego de refrescar el navegador
  // hay que quitar los comentarios a estas tres líneas
  // y comentar o quitar la línea que está después.
  // const [cuentaActiva, setcuentaActiva] = useState(
  //   JSON.parse(sessionStorage.getItem(const_CuentaAdicionalActiva)) || null
  // );
  const [cuentaActiva, setCuentaActiva] = useState(null);
  const [cuentaActivaInicializada, setCuentaActivaInicializada] =
    useState(false);

  const [refrescarAvatarCuentas, setRefrescarAvatarCuentas] = useState(false);

  // Creo este estado para marcar cada vez que se actualiza el avatar de una cuenta
  // para que posteriormente se revise si es el de la cuenta activa
  const [contadorAvatarCargado, setContadorAvatarCargado] = useState(0);

  // Cargar el avatar de cada cuenta
  useEffect(() => {
    //console.log("Cargar el avatar de cada cuenta adicional");
    if (user === null) {
      return;
    }
    user.cuentasAdicionales.lista.forEach((item) => {
      (async () => {
        try {
          //console.log(item);
          const request = {
            Token: user.token,
          };
          var url;
          if (item.codigoCategoria !== null) {
            // Es una cuenta adicional: se agrega a la solicitud el guid de la cuenta
            request.GuidCuentaAdicional = item.guid;
            url = `${API_URL()}/Users/getAvatarCuentaAdicional`;
          } else {
            url = `${API_URL()}/Users/getAvatar`;
          }
          const options = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(request),
          };
          const res = await fetch(url, options);
          if (res.status === 200) {
            const blob = await res.blob();
            item.avatar = blob;
            item.avatarURL = URL.createObjectURL(blob);
            setContadorAvatarCargado((previous) => {
              return previous + 1;
            });
          } else {
            item.avatar = null;
            item.avatarURL = "."; // Para que muestre las iniciales
          }
        } catch (err) {
          //console.log(err);
          //setCargandoAvatar(false);
        }
      })();
    });
  }, [user, refrescarAvatarCuentas]);

  // Establecer cuenta activa por defecto
  // Si la cuenta activa no está inicializada se carga la primera cuenta de la lista,
  // que es la cuenta principal del usuario
  useEffect(() => {
    if (user === null) {
      return;
    }
    if (user.cuentasAdicionales === null) {
      return;
    }
    if (!cuentaActivaInicializada) {
      setCuentaActiva(user.cuentasAdicionales.lista[0]);
      setCuentaActivaInicializada(true);
    }
    // eslint-disable-next-line
  }, [
    user, //cuentaActivaInicializada
  ]);

  // Revisar si se cargó el avatar de la cuenta activa
  useEffect(() => {
    if (user === null) {
      return;
    }
    if (user.cuentasAdicionales === null) {
      return;
    }
    if (cuentaActiva === null) {
      return;
    }
    user.cuentasAdicionales.lista.forEach((item) => {
      if (item.guid === cuentaActiva.guid) {
        setCuentaActiva((previous) => {
          return {
            ...previous,
            avatar: item.avatar,
            avatarURL: item.avatarURL,
          };
        });
      }
    });
    // eslint-disable-next-line
  }, [user, contadorAvatarCargado]);

  const contextValue = {
    user,
    cuentaActiva,
    refrescarAvatarCuentas() {
      setRefrescarAvatarCuentas(true);
    },
    actualizarAvatarActivo(blob) {
      let nuevaListaCuentas = user.cuentasAdicionales.lista.map((item) => {
        if (item.guid === cuentaActiva.guid) {
          item.avatar = blob;
          item.avatarURL = URL.createObjectURL(blob);
        }
        return item;
      });
      user.cuentasAdicionales.lista = nuevaListaCuentas;
      setCuentaActiva((previous) => {
        return {
          ...previous,
          avatar: blob,
          avatarURL: URL.createObjectURL(blob),
        };
      });
    },
    activarCuenta(index) {
      const cuentaActiva = user.cuentasAdicionales.lista[index];
      setCuentaActiva(cuentaActiva);
    },
    setLoggedIn(loggedUser) {
      setUser(loggedUser);
    },
    logout() {
      setUser(null);
      setCuentaActiva(null);
      setCuentaActivaInicializada(false);
      setRefrescarAvatarCuentas(false);
      setContadorAvatarCargado(0);
      // Si se quiere persistencia entre sesiones o luego de refrescar el navegador
      // hay que quitar los comentarios a estas dos líneas.
      // sessionStorage.removeItem(const_CuentaAdicionalActiva);
      // sessionStorage.removeItem(const_CurrentUser);
    },
    isLogged() {
      const respuesta = !!user;
      return respuesta;
    },
    setAdditionalAccounts(additionalAccounts) {
      setUser((previous) => {
        return { ...previous, cuentasAdicionales: additionalAccounts };
      });
    },
    updateUser(updateData) {
      //console.log(updateData);
      setUser((previous) => {
        return {
          ...previous,
          codigoPaisDocumento: updateData.codigoPaisDocumento,
          tipoDocumento: updateData.tipoDocumento,
          numeroDocumento: updateData.numeroDocumento,
          primerNombre: updateData.primerNombre,
          segundoNombre: updateData.segundoNombre,
          primerApellido: updateData.primerApellido,
          segundoApellido: updateData.segundoApellido,
          fechaNacimiento: updateData.fechaNacimiento,
          lugarNacimiento: updateData.lugarNacimiento,
          direccion: updateData.direccion,
          email: updateData.email,
          telefonoCelular: updateData.telefonoCelular,
          telefonoFijo: updateData.telefonoFijo,
          nombrePaisDocumeto: updateData.nombrePaisDocumeto,
          //codigoIdioma: updateData.codigoIdioma,
        };
      });
    },
    updateConfiguracion(updateData) {
      setUser((previous) => {
        return {
          ...previous,
          codigoIdioma: updateData.codigoIdioma,
        };
      });
    },
    isProfessional() {
      if (!cuentaActiva) {
        return false;
      } else {
        if (
          cuentaActiva.codigoCategoria === const_TipoCuentaAdicional.PROFESIONAL
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    esInstitucion() {
      if (!cuentaActiva) {
        return false;
      } else {
        if (
          cuentaActiva.codigoCategoria === const_TipoCuentaAdicional.INSTITUCION
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    isCuentaPrincipal() {
      if (!cuentaActiva) {
        return false;
      } else {
        if (cuentaActiva.codigoCategoria === null) {
          return true;
        } else {
          return false;
        }
      }
    },
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
