import React from "react";

import BoxPregunta from "./BoxPregunta";

import { const_TipoOrdenableFormularioClinico } from "../../../../utils/constants";

export default function BoxSeccion(props) {
  const {
    itemSecciones,
    indexSecciones,
    indexUltimo,
    guid,
    handleQuitarPregunta,
    handleSubirPregunta,
    handleBajarPregunta,
    cambiarAlineacionAreasPreguntas,
    children,
  } = props;

  return (
    <BoxPregunta
      guid={guid}
      tipo={const_TipoOrdenableFormularioClinico.SECCION}
      subible={indexSecciones !== 0}
      bajable={indexSecciones < indexUltimo}
      alineable={true}
      handleQuitarPregunta={handleQuitarPregunta}
      handleSubirPregunta={handleSubirPregunta}
      handleBajarPregunta={handleBajarPregunta}
      cambiarAlineacionAreasPreguntas={cambiarAlineacionAreasPreguntas}
      areasPreguntasHorizontal={itemSecciones.areasPreguntasHorizontal}
    >
      {children}
    </BoxPregunta>
  );
}
