import React from "react";

import { Box } from "@mui/material";

import HelloImage from "../../assets/img/Hello_EN_NoTRN_82X30.png";
import Logo_Beemedics from "../../assets/img/Logo_BeeMedics.png";
import Loading from "../Feedback/Loading";

import Login from "./Login";
import Signup from "./Signup";

//import "./Hello.scss";

export default function Hello(props) {
  const { terminos, loadingTerminos } = props;

  if (loadingTerminos) {
    return <Loading />;
  }

  return (
    <Box
      id="helloContainer"
      display={"flex"}
      flexDirection="column"
      justifyContent={"space-between"}
      padding="20px"
      borderRadius={"3%"}
      textAlign="left"
      color="rgb(113,113,117)"
      flexGrow={1}
      height="100%"
      width="clamp(198px, 90vw, 327px)"
      bgcolor={"rgb(224,234,238)"}
    >
      <Header />
      <Notice1 terminos={terminos} />
      <Notice2 terminos={terminos}></Notice2>
      <Login terminos={terminos} />
      <Signup terminos={terminos} />
    </Box>
  );
}

function Header() {
  return (
    <Box id="header" height={"30px"} width="100%">
      <img style={{ height: "100%" }} src={HelloImage} alt="Hello"></img>
    </Box>
  );
}

function Notice1(props) {
  const { terminos } = props;

  return (
    <Box
      id="notice1"
      paddingTop={"10px"}
      height="auto"
      flexDirection={"row"}
      verticalalign="middle"
      fontSize={"16px"}
      color="rgb(0,103,122)"
      fontWeight={"bold"}
      sx={{ wordWrap: "normal" }}
    >
      <img
        style={{ height: "30px", marginRight: "5px", verticalalign: "middle" }}
        src={Logo_Beemedics}
        alt="BeeMedics"
      ></img>
      <span style={{ verticalAlign: "sub", marginTop: "10px" }}>
        {terminos.AVI001}
      </span>
    </Box>
  );
}

function Notice2(props) {
  const { terminos } = props;

  return (
    <Box id="notice2" flexGrow={0} fontStyle="italic" fontSize="12px">
      <span>{terminos.AVI002}</span>
    </Box>
  );
}
