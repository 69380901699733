import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  Typography,
  FormControl,
  FormGroup,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Button,
  Grid,
  Stack,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

import {
  API_URL,
  const_TipoDocumentoIdentidad,
} from "../../../../utils/constants";

import useAuth from "../../../../auth/useAuth";
import useToastContext from "../../../../hooks/useToastContext";
import { Severities } from "../../../../contexts/ToastContext";
import ErrorSummary from "../../../Forms/ErrorSummary";
import theme from "../../../../utils/theme";
import useGlobalDataContext from "../../../../hooks/useGlobalDataContext";
import Loading from "../../../Feedback/Loading";
import DatosUsuario from "../../Comunes/DatosUsuario";

const anchoBotones = "150px";

export default function CrearPaciente(props) {
  const { setPaciente, solicitarCancelar } = props;
  const [paginaWizard, setPaginaWizard] = useState(1);

  const [identificacionPaciente, setIdentificacionPaciente] = useState({
    PaisDocumentoIdentidad: "UY",
    TipoDocumentoIdentidad: "CI",
    NumeroDocumentoIdentidad: "",
  });

  const [informacionPaciente, setInformacionPaciente] = useState({
    Existe: false,
    Cargando: false,
    PrimerNombre: "",
    SegundoNombre: "",
    PrimerApellido: "",
    SegundoApellido: "",
    FechaNacimiento: null,
    LugarNacimiento: "",
    TelefonoMovil: "",
    TelefonoFijo: "",
    Email: "",
    Domicilio: "",
  });

  const auth = useAuth();
  const addToast = useToastContext();
  const globalData = useGlobalDataContext();

  // Se deberían obtener desde el servidor en función del idioma del usuario
  const textos = {
    titulo: globalData.terminosLocalizados.ETI074, // "Crear nueva timeline de paciente",
    tituloIdentificacionPaciente: globalData.terminosLocalizados.ETI075, // "Identificación del paciente",
    etiquetaPaisDocumento: globalData.terminosLocalizados.ETI076, // "País del documento",
    etiquetaTipoDocumento: globalData.terminosLocalizados.ETI077, // "Tipo de documento",
    etiquetaNumeroDocumento: globalData.terminosLocalizados.ETI078, // "Número de documento",
    tituloInformacionPersonal: globalData.terminosLocalizados.ETI079, // "Información personal",
    etiquetaNombre1: globalData.terminosLocalizados.ETI009, // "Primer Nombre",
    etiquetaNombre2: globalData.terminosLocalizados.ETI010, // "Segundo Nombre",
    etiquetaApellido1: globalData.terminosLocalizados.ETI011, // "Primer Apellido",
    etiquetaApellido2: globalData.terminosLocalizados.ETI012, // "Segundo Apellido",
    etiquetaFechaNacimiento: globalData.terminosLocalizados.ETI080, // "Fecha de Nacimiento",
    etiquetaLugarNacimiento: globalData.terminosLocalizados.ETI081, // "Lugar de Nacimiento",
    tituloInformacionContacto: globalData.terminosLocalizados.ETI082, // "Información de contacto",
    etiquetaTelefonoCelular: globalData.terminosLocalizados.ETI083, // "Teléfono móvil",
    etiquetaTelefonoFijo: globalData.terminosLocalizados.ETI084, // "Teléfono fijo",
    etiquetaEmail: globalData.terminosLocalizados.ETI085, // "Email",
    etiquetaDomicilio: globalData.terminosLocalizados.ETI086, // "Domicilio",
    etiquetaAceptar: globalData.terminosLocalizados.COM031, // "Crear nueva timeline",
    textoCrear: globalData.terminosLocalizados.ETI087, // "Crear Nueva Timeline",
    textoCancelar: globalData.terminosLocalizados.COM004, // "Cancelar",
    textoAnterior: globalData.terminosLocalizados.COM032, // "Anterior",
    textoSiguiente: globalData.terminosLocalizados.COM033, // "Siguiente",
    yaExiste1: globalData.terminosLocalizados.AVI028, // "El usuario ya se encuentra registrado en el sistema.",
    yaExiste2: globalData.terminosLocalizados.AVI029, // "Los datos del usuario registrado se presentan a continuación.",
    yaExiste3: globalData.terminosLocalizados.AVI030, // "¿Quiere agregar un registro de paciente para este usuario?",
    yaExisteCrear: globalData.terminosLocalizados.AVI031, // "Sí, crear un registro de paciente para este usuario.",
    yaExisteNoAsociar: globalData.terminosLocalizados.AVI032, // "Debe indicar que quiere usar los datos presentados, o cancelar.",
  };

  // Se debería generalizar y obtener en algún punto en común, porque
  // se utiliza en varios componentes.
  const descripcionTipoDocumentoIdentidad = {
    CedulaIdentidad: globalData.terminosLocalizados.ETI068, // "Cédula de Identidad",
    Pasaporte: globalData.terminosLocalizados.ETI069, // "Pasaporte",
    DNI: globalData.terminosLocalizados.ETI070, // "DNI",
  };

  // Se debería generalizar y obtener el algún punto en compun, porque
  //se utiliza en varios componentes.
  const descripcionCampos = {
    CodigoPaisDocumentoIdentidad: globalData.terminosLocalizados.ETI071, // "Código de país",
    TipoDocumentoIdentidad: globalData.terminosLocalizados.ETI072, // "Tipo de documento",
    NumeroDocumentoIdentidad: globalData.terminosLocalizados.ETI073, // "Número de documento",
  };

  const handleCancelar = () => {
    solicitarCancelar();
  };

  const handleAvanzar = () => {
    setPaginaWizard(paginaWizard + 1);
  };

  const handleRetroceder = () => {
    setPaginaWizard(paginaWizard - 1);
  };

  return (
    <Box
      id="crearPacienteContenedor"
      width={"100%"}
      height={"100%"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      paddingLeft={1}
      paddingRight={2}
    >
      <Box width={"100%"}>
        <Box
          width={"100%"}
          bgcolor={theme.palette.terciary.main}
          padding={1}
          borderRadius={2}
        >
          <Box width={"100%"}>
            <Typography variant="h6" marginY={0.5}>
              {textos.titulo}
            </Typography>
          </Box>
          {((pagina) => {
            switch (pagina) {
              case 1:
                return (
                  <RenderIdentificarPaciente
                    handleCancelar={handleCancelar}
                    handleAvanzar={handleAvanzar}
                    handleRetroceder={handleRetroceder}
                    paginaWizard={paginaWizard}
                    auth={auth}
                    addToast={addToast}
                    identificacionPaciente={identificacionPaciente}
                    setIdentificacionPaciente={setIdentificacionPaciente}
                    informacionPaciente={informacionPaciente}
                    setInformacionPaciente={setInformacionPaciente}
                    descripcionTipoDocumentoIdentidad={
                      descripcionTipoDocumentoIdentidad
                    }
                    descripcionCampos={descripcionCampos}
                    textos={textos}
                  />
                );
              case 2: {
                if (informacionPaciente.Existe) {
                  return (
                    <RenderIngresarPaciente
                      handleCancelar={handleCancelar}
                      handleAvanzar={handleAvanzar}
                      handleRetroceder={handleRetroceder}
                      paginaWizard={paginaWizard}
                      auth={auth}
                      addToast={addToast}
                      setPaciente={setPaciente}
                      identificacionPaciente={identificacionPaciente}
                      setIdentificacionPaciente={setIdentificacionPaciente}
                      informacionPaciente={informacionPaciente}
                      setInformacionPaciente={setInformacionPaciente}
                      descripcionCampos={descripcionCampos}
                      textos={textos}
                    />
                  );
                } else {
                  return (
                    <RenderIngresarPaciente
                      handleCancelar={handleCancelar}
                      handleRetroceder={handleRetroceder}
                      paginaWizard={paginaWizard}
                      auth={auth}
                      addToast={addToast}
                      setPaciente={setPaciente}
                      identificacionPaciente={identificacionPaciente}
                      informacionPaciente={informacionPaciente}
                      descripcionCampos={descripcionCampos}
                      textos={textos}
                    />
                  );
                }
              }
              default:
                alert(pagina);
                return null;
            }
          })(paginaWizard)}
        </Box>
      </Box>
    </Box>
  );
}

function RenderIdentificarPaciente(props) {
  const {
    handleCancelar,
    handleAvanzar,
    handleRetroceder,
    paginaWizard,
    auth,
    addToast,
    identificacionPaciente,
    setIdentificacionPaciente,
    setInformacionPaciente,
    descripcionTipoDocumentoIdentidad,
    descripcionCampos,
    textos,
  } = props;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      PaisDocumentoIdentidad: identificacionPaciente.PaisDocumentoIdentidad,
      TipoDocumentoIdentidad: identificacionPaciente.TipoDocumentoIdentidad,
      NumeroDocumentoIdentidad: identificacionPaciente.NumeroDocumentoIdentidad,
    },
  });

  const globalData = useGlobalDataContext();

  const validarPaciente = (data) => {
    setIdentificacionPaciente({
      PaisDocumentoIdentidad: data.PaisDocumentoIdentidad,
      TipoDocumentoIdentidad: data.TipoDocumentoIdentidad,
      NumeroDocumentoIdentidad: data.NumeroDocumentoIdentidad,
    });
    buscarPaciente(
      data.PaisDocumentoIdentidad,
      data.TipoDocumentoIdentidad,
      data.NumeroDocumentoIdentidad
    );
    handleAvanzar();
  };

  const buscarPaciente = async (
    paisDocumento,
    tipoDocumento,
    numeroDocumento
  ) => {
    setInformacionPaciente((prevState) => ({
      ...prevState,
      Existe: false,
      Cargando: true,
    }));

    const request = {
      Token: auth.user.token,
      CodigoPaisDocumento: paisDocumento,
      TipoDocumento: tipoDocumento,
      NumeroDocumento: numeroDocumento,
    };
    const url = `${API_URL()}/users/getUsuario`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(request),
    };
    try {
      const res = await fetch(url, options);
      if (res.ok) {
        const json = await res.json();
        if (json.resultadoOK) {
          if (json.existe) {
            setInformacionPaciente((prevState) => ({
              ...prevState,
              Existe: json.existe,
              PrimerNombre: json.usuario.primerNombre,
              SegundoNombre: json.usuario.segundoNombre,
              PrimerApellido: json.usuario.primerApellido,
              SegundoApellido: json.usuario.segundoApellido,
              FechaNacimiento: json.usuario.fechaNacimiento,
              LugarNacimiento: json.usuario.lugarNacimiento,
              TelefonoMovil: json.usuario.telefonoMovil,
              TelefonoFijo: json.usuario.telefonoFijo,
              Email: json.usuario.email,
              Domicilio: json.usuario.domicilio,
              Cargando: false,
            }));
          } else {
            setInformacionPaciente((prevState) => ({
              ...prevState,
              Existe: json.existe,
              PrimerNombre: "",
              SegundoNombre: "",
              PrimerApellido: "",
              SegundoApellido: "",
              FechaNacimiento: "",
              LugarNacimiento: "",
              TelefonoMovil: "",
              TelefonoFijo: "",
              Email: "",
              Domicilio: "",
              Cargando: false,
            }));
          }
        } else {
          setInformacionPaciente((prevState) => ({
            ...prevState,
            Existe: false,
            Cargando: false,
          }));
          json.errores.forEach((item) => {
            addToast(item, Severities.error);
          });
        }
      } else {
        //addToast("Error de servicio.", Severities.error);
        addToast(globalData.terminosLocalizados.AVI004, Severities.error);
      }
    } catch (err) {
      //addToast("Error de conexión", Severities.error);
      addToast(globalData.terminosLocalizados.AVI005, Severities.error);
    }
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(validarPaciente)}>
        <Grid container rowSpacing={1} columnSpacing={1}>
          <Grid item md={12}>
            <Typography variant="h7" fontStyle={"italic"}>
              {textos.tituloIdentificacionPaciente}
            </Typography>
          </Grid>
          <Grid item md={12}>
            <FormControl size="small">
              <InputLabel>{textos.etiquetaCodigoPaisDocumento}</InputLabel>
              <Controller
                name="PaisDocumentoIdentidad"
                control={control}
                render={({ field }) => (
                  <Select
                    // A los <Select> no se les aplica "disabled" porque le cambia el color al contenido
                    inputProps={
                      {
                        //readOnly: !modoEdicion,
                        //disabled: !modoEdicion
                      }
                    }
                    label={textos.etiquetaCodigoPaisDocumento}
                    MenuProps={{
                      PaperProps: { sx: { maxHeight: 190, width: "" } },
                    }}
                    {...field}
                  >
                    {globalData.loadingPaises ? (
                      <Loading />
                    ) : (
                      globalData.paises.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item.codigo}>
                            {item.nombre}
                          </MenuItem>
                        );
                      })
                    )}
                  </Select>
                )}
              ></Controller>
            </FormControl>
          </Grid>
          <Grid item md={6}>
            <FormControl size="small">
              {/* Si no se asigna "top" de 5px la etiqueta queda desfazada */}
              <InputLabel id="select-tipo-documento-identidad-label">
                {textos.etiquetaTipoDocumento}
              </InputLabel>
              <Controller
                name="TipoDocumentoIdentidad"
                control={control}
                rules={{ required: { value: true } }}
                render={({ field }) => (
                  <Select
                    labelId="select-tipo-documento-identidad-label"
                    id="select-tipo-documento-identidad"
                    value={identificacionPaciente.TipoDocumentoIdentidad}
                    label="Tipo de Documento"
                    defaultValue={identificacionPaciente.TipoDocumentoIdentidad}
                    {...field}
                  >
                    <MenuItem
                      value={const_TipoDocumentoIdentidad.CEDULA_IDENTIDAD}
                    >
                      {descripcionTipoDocumentoIdentidad.CedulaIdentidad}
                    </MenuItem>
                    <MenuItem value={const_TipoDocumentoIdentidad.PASAPORTE}>
                      {descripcionTipoDocumentoIdentidad.Pasaporte}
                    </MenuItem>
                    <MenuItem value={const_TipoDocumentoIdentidad.DNI}>
                      {descripcionTipoDocumentoIdentidad.DNI}
                    </MenuItem>
                  </Select>
                )}
              ></Controller>
            </FormControl>
          </Grid>
          <Grid item md={6}>
            {/* Nota: debe establecerse marginTop=0 porque el control anterior está agregando espacio
              adicional por debajo, y produce una separación superior entre ambos controles. */}
            <Controller
              name="NumeroDocumentoIdentidad"
              control={control}
              rules={{
                required: {
                  value: true,
                },
                maxLength: {
                  value: 12,
                },
              }}
              render={({ field }) => (
                <TextField
                  type="text"
                  size="small"
                  //name="NumeroDocumentoIdentidad"
                  label="Numero del documento"
                  //defaultValue={identificacionPaciente.NumeroDocumentoIdentidad}
                  {...field}
                />
              )}
            ></Controller>
          </Grid>
          <Grid item md={12}>
            <RenderCommandBar
              handleCancelar={handleCancelar}
              handleRetroceder={handleRetroceder}
              paginaWizard={paginaWizard}
              textos={textos}
            />
          </Grid>
          <Grid item md={12}>
            <FormGroup>
              <ErrorSummary
                errors={errors}
                descripciones={descripcionCampos}
              ></ErrorSummary>
            </FormGroup>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

function RenderIngresarPaciente(props) {
  const {
    handleCancelar,
    handleRetroceder,
    paginaWizard,
    auth,
    addToast,
    setPaciente,
    identificacionPaciente,
    informacionPaciente,
    descripcionCampos,
    textos,
  } = props;

  const globalData = useGlobalDataContext();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({});

  const crearTimeline = async (data) => {
    if (informacionPaciente.existe && !data.AsociarUsuario) {
      alert("No se seleccionó el checkbox");
    }

    const request = {
      Token: auth.user.token,
      GuidCuentaAdicional: auth.cuentaActiva.guid,
      CodigoPaisDocumento: identificacionPaciente.PaisDocumentoIdentidad,
      TipoDocumento: identificacionPaciente.TipoDocumentoIdentidad,
      NumeroDocumento: identificacionPaciente.NumeroDocumentoIdentidad,
      Existe: informacionPaciente.Existe,
    };
    if (!informacionPaciente.Existe) {
      request.DatosNuevoPaciente = {
        PrimerNombre: data.PrimerNombre,
        SegundoNombre: data.SegundoNombre,
        PrimerApellido: data.PrimerApellido,
        SegundoApellido: data.SegundoApellido,
        FechaNacimiento: data.FechaNacimiento.toISOString().substring(0, 10),
        LugarNacimiento: data.LugarNacimiento,
        Email: data.Email,
        TelefonoCelular: data.TelefonoCelular,
        TelefonoFijo: data.TelefonoFijo,
        Direccion: data.Direccion,
        CodigoPaisDocumento: identificacionPaciente.PaisDocumentoIdentidad,
        TipoDocumento: identificacionPaciente.TipoDocumentoIdentidad,
        NumeroDocumento: identificacionPaciente.NumeroDocumentoIdentidad,
      };
    }
    const url = `${API_URL()}/formularioClinico/addPaciente`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(request),
    };
    try {
      const res = await fetch(url, options);
      if (res.ok) {
        const json = await res.json();
        if (json.resultadoOK) {
          setPaciente((prevState) => ({
            ...prevState,
            estado: { cargado: true, creando: false },
            paisDocumento: identificacionPaciente.PaisDocumentoIdentidad,
            tipoDocumento: identificacionPaciente.TipoDocumentoIdentidad,
            numeroDocumento: identificacionPaciente.NumeroDocumentoIdentidad,
            nombre: json.nombre,
            nombreCompleto: json.nombreCompleto,
            edad: json.edad,
            ultimaVisita: json.ultimaVisita,
            timeline: json.timeline,
          }));
        } else {
          json.errores.forEach((item) => {
            addToast(item, Severities.error);
          });
        }
      } else {
        //addToast("Error de servicio.", Severities.error);
        addToast(globalData.terminosLocalizados.AVI004, Severities.error);
      }
    } catch (err) {
      //addToast("Error de conexión", Severities.error);
      addToast(globalData.terminosLocalizados.AVI005, Severities.error);
    }
  };

  useEffect(() => {
    reset({
      PrimerNombre: informacionPaciente.PrimerNombre,
      SegundoNombre: informacionPaciente.SegundoNombre,
      PrimerApellido: informacionPaciente.PrimerApellido,
      SegundoApellido: informacionPaciente.SegundoApellido,
      FechaNacimiento: informacionPaciente.FechaNacimiento,
      LugarNacimiento: informacionPaciente.LugarNacimiento,
      TelefonoMovil: informacionPaciente.TelefonoMovil,
      TelefonoFijo: informacionPaciente.TelefonoFijo,
      Email: informacionPaciente.Email,
      Domicilio: informacionPaciente.Domicilio,
    });
  }, [informacionPaciente, reset]);

  var inputProps = {};
  if (informacionPaciente.Existe) {
    inputProps.readOnly = true;
  }

  if (informacionPaciente.Cargando) {
    return <Box>Cargando...</Box>;
  }

  return (
    <Box width={"100%"}>
      <form onSubmit={handleSubmit(crearTimeline)}>
        <Grid container rowSpacing={1} columnSpacing={1}>
          {informacionPaciente.Existe && (
            <Grid item md={12}>
              <Box
                bgcolor={theme.palette.infoBox.main}
                color={theme.palette.infoBox.contrastText}
                padding={1}
                textAlign="center"
              >
                <Typography variant="body2">{textos.yaExiste1}</Typography>
                <Typography variant="body2">{textos.yaExiste2}</Typography>
                <Typography variant="body2">{textos.yaExiste3}</Typography>
              </Box>
            </Grid>
          )}
          <DatosUsuario control={control} inputProps={inputProps} />
          {informacionPaciente.Existe && (
            <Grid item md={12}>
              <Controller
                name="AsociarUsuario"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: textos.yaExisteNoAsociar,
                  },
                }}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox size="small" />}
                    label={textos.yaExisteCrear}
                    {...field}
                  />
                )}
              ></Controller>
            </Grid>
          )}
          <Grid item md={12}>
            <RenderCommandBar
              handleCancelar={handleCancelar}
              handleRetroceder={handleRetroceder}
              paginaWizard={paginaWizard}
              textos={textos}
            />
          </Grid>
          <Grid item md={12}>
            <ErrorSummary
              errors={errors}
              descripciones={descripcionCampos}
            ></ErrorSummary>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

function RenderCommandBar(props) {
  const { handleCancelar, handleRetroceder, paginaWizard, textos } = props;
  return (
    <Box display={"flex"} justifyContent="space-between">
      <Button
        variant="CommandBar3"
        onClick={handleCancelar}
        sx={{
          width: anchoBotones,
        }}
      >
        {textos.textoCancelar}
      </Button>
      <Stack direction={"row"} spacing={1} justifyContent="flex-end">
        {paginaWizard !== 1 && (
          <Button
            variant="CommandBar3"
            onClick={handleRetroceder}
            sx={{
              width: anchoBotones,
            }}
          >
            {textos.textoAnterior}
          </Button>
        )}
        <Button
          variant="CommandBar3"
          type="submit"
          sx={{
            width: anchoBotones,
          }}
        >
          {textos.textoSiguiente}
        </Button>
      </Stack>
    </Box>
  );
}
