import React, { useState, useEffect } from "react";

import { Box } from "@mui/material";

import GrillaHexagonos from "../components/Datos/Dashboard/GrillaHexagonos";

import { const_Paginas } from "../utils/constants";

import useGlobalDataContext from "../hooks/useGlobalDataContext";
import useAuth from "../auth/useAuth";

export default function DashboardPage(props) {
  const { setCurrentPage } = props;

  const [valoresGrilla, setValoresGrilla] = useState(null);

  const [hexagonoActivo, setHexagonoActivo] = useState([]);

  const globalData = useGlobalDataContext();
  const auth = useAuth();

  const calcularLeft = (columna, hexagonoAncho, separacionHorizontal) => {
    var left = (columna * hexagonoAncho) / 1.2 + separacionHorizontal;
    return left;
  };

  useEffect(() => {
    const calcularCantidadColumnas = (
      grillaAncho,
      hexagonoAncho,
      separacionHorizontal
    ) => {
      let contadorColumnas = 0;
      let left = 0;
      let continuar = true;
      while (continuar) {
        left = calcularLeft(
          contadorColumnas,
          hexagonoAncho,
          separacionHorizontal
        );
        if (left + hexagonoAncho + separacionHorizontal > grillaAncho) {
          continuar = false;
        }
        contadorColumnas += 1;
      }
      return contadorColumnas - 1;
    };
    const calcularValoresGrilla = () => {
      const alturaHeader = 50;

      const grillaAncho = window.innerWidth;
      const grillaAltura = window.innerHeight - alturaHeader;

      const separacionHorizontal = 6;
      const separacionVertical = 6;
      const hexagonoAnchoOriginal = 239;
      const hexagonoAlturaOriginal = 209; // + separacionVertical;

      let cantidadFilas = 0;
      let cantidadColumnas = 0;

      const factorAnchoHexagonos =
        hexagonoAnchoOriginal / hexagonoAlturaOriginal;

      let hexagonoAncho = hexagonoAnchoOriginal;
      let hexagonoAltura = hexagonoAlturaOriginal;

      let orientacion;

      if (grillaAncho > grillaAltura) {
        // La pantalla está dispuesta horizontalmente:
        // debe haber tres hexágonos verticales.
        orientacion = "H";
        cantidadFilas = 3;
        const cantidadHexagonos = 3;
        hexagonoAltura = grillaAltura / cantidadHexagonos;
        hexagonoAncho = hexagonoAltura * factorAnchoHexagonos;
        cantidadColumnas = calcularCantidadColumnas(
          grillaAncho,
          hexagonoAncho,
          separacionHorizontal
        );
        // Se aseguran como mínimo tres columnas
        if (cantidadColumnas < 3) {
          cantidadColumnas = 3;
        }
      } else {
        // La pantalla está dispuesta verticalmente:
        // debe haber tres hexágonos horozintales entrelazados
        orientacion = "V";
        cantidadColumnas = 3;
        const factorHorizontal = 2.74;
        hexagonoAncho = grillaAncho / factorHorizontal;
        hexagonoAltura = hexagonoAncho / factorAnchoHexagonos;
        cantidadFilas = Math.floor(grillaAltura / hexagonoAltura);
      }

      setValoresGrilla({
        orientacion: orientacion,
        grillaAncho: grillaAncho,
        grillaAltura: grillaAltura,
        hexagonoAncho: hexagonoAncho,
        hexagonoAltura: hexagonoAltura,
        separacionVertical: separacionVertical,
        separacionHorizontal: separacionHorizontal,
        hexagonoAnchoOriginal: hexagonoAnchoOriginal,
        hexagonoAlturaOriginal: hexagonoAlturaOriginal,
        cantidadFilas: cantidadFilas,
        cantidadColumnas: cantidadColumnas,
        overflow_x: false,
        overflow_y: false,
      });
    };
    calcularValoresGrilla();

    window.addEventListener("resize", calcularValoresGrilla);

    return () => {
      window.removeEventListener("resize", calcularValoresGrilla);
    };
  }, []);

  if (globalData.terminosLocalizados === null) {
    return null;
  }

  const textos = {
    suiteProfesional: globalData.terminosLocalizados.ETI196,
    formularioClinico: globalData.terminosLocalizados.ETI152,
    abrirFormularioClinico: globalData.terminosLocalizados.ETI195,
    editarFormularioClinico: globalData.terminosLocalizados.ETI153,
    agenda: globalData.terminosLocalizados.ETI192,
    beeGeo: globalData.terminosLocalizados.ETI191,
    archivoPacientes: globalData.terminosLocalizados.ETI151,
    blogJournal: globalData.terminosLocalizados.ETI197,
    centroNotificaciones: globalData.terminosLocalizados.ETI198,
    borradores: globalData.terminosLocalizados.ETI199,
    publicar: globalData.terminosLocalizados.ETI200,
    redSocial: globalData.terminosLocalizados.ETI201,
    noticias: globalData.terminosLocalizados.ETI202,
  };

  function prepararRepositorioContenido() {
    let contenido = [];
    // Agregar suite profesional
    if (auth.isProfessional()) {
      contenido.push({
        texto: textos.suiteProfesional,
        id: "1",
        proximamente: false,
        destino: [
          {
            texto: textos.formularioClinico,
            id: "1.1",
            destino: [
              {
                texto: textos.abrirFormularioClinico,
                id: "1.1.1",
                proximamente: false,
                destino: const_Paginas.CLINIC_FORM,
              },
              {
                texto: textos.editarFormularioClinico,
                id: "1.1.2",
                proximamente: false,
                destino: const_Paginas.EDIT_CLINIC_FORM,
              },
            ],
          },
          {
            texto: textos.agenda,
            id: "1.2",
            proximamente: true,
            destino: null,
          },
          {
            texto: textos.beeGeo,
            id: "1.3",
            proximamente: true,
            destino: null,
          },
          {
            texto: textos.archivoPacientes,
            id: "1.4",
            proximamente: false,
            destino: const_Paginas.ARCHIVO,
          },
        ],
      });
    }
    // Agregar blog & journal
    if (auth.isProfessional() || true) {
      contenido.push({
        texto: textos.blogJournal,
        id: "2",
        proximamente: true,
        destino: [
          {
            texto: textos.centroNotificaciones,
            id: "2.1",
            proximamente: true,
            destino: null,
          },
          {
            texto: textos.borradores,
            id: "2.2",
            proximamente: true,
            destino: null,
          },
          {
            texto: textos.publicar,
            id: "2.3",
            proximamente: true,
            destino: null,
          },
          // {
          //   texto: "2.4",
          //   id: "2.4",
          //   proximamente: true,
          //   destino: null,
          // },
          // {
          //   texto: "2.5",
          //   id: "2.5",
          //   proximamente: true,
          //   destino: null,
          // },
          // {
          //   texto: "2.6",
          //   id: "2.6",
          //   proximamente: true,
          //   destino: null,
          // },
          // {
          //   texto: "2.7",
          //   id: "2.7",
          //   proximamente: true,
          //   destino: null,
          // },
          // {
          //   texto: "2.8",
          //   id: "2.8",
          //   proximamente: true,
          //   destino: null,
          // },
          // {
          //   texto: "2.9",
          //   id: "2.9",
          //   proximamente: true,
          //   destino: null,
          // },
          // {
          //   texto: "2.A",
          //   id: "2.A",
          //   proximamente: true,
          //   destino: null,
          // },
        ],
      });
    }
    // Agregar red social
    if (auth.isProfessional || true) {
      contenido.push({
        texto: textos.redSocial,
        id: "3",
        proximamente: true,
        destino: null,
      });
    }
    // Agregar noticias
    if (auth.isProfessional || true) {
      contenido.push({
        texto: textos.noticias,
        id: "4",
        proximamente: true,
        destino: null,
      });
    }
    //---------------- TESTING -----------------
    // Agregar testing para completar
    // if (auth.isProfessional || true) {
    //   contenido.push({
    //     texto: "cinco",
    //     id: "5",
    //     proximamente: true,
    //     destino: null,
    //   });
    // }
    // if (auth.isProfessional || true) {
    //   contenido.push({
    //     texto: "seis",
    //     id: "6",
    //     proximamente: true,
    //     destino: null,
    //   });
    // }
    // if (auth.isProfessional || true) {
    //   contenido.push({
    //     texto: "siete",
    //     id: "7",
    //     proximamente: true,
    //     destino: null,
    //   });
    // }
    // if (auth.isProfessional || true) {
    //   contenido.push({
    //     texto: "ocho",
    //     id: "8",
    //     proximamente: true,
    //     destino: null,
    //   });
    // }
    // if (auth.isProfessional || true) {
    //   contenido.push({
    //     texto: "nueve",
    //     id: "9",
    //     proximamente: true,
    //     destino: null,
    //   });
    // }
    // if (auth.isProfessional || true) {
    //   contenido.push({
    //     texto: "diez",
    //     id: "10",
    //     proximamente: true,
    //     destino: null,
    //   });
    // }
    // if (auth.isProfessional || true) {
    //   contenido.push({
    //     texto: "once",
    //     id: "11",
    //     proximamente: true,
    //     //destino: null,
    //     destino: [
    //       {
    //         texto: "once-1",
    //         id: "11.1",
    //         proximamente: true,
    //         destino: null,
    //       },
    //       {
    //         texto: "once-2",
    //         id: "11.2",
    //         proximamente: true,
    //         destino: null,
    //       },
    //       {
    //         texto: "once-3",
    //         id: "11.3",
    //         proximamente: true,
    //         destino: null,
    //       },
    //       {
    //         texto: "once-4",
    //         id: "11.4",
    //         proximamente: true,
    //         destino: null,
    //       },
    //       {
    //         texto: "once-5",
    //         id: "11.5",
    //         proximamente: true,
    //         destino: null,
    //       },
    //       {
    //         texto: "once-6",
    //         id: "11.6",
    //         proximamente: true,
    //         destino: null,
    //       },
    //       {
    //         texto: "once-7",
    //         id: "11.7",
    //         proximamente: true,
    //         destino: null,
    //       },
    //       // {
    //       //   texto: "2.4",
    //       //   id: "2.4",
    //       //   proximamente: true,
    //       //   destino: null,
    //       // },
    //       // {
    //       //   texto: "2.5",
    //       //   id: "2.5",
    //       //   proximamente: true,
    //       //   destino: null,
    //       // },
    //       // {
    //       //   texto: "2.6",
    //       //   id: "2.6",
    //       //   proximamente: true,
    //       //   destino: null,
    //       // },
    //     ],
    //   });
    // }
    // if (auth.isProfessional || true) {
    //   contenido.push({
    //     texto: "doce",
    //     id: "12",
    //     proximamente: true,
    //     destino: null,
    //   });
    // }
    // if (auth.isProfessional || true) {
    //   contenido.push({
    //     texto: "trece",
    //     id: "13",
    //     proximamente: true,
    //     destino: null,
    //   });
    // }
    // if (auth.isProfessional || true) {
    //   contenido.push({
    //     texto: "catorce",
    //     id: "14",
    //     proximamente: true,
    //     destino: [
    //       {
    //         texto: "catorce-1",
    //         id: "14.1",
    //         proximamente: true,
    //         destino: null,
    //       },
    //       {
    //         texto: "catorce-2",
    //         id: "14.2",
    //         proximamente: true,
    //         destino: null,
    //       },
    //       {
    //         texto: "catorce-3",
    //         id: "14.3",
    //         proximamente: true,
    //         destino: null,
    //       },
    //       {
    //         texto: "catorce-4",
    //         id: "14.4",
    //         proximamente: true,
    //         destino: null,
    //       },
    //       {
    //         texto: "catorce-5",
    //         id: "14.5",
    //         proximamente: true,
    //         destino: null,
    //       },
    //       {
    //         texto: "catorce-6",
    //         id: "14.6",
    //         proximamente: true,
    //         destino: null,
    //       },
    //       {
    //         texto: "catorce-7",
    //         id: "14.7",
    //         proximamente: true,
    //         destino: null,
    //       },
    //       {
    //         texto: "catorce-8",
    //         id: "14.8",
    //         proximamente: true,
    //         destino: null,
    //       },
    //       {
    //         texto: "catorce-9",
    //         id: "14.9",
    //         proximamente: true,
    //         destino: null,
    //       },
    //       {
    //         texto: "catorce-A",
    //         id: "14.A",
    //         proximamente: true,
    //         destino: null,
    //       },
    //       {
    //         texto: "catorce-b",
    //         id: "14.B",
    //         proximamente: true,
    //         destino: null,
    //       },
    //       {
    //         texto: "catorce-C",
    //         id: "14.C",
    //         proximamente: true,
    //         destino: null,
    //       },
    //       {
    //         texto: "catorce-D",
    //         id: "14.D",
    //         proximamente: true,
    //         destino: null,
    //       },
    //     ],
    //   });
    // }
    // if (auth.isProfessional || true) {
    //   contenido.push({
    //     texto: "quince",
    //     id: "15",
    //     proximamente: true,
    //     destino: null,
    //   });
    // }
    // if (auth.isProfessional || true) {
    //   contenido.push({
    //     texto: "dieciseis",
    //     id: "16",
    //     proximamente: true,
    //     destino: null,
    //   });
    // }
    // if (auth.isProfessional || true) {
    //   contenido.push({
    //     texto: "diecisiete",
    //     id: "17",
    //     proximamente: true,
    //     destino: null,
    //   });
    // }
    // if (auth.isProfessional || true) {
    //   contenido.push({
    //     texto: "dieciocho",
    //     id: "18",
    //     proximamente: true,
    //     destino: null,
    //   });
    // }
    // if (auth.isProfessional || true) {
    //   contenido.push({
    //     texto: "diecinueve",
    //     id: "19",
    //     proximamente: true,
    //     destino: null,
    //   });
    // }
    // if (auth.isProfessional || true) {
    //   contenido.push({
    //     texto: "veinte",
    //     id: "20",
    //     proximamente: true,
    //     destino: null,
    //   });
    // }
    // if (auth.isProfessional || true) {
    //   contenido.push({
    //     texto: "veintinuno",
    //     id: "21",
    //     proximamente: true,
    //     destino: null,
    //   });
    // }
    return contenido;
  }

  const repositorioContenido = prepararRepositorioContenido();

  const detectarHexagonoActivo = (id) => {
    const respuesta =
      hexagonoActivo.length > 0 &&
      hexagonoActivo[hexagonoActivo.length - 1].id === id;
    return respuesta;
  };

  const retirarHexagonoActivo = () => {
    setHexagonoActivo((previous) => previous.slice(0, -1));
  };

  const agregarHexagonoActivo = (id, fila, columna) => {
    const nuevoElemento = { id: id, fila: fila, columna: columna };
    setHexagonoActivo((previous) => [...previous, nuevoElemento]);
  };

  const handleClick = (id, destino) => {
    if (typeof destino === "string") {
      setCurrentPage(destino);
    } else {
      if (Array.isArray(destino)) {
        if (detectarHexagonoActivo(id)) {
          retirarHexagonoActivo();
        } else {
          agregarHexagonoActivo(id);
        }
      }
    }
  };

  const hayHexagonoActivo = () => {
    return hexagonoActivo.length > 0;
  };

  return (
    <Box
      width={"100%"}
      height={"100%"}
      bgcolor={"transparent"}
      overflow={"hidden"}
    >
      <GrillaHexagonos
        valoresGrilla={valoresGrilla}
        handleClick={handleClick}
        repositorioContenido={repositorioContenido}
        calcularLeft={calcularLeft}
        hexagonoActivo={hexagonoActivo}
        hayHexagonoActivo={hayHexagonoActivo}
        detectarHexagonoActivo={detectarHexagonoActivo}
      />
    </Box>
  );
}
