import React, { useState, useEffect } from "react";

import {
  Grid,
  Stack,
  Box,
  Typography,
  Toolbar,
  IconButton,
  Tooltip,
} from "@mui/material";

import { styled } from "@mui/material/styles";

import {
  ZoomIn,
  ZoomOut,
  Fullscreen,
  FullscreenExit,
  ColorLens,
  Info,
  InfoOutlined,
  Close,
  ChevronLeft,
  ChevronRight,
  FirstPage,
  LastPage,
} from "@mui/icons-material";

import { Buffer } from "buffer";

import { Document, Page, pdfjs } from "react-pdf";

// Para que no quede espacio en blanco al final de la página
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

// Para que los textos no se repitan al final de la página
import "react-pdf/dist/esm/Page/TextLayer.css";

import useAuth from "../../../../../auth/useAuth";
import useToastContext from "../../../../../hooks/useToastContext";
import { Severities } from "../../../../../contexts/ToastContext";

import useGlobalDataContext from "../../../../../hooks/useGlobalDataContext";

import { API_URL } from "../../../../../utils/constants";

import Loading from "../../../../Feedback/Loading";

import { tipoMultimedia } from "./ElementoTimelineAbierto";

import { esImagen } from "../../../../../utils/multimediaUtils";
import { esPDF } from "../../../../../utils/multimediaUtils";

// Así da error en producción
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   "pdfjs-dist/build/pdf.worker.min.js",
//   import.meta.url
// ).toString();

// Intento #1 de corregir el error en producción
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   "pdfjs-dist/build/pdf.worker.entry.js",
//   import.meta.url
// ).toString();

// Intento #2 de corregir el error en producción
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export default function ArchivoMultimedia(props) {
  const { archivo, tipo, close } = props;

  const [scale, setScale] = useState(1);
  const [originalSize, setOriginalSize] = useState(false);
  const [darkMode, setDarkMode] = useState(true);
  const [info, setInfo] = useState(false);

  // Contador de páginas para visualización de PDF
  const [pageNumber, setPageNumber] = useState(1);

  const [multimedia, setMultimedia] = useState({
    imagen: {
      contenido: null,
      tipoContenido: null,
      url: null,
      width: null,
      height: null,
    },
    pdf: {
      contenido: null,
      tipoContenido: null,
      url: null,
      numPages: null,
    },
    cargadoArchivo: false,
    cargadoContenido: false,
  });

  const auth = useAuth();
  const globalData = useGlobalDataContext();
  const addToast = useToastContext();

  const textos = {
    titulosGenerales: {
      archivoAjunto: globalData.terminosLocalizados.ETI188,
      respuestaMultimedia: globalData.terminosLocalizados.ETI189,
      registroAntiguo: globalData.terminosLocalizados.ETI190,
    },
    toolbarTips: {
      detalles: globalData.terminosLocalizados.ETI174,
      zoomOut: globalData.terminosLocalizados.ETI175,
      zoomIn: globalData.terminosLocalizados.ETI176,
      tamañoReal: globalData.terminosLocalizados.ETI177,
      ajustar: globalData.terminosLocalizados.ETI178,
      modoClaro: globalData.terminosLocalizados.ETI179,
      modoOscuro: globalData.terminosLocalizados.ETI180,
    },
    detalles: {
      titulo: globalData.terminosLocalizados.ETI171,
      nombreAsignado: globalData.terminosLocalizados.ETI172,
      nombreOriginal: globalData.terminosLocalizados.ETI173,
      nota: globalData.terminosLocalizados.ETI167,
    },
    conjuncion: globalData.terminosLocalizados.OCN003,
  };

  const TitulosNotas = styled(Typography)({
    fontSize: 12,
    color: "grey",
  });

  const ToolbarTooltip = ({ ...rest }) => {
    return (
      <Tooltip
        {...rest}
        arrow
        componentsProps={{
          arrow: { style: { color: "grey" } },
          tooltip: { style: { backgroundColor: "grey", color: "white" } },
        }}
      />
    );
  };

  const onPdfLoadSuccess = ({ numPages }) => {
    setMultimedia((previous) => {
      return {
        ...previous,
        pdf: {
          ...previous.pdf,
          numPages: numPages,
        },
      };
    });
  };

  // Obtener un archivo adjunto
  useEffect(() => {
    (async () => {
      var url;
      switch (tipo) {
        case tipoMultimedia.ARCHIVO_ADJUNTO:
        case tipoMultimedia.REGISTRO_ANTIGUO:
          url = `${API_URL()}/FormularioClinico/getArchivo`;
          break;
        case tipoMultimedia.RESPUESTA_MULTIMEDIA:
          url = `${API_URL()}/FormularioClinico/getRespuestaMultimedia`;
          break;
        default:
          throw new Error(`${globalData.terminosLocalizados.AVI042} (${tipo})`);
      }
      const request = {
        Token: auth.user.token,
        Guid: archivo.guid,
      };
      const img = new Image();
      img.onload = function () {
        setMultimedia((previous) => {
          return {
            ...previous,
            imagen: {
              ...previous.imagen,
              width: img.width,
              height: img.height,
            },
            cargadoContenido: true,
          };
        });
      };
      try {
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(request),
        };
        const res = await fetch(url, options);
        if (res.ok) {
          const json = await res.json();
          if (json.resultadoOK) {
            //console.log(json);
            const archivo = json.archivoAdjunto;
            const byteArray = Buffer.from(archivo.contenido, "base64");
            if (esImagen(archivo.tipoContenido)) {
              const blob = new Blob([byteArray], {
                type: archivo.tipoContenido,
              });
              const imageUrl = URL.createObjectURL(blob);
              img.src = imageUrl;
              setMultimedia((previous) => {
                return {
                  ...previous,
                  imagen: {
                    ...previous.imagen,
                    contenido: archivo.contenido,
                    tipoContenido: archivo.tipoContenido,
                    url: imageUrl,
                  },
                  cargadoArchivo: true,
                };
              });
            } else if (esPDF(archivo.tipoContenido)) {
              const blob = new Blob([byteArray], {
                type: archivo.tipoContenido,
              });
              const pdfUrl = URL.createObjectURL(blob);
              setMultimedia((previous) => {
                return {
                  ...previous,
                  pdf: {
                    ...previous.pdf,
                    contenido: archivo.contenido,
                    tipoContenido: archivo.tipoContenido,
                    url: pdfUrl,
                  },
                  cargadoArchivo: true,
                  cargadoContenido: true,
                };
              });
            } else {
              addToast(
                `${globalData.terminosLocalizados.AVI041} (${archivo.tipoContenido})`,
                Severities.error
              );
              close();
            }
          } else {
            json.errores.forEach((item) => {
              addToast(item, Severities.error);
            });
          }
        } else {
          addToast(globalData.terminosLocalizados.AVI004, Severities.error);
        }
        return;
      } catch (err) {
        addToast(globalData.terminosLocalizados.AVI005, Severities.error);
      }
    })();
  }, [
    addToast,
    archivo.guid,
    auth.user.token,
    globalData.terminosLocalizados.AVI004,
    globalData.terminosLocalizados.AVI005,
    globalData.terminosLocalizados.AVI041,
    globalData.terminosLocalizados.AVI042,
    tipo,
    close,
  ]);

  // Se presenta aquí el "loading" porque si se intenta presentar dentro de la caja de la publiación
  // o bien no se presenta, o sale recostado siempre a la izquierda o a la derecha (no centrado)
  if (!(multimedia.cargadoArchivo && multimedia.cargadoContenido)) {
    return <Loading />;
  }

  const handleInfo = () => {
    setInfo((previous) => {
      return !previous;
    });
  };

  const handleZoomIn = () => {
    setScale(scale + 0.1);
  };

  const handleZoomOut = () => {
    if (scale > 0.2) {
      setScale(scale - 0.1);
    }
  };

  const handleFullscreen = () => {
    setOriginalSize((previous) => {
      if (previous) {
        return false;
      } else {
        if (scale > 1) {
          return false;
        } else {
          return true;
        }
      }
    });
    setScale(1);
  };

  const handleChangeTheme = () => {
    setDarkMode((previous) => {
      return !previous;
    });
  };

  const handleClose = (event, reason) => {
    close();
  };

  const zoomOutDisabled = () => {
    return !(scale > 1);
  };

  const firstPageDisabled = () => {
    return !(pageNumber > 1);
  };

  const lastPageDisabled = () => {
    return !(pageNumber < multimedia.pdf.numPages);
  };

  const nextPageDisabled = () => {
    return pageNumber === multimedia.pdf.numPages;
  };

  const previousPageDisabled = () => {
    return pageNumber === 1;
  };

  const handlePDFFirstPage = () => {
    //console.log("FirstPage");
    //return;
    setPageNumber(1);
  };

  const handlePDFLastPage = () => {
    //console.log("LastPage");
    //return;
    setPageNumber(multimedia.pdf.numPages);
  };

  const handlePDFPreviousPage = () => {
    //console.log("PreviousPage");
    //return;
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);
  };

  const handlePDFNextPage = () => {
    // console.log("NextPage");
    // return;
    setPageNumber(
      pageNumber + 1 >= multimedia.pdf.numPages
        ? multimedia.pdf.numPages
        : pageNumber + 1
    );
  };

  // Obtener desde el servidor según el idioma del usuario
  var tituloGeneral;
  switch (tipo) {
    case tipoMultimedia.ARCHIVO_ADJUNTO:
      tituloGeneral = textos.titulosGenerales.archivoAjunto;
      break;
    case tipoMultimedia.RESPUESTA_MULTIMEDIA:
      tituloGeneral = textos.titulosGenerales.respuestaMultimedia;
      break;
    case tipoMultimedia.REGISTRO_ANTIGUO:
      tituloGeneral = textos.titulosGenerales.registroAntiguo;
      break;
    default:
      throw new Error(`${globalData.terminosLocalizados.AVI042} (${tipo})`);
  }

  return (
    <Box
      id="archivoMultimediaContainer"
      display="flex"
      flexDirection={"column"}
      sx={{
        height: "100%",
        width: "100%",
        backgroundColor: darkMode ? "black" : "white",
      }}
    >
      <Toolbar
        sx={{
          justifyContent: "flex-end",
          "& .MuiIconButton-root": { color: darkMode ? "white" : "black" },
          "& .ZoomOutIcon": { color: zoomOutDisabled() ? "grey" : "none" },
        }}
      >
        <Box
          display="flex"
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
        >
          <Typography color={darkMode ? "white" : "black"}>
            {tituloGeneral}
          </Typography>
          <Box>
            <ToolbarTooltip title={textos.toolbarTips.detalles}>
              <span>
                <IconButton onClick={handleInfo}>
                  {info ? <Info /> : <InfoOutlined />}
                </IconButton>
              </span>
            </ToolbarTooltip>
            <ToolbarTooltip title={textos.toolbarTips.zoomOut}>
              <span>
                <IconButton
                  className="ZoomOutIcon"
                  onClick={handleZoomOut}
                  disabled={zoomOutDisabled()}
                >
                  <ZoomOut />
                </IconButton>
              </span>
            </ToolbarTooltip>
            <ToolbarTooltip title={textos.toolbarTips.zoomIn}>
              <span>
                <IconButton onClick={handleZoomIn}>
                  <ZoomIn />
                </IconButton>
              </span>
            </ToolbarTooltip>
            <ToolbarTooltip
              title={
                originalSize
                  ? textos.toolbarTips.ajustar
                  : scale > 1
                  ? textos.toolbarTips.ajustar
                  : textos.toolbarTips.tamañoReal
              }
            >
              <span>
                <IconButton onClick={handleFullscreen}>
                  {originalSize || scale > 1 ? (
                    <FullscreenExit />
                  ) : (
                    <Fullscreen />
                  )}
                </IconButton>
              </span>
            </ToolbarTooltip>
            <ToolbarTooltip
              title={
                darkMode
                  ? textos.toolbarTips.modoClaro
                  : textos.toolbarTips.modoOscuro
              }
            >
              <span>
                <IconButton onClick={handleChangeTheme}>
                  <ColorLens />
                </IconButton>
              </span>
            </ToolbarTooltip>
            {multimedia.pdf.contenido !== null && (
              // Controles de navegación dentro del PDF
              <>
                <ToolbarTooltip>
                  <span>
                    <IconButton
                      onClick={handlePDFFirstPage}
                      disabled={firstPageDisabled()}
                    >
                      <FirstPage />
                    </IconButton>
                  </span>
                </ToolbarTooltip>
                <ToolbarTooltip>
                  <span>
                    <IconButton
                      onClick={handlePDFPreviousPage}
                      disabled={previousPageDisabled()}
                    >
                      <ChevronLeft />
                    </IconButton>
                  </span>
                </ToolbarTooltip>
                <ToolbarTooltip>
                  <span>
                    <IconButton
                      onClick={handlePDFNextPage}
                      disabled={nextPageDisabled()}
                    >
                      <ChevronRight />
                    </IconButton>
                  </span>
                </ToolbarTooltip>
                <ToolbarTooltip>
                  <span>
                    <IconButton
                      onClick={handlePDFLastPage}
                      disabled={lastPageDisabled()}
                    >
                      <LastPage />
                    </IconButton>
                  </span>
                </ToolbarTooltip>
                <Typography
                  component="span"
                  color={darkMode ? "white" : "black"}
                >
                  {pageNumber} {textos.conjuncion} {multimedia.pdf.numPages}
                </Typography>
              </>
            )}
          </Box>
          <Box>
            <ToolbarTooltip title="Cerrar">
              <span>
                <IconButton onClick={handleClose}>
                  <Close />
                </IconButton>
              </span>
            </ToolbarTooltip>
          </Box>
        </Box>
      </Toolbar>
      <Box
        // 64px es la altura de la toolbar
        height={"calc(100% - 64px)"}
        overflow={"auto"}
      >
        <Grid container height="100%">
          <Grid
            item
            xs={12}
            sm={info ? 8 : 12}
            md={info ? 8 : 12}
            lg={info ? 8 : 12}
            height="100%"
          >
            <Box
              height="100%"
              display="flex"
              justifyContent={"center"}
              alignItems={"center"}
              overflow={"auto"}
              paddingLeft={2}
              paddingRight={2}
              paddingBottom={2}
            >
              <Box height={"100%"}>
                {multimedia.imagen.contenido !== null && (
                  <RenderImagen
                    url={multimedia.imagen.url}
                    tipoContenido={multimedia.imagen.tipoContenido}
                    originalSize={originalSize}
                    scale={scale}
                  />
                )}
                {multimedia.pdf.contenido !== null && (
                  <RenderPDF
                    multimedia={multimedia}
                    url={multimedia.pdf.url}
                    onDocumentLoadSuccess={onPdfLoadSuccess}
                    pageNumber={pageNumber}
                    scale={scale}
                  />
                )}
              </Box>
            </Box>
          </Grid>
          {info && (
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Stack
                spacing={2}
                color={darkMode ? "white" : "black"}
                paddingLeft={2}
                paddingRight={2}
                paddingBottom={2}
              >
                <Typography variant="h5">{textos.detalles.titulo}</Typography>
                {typeof archivo.nombreAsignado !== "undefined" &&
                  archivo.nombreAsignado != null && (
                    <Box>
                      <TitulosNotas>
                        {textos.detalles.nombreAsignado}:
                      </TitulosNotas>
                      <Typography>{archivo.nombreAsignado}</Typography>
                    </Box>
                  )}
                <Box>
                  <TitulosNotas>{textos.detalles.nombreOriginal}:</TitulosNotas>
                  <Typography>{archivo.nombreOriginal}</Typography>
                </Box>
                {typeof archivo.nota !== "undefined" &&
                  archivo.nota != null && (
                    <Box>
                      <TitulosNotas>{textos.detalles.nota}:</TitulosNotas>
                      <Typography>{archivo.nota}</Typography>
                    </Box>
                  )}
              </Stack>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
}

function RenderImagen(props) {
  const { url, tipoContenido, originalSize, scale } = props;
  return (
    <img
      src={url}
      type={tipoContenido}
      alt="preview"
      style={{
        maxWidth: originalSize ? "none" : "100%",
        maxHeight: originalSize ? "none" : "100%",
        objectFit: "contain",
        transformOrigin: "top",
        transform: `scale(${scale})`,
      }}
    />
  );
}

function RenderPDF(props) {
  const { multimedia, onDocumentLoadSuccess, pageNumber, scale } = props;

  //const [numPages, setNumPages] = useState(null);
  //const [pageNumber, setPageNumber] = useState(1);

  //console.log(multimedia);
  console.log(pageNumber);

  return (
    <div>
      <Document
        file={multimedia.pdf.url}
        //file="pspdfkit-web-demo.pdf"
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page
          pageNumber={pageNumber}
          // Si no apago esta propiedad me presenta la página
          // más abajo me repite los textos.
          //renderTextLayer={false}
          scale={scale}
        />
      </Document>
      {/* {multimedia.pdf.numPages && (
        <p>
          Página {pageNumber} de {multimedia.pdf.numPages}
        </p>
      )} */}
    </div>
  );
}
