import { React, useState } from "react";

import useAuth from "../../auth/useAuth";
import MenuIcon from "@mui/icons-material/Menu";
import { Menu, MenuItem, Button, Divider, Stack } from "@mui/material";
import { Tooltip } from "@mui/material";

import theme from "../../utils/theme";
import { const_Paginas } from "../../utils/constants";

import useGlobalDataContext from "../../hooks/useGlobalDataContext";

import SeleccionarCuenta from "./SeleccionarCuenta";

export default function MenuBar(props) {
  const { setCurrentPage } = props;
  const auth = useAuth();

  const globalData = useGlobalDataContext();

  const [anchorEl, setAnchorEl] = useState(null);

  const [openCuentas, setOpenCuentas] = useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    setAnchorEl(null);
  };

  const handleMenu = (selected) => {
    setCurrentPage(selected);
    handleClose();
  };

  const handleOpenCuentas = () => {
    setOpenCuentas(true);
  };

  const handleCloseCuentas = () => {
    setOpenCuentas(false);
    handleClose();
  };

  if (globalData.loadingTerminosLocalizados) {
    return null;
  }

  const textos = {
    miCuenta: globalData.terminosLocalizados.ETI105,
    cerrarSesion: globalData.terminosLocalizados.ETI150,
    archivo: globalData.terminosLocalizados.ETI151,
    formularioClinico: globalData.terminosLocalizados.ETI152,
    editarFormularioClinico: globalData.terminosLocalizados.ETI153,
    cuentasAdicionales: globalData.terminosLocalizados.ETI160,
    salir: globalData.terminosLocalizados.COM050,
  };

  return (
    <Stack
      direction={"row"}
      spacing={1}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Button
        variant="icon"
        sx={{
          borderRadius: 25,
          minWidth: 24,
          padding: 0,
        }}
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MenuIcon viewBox="0 0 24 24" />
      </Button>
      <Menu
        variant="menu"
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: theme.palette.primary.main,
            paddingX: 1,
          },
        }}
      >
        {/* Nota: 
        la propiedad 'borderRadius' no se puede establecer por tema así que se establece por sx */}
        {/* Si está activa una cuenta de profesional se presenta la opción de editar el formulario clínico */}
        {auth.isProfessional() && (
          <MenuItem
            variant="menu"
            sx={{ borderRadius: 2 }}
            onClick={() => handleMenu(const_Paginas.EDIT_CLINIC_FORM)}
          >
            {textos.editarFormularioClinico}
          </MenuItem>
        )}
        {/* Si está activa una cuenta de profesional se presenta la opción de formulario clínico */}
        {auth.isProfessional() && (
          <MenuItem
            variant="menu"
            sx={{ borderRadius: 2 }}
            onClick={() => handleMenu(const_Paginas.CLINIC_FORM)}
          >
            {textos.formularioClinico}
          </MenuItem>
        )}
        {/* Si está activa una cuenta de profesional se presenta la opción de la lista de pacientes */}
        {auth.isProfessional() && (
          <MenuItem
            variant="menu"
            sx={{ borderRadius: 2 }}
            onClick={() => handleMenu(const_Paginas.ARCHIVO)}
          >
            {textos.archivo}
          </MenuItem>
        )}
        <MenuItem
          variant="menu"
          sx={{ borderRadius: 2 }}
          onClick={handleOpenCuentas}
        >
          {textos.cuentasAdicionales}
        </MenuItem>
        {/* Si está activa una cuenta de profesional se presenta el separador */}
        {auth.isProfessional() && <Divider />}
        <MenuItem
          variant="menu"
          sx={{ borderRadius: 2 }}
          onClick={() => handleMenu(const_Paginas.MY_ACCOUNT)}
        >
          {textos.miCuenta}
        </MenuItem>
        <Divider></Divider>
        {/* <MenuItem
          variant="menu"
          sx={{ borderRadius: 2 }}
          onClick={() => handleMenu(const_Paginas.TEST_HEXAGONOS)}
        >
          TEST HEXÁGONOS
        </MenuItem> */}
        <Divider></Divider>
        <MenuItem variant="menu" sx={{ borderRadius: 2 }} onClick={auth.logout}>
          {textos.salir}
        </MenuItem>
      </Menu>
      <Tooltip
        title={textos.cerrarSesion}
        sx={{
          [theme.breakpoints.down("1200")]: { display: "none" },
        }}
      >
        <Button
          variant="command"
          sx={{
            marginRight: "8px !important",
            [theme.breakpoints.down("1200")]: { display: "none" },
          }}
          onClick={auth.logout}
        >
          {textos.salir}
        </Button>
      </Tooltip>
      <SeleccionarCuenta
        openCuentas={openCuentas}
        handleCloseCuentas={handleCloseCuentas}
      />
    </Stack>
  );
}
