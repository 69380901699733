import React from "react";
import { Box, Avatar } from "@mui/material";

import LineaCabeceraPaciente from "./LineaCabeceraPaciente";

import theme from "../../../../../utils/theme";

import useGlobalDataContext from "../../../../../hooks/useGlobalDataContext";

export default function CabeceraPaciente(props) {
  const { paciente } = props;

  const globalData = useGlobalDataContext();

  // Se deberían obtener desde el servidor en función del idioma del usuario
  const etiquetaPaciente = globalData.terminosLocalizados.ETI123;
  const etiquetaEdad = globalData.terminosLocalizados.ETI143;
  const etiquetaUltimaVisita = globalData.terminosLocalizados.ETI144;

  return (
    <Box
      display={"flex"}
      bgcolor={theme.palette.terciary.dark}
      color={theme.palette.terciary.contrastText}
      width={"100%"}
      borderRadius={2}
      padding={0.5}
    >
      <Box padding={0.5} marginRight={0.5}>
        <Avatar
          alt={paciente.nombre}
          src="../../../assets/img/DSCN4473.JPG"
          sx={{
            backgroundColor: theme.palette.secondary.main,
            width: "45px",
            height: "45px",
          }}
        />
      </Box>
      <Box display={"flex"} flexDirection={"column"}>
        <LineaCabeceraPaciente
          etiqueta={etiquetaPaciente}
          valor={paciente.nombreCompleto}
        />
        <LineaCabeceraPaciente etiqueta={etiquetaEdad} valor={paciente.edad} />
        <LineaCabeceraPaciente
          etiqueta={etiquetaUltimaVisita}
          valor={paciente.ultimaVisita}
        />
      </Box>
    </Box>
  );
}
