import React, { useState, useCallback } from "react";

import { Box } from "@mui/material";

import RepositorioVariables from "../components/Datos/EditarFormularioClinico/Repositorio/RepositorioVariables";
import FormularioClinicoEdicion from "../components/Datos/EditarFormularioClinico/Edicion/FormularioClinicoEdicion";

import { const_TipoSeleccionEdicionFormularioClinico } from "../utils/constants";

export default function EditClinicFormPage() {
  const [refrescarFormulario, setRefrescarFormulario] = useState(false);

  const [formularioClinico, setFormularioClinico] = useState({
    procesos: [
      {
        areasSalud: [
          {
            especialidades: [
              {
                secciones: [
                  {
                    areasPreguntas: [
                      {
                        preguntas: [
                          {
                            multimedia: false,
                            textoPleno: false,
                            opciones: [],
                            unidadMedida: "",
                            valoraciones: [{}],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  });

  const administrarCambioElementoRepositorio = useCallback(
    function actualiarFormularioCambioElementoRepositorio(
      tipo,
      id,
      nuevaDescripcion
    ) {
      const nuevaListaProcesos = formularioClinico.procesos.map(
        (itemProcesos) => {
          let itemProcesosCambiado = { ...itemProcesos };
          if (
            tipo === const_TipoSeleccionEdicionFormularioClinico.PROCESO &&
            itemProcesosCambiado.repositorioId === id
          ) {
            itemProcesosCambiado.descripcion = nuevaDescripcion;
          }
          itemProcesosCambiado.areasSalud = itemProcesos.areasSalud.map(
            (itemAreasSalud) => {
              let itemAreasSaludCambiado = { ...itemAreasSalud };
              if (
                tipo ===
                  const_TipoSeleccionEdicionFormularioClinico.AREA_SALUD &&
                itemAreasSaludCambiado.repositorioId === id
              ) {
                itemAreasSaludCambiado.descripcion = nuevaDescripcion;
              }
              itemAreasSaludCambiado.especialidades =
                itemAreasSalud.especialidades.map((itemEspecialidades) => {
                  let itemEspecialidadesCambiado = {
                    ...itemEspecialidades,
                  };
                  if (
                    tipo ===
                      const_TipoSeleccionEdicionFormularioClinico.ESPECIALIDAD &&
                    itemEspecialidadesCambiado.repositorioId === id
                  ) {
                    itemEspecialidadesCambiado.descripcion = nuevaDescripcion;
                  }
                  itemEspecialidadesCambiado.secciones =
                    itemEspecialidades.secciones.map((itemSecciones) => {
                      let itemSeccionesCambiado = { ...itemSecciones };
                      if (
                        tipo ===
                          const_TipoSeleccionEdicionFormularioClinico.SECCION &&
                        itemSeccionesCambiado.repositorioId === id
                      ) {
                        itemSeccionesCambiado.descripcion = nuevaDescripcion;
                      }
                      itemSeccionesCambiado.areasPreguntas =
                        itemSecciones.areasPreguntas.map(
                          (itemAreasPreguntas) => {
                            let itemAreasPreguntasCambiado = {
                              ...itemAreasPreguntas,
                            };
                            if (
                              tipo ===
                                const_TipoSeleccionEdicionFormularioClinico.AREA_PREGUNTAS &&
                              itemAreasPreguntasCambiado.repositorioId === id
                            ) {
                              itemAreasPreguntasCambiado.descripcion =
                                nuevaDescripcion;
                            }
                            itemAreasPreguntasCambiado.preguntas =
                              itemAreasPreguntas.preguntas.map(
                                (itemPreguntas) => {
                                  let itemPreguntasCambiado = {
                                    ...itemPreguntas,
                                  };
                                  if (
                                    tipo ===
                                      const_TipoSeleccionEdicionFormularioClinico.PREGUNTA &&
                                    itemPreguntasCambiado.repositorioId === id
                                  ) {
                                    itemPreguntasCambiado.descripcion =
                                      nuevaDescripcion;
                                  }
                                  if (
                                    tipo ===
                                    const_TipoSeleccionEdicionFormularioClinico.UNIDAD_MEDIDA
                                  ) {
                                    if (
                                      itemPreguntasCambiado.unidadMedidaRepositorioID !==
                                      null
                                    ) {
                                      if (
                                        itemPreguntasCambiado.unidadMedidaRepositorioID ===
                                        id
                                      ) {
                                        itemPreguntasCambiado.unidadMedida =
                                          nuevaDescripcion;
                                      }
                                    }
                                  }
                                  itemPreguntasCambiado.opciones =
                                    itemPreguntas.opciones.map(
                                      (itemOpciones) => {
                                        let itemOpcionesCambiado = {
                                          ...itemOpciones,
                                        };
                                        if (
                                          tipo ===
                                            const_TipoSeleccionEdicionFormularioClinico.OPCION_PREGUNTA &&
                                          itemOpcionesCambiado.repositorioId ===
                                            id
                                        ) {
                                          itemOpcionesCambiado.descripcion =
                                            nuevaDescripcion;
                                        }
                                        return itemOpcionesCambiado;
                                      }
                                    );
                                  return itemPreguntasCambiado;
                                }
                              );
                            return itemAreasPreguntasCambiado;
                          }
                        );
                      return itemSeccionesCambiado;
                    });
                  return itemEspecialidadesCambiado;
                });
              return itemAreasSaludCambiado;
            }
          );
          return itemProcesosCambiado;
        }
      );
      console.log(nuevaListaProcesos);
      setFormularioClinico({
        ...formularioClinico,
        procesos: nuevaListaProcesos,
      });
    },
    [formularioClinico]
  );

  return (
    <Box
      id="edit-clinic-form-page-main-box"
      sx={{
        flexGrow: 1,
        width: "100vw",
        height: "calc(100vh - 50px)",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Primera sección del formulario */}
      <RepositorioVariables
        setFormularioClinico={setFormularioClinico}
        administrarCambioElementoRepositorio={
          administrarCambioElementoRepositorio
        }
      ></RepositorioVariables>
      <FormularioClinicoEdicion
        refrescarFormulario={refrescarFormulario}
        setRefrescarFormulario={setRefrescarFormulario}
        formularioClinico={formularioClinico}
        setFormularioClinico={setFormularioClinico}
      ></FormularioClinicoEdicion>
    </Box>
  );
}
