import React from "react";
import { Grid, Stack, Box, Button } from "@mui/material";

import SeleccionCategorias from "./InformacionGeneral/SeleccionCategorias";
import ClaveColores from "./InformacionGeneral/ClaveColores";

import useGlobalDataContext from "../../../../hooks/useGlobalDataContext";
import theme from "../../../../utils/theme";

export default function InformacionGeneral(props) {
  const {
    textoBusqueda,
    setTextoBusqueda,
    solicitarCambioPaciente,
    categorias,
    setCategorias,
    solicitarAgregarRegistrosAntiguos,
    readOnly,
  } = props;

  const globalData = useGlobalDataContext();

  const textos = {
    cambiarPaciente: globalData.terminosLocalizados.COM035,
    registrosAntiguos: globalData.terminosLocalizados.ETI165,
  };

  const handleCambiarPaciente = () => {
    solicitarCambioPaciente();
  };

  const handleAgregarRegistrosAntiguos = () => {
    solicitarAgregarRegistrosAntiguos();
  };

  return (
    <Box>
      <Grid
        container
        id="informacion-general-contenido"
        height={"100%"}
        spacing={1}
      >
        <Grid
          item
          id="seleccionCategoriasContenedor"
          xs={12}
          xs2={6}
          sm={4}
          md={12}
        >
          <SeleccionCategorias
            textoBusqueda={textoBusqueda}
            setTextoBusqueda={setTextoBusqueda}
            categorias={categorias}
            setCategorias={setCategorias}
          />
        </Grid>
        <Grid item id="claveColoresContenedor" xs={12} xs2={6} sm={4} md={12}>
          <ClaveColores />
        </Grid>
        <Grid
          item
          id="comandosContenedor"
          xs={12}
          sm={4}
          md={12}
          sx={{ [theme.breakpoints.down("xs2")]: {} }}
          display="flex"
          alignItems="start"
        >
          <Stack width={"100%"} spacing={1}>
            {!readOnly && (
              <Button
                variant="base"
                onClick={handleCambiarPaciente}
                sx={{ width: "100%" }}
              >
                {textos.cambiarPaciente}
              </Button>
            )}
            {!readOnly && (
              <Button
                variant="destacado"
                onClick={handleAgregarRegistrosAntiguos}
                sx={{
                  width: "100%",
                  color: "#000",
                }}
              >
                {textos.registrosAntiguos}
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
