import React, { useCallback, useEffect } from "react";

import { Box } from "@mui/material";

import EdicionListaProcesos from "./EdicionListaProcesos";

import { API_URL } from "../../../../utils/constants";

import useAuth from "../../../../auth/useAuth";
import useToastContext from "../../../../hooks/useToastContext";
import { Severities } from "../../../../contexts/ToastContext";
import useGlobalDataContext from "../../../../hooks/useGlobalDataContext";

export default function FormularioClinicoEdicion(props) {
  const { setRefrescarFormulario, formularioClinico, setFormularioClinico } =
    props;

  const auth = useAuth();
  const addToast = useToastContext();
  const globalData = useGlobalDataContext();

  const urlFormularioClinico = `${API_URL()}/FormularioClinico/getFormularioClinico`;

  // Quitar una pregunta (elemento) del formulario clínico
  const quitarElemento = useCallback(
    async (guid, tipo) => {
      const url = `${API_URL()}/EditarFormularioClinico/deleteElementoFormulario`;
      let datos = {
        Token: auth.user.token,
        GuidCuentaAdicional: auth.cuentaActiva.guid,
        GuidElemento: guid,
        TipoElemento: tipo,
      };
      try {
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(datos),
        };
        const res = await fetch(url, options);
        if (res.ok) {
          const json = await res.json();
          if (json.resultadoOK) {
            setFormularioClinico(json.nuevoFormularioClinico);
          } else {
            json.errores.forEach((item) => {
              addToast(item, Severities.error);
            });
          }
        } else {
          //addToast("Error de servicio.", Severities.error);
          addToast(globalData.terminosLocalizados.AVI004, Severities.error);
        }
      } catch (err) {
        //addToast("Error de conexión", Severities.error);
        addToast(globalData.terminosLocalizados.AVI005, Severities.error);
      }
    },
    [
      addToast,
      auth.cuentaActiva.guid,
      auth.user.token,
      setFormularioClinico,
      globalData.terminosLocalizados,
    ]
  );

  // Subir una pregunta (elemento) en el formulario clínico
  const subirElemento = useCallback(
    async (guid, tipo) => {
      const url = `${API_URL()}/EditarFormularioClinico/subirElementoFormulario`;
      let datos = {
        Token: auth.user.token,
        GuidCuentaAdicional: auth.cuentaActiva.guid,
        GuidElemento: guid,
        TipoElemento: tipo,
      };
      try {
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(datos),
        };
        const res = await fetch(url, options);
        if (res.ok) {
          const json = await res.json();
          if (json.resultadoOK) {
            setFormularioClinico(json.nuevoFormularioClinico);
          } else {
            json.errores.forEach((item) => {
              addToast(item, Severities.error);
            });
          }
        } else {
          //addToast("Error de servicio.", Severities.error);
          addToast(globalData.terminosLocalizados.AVI004, Severities.error);
        }
      } catch (err) {
        //addToast("Error de conexión", Severities.error);
        addToast(globalData.terminosLocalizados.AVI005, Severities.error);
      }
    },
    [
      addToast,
      auth.cuentaActiva.guid,
      auth.user.token,
      setFormularioClinico,
      globalData.terminosLocalizados,
    ]
  );

  // Bajar una pregunta (elemento) en el formulario clínico
  const bajarElemento = useCallback(
    async (guid, tipo) => {
      const url = `${API_URL()}/EditarFormularioClinico/bajarElementoFormulario`;
      let datos = {
        Token: auth.user.token,
        GuidCuentaAdicional: auth.cuentaActiva.guid,
        GuidElemento: guid,
        TipoElemento: tipo,
      };
      try {
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(datos),
        };
        const res = await fetch(url, options);
        if (res.ok) {
          const json = await res.json();
          if (json.resultadoOK) {
            setFormularioClinico(json.nuevoFormularioClinico);
          } else {
            json.errores.forEach((item) => {
              addToast(item, Severities.error);
            });
          }
        } else {
          // addToast("Error de servicio.", Severities.error);
          addToast(globalData.terminosLocalizados.AVI004, Severities.error);
        }
      } catch (err) {
        // addToast("Error de conexión", Severities.error);
        addToast(globalData.terminosLocalizados.AVI005, Severities.error);
      }
    },
    [
      addToast,
      auth.cuentaActiva.guid,
      auth.user.token,
      setFormularioClinico,
      globalData.terminosLocalizados.AVI004,
      globalData.terminosLocalizados.AVI005,
    ]
  );

  const actualizarAlineacionAreasPreguntas = useCallback(
    function actualizarAlineacionAreasPreguntas(
      guid,
      areasPreguntasHorizontal
    ) {
      const nuevaListaProcesos = formularioClinico.procesos.map(
        (itemProcesos) => {
          let itemProcesosCambiado = { ...itemProcesos };
          itemProcesosCambiado.areasSalud = itemProcesos.areasSalud.map(
            (itemAreasSalud) => {
              let itemAreasSaludCambiado = { ...itemAreasSalud };
              itemAreasSaludCambiado.especialidades =
                itemAreasSalud.especialidades.map((itemEspecialidades) => {
                  let itemEspecialidadesCambiado = { ...itemEspecialidades };
                  itemEspecialidadesCambiado.secciones =
                    itemEspecialidades.secciones.map((itemSecciones) => {
                      let itemSeccionesCambiado = { ...itemSecciones };
                      if (itemSeccionesCambiado.guid === guid) {
                        itemSeccionesCambiado.areasPreguntasHorizontal =
                          areasPreguntasHorizontal;
                      }
                      return itemSeccionesCambiado;
                    });
                  return itemEspecialidadesCambiado;
                });
              return itemAreasSaludCambiado;
            }
          );
          return itemProcesosCambiado;
        }
      );
      setFormularioClinico({
        ...formularioClinico,
        procesos: nuevaListaProcesos,
      });
    },
    [formularioClinico, setFormularioClinico]
  );

  // Cambiar la presentación de las áreas de preguntas de una sección en el formulario clínico
  const cambiarAlineacionAreasPreguntas = useCallback(
    async (guid, areasPreguntasHorizontal) => {
      const url = `${API_URL()}/EditarFormularioClinico/setSeccionAreasPreguntasDisplay`;
      let datos = {
        Token: auth.user.token,
        GuidSeccion: guid,
        Horizontal: !areasPreguntasHorizontal,
      };
      try {
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(datos),
        };
        const res = await fetch(url, options);
        if (res.ok) {
          const json = await res.json();
          if (json.resultadoOK) {
            actualizarAlineacionAreasPreguntas(guid, json.horizontal);
          } else {
            json.errores.forEach((item) => {
              addToast(item, Severities.error);
            });
          }
        } else {
          //addToast("Error de servicio.", Severities.error);
          addToast(globalData.terminosLocalizados.AVI004, Severities.error);
        }
      } catch (err) {
        //addToast("Error de conexión", Severities.error);
        addToast(globalData.terminosLocalizados.AVI005, Severities.error);
      }
    },
    [
      addToast,
      auth.user.token,
      actualizarAlineacionAreasPreguntas,
      globalData.terminosLocalizados,
    ]
  );

  // Obtener el formulario (primera vez)
  // Se está ejecutando también después de agregar preguntas, entre tanto se busca
  // la forma de actualizar el formulario una vez recibida la respuesta (que ya trae
  // el nuevo contenido del formulario)
  useEffect(() => {
    (async () => {
      const request = {
        Token: auth.user.token,
        GuidCuentaAdicional: auth.cuentaActiva.guid,
      };
      try {
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(request),
        };
        const res = await fetch(urlFormularioClinico, options);
        if (res.ok) {
          const json = await res.json();
          if (json.resultadoOK) {
            const form = json.formularioClinico;
            //console.log(form);
            setFormularioClinico(form);
          } else {
            json.errores.forEach((item) => {
              addToast(item, Severities.error);
            });
          }
        } else {
          //addToast("Error de servicio.", Severities.error);
          addToast(globalData.terminosLocalizados.AVI004, Severities.error);
        }
        setRefrescarFormulario(false);
        return;
      } catch (err) {
        //addToast("Error de conexión", Severities.error);
        addToast(globalData.terminosLocalizados.AVI005, Severities.error);
      }
    })();
  }, [
    addToast,
    auth.cuentaActiva.guid,
    auth.user.token,
    setRefrescarFormulario,
    urlFormularioClinico,
    setFormularioClinico,
    globalData.terminosLocalizados,
  ]);

  //console.log(formularioClinico);

  return (
    <Box
      id="edit-clinic-form-page-second-section"
      sx={{
        flexGrow: 1,
        maxHeight: "50%",
        overflowY: "scroll",
        overflowX: "scroll",
      }}
    >
      <EdicionListaProcesos
        procesos={formularioClinico.procesos}
        quitarElemento={quitarElemento}
        subirElemento={subirElemento}
        bajarElemento={bajarElemento}
        cambiarAlineacionAreasPreguntas={cambiarAlineacionAreasPreguntas}
      ></EdicionListaProcesos>
    </Box>
  );
}
