import React, { useState, useEffect, useCallback } from "react";
import getUserLocale from "get-user-locale";

import { Box, Fab } from "@mui/material";

import SaveIcon from "@mui/icons-material/Save";

import ListaSeleccion from "./ListaSeleccion";
import ListaSeleccionPropiedades from "./ListaSeleccionPropiedades";

import {
  API_URL,
  const_TipoVariable,
  const_TipoDato,
  const_TipoVariableCualitativa,
  const_TipoSeleccionEdicionFormularioClinico,
  const_TipoFactorValoracion,
} from "../../../../utils/constants";

import useAuth from "../../../../auth/useAuth";
import useToastContext from "../../../../hooks/useToastContext";
import { Severities } from "../../../../contexts/ToastContext";

import useGlobalDataContext from "../../../../hooks/useGlobalDataContext";

import theme from "../../../../utils/theme";
import ResultadoValoracion from "./ResultadoValoracion";

export default function RepositorioVariables(props) {
  const { setFormularioClinico, administrarCambioElementoRepositorio } = props;

  const auth = useAuth();
  const addToast = useToastContext();

  const globalData = useGlobalDataContext();

  const codigoIdiomaLocal = getUserLocale();

  const [procesoId, setProcesoId] = useState();
  const [areaSaludId, setAreaSaludId] = useState();
  const [especialidadId, setEspecialidadId] = useState();
  const [seccionId, setSeccionId] = useState();
  const [areaPreguntasId, setAreaPreguntasId] = useState();
  const [preguntaId, setPreguntaId] = useState();
  const [tipoVariableId, setTipoVariableId] = useState();
  const [tipoVariableCualitativaId, setTipoVariableCualitativaId] = useState();
  const [
    tipoVariableCualitativaCajasOpcionId,
    setTipoVariableCualitativaCajasOpcionId,
  ] = useState();
  const [unidadMedidaId, setUnidadMedidaId] = useState();
  const [valoracionId, setValoracionId] = useState();
  const [factorValoracionId, setFactorValoracionId] = useState();

  const [procesos, setProcesos] = useState([]);
  const [areasSalud, setAreasSalud] = useState([]);
  const [especialidades, setEspecialidades] = useState([]);
  const [secciones, setSecciones] = useState([]);
  const [areasPreguntas, setAreasPreguntas] = useState([]);
  const [preguntas, setPreguntas] = useState([]);
  const [tiposVariables, setTiposVariables] = useState([]);
  const [tiposVariablesCualitativas, setTiposVariablesCualitativas] = useState(
    []
  );
  const [
    tipoVariableCualitativaCajasOpciones,
    setTipoVariableCualitativaCajasOpciones,
  ] = useState([]);
  const [unidadesMedida, setUnidadesMedida] = useState([]);
  const [
    tipoVariableCualitativaCajasPropiedades,
    setTipoVariableCualitativaCajasPropiedades,
  ] = useState([]);
  const [valoraciones, setValoraciones] = useState([]);

  const [resultadoValoracion, setResultadoValoracion] = useState({
    valor: 0,
    minimo: 0,
    maximo: 0,
  });

  // Para poder administrar las propiedades de tipo booleano que están seleccionadas
  // es necesario mantener un array de los Id que están seleccionados.
  const [propiedadesChecked, setPropiedadesChecked] = useState([]);

  const niveles = {
    procesos: 0,
    areasSalud: 1,
    especialidades: 2,
    secciones: 3,
    areasPreguntas: 4,
    preguntas: 5,
    tiposVariable: 6,
    unidadesMedida: 7, // Está al mismo nivel que variablesCualitativas
    variablesCualitativas: 7, // Está al mismo nivel que unidadesMedida
    valoracion: 8, // Está al mismo nivel que las opciones de las variables cualitativas de caja
    opciones: 8, // Está al mismo nivel que las valoraciones de las variables cuantitativas
    factorValoracion: 9,
  };

  const idPropiedades = {
    InformacionAdicional: 0,
  };

  const urlBasica = API_URL();
  const urlProcesos = `${urlBasica}/EditarFormularioClinico/getProcesos`;
  const urlAreasSalud = `${urlBasica}/EditarFormularioClinico/getAreasSalud`;
  const urlEspecialidades = `${urlBasica}/EditarFormularioClinico/getEspecialidades`;
  const urlSecciones = `${urlBasica}/EditarFormularioClinico/getSecciones`;
  const urlAreasPreguntas = `${urlBasica}/EditarFormularioClinico/getAreasPreguntas`;
  const urlPreguntas = `${urlBasica}/EditarFormularioClinico/getPreguntas`;
  const urlTipoVariableCualitativaCajaOpciones = `${urlBasica}/EditarFormularioClinico/getOpcionesPregunta`;
  const urlUnidadesMedida = `${urlBasica}/EditarFormularioClinico/getUnidadesMedida`;
  const urlValoraciones = `${urlBasica}/EditarFormularioClinico/getValoraciones`;

  const tipoVariableCualitativaCajasPropiedadesElementos = [
    {
      id: idPropiedades.InformacionAdicional,
      // nombre: "Informacion Adicional",
      nombre: globalData.terminosLocalizados.ETI129,
      tipo: const_TipoDato.BOOLEANO,
      value: false,
    },
  ];

  // Se recibirán del servidor cuando esté implementado el multi-lenguaje.
  const descripcionTiposVariables = {
    // Cualitativa: "Cualitativa",
    // Cuantitativa: "Cuantitativa",
    // Multimedia: "Multimedia",
    Cualitativa: globalData.terminosLocalizados.TVA001,
    Cuantitativa: globalData.terminosLocalizados.TVA002,
    Multimedia: globalData.terminosLocalizados.TVA003,
  };

  // Se recibirán del servidor cuando esté implementado el multi-lenguaje.
  const descripcionTiposVariablesCualitativas = {
    // Multimedia: "Multimedia",
    // Cajas: "Cajas",
    // TextoPleno: "TextoPleno",
    // Fecha: "Fecha",
    Multimedia: globalData.terminosLocalizados.TVC001,
    Cajas: globalData.terminosLocalizados.TVC002,
    TextoPleno: globalData.terminosLocalizados.TVC003,
    Fecha: globalData.terminosLocalizados.TVC004,
  };

  // Se recibirán del servidor cuando esté implementado el multi-lenguaje.
  const descripcionFactoresValoración = {
    // Menor: "Menor que",
    // MenorIgual: "Menor o igual que",
    // Igual: "Igual que",
    // MayorIgual: "Mayor o igual que",
    // Mayor: "Mayor que",
    // Rango: "Rango",
    Menor: globalData.terminosLocalizados.VAL001,
    MenorIgual: globalData.terminosLocalizados.VAL002,
    Igual: globalData.terminosLocalizados.VAL003,
    MayorIgual: globalData.terminosLocalizados.VAL004,
    Mayor: globalData.terminosLocalizados.VAL005,
    Rango: globalData.terminosLocalizados.VAL006,
  };

  const seleccionarProceso = (id) => {
    if (id !== procesoId) {
      setProcesoId(id);
      limpiarElementos(niveles.procesos);
      obtenerElementos(id, urlAreasSalud, setAreasSalud);
    }
  };

  const seleccionarAreaSalud = (id) => {
    if (id !== areaSaludId) {
      setAreaSaludId(id);
      limpiarElementos(niveles.areasSalud);
      obtenerElementos(id, urlEspecialidades, setEspecialidades);
    }
  };

  const seleccionarEspecialidad = (id) => {
    if (id !== especialidadId) {
      setEspecialidadId(id);
      limpiarElementos(niveles.especialidades);
      obtenerElementos(id, urlSecciones, setSecciones);
    }
  };

  const seleccionarSeccion = (id) => {
    if (id !== seccionId) {
      setSeccionId(id);
      limpiarElementos(niveles.secciones);
      obtenerElementos(id, urlAreasPreguntas, setAreasPreguntas);
    }
  };

  const seleccionarAreaPreguntas = (id) => {
    if (id !== areaPreguntasId) {
      setAreaPreguntasId(id);
      limpiarElementos(niveles.areasPreguntas);
      obtenerElementos(id, urlPreguntas, setPreguntas);
    }
  };

  const seleccionarPreguntas = (id) => {
    if (id !== preguntaId) {
      setPreguntaId(id);
      limpiarElementos(niveles.preguntas);
      setTiposVariables(elementosTiposVariables);
    }
  };

  const seleccionarTiposVariables = (id) => {
    if (id !== tipoVariableId) {
      setTipoVariableId(id);
      limpiarElementos(niveles.tiposVariable);
      switch (id) {
        case const_TipoVariable.CUALITATIVA:
          setTiposVariablesCualitativas(elementosTiposVariablesCualitativas);
          break;
        case const_TipoVariable.CUANTITATIVA:
          obtenerElementos(preguntaId, urlUnidadesMedida, setUnidadesMedida);
          break;
        default:
          break;
      }
    }
  };

  const seleccionarTiposVariablesCualitativas = (id) => {
    if (id !== tipoVariableId) {
      setTipoVariableCualitativaId(id);
      limpiarElementos(niveles.variablesCualitativas);
      if (id === const_TipoVariableCualitativa.CAJAS) {
        obtenerElementos(
          preguntaId,
          urlTipoVariableCualitativaCajaOpciones,
          setTipoVariableCualitativaCajasOpciones
        );
        setTipoVariableCualitativaCajasPropiedades(
          tipoVariableCualitativaCajasPropiedadesElementos
        );
      } else {
        setTipoVariableCualitativaCajasOpciones([]);
      }
    }
  };

  const seleccionarTipoVariableCualitativaCajasOpciones = (id) => {
    if (id !== tipoVariableId) {
      setTipoVariableCualitativaCajasOpcionId(id);
    }
  };

  const seleccionarUnidadesMedida = (id) => {
    if (id !== unidadMedidaId) {
      setUnidadMedidaId(id);
      limpiarElementos(niveles.unidadesMedida);
      obtenerElementos(id, urlValoraciones, setValoraciones);
    }
  };

  const seleccionarValoracion = (id) => {
    if (id !== valoracionId) {
      setValoracionId(id);
      limpiarResultadoValoracion();
    }
  };

  const seleccionarFactorValoracion = (id) => {
    if (id !== factorValoracionId) {
      setFactorValoracionId(id);
    }
  };

  // Se utiliza un nuevo arreglo independiente para registrar
  // los ID de los elementos seleccionados.
  const seleccionarTipoVariableCualitativaCajasPropiedades = (selectedId) => {
    const currentIndex = propiedadesChecked.indexOf(selectedId);
    const newPropiedadesChecked = [...propiedadesChecked];
    if (currentIndex === -1) {
      // El ID seleccionado no está en la lista: se agrega.
      newPropiedadesChecked.push(selectedId);
    } else {
      // El ID seleccionado está en la lista: se quita.
      newPropiedadesChecked.splice(currentIndex, 1);
    }
    setPropiedadesChecked(newPropiedadesChecked);
  };

  const limpiarElementos = (nivel) => {
    if (nivel <= niveles.procesos) {
      setAreasSalud([]);
      setAreaSaludId();
    }
    if (nivel <= niveles.areasSalud) {
      setEspecialidades([]);
      setEspecialidadId();
    }
    if (nivel <= niveles.especialidades) {
      setSecciones([]);
      setSeccionId();
    }
    if (nivel <= niveles.secciones) {
      setAreasPreguntas([]);
      setAreaPreguntasId();
    }
    if (nivel <= niveles.areasPreguntas) {
      setPreguntas([]);
      setPreguntaId();
    }
    if (nivel <= niveles.preguntas) {
      setTiposVariables([]);
      setTipoVariableId();
    }
    if (nivel <= niveles.tiposVariable) {
      setTiposVariablesCualitativas([]);
      setTipoVariableCualitativaId();

      setTipoVariableCualitativaCajasOpciones([]);
      setTipoVariableCualitativaCajasOpcionId();

      setUnidadesMedida([]);
      setUnidadMedidaId();

      setPropiedadesChecked([]);
    }
    if (nivel <= niveles.unidadesMedida) {
      setValoraciones([]);
      setValoracionId();
    }
    if (nivel <= niveles.variablesCualitativas) {
      // Se debe limpiar si el nivel es inferior, o si es el mismo y
      // el tipo de variable seleccionado no es de cajas.
      if (
        nivel < niveles.variablesCualitativas ||
        tipoVariableCualitativaId !== const_TipoVariableCualitativa.CAJAS
      ) {
        setTipoVariableCualitativaCajasOpcionId();
        setPropiedadesChecked([]);
      }
    }
    if (nivel <= niveles.valoracion) {
      setFactorValoracionId();
      limpiarResultadoValoracion();
    }
    if (nivel <= niveles.factorValoracion) {
    }
  };

  const limpiarResultadoValoracion = () => {
    setResultadoValoracion({ valor: 0, minimo: 0, maximo: 0 });
  };

  // Agregar una pregunta al formulario clínico
  const guardarPregunta = async () => {
    const url = `${API_URL()}/EditarFormularioClinico/addPregunta`;
    let request = {
      Token: auth.user.token,
      GuidCuentaAdicional: auth.cuentaActiva.guid,
      PreguntaId: preguntaId,
      TipoVariable: tipoVariableId,
      TipoRespuesta: "",
      RespuestaVariableRespuestaID: 0,
      InformacionAdicional: false,
      ValoracionId: 0,
      CodigoComparacion: "",
      ValorReferencia: 0,
      ValorReferenciaSuperior: 0,
    };
    if (tipoVariableId === const_TipoVariable.CUALITATIVA) {
      request.TipoRespuesta = tipoVariableCualitativaId;
      if (tipoVariableCualitativaId === const_TipoVariableCualitativa.CAJAS) {
        request.RespuestaVariableRespuestaID =
          tipoVariableCualitativaCajasOpcionId;
        // Determinar si está marcada la inclusión de información adicional
        const indexCheckedInformacionAdicional = propiedadesChecked.indexOf(
          idPropiedades.InformacionAdicional
        );
        if (indexCheckedInformacionAdicional !== -1) {
          request.InformacionAdicional = true;
        }
      }
    } else {
      if (tipoVariableId === const_TipoVariable.CUANTITATIVA) {
        request.RespuestaVariableRespuestaID = unidadMedidaId;
        //
        // Aquí faltaría agregar el código correspondiente a las valoraciones
        //
        request.ValoracionId = valoracionId;
        request.CodigoComparacion = factorValoracionId
          ? factorValoracionId
          : "";
        if (factorValoracionId === const_TipoFactorValoracion.RANGO) {
          request.ValorReferencia = resultadoValoracion.minimo;
          request.ValorReferenciaSuperior = resultadoValoracion.maximo;
        } else {
          request.ValorReferencia = resultadoValoracion.valor;
          request.ValorReferenciaSuperior = 0;
        }
      }
    }
    try {
      const options = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(request),
      };
      const res = await fetch(url, options);
      if (res.ok) {
        const json = await res.json();
        if (json.resultadoOK) {
          setFormularioClinico(json.nuevoFormularioClinico);
        } else {
          json.errores.forEach((item) => {
            addToast(item, Severities.error);
          });
        }
      } else {
        //addToast("Error de servicio.", Severities.error);
        addToast(globalData.terminosLocalizados.AVI004, Severities.error);
      }
    } catch (err) {
      //addToast("Error de conexión", Severities.error);
      addToast(globalData.terminosLocalizados.AVI005, Severities.error);
    }
  };

  // Obtener los elementos para una lista de selección
  const obtenerElementos = useCallback(
    async (input, url, setValue) => {
      const request = {
        token: auth.user.token,
        parentId: input,
      };
      try {
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(request),
        };
        const res = await fetch(url, options);
        if (res.ok) {
          const json = await res.json();
          if (json.resultadoOK) {
            setValue(json.elementos);
          } else {
            json.errores.forEach((item) => {
              addToast(item, Severities.error);
            });
          }
        } else {
          //addToast("Error de servicio.", Severities.error);
          addToast(globalData.terminosLocalizados.AVI004, Severities.error);
        }
      } catch (err) {
        //addToast("Error de conexión", Severities.error);
        addToast(globalData.terminosLocalizados.AVI005, Severities.error);
      }
    },
    [auth.user.token, addToast, globalData.terminosLocalizados]
  );

  // Definición de los elementos constantes para la lista de selección de tipos de variables
  const elementosTiposVariables = [
    {
      id: const_TipoVariable.CUALITATIVA,
      descripcion: descripcionTiposVariables.Cualitativa,
    },
    {
      id: const_TipoVariable.CUANTITATIVA,
      descripcion: descripcionTiposVariables.Cuantitativa,
    },
    {
      id: const_TipoVariable.MULTIMEDIA,
      descripcion: descripcionTiposVariables.Multimedia,
    },
  ];

  // Definición de los elementos costantes para la lista
  // de selección de tipos de variables cualitativas
  const elementosTiposVariablesCualitativas = [
    {
      id: const_TipoVariableCualitativa.MULTIMEDIA,
      descripcion: descripcionTiposVariablesCualitativas.Multimedia,
    },
    {
      id: const_TipoVariableCualitativa.CAJAS,
      descripcion: descripcionTiposVariablesCualitativas.Cajas,
    },
    {
      id: const_TipoVariableCualitativa.TEXTO_PLENO,
      descripcion: descripcionTiposVariablesCualitativas.TextoPleno,
    },
    {
      id: const_TipoVariableCualitativa.FECHA,
      descripcion: descripcionTiposVariablesCualitativas.Fecha,
    },
  ];

  // Definición de los elementos constantes para la lista de selección de factores de valoración
  const elementosFactoresValoracion = [
    {
      id: const_TipoFactorValoracion.MENOR,
      descripcion: descripcionFactoresValoración.Menor,
    },
    {
      id: const_TipoFactorValoracion.MENOR_IGUAL,
      descripcion: descripcionFactoresValoración.MenorIgual,
    },
    {
      id: const_TipoFactorValoracion.IGUAL,
      descripcion: descripcionFactoresValoración.Igual,
    },
    {
      id: const_TipoFactorValoracion.MAYOR_IGUAL,
      descripcion: descripcionFactoresValoración.MayorIgual,
    },
    {
      id: const_TipoFactorValoracion.MAYOR,
      descripcion: descripcionFactoresValoración.Mayor,
    },
    {
      id: const_TipoFactorValoracion.RANGO,
      descripcion: descripcionFactoresValoración.Rango,
    },
  ];

  // Obtener del repositorio la lista de procesos
  useEffect(() => {
    (async () => {
      const request = {
        token: auth.user.token,
        codigoIdioma: auth.user.codigoIdioma
          ? auth.user.codigoIdioma
          : codigoIdiomaLocal,
      };
      try {
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(request),
        };
        const res = await fetch(urlProcesos, options);
        if (res.ok) {
          const json = await res.json();
          if (json.resultadoOK) {
            setProcesos(json.elementos);
          } else {
            json.errores.forEach((item) => {
              addToast(item, Severities.error);
            });
          }
        } else {
          //addToast("Error de servicio.", Severities.error);
          addToast(globalData.terminosLocalizados.AVI004, Severities.error);
        }
        return;
      } catch (err) {
        //addToast("Error de conexión", Severities.error);
        addToast(globalData.terminosLocalizados.AVI005, Severities.error);
      }
    })();
  }, [
    addToast,
    auth.user.token,
    urlProcesos,
    globalData.terminosLocalizados,
    globalData.terminosLocalizados.AVI004,
    globalData.terminosLocalizados.AVI005,
    auth.user.codigoIdioma,
  ]);

  return (
    <>
      <Box
        id="edit-clinic-form-page-first-section"
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "row",
          maxHeight: "50%",
          height: "50%",
          paddingY: 1,
          overflowX: "scroll",
          position: "relative",
        }}
      >
        <ListaSeleccion
          id="edit-clinic-form-page-lista-seleccion"
          tipo={const_TipoSeleccionEdicionFormularioClinico.PROCESO}
          //titulo="Proceso"
          titulo={globalData.terminosLocalizados.LSR001}
          datos={procesos}
          seleccionado={procesoId}
          seleccionar={seleccionarProceso}
          setElementos={setProcesos}
          administrarCambioElementoRepositorio={
            administrarCambioElementoRepositorio
          }
        ></ListaSeleccion>
        <ListaSeleccion
          id="edit-clinic-form-page-lista-seleccion"
          tipo={const_TipoSeleccionEdicionFormularioClinico.AREA_SALUD}
          //titulo="Área de Salud"
          titulo={globalData.terminosLocalizados.LSR002}
          datos={areasSalud}
          seleccionado={areaSaludId}
          seleccionar={seleccionarAreaSalud}
          padreId={procesoId}
          setElementos={setAreasSalud}
          administrarCambioElementoRepositorio={
            administrarCambioElementoRepositorio
          }
        ></ListaSeleccion>
        <ListaSeleccion
          id="edit-clinic-form-page-lista-seleccion"
          tipo={const_TipoSeleccionEdicionFormularioClinico.ESPECIALIDAD}
          //titulo="Especialidad"
          titulo={globalData.terminosLocalizados.LSR003}
          datos={especialidades}
          seleccionado={especialidadId}
          seleccionar={seleccionarEspecialidad}
          padreId={areaSaludId}
          setElementos={setEspecialidades}
          administrarCambioElementoRepositorio={
            administrarCambioElementoRepositorio
          }
        ></ListaSeleccion>
        <ListaSeleccion
          id="edit-clinic-form-page-lista-seleccion"
          tipo={const_TipoSeleccionEdicionFormularioClinico.SECCION}
          //titulo="Sección"
          titulo={globalData.terminosLocalizados.LSR004}
          datos={secciones}
          seleccionado={seccionId}
          seleccionar={seleccionarSeccion}
          padreId={especialidadId}
          setElementos={setSecciones}
          administrarCambioElementoRepositorio={
            administrarCambioElementoRepositorio
          }
        ></ListaSeleccion>
        <ListaSeleccion
          id="edit-clinic-form-page-lista-seleccion"
          tipo={const_TipoSeleccionEdicionFormularioClinico.AREA_PREGUNTAS}
          //titulo="Área de Preguntas"
          titulo={globalData.terminosLocalizados.LSR005}
          datos={areasPreguntas}
          seleccionado={areaPreguntasId}
          seleccionar={seleccionarAreaPreguntas}
          padreId={seccionId}
          setElementos={setAreasPreguntas}
          administrarCambioElementoRepositorio={
            administrarCambioElementoRepositorio
          }
        ></ListaSeleccion>
        <ListaSeleccion
          id="edit-clinic-form-page-lista-seleccion"
          tipo={const_TipoSeleccionEdicionFormularioClinico.PREGUNTA}
          //titulo="Preguntas"
          titulo={globalData.terminosLocalizados.LSR006}
          datos={preguntas}
          seleccionado={preguntaId}
          seleccionar={seleccionarPreguntas}
          padreId={areaPreguntasId}
          setElementos={setPreguntas}
          administrarCambioElementoRepositorio={
            administrarCambioElementoRepositorio
          }
        ></ListaSeleccion>
        <ListaSeleccion
          id="edit-clinic-form-page-lista-seleccion"
          tipo={const_TipoSeleccionEdicionFormularioClinico.TIPO_VARIABLE}
          //titulo="Tipo de Variable"
          titulo={globalData.terminosLocalizados.LSR007}
          datos={tiposVariables}
          seleccionado={tipoVariableId}
          seleccionar={seleccionarTiposVariables}
          padreId={preguntaId}
          administrarCambioElementoRepositorio={
            administrarCambioElementoRepositorio
          }
        ></ListaSeleccion>
        {tipoVariableId === const_TipoVariable.CUALITATIVA && (
          <ListaSeleccion
            id="edit-clinic-form-page-lista-seleccion"
            tipo="VariableRespuesta"
            //titulo="Variab. de Respuesta"
            titulo={globalData.terminosLocalizados.LSR008}
            datos={tiposVariablesCualitativas}
            seleccionado={tipoVariableCualitativaId}
            seleccionar={seleccionarTiposVariablesCualitativas}
            administrarCambioElementoRepositorio={
              administrarCambioElementoRepositorio
            }
          ></ListaSeleccion>
        )}
        {tipoVariableId === const_TipoVariable.CUANTITATIVA && (
          <ListaSeleccion
            id="edit-clinic-form-page-lista-seleccion"
            tipo={const_TipoSeleccionEdicionFormularioClinico.UNIDAD_MEDIDA}
            //titulo="Unidades de Medida"
            titulo={globalData.terminosLocalizados.LSR009}
            datos={unidadesMedida}
            seleccionado={unidadMedidaId}
            seleccionar={seleccionarUnidadesMedida}
            padreId={preguntaId}
            setElementos={setUnidadesMedida}
            administrarCambioElementoRepositorio={
              administrarCambioElementoRepositorio
            }
          ></ListaSeleccion>
        )}
        {tipoVariableCualitativaId === const_TipoVariableCualitativa.CAJAS && (
          <ListaSeleccion
            id="edit-clinic-form-page-lista-seleccion"
            tipo={const_TipoSeleccionEdicionFormularioClinico.OPCION_PREGUNTA}
            //titulo="Opciones"
            titulo={globalData.terminosLocalizados.LSR010}
            datos={tipoVariableCualitativaCajasOpciones}
            seleccionado={tipoVariableCualitativaCajasOpcionId}
            seleccionar={seleccionarTipoVariableCualitativaCajasOpciones}
            padreId={preguntaId}
            setElementos={setTipoVariableCualitativaCajasOpciones}
            administrarCambioElementoRepositorio={
              administrarCambioElementoRepositorio
            }
          ></ListaSeleccion>
        )}
        {tipoVariableCualitativaId === const_TipoVariableCualitativa.CAJAS && (
          <ListaSeleccionPropiedades
            id="edit-clinic-form-page-lista-seleccion"
            //titulo="Propiedades"
            titulo={globalData.terminosLocalizados.LSR011}
            datos={tipoVariableCualitativaCajasPropiedades}
            seleccionar={seleccionarTipoVariableCualitativaCajasPropiedades}
            seleccionados={propiedadesChecked}
          ></ListaSeleccionPropiedades>
        )}
        {tipoVariableId === const_TipoVariable.CUANTITATIVA && (
          <ListaSeleccion
            id="edit-clinic-form-page-lista-seleccion"
            tipo={const_TipoSeleccionEdicionFormularioClinico.VALORACION}
            //titulo="Valoración"
            titulo={globalData.terminosLocalizados.LSR012}
            datos={valoraciones}
            seleccionado={valoracionId}
            seleccionar={seleccionarValoracion}
            padreId={unidadMedidaId}
            setElementos={setValoraciones}
          ></ListaSeleccion>
        )}
        {tipoVariableId === const_TipoVariable.CUANTITATIVA && (
          <ListaSeleccion
            id="edit-clinic-form-page-lista-seleccion"
            tipo={const_TipoSeleccionEdicionFormularioClinico.FACTOR_VALORACION}
            //titulo="Factor"
            titulo={globalData.terminosLocalizados.LSR013}
            datos={elementosFactoresValoracion}
            seleccionado={factorValoracionId}
            seleccionar={seleccionarFactorValoracion}
          ></ListaSeleccion>
        )}
        {tipoVariableId === const_TipoVariable.CUANTITATIVA && (
          <ResultadoValoracion
            factor={factorValoracionId}
            //titulo="Resultado"
            titulo={globalData.terminosLocalizados.LSR014}
            resultado={resultadoValoracion}
            setResultado={setResultadoValoracion}
          ></ResultadoValoracion>
        )}
        <Box sx={{ display: "flex", flexDirection: "column-reverse" }}>
          <Fab
            sx={{
              color: theme.palette.primary.contrastText,
              width: 36, // Es lo mínimo que se puede establecer sin que se deforme
              height: 36,
              bottom: 4,
              right: 8,
              marginLeft: 2,
            }}
            onClick={guardarPregunta}
          >
            <SaveIcon></SaveIcon>
          </Fab>
        </Box>
      </Box>
    </>
  );
}
