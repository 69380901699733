import React from "react";

import { makeStyles } from "@material-ui/styles";

import Logo_Beemedics from "../../assets/img/Logo_BeeMedics_TRN.png";

import { Box, Stack, Button } from "@mui/material";

export default function SearchAnonimo(props) {
  const { terminos } = props;

  const useStyles = makeStyles({
    logo: {
      width: "100%",
      height: "auto",
      // Mantener como ejemplo de utilización
      //[theme.breakpoints.down("md")]: { height: "auto", maxWidth: "800px" },
      //[theme.breakpoints.down("sm")]: { height: "auto", maxWidth: "100%" },
    },
  });
  const classes = useStyles();

  return (
    <Box
      id="searchAnonimo"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      flexShrink={0}
      height="100%"
    >
      <Box
        // Este elemento es necesario para que el input quede del mismo ancho que el logo
        id="searchAnonimoContainer"
        width={"clamp(100px,90vw,460px)"}
      >
        <Stack spacing={"10px"}>
          <Box id="logoContainer">
            <img
              className={classes.logo}
              src={Logo_Beemedics}
              alt="BeeMedics"
            ></img>
          </Box>
          <Box id="searchContainer">
            <Stack spacing={"5px"}>
              <Box id="inputContainer">
                <input
                  className="inputText"
                  type="text"
                  id="searchText"
                ></input>
              </Box>
              <Box
                id="commandContainer"
                display={"flex"}
                justifyContent="center"
              >
                <Button variant="CommandBar1">{terminos.COM034}</Button>
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}
