import React from "react";
import { Controller } from "react-hook-form";
import { Typography, TextField, Grid } from "@mui/material";

import AdapterDateFns from "@mui/lab/AdapterDateFns";

import { LocalizationProvider, DesktopDatePicker } from "@mui/lab";

import useGlobalDataContext from "../../../hooks/useGlobalDataContext";

export default function DatosUsuario(props) {
  const {
    control,
    // Estas propiedades son para todos los campos excepto los de tipo <Select>
    inputProps,
    // Si hubiera algún campo de tipo <Select> se le debería aplicar estas propiedades
    selectInputProps,
  } = props;

  const globalData = useGlobalDataContext();

  const textos = {
    titulo: globalData.terminosLocalizados.ETI074,
    tituloIdentificacionPaciente: globalData.terminosLocalizados.ETI075,
    etiquetaPaisDocumento: globalData.terminosLocalizados.ETI076,
    etiquetaTipoDocumento: globalData.terminosLocalizados.ETI077,
    etiquetaNumeroDocumento: globalData.terminosLocalizados.ETI078,
    tituloInformacionPersonal: globalData.terminosLocalizados.ETI079,
    etiquetaNombre1: globalData.terminosLocalizados.ETI009,
    etiquetaNombre2: globalData.terminosLocalizados.ETI010,
    etiquetaApellido1: globalData.terminosLocalizados.ETI011,
    etiquetaApellido2: globalData.terminosLocalizados.ETI012,
    etiquetaFechaNacimiento: globalData.terminosLocalizados.ETI080,
    etiquetaLugarNacimiento: globalData.terminosLocalizados.ETI081,
    tituloInformacionContacto: globalData.terminosLocalizados.ETI082,
    etiquetaTelefonoCelular: globalData.terminosLocalizados.ETI083,
    etiquetaTelefonoFijo: globalData.terminosLocalizados.ETI084,
    etiquetaEmail: globalData.terminosLocalizados.ETI085,
    etiquetaDireccion: globalData.terminosLocalizados.ETI086,
    etiquetaAceptar: globalData.terminosLocalizados.ETI087,
    textoCrear: globalData.terminosLocalizados.COM031,
    textoCancelar: globalData.terminosLocalizados.COM004,
    textoAnterior: globalData.terminosLocalizados.COM032,
    textoSiguiente: globalData.terminosLocalizados.COM033,
    yaExiste1: globalData.terminosLocalizados.AVI028,
    yaExiste2: globalData.terminosLocalizados.AVI029,
    yaExiste3: globalData.terminosLocalizados.AVI030,
    yaExisteCrear: globalData.terminosLocalizados.AVI031,
    yaExisteNoAsociar: globalData.terminosLocalizados.AVI032,
  };

  return (
    <>
      <Grid item md={12} width="100%">
        <Typography variant="h7" fontStyle="italic">
          {textos.tituloInformacionPersonal}
        </Typography>
      </Grid>
      <Grid item md={6}>
        <Controller
          name="PrimerNombre"
          control={control}
          rules={{
            required: {
              value: true,
            },
            maxLength: {
              value: 40,
            },
          }}
          render={({ field }) => (
            <TextField
              type="text"
              size="small"
              name={textos.etiquetaNombre1}
              label={textos.etiquetaNombre1}
              inputProps={inputProps}
              {...field}
            />
          )}
        ></Controller>
      </Grid>
      <Grid item md={6}>
        <Controller
          name="SegundoNombre"
          control={control}
          rules={{
            maxLength: {
              value: 40,
            },
          }}
          render={({ field }) => (
            <TextField
              type="text"
              size="small"
              name={textos.etiquetaNombre2}
              label={textos.etiquetaNombre2}
              inputProps={inputProps}
              {...field}
            />
          )}
        ></Controller>
      </Grid>
      <Grid item md={6}>
        <Controller
          name="PrimerApellido"
          control={control}
          rules={{
            required: {
              value: true,
            },
            maxLength: {
              value: 40,
            },
          }}
          render={({ field }) => (
            <TextField
              type="text"
              name={textos.etiquetaApellido1}
              label={textos.etiquetaApellido1}
              size="small"
              inputProps={inputProps}
              {...field}
            />
          )}
        ></Controller>
      </Grid>
      <Grid item md={6}>
        <Controller
          name="SegundoApellido"
          control={control}
          rules={{
            maxLength: {
              value: 40,
            },
          }}
          render={({ field }) => (
            <TextField
              type="text"
              name={textos.etiquetaApellido2}
              label={textos.etiquetaApellido2}
              size="small"
              inputProps={inputProps}
              {...field}
            />
          )}
        ></Controller>
      </Grid>
      <Grid item md={6}>
        <Controller
          name="FechaNacimiento"
          control={control}
          defaultValue={null}
          rules={{
            required: {
              value: true,
            },
          }}
          render={({ field }) => (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                name={textos.etiquetaFechaNacimiento}
                label={textos.etiquetaFechaNacimiento}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => (
                  <TextField
                    size="small"
                    inputProps={selectInputProps}
                    {...params}
                  />
                )}
                {...field}
              />
            </LocalizationProvider>
          )}
        ></Controller>
      </Grid>
      <Grid item md={6}>
        <Controller
          name="LugarNacimiento"
          control={control}
          rules={{
            required: {
              value: true,
            },
            maxLength: {
              value: 12,
            },
          }}
          render={({ field }) => (
            <TextField
              type="text"
              name={textos.etiquetaLugarNacimiento}
              label={textos.etiquetaLugarNacimiento}
              size="small"
              inputProps={inputProps}
              {...field}
            />
          )}
        ></Controller>
      </Grid>
      <Grid item md={12} width="100%">
        <Typography variant="h7" fontStyle="italic">
          {textos.tituloInformacionContacto}
        </Typography>
      </Grid>
      <Grid item md={6}>
        <Controller
          name="TelefonoCelular"
          control={control}
          rules={{
            maxLength: {
              value: 40,
            },
          }}
          render={({ field }) => (
            <TextField
              type="text"
              name={textos.etiquetaTelefonoCelular}
              label={textos.etiquetaTelefonoCelular}
              size="small"
              inputProps={inputProps}
              {...field}
            />
          )}
        ></Controller>
      </Grid>
      <Grid item md={6}>
        <Controller
          name="TelefonoFijo"
          control={control}
          rules={{
            maxLength: {
              value: 40,
            },
          }}
          render={({ field }) => (
            <TextField
              type="text"
              name={textos.etiquetaTelefonoFijo}
              label={textos.etiquetaTelefonoFijo}
              size="small"
              inputProps={inputProps}
              {...field}
            />
          )}
        ></Controller>
      </Grid>
      <Grid item md={12}>
        <Controller
          name="Email"
          control={control}
          rules={{
            required: {
              value: true,
            },
            pattern: {
              value:
                // Este es el patern original, con \. para indicar el punto en la columna 28,
                // pero emite una advertencia diciendo que es escape es innecesario.
                // Lo quito, pero dejo el original en comentarios, por las dudas.
                // /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/,
                /^([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/,
            },
          }}
          render={({ field }) => (
            <TextField
              type="text"
              name="Email"
              label="Email"
              size="small"
              inputProps={inputProps}
              {...field}
            />
          )}
        ></Controller>
      </Grid>
      <Grid item md={12}>
        <Controller
          name="Direccion"
          control={control}
          rules={{
            maxLength: {
              value: 40,
            },
          }}
          render={({ field }) => (
            <TextField
              type="text"
              name={textos.etiquetaDireccion}
              label={textos.etiquetaDireccion}
              size="small"
              inputProps={inputProps}
              {...field}
            />
          )}
        ></Controller>
      </Grid>
    </>
  );
}
