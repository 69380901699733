import React from "react";

import { TextField } from "@mui/material";

import BoxPregunta from "./BoxPregunta";

import { const_TipoOrdenableFormularioClinico } from "../../../../utils/constants";

export default function EdicionTextoPleno(props) {
  const {
    itemPreguntas,
    handleQuitarPregunta,
    handleSubirPregunta,
    handleBajarPregunta,
  } = props;
  return (
    <BoxPregunta
      guid={itemPreguntas.guid}
      tipo={const_TipoOrdenableFormularioClinico.TEXTO_PLENO}
      suprimible
      handleQuitarPregunta={handleQuitarPregunta}
      handleSubirPregunta={handleSubirPregunta}
      handleBajarPregunta={handleBajarPregunta}
    >
      <TextField
        type="text"
        name={itemPreguntas.descripcion}
        placeholder={itemPreguntas.descripcion}
        multiline={true}
        rows="3"
        sx={{
          width: "50%",
          "&:hover": {
            backgroundColor: "divider",
          },
        }}
      ></TextField>
    </BoxPregunta>
  );
}
