import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";

import {
  FormControl,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Card,
  Typography,
  Stack,
  Button,
} from "@mui/material";

import {
  const_TipoCuentaAdicional,
  const_TipoDocumentoIdentidad,
} from "../../utils/constants";
import ErrorSummary from "../Forms/ErrorSummary";
import useGlobalDataContext from "../../hooks/useGlobalDataContext";
import Loading from "../Feedback/Loading";
import SelectorPaises from "../Datos/SelectorPaises";

export default function AgregarCuentaAdicional(props) {
  const { close, agregar } = props;

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({});

  const globalData = useGlobalDataContext();

  // Se deberían obtener desde el servidor en función del idioma del usuario
  const textos = {
    titulo: globalData.terminosLocalizados.ETI181, //"Agregar Cuenta Adicional",
    TipoCuenta: globalData.terminosLocalizados.ETI182, //"Tipo de Cuenta",
    TipoCuentaProfesional: globalData.terminosLocalizados.ETI183, //"Profesional",
    TipoCuentaInstitucion: globalData.terminosLocalizados.ETI184, //"Institución",
    etiquetaTipoCuenta: globalData.terminosLocalizados.ETI182, //"Tipo de cuenta",
    etiquetaCodigoPaisDocumentoProfesional:
      globalData.terminosLocalizados.ETI076, //"País del documento",
    etiquetaTipoDocumentoProfesional: globalData.terminosLocalizados.ETI077, //"Tipo de documento",
    etiquetaNumeroDocumentoProfesional: globalData.terminosLocalizados.ETI078, //"Número de documento",
    etiquetaCodigoPaisInstitucion: globalData.terminosLocalizados.ETI112, //"País",
    etiquetaProfesion: globalData.terminosLocalizados.ETI107, //"Profesión",
    etiquetaNombre: globalData.terminosLocalizados.ETI108, //"Nombre",
    etiquetaApellido: globalData.terminosLocalizados.ETI109, //"Apellido",
    etiquetaEmail: globalData.terminosLocalizados.ETI004, //"Email",
    etiquetaTelefono: globalData.terminosLocalizados.ETI005, //"Teléfono",
    etiquetaLugarTrabajo: globalData.terminosLocalizados.ETI110, // "Lugar de trabajo",
    etiquetaProvincia: globalData.terminosLocalizados.ETI113, // "Provincia",
    etiquetaCiudad: globalData.terminosLocalizados.ETI114, // "Ciudad",
    etiquetaDireccion: globalData.terminosLocalizados.ETI086, // "Dirección",
    etiquetaPaginaWeb: globalData.terminosLocalizados.ETI115, // "Página Web",
    etiquetaAgregar: globalData.terminosLocalizados.COM037, // "Agregar",
    etiquetaCancelar: globalData.terminosLocalizados.COM004, // "Cancelar",
  };

  // En esta estructura se tienen los nombres de los campos en el idioma del usuario
  // Se recibirán del servidor cuando esté implementado el multi-lenguaje.
  const descripcionCampos = {
    Profesion: globalData.terminosLocalizados.ETI107, // "Profesión",
    Nombre: globalData.terminosLocalizados.ETI108, // "Nombre",
    Apellido: globalData.terminosLocalizados.ETI109, // "Apellido",
    Email: globalData.terminosLocalizados.ETI004, // "Email",
    Telefono: globalData.terminosLocalizados.ETI005, // "Teléfono",
    CodigoPaisDocumentoIdentidad: globalData.terminosLocalizados.ETI076, // "Código de país del documento de identidad",
    TipoDocumentoIdentidad: globalData.terminosLocalizados.ET077, // "Tipo de documento de identidad",
    NumeroDocumentoIdentidad: globalData.terminosLocalizados.ETI078, // "Número de documento de identidad",
    LugarTrabajo: globalData.terminosLocalizados.ETI110, // "Lugar de trabajo",
    País: globalData.terminosLocalizados.ETI112, // "País",
    Provincia: globalData.terminosLocalizados.ETI113, // "Provincia",
    Ciudad: globalData.terminosLocalizados.ETI114, // "Ciudad",
    Direccion: globalData.terminosLocalizados.ETI086, // "Dirección",
    PaginaWeb: globalData.terminosLocalizados.ETI115, // "Página web",
  };

  // Se recibirán del servidor cuando esté implementado el multi-lenguaje.
  const descripcionTipoDocumentoIdentidad = {
    CedulaIdentidad: globalData.terminosLocalizados.ETI068, // "Cédula de Identidad",
    Pasaporte: globalData.terminosLocalizados.ETI069, // "Pasaporte",
    DNI: globalData.terminosLocalizados.ETI070, // "DNI",
  };

  const [tipoCuenta, setTipoCuenta] = useState("");

  const procesarCambioTipoCuenta = (event) => {
    setTipoCuenta(event.target.value);
  };

  const cancelar = (event, reason) => {
    close(event, reason);
  };

  const agregarLocal = (data) => {
    console.log(data);
    if (tipoCuenta === const_TipoCuentaAdicional.PROFESIONAL) {
      if (data.CodigoPaisDocumentoProfesional === "") {
        setError("CodigoPaisDocumentoProfesional", {
          type: "manual",
          message: "El país es requerido",
        });
        return;
      }
      if (data.TipoDocumentoProfesional === "") {
        setError("TipoDocumentoProfesional", {
          type: "manual",
          message: "El tipo de documento es requerido",
        });
        return;
      }
    } else {
      if (tipoCuenta === const_TipoCuentaAdicional.INSTITUCION) {
        if (data.CodigoPaisInstitucion === "") {
          setError("CodigoPaisInstitucion", {
            type: "manual",
            message: "El país es requerido",
          });
          return;
        }
      }
    }
    data.TipoCuenta = tipoCuenta;
    agregar(data);
  };

  return (
    <Card
      sx={{
        p: 2,
        width: 400,
        borderRadius: "10px",
      }}
    >
      <form onSubmit={handleSubmit(agregarLocal)}>
        <Stack spacing={1}>
          <Typography variant="h5">{textos.titulo}</Typography>
          <FormControl size="small">
            <InputLabel id="select-tipo-cuenta-label">
              {textos.etiquetaTipoCuenta}
            </InputLabel>
            <Select
              labelId="select-tipo-cuenta-label"
              id="select-tipo-cuenta"
              value={tipoCuenta}
              defaultValue=""
              label={textos.etiquetaTipoCuenta}
              onChange={procesarCambioTipoCuenta}
            >
              <MenuItem value={const_TipoCuentaAdicional.PROFESIONAL}>
                {textos.TipoCuentaProfesional}
              </MenuItem>
              <MenuItem value={const_TipoCuentaAdicional.INSTITUCION}>
                {textos.TipoCuentaInstitucion}
              </MenuItem>
            </Select>
          </FormControl>
          {tipoCuenta === const_TipoCuentaAdicional.PROFESIONAL && (
            <Controller
              name="Profesion"
              control={control}
              defaultValue=""
              rules={{
                required: {
                  value: true,
                },
                maxLength: {
                  value: 40,
                },
              }}
              render={({ field }) => (
                <TextField
                  type="text"
                  size="small"
                  name="Profesion"
                  label={textos.etiquetaProfesion}
                  {...field}
                />
              )}
            ></Controller>
          )}
          <Controller
            name="Nombre"
            control={control}
            defaultValue=""
            rules={{
              required: {
                value: true,
              },
              maxLength: {
                value: 40,
              },
            }}
            render={({ field }) => (
              <TextField
                type="text"
                size="small"
                name="Nombre"
                label={textos.etiquetaNombre}
                {...field}
              />
            )}
          ></Controller>
          {tipoCuenta === const_TipoCuentaAdicional.PROFESIONAL && (
            <Controller
              name="Apellido"
              control={control}
              defaultValue=""
              rules={{
                required: {
                  value: true,
                },
                maxLength: {
                  value: 40,
                },
              }}
              render={({ field }) => (
                <TextField
                  type="text"
                  size="small"
                  name="Apellido"
                  label={textos.etiquetaApellido}
                  {...field}
                />
              )}
            ></Controller>
          )}
          <Controller
            name="Email"
            control={control}
            defaultValue=""
            rules={{
              required: {
                value: true,
              },
              pattern: {
                value:
                  /^([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/, ///^(\[[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.)|(())([a-zA-Z]{2,4}|[0-9]{1,3})$/,
              },
              maxLength: {
                value: 255,
              },
            }}
            render={({ field }) => (
              <TextField
                type="text"
                size="small"
                name="Email"
                label={textos.etiquetaEmail}
                sx={{ width: "100%" }}
                {...field}
              />
            )}
          ></Controller>
          <Controller
            name="Telefono"
            control={control}
            defaultValue=""
            rules={{
              maxLength: {
                value: 40,
              },
            }}
            render={({ field }) => (
              <TextField
                type="text"
                size="small"
                name="Telefono"
                label={textos.etiquetaTelefono}
                {...field}
              />
            )}
          ></Controller>
          {tipoCuenta === const_TipoCuentaAdicional.INSTITUCION && (
            <FormControl size="small">
              <SelectorPaises
                control={control}
                defaultValue=""
                name="CodigoPaisInstitucion"
                label={textos.etiquetaCodigoPaisInstitucion}
              />
            </FormControl>
          )}
          {tipoCuenta === const_TipoCuentaAdicional.PROFESIONAL && (
            <FormControl size="small">
              <InputLabel>
                {textos.etiquetaCodigoPaisDocumentoProfesional}
              </InputLabel>
              <Controller
                name="CodigoPaisDocumentoProfesional"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    // A los <Select> no se les aplica "disabled" porque le cambia el color al contenido
                    inputProps={
                      {
                        //readOnly: !modoEdicion,
                        //disabled: !modoEdicion
                      }
                    }
                    label={textos.etiquetaCodigoPaisDocumentoProfesional}
                    MenuProps={{
                      PaperProps: { sx: { maxHeight: 190, width: "" } },
                    }}
                    {...field}
                  >
                    {globalData.loadingPaises ? (
                      <Loading />
                    ) : (
                      globalData.paises.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item.codigo}>
                            {item.nombre}
                          </MenuItem>
                        );
                      })
                    )}
                  </Select>
                )}
              ></Controller>
            </FormControl>
          )}
          {tipoCuenta === const_TipoCuentaAdicional.PROFESIONAL && (
            <FormControl size="small">
              <InputLabel
              //inputProps={{ readOnly: !modoEdicion, disabled: !modoEdicion }}
              >
                {textos.etiquetaTipoDocumentoProfesional}
              </InputLabel>
              <Controller
                name="TipoDocumentoProfesional"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    // A los <Select> no se les aplica "disabled" porque le cambia el color al contenido
                    inputProps={
                      {
                        //readOnly: !modoEdicion,
                        //disabled: !modoEdicion
                      }
                    }
                    label={textos.etiquetaTipoDocumentoProfesional}
                    {...field}
                  >
                    <MenuItem
                      value={const_TipoDocumentoIdentidad.CEDULA_IDENTIDAD}
                    >
                      {descripcionTipoDocumentoIdentidad.CedulaIdentidad}
                    </MenuItem>
                    <MenuItem value={const_TipoDocumentoIdentidad.PASAPORTE}>
                      {descripcionTipoDocumentoIdentidad.Pasaporte}
                    </MenuItem>
                    <MenuItem value={const_TipoDocumentoIdentidad.DNI}>
                      {descripcionTipoDocumentoIdentidad.DNI}
                    </MenuItem>
                  </Select>
                )}
              ></Controller>
            </FormControl>
          )}
          {tipoCuenta === const_TipoCuentaAdicional.PROFESIONAL && (
            <Controller
              name="NumeroDocumentoProfesional"
              control={control}
              defaultValue=""
              rules={{
                required: {
                  value: true,
                },
                maxLength: {
                  value: 12,
                },
              }}
              render={({ field }) => (
                <TextField
                  type="text"
                  size="small"
                  label={textos.etiquetaNumeroDocumentoProfesional}
                  {...field}
                />
              )}
            ></Controller>
          )}
          {tipoCuenta === const_TipoCuentaAdicional.PROFESIONAL && (
            <Controller
              name="LugarTrabajo"
              control={control}
              defaultValue=""
              rules={{
                maxLength: {
                  value: 40,
                },
              }}
              render={({ field }) => (
                <TextField
                  type="text"
                  size="small"
                  name="LugarTrabajo"
                  label={textos.etiquetaLugarTrabajo}
                  {...field}
                />
              )}
            ></Controller>
          )}
          {tipoCuenta === const_TipoCuentaAdicional.INSTITUCION && (
            <Controller
              name="Provincia"
              control={control}
              defaultValue=""
              rules={{
                required: {
                  value: true,
                },
                maxLength: {
                  value: 40,
                },
              }}
              render={({ field }) => (
                <TextField
                  type="text"
                  size="small"
                  name="Provincia"
                  label={textos.etiquetaProvincia}
                  {...field}
                />
              )}
            ></Controller>
          )}
          {tipoCuenta === const_TipoCuentaAdicional.INSTITUCION && (
            <Controller
              name="Ciudad"
              control={control}
              defaultValue=""
              rules={{
                required: {
                  value: true,
                },
                maxLength: {
                  value: 40,
                },
              }}
              render={({ field }) => (
                <TextField
                  type="text"
                  size="small"
                  name="Ciudad"
                  label={textos.etiquetaCiudad}
                  {...field}
                />
              )}
            ></Controller>
          )}
          {tipoCuenta === const_TipoCuentaAdicional.INSTITUCION && (
            <Controller
              name="Direccion"
              control={control}
              defaultValue=""
              rules={{
                required: {
                  value: true,
                },
                maxLength: {
                  value: 40,
                },
              }}
              render={({ field }) => (
                <TextField
                  type="text"
                  size="small"
                  name="Direccion"
                  label={textos.etiquetaDireccion}
                  {...field}
                />
              )}
            ></Controller>
          )}
          {tipoCuenta === const_TipoCuentaAdicional.INSTITUCION && (
            <Controller
              name="PaginaWeb"
              control={control}
              defaultValue=""
              rules={{
                maxLength: {
                  value: 40,
                },
              }}
              render={({ field }) => (
                <TextField
                  type="text"
                  size="small"
                  name="PaginaWeb"
                  label={textos.etiquetaPaginaWeb}
                  {...field}
                />
              )}
            ></Controller>
          )}
          <Stack direction={"row"} spacing={1} justifyContent="flex-end">
            <Button variant="CommandBar2" type="submit">
              {textos.etiquetaAgregar}
            </Button>
            <Button variant="CommandBar2" onClick={cancelar}>
              {textos.etiquetaCancelar}
            </Button>
          </Stack>
          <ErrorSummary
            errors={errors}
            descripciones={descripcionCampos}
          ></ErrorSummary>
        </Stack>
      </form>
    </Card>
  );
}
