import React from "react";

import {
  Box,
  Tabs,
  Tab,
  Typography,
  tabsClasses,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  Stack,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import PreguntaFecha from "./PreguntaFecha";
import PreguntaMultimedia from "./PreguntaMultimedia";
import PreguntaUnidadMedida from "./PreguntaUnidadMedida";
import PreguntaOpciones from "./PreguntaOpciones";
import PreguntaTextoPleno from "./PreguntaTextoPleno";

import theme from "../../../../../utils/theme";
import BarraTituloPreguntas from "./BarraTituloPreguntas";

import { uso_barra_titulo } from "../Formulario";

import { TextoNivel3 } from "../Textos";

import useGlobalDataContext from "../../../../../hooks/useGlobalDataContext";

export default function Preguntas(props) {
  const { formularioClinico, inhabilitado, actualizarRespuesta } = props;

  const [procesoSeleccionado, setProcesoSeleccionado] = React.useState(0);

  const handleChange = (event, newValue) => {
    setProcesoSeleccionado(newValue);
  };

  return (
    <Box
      id="preguntasContenedor"
      height={"inherit"}
      // Se define este color porque luego se utiliza como borde en los tabs.
      // Si se cambiara el color del fondo debe ser el mismo en ambos lados.
      backgroundColor={theme.palette.primary.background}
      display="flex"
      flexDirection={"column"}
    >
      <Tabs
        id="tabs"
        value={procesoSeleccionado}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        sx={{
          height: "30px",
          minHeight: "30px",
          [`& .${tabsClasses.scrollButtons}`]: {
            width: "20px",
            "&.Mui-disabled": { opacity: 0.3 },
          },
        }}
        TabIndicatorProps={{
          style: { background: theme.palette.secondary.main },
        }}
      >
        {formularioClinico.procesos.map((itemProceso, indexProceso) => {
          return (
            <Tab
              key={indexProceso}
              label={itemProceso.descripcion}
              sx={{
                height: "30px",
                minHeight: "30px",
                fontSize: "9px",
                bgcolor: theme.palette.primary.main,
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: theme.palette.primary.background,
                borderRadius: "10px 10px 0 0",
                color: theme.palette.primary.contrastText,
                "&:hover": {
                  backgroundColor: theme.palette.primary.light,
                  color: theme.palette.primary.contrastText,
                },
                "&.Mui-selected": {
                  backgroundColor: theme.palette.secondary.main,
                  color: theme.palette.secondary.contrastText,
                },
                "&.Mui-selected:hover": {
                  backgroundColor: theme.palette.secondary.main,
                  color: theme.palette.secondary.contrastText,
                },
              }}
            ></Tab>
          );
        })}
      </Tabs>
      <RenderProcesos
        formularioClinico={formularioClinico}
        seleccionado={procesoSeleccionado}
        inhabilitado={inhabilitado}
        actualizarRespuesta={actualizarRespuesta}
      />
    </Box>
  );
}

function TabPanel(props) {
  const { children, value, index } = props;
  return (
    <Box id="formulario-clínico-ingreso-proceso">
      {value === index && (
        <Box
          id="formulario-clinico-ingreso-proceso-caja-interior"
          paddingY={0.5}
          paddingX={1}
        >
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

function RenderProcesos(props) {
  const {
    formularioClinico,
    seleccionado,
    cargarRespuesta,
    buscarRespuesta,
    inhabilitado,
    actualizarRespuesta,
  } = props;

  return (
    <Box
      id="procesos"
      sx={{
        overflow: "auto",
      }}
    >
      {formularioClinico.procesos.map((itemProceso, indexProceso) => {
        return (
          <TabPanel
            key={indexProceso}
            value={seleccionado}
            index={indexProceso}
          >
            <RenderAreasSalud
              id="areasSalud"
              guidProceso={itemProceso.guid}
              cargarRespuesta={cargarRespuesta}
              buscarRespuesta={buscarRespuesta}
              inhabilitado={inhabilitado}
              actualizarRespuesta={actualizarRespuesta}
              itemProceso={itemProceso}
              indexProceso={indexProceso}
            />
          </TabPanel>
        );
      })}
    </Box>
  );
}

function RenderAreasSalud(props) {
  const {
    guidProceso,
    cargarRespuesta,
    buscarRespuesta,
    inhabilitado,
    actualizarRespuesta,
    itemProceso,
    indexProceso,
  } = props;

  return (
    <>
      {itemProceso.areasSalud.map((itemAreaSalud, indexAreaSalud) => {
        return (
          <RenderEspecialidades
            id="especialidades"
            key={indexAreaSalud}
            guidProceso={guidProceso}
            guidAreaSalud={itemAreaSalud.guid}
            cargarRespuesta={cargarRespuesta}
            buscarRespuesta={buscarRespuesta}
            inhabilitado={inhabilitado}
            actualizarRespuesta={actualizarRespuesta}
            itemAreaSalud={itemAreaSalud}
            indexProceso={indexProceso}
            indexAreaSalud={indexAreaSalud}
          />
        );
      })}
    </>
  );
}

function RenderEspecialidades(props) {
  const {
    guidProceso,
    guidAreaSalud,
    cargarRespuesta,
    buscarRespuesta,
    inhabilitado,
    actualizarRespuesta,
    itemAreaSalud,
    indexProceso,
    indexAreaSalud,
  } = props;

  return (
    <>
      {itemAreaSalud.especialidades.map(
        (itemEspecialidad, indexEspecialidad) => {
          return (
            <RenderSecciones
              id="secciones"
              key={indexEspecialidad}
              guidProceso={guidProceso}
              guidAreaSalud={guidAreaSalud}
              guidEspecialidad={itemEspecialidad.guid}
              secciones={itemEspecialidad.secciones}
              parentGuid={itemEspecialidad.guidEspecialidad}
              cargarRespuesta={cargarRespuesta}
              buscarRespuesta={buscarRespuesta}
              inhabilitado={inhabilitado}
              actualizarRespuesta={actualizarRespuesta}
              itemEspecialidad={itemEspecialidad}
              indexProceso={indexProceso}
              indexAreaSalud={indexAreaSalud}
              indexEspecialidad={indexEspecialidad}
              descripcionAreaSalud={itemAreaSalud.descripcion}
            />
          );
        }
      )}
    </>
  );
}

function RenderSecciones(props) {
  const {
    guidProceso,
    guidAreaSalud,
    guidEspecialidad,
    cargarRespuesta,
    buscarRespuesta,
    inhabilitado,
    actualizarRespuesta,
    itemEspecialidad,
    indexProceso,
    indexAreaSalud,
    indexEspecialidad,
    descripcionAreaSalud,
  } = props;

  const globalData = useGlobalDataContext();

  const textos = {
    areaSalud: globalData.terminosLocalizados.ETI103,
    especialidad: globalData.terminosLocalizados.ETI104,
  };

  return (
    <>
      {itemEspecialidad.secciones.map((itemSeccion, indexSeccion) => {
        //console.log(itemSeccion);
        return (
          <Accordion
            id="accordion"
            key={indexSeccion}
            elevation={0}
            sx={{
              // Para evitar la elevación de los paneles, que no coinciden con los elementos redondeados
              "&.MuiAccordion-root:before": {
                backgroundColor: "white",
                top: 0,
              },
              "&.Mui-expanded": {
                marginY: "0px",
              },
            }}
          >
            <AccordionSummary
              id="accordionSummary"
              expandIcon={
                <ExpandMoreIcon
                  sx={{
                    color: theme.palette.primary.contrastText,
                  }}
                />
              }
              sx={{
                marginTop: "4px",
                height: "30px",
                minHeight: "30px",
                color: theme.palette.primary.contrastText,
                backgroundColor: theme.palette.primary.dark,
                borderRadius: "30px",
                width: "100%",
                boxSizing: "border-box",
                "& .MuiAccordionSummary-content": {
                  width: "90%",
                  paddingX: "16px",
                  marginY: "0",
                  boxSizing: "border-box",
                },
                "&.Mui-expanded": {
                  height: "30px",
                  minHeight: "30px",
                },
              }}
            >
              <Tooltip
                title={
                  <React.Fragment>
                    {`${textos.areaSalud}: ${descripcionAreaSalud}`}
                    <br />
                    {`${textos.especialidad}: ${itemEspecialidad.descripcion}`}
                  </React.Fragment>
                }
                arrow
                placement="bottom-start"
              >
                <Typography
                  component={"span"}
                  sx={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {itemSeccion.descripcion}
                </Typography>
              </Tooltip>
            </AccordionSummary>
            <AccordionDetails id="accordionDetails" sx={{ padding: 1 }}>
              <RenderAreasPreguntas
                id="areasPreguntas"
                guidProceso={guidProceso}
                guidAreaSalud={guidAreaSalud}
                guidEspecialidad={guidEspecialidad}
                guidSeccion={itemSeccion.guid}
                cargarRespuesta={cargarRespuesta}
                buscarRespuesta={buscarRespuesta}
                inhabilitado={inhabilitado}
                areasPreguntasHorizontal={itemSeccion.areasPreguntasHorizontal}
                actualizarRespuesta={actualizarRespuesta}
                itemSeccion={itemSeccion}
                indexProceso={indexProceso}
                indexAreaSalud={indexAreaSalud}
                indexEspecialidad={indexEspecialidad}
                indexSeccion={indexSeccion}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
}

function RenderAreasPreguntas(props) {
  const {
    guidProceso,
    guidAreaSalud,
    guidEspecialidad,
    guidSeccion,
    cargarRespuesta,
    buscarRespuesta,
    inhabilitado,
    areasPreguntasHorizontal,
    actualizarRespuesta,
    itemSeccion,
    indexProceso,
    indexAreaSalud,
    indexEspecialidad,
    indexSeccion,
  } = props;

  return (
    <Box id="areasPreguntasContenedor" width={"100%"}>
      {areasPreguntasHorizontal ? (
        // Se agrega otra caja exterior porque para que la barra de desplazamiento quede
        // en la parte superior es necesario rotar el contenedor, y es necesaria otra caja interior
        // para volver a rotarla y que el contenido quede bien orientado.
        <Box
          // Se rota esta caja exterior para que la barra de desplazamiento quede en la parte superior
          sx={{
            transform: "rotateX(180deg)",
          }}
          overflow="auto"
        >
          {/* La caja interior se vuelve a rotar para que el contenido vuelta a quedar de la forma correcta*/}
          <Stack
            direction={"row"}
            spacing={1}
            id="formulario-clínico-ingreso-contenido-preguntas-areasPreguntas"
            display="flex"
            flexDirection={areasPreguntasHorizontal ? "row" : "column"}
            width="100%"
            // Se vuelve a rotar para que el contenido no quede dado vuelta
            sx={{ transform: "rotateX(180deg)" }}
          >
            {itemSeccion.areasPreguntas.map(
              (itemAreaPreguntas, indexAreaPreguntas) => {
                return (
                  <Box key={indexAreaPreguntas}>
                    <Box width="100%">
                      {(itemAreaPreguntas.visible ||
                        areasPreguntasHorizontal) && (
                        <Typography
                          fontSize={12}
                          fontStyle="italic"
                          fontWeight="bold"
                          textOverflow="ellipsis"
                          overflow="hidden"
                          whiteSpace="nowrap"
                          width={"calc(100% - 20px)"}
                        >
                          {itemAreaPreguntas.descripcion}
                        </Typography>
                      )}
                      <RenderPreguntas
                        key={indexAreaPreguntas}
                        guidProceso={guidProceso}
                        guidAreaSalud={guidAreaSalud}
                        guidEspecialidad={guidEspecialidad}
                        guidSeccion={guidSeccion}
                        guidAreaPreguntas={itemAreaPreguntas.guid}
                        preguntas={itemAreaPreguntas.preguntas}
                        cargarRespuesta={cargarRespuesta}
                        buscarRespuesta={buscarRespuesta}
                        inhabilitado={inhabilitado}
                        actualizarRespuesta={actualizarRespuesta}
                        itemAreaPreguntas={itemAreaPreguntas}
                        indexProceso={indexProceso}
                        indexAreaSalud={indexAreaSalud}
                        indexEspecialidad={indexEspecialidad}
                        indexSeccion={indexSeccion}
                        indexAreaPreguntas={indexAreaPreguntas}
                      />
                    </Box>
                  </Box>
                );
              }
            )}
          </Stack>
        </Box>
      ) : (
        // Las áreas de preguntas se exponen de forma horizontal
        <Box id="formulario-clínico-ingreso-contenido-preguntas-areasPreguntas">
          {itemSeccion.areasPreguntas.map(
            (itemAreaPreguntas, indexAreaPreguntas) => {
              return (
                <Box key={indexAreaPreguntas}>
                  <Box>
                    {(itemAreaPreguntas.visible ||
                      areasPreguntasHorizontal) && (
                      <TextoNivel3
                        texto={itemAreaPreguntas.descripcion}
                        estilo="italic"
                        peso="bold"
                      />
                    )}
                    <RenderPreguntas
                      key={indexAreaPreguntas}
                      guidProceso={guidProceso}
                      guidAreaSalud={guidAreaSalud}
                      guidEspecialidad={guidEspecialidad}
                      guidSeccion={guidSeccion}
                      guidAreaPreguntas={itemAreaPreguntas.guid}
                      cargarRespuesta={cargarRespuesta}
                      buscarRespuesta={buscarRespuesta}
                      inhabilitado={inhabilitado}
                      actualizarRespuesta={actualizarRespuesta}
                      itemAreaPreguntas={itemAreaPreguntas}
                      indexProceso={indexProceso}
                      indexAreaSalud={indexAreaSalud}
                      indexEspecialidad={indexEspecialidad}
                      indexSeccion={indexSeccion}
                      indexAreaPreguntas={indexAreaPreguntas}
                    />
                  </Box>
                </Box>
              );
            }
          )}
        </Box>
      )}
    </Box>
  );
}

function RenderPreguntas(props) {
  const {
    guidProceso,
    guidAreaSalud,
    guidEspecialidad,
    guidSeccion,
    guidAreaPreguntas,
    cargarRespuesta,
    buscarRespuesta,
    inhabilitado,
    actualizarRespuesta,
    itemAreaPreguntas,
    indexProceso,
    indexAreaSalud,
    indexEspecialidad,
    indexSeccion,
    indexAreaPreguntas,
  } = props;

  return (
    <>
      {itemAreaPreguntas.preguntas.map((itemPregunta, indexPregunta) => {
        return (
          <RenderPregunta
            guidProceso={guidProceso}
            guidAreaSalud={guidAreaSalud}
            guidEspecialidad={guidEspecialidad}
            guidSeccion={guidSeccion}
            guidAreaPreguntas={guidAreaPreguntas}
            key={indexPregunta}
            cargarRespuesta={cargarRespuesta}
            buscarRespuesta={buscarRespuesta}
            inhabilitado={inhabilitado}
            actualizarRespuesta={actualizarRespuesta}
            itemPregunta={itemPregunta}
            indexProceso={indexProceso}
            indexAreaSalud={indexAreaSalud}
            indexEspecialidad={indexEspecialidad}
            indexSeccion={indexSeccion}
            indexAreaPreguntas={indexAreaPreguntas}
            indexPregunta={indexPregunta}
          />
        );
      })}
    </>
  );
}

function RenderPregunta(props) {
  const {
    buscarRespuesta,
    inhabilitado,
    actualizarRespuesta,
    itemPregunta,
    indexProceso,
    indexAreaSalud,
    indexEspecialidad,
    indexSeccion,
    indexAreaPreguntas,
    indexPregunta,
  } = props;

  return (
    <Box key={indexPregunta}>
      <BarraTituloPreguntas
        uso={uso_barra_titulo.pregunta}
        inhabilitado={inhabilitado}
        actualizarRespuesta={actualizarRespuesta}
        itemPregunta={itemPregunta}
        indexProceso={indexProceso}
        indexAreaSalud={indexAreaSalud}
        indexEspecialidad={indexEspecialidad}
        indexSeccion={indexSeccion}
        indexAreaPreguntas={indexAreaPreguntas}
        indexPregunta={indexPregunta}
      />
      <Stack spacing={0.2}>
        {itemPregunta.textoPleno && (
          <PreguntaTextoPleno
            actualizarRespuesta={actualizarRespuesta}
            itemPregunta={itemPregunta}
            indexProceso={indexProceso}
            indexAreaSalud={indexAreaSalud}
            indexEspecialidad={indexEspecialidad}
            indexSeccion={indexSeccion}
            indexAreaPreguntas={indexAreaPreguntas}
            indexPregunta={indexPregunta}
          />
        )}
        {itemPregunta.opciones.length !== 0 && (
          <PreguntaOpciones
            inhabilitado={inhabilitado}
            actualizarRespuesta={actualizarRespuesta}
            itemPregunta={itemPregunta}
            indexProceso={indexProceso}
            indexAreaSalud={indexAreaSalud}
            indexEspecialidad={indexEspecialidad}
            indexSeccion={indexSeccion}
            indexAreaPreguntas={indexAreaPreguntas}
            indexPregunta={indexPregunta}
          />
        )}
        {itemPregunta.unidadMedida != null && (
          <PreguntaUnidadMedida
            inhabilitado={inhabilitado}
            actualizarRespuesta={actualizarRespuesta}
            itemPregunta={itemPregunta}
            indexProceso={indexProceso}
            indexAreaSalud={indexAreaSalud}
            indexEspecialidad={indexEspecialidad}
            indexSeccion={indexSeccion}
            indexAreaPreguntas={indexAreaPreguntas}
            indexPregunta={indexPregunta}
          />
        )}
        {itemPregunta.multimedia && (
          <PreguntaMultimedia
            inhabilitado={inhabilitado}
            actualizarRespuesta={actualizarRespuesta}
            itemPregunta={itemPregunta}
            indexProceso={indexProceso}
            indexAreaSalud={indexAreaSalud}
            indexEspecialidad={indexEspecialidad}
            indexSeccion={indexSeccion}
            indexAreaPreguntas={indexAreaPreguntas}
            indexPregunta={indexPregunta}
          />
        )}
        {itemPregunta.fecha && (
          <PreguntaFecha
            buscarRespuesta={buscarRespuesta}
            inhabilitado={inhabilitado}
            actualizarRespuesta={actualizarRespuesta}
            itemPregunta={itemPregunta}
            indexProceso={indexProceso}
            indexAreaSalud={indexAreaSalud}
            indexEspecialidad={indexEspecialidad}
            indexSeccion={indexSeccion}
            indexAreaPreguntas={indexAreaPreguntas}
            indexPregunta={indexPregunta}
          />
        )}
      </Stack>
    </Box>
  );
}
