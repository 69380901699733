import { Typography } from "@mui/material";

import theme from "../../utils/theme";

export default function ErrorMessageContainer(props) {
  const { children } = props;
  return (
    <Typography
      sx={{
        color: theme.palette.error.main,
        fontWeight: "bold",
        fontSize: 12,
        lineHeight: 1.2,
      }}
    >
      *{children}
    </Typography>
  );
}
