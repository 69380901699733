import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  AppBar,
  Button,
  Card,
  Grid,
  Stack,
  Divider,
  //TextField,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import Loading from "../components/Feedback/Loading";
import ModalContainer from "../components/Autenticados/ModalContainer";

import { API_URL } from "../utils/constants";

import useAuth from "../auth/useAuth";
import useGlobalDataContext from "../hooks/useGlobalDataContext";
import useToastContext from "../hooks/useToastContext";
import { Severities } from "../contexts/ToastContext";
import theme from "../utils/theme";

import InfoPaciente from "../components/Datos/FormularioClinico/InfoPaciente/InfoPaciente";

export default function ArchivoPage() {
  const [loading, setLoading] = useState(true);
  const [filas, setFilas] = useState([]);
  const [isOpenCambiarEdicion, setIsOpenCambiarEdicion] = useState(false);
  const [datosEdicion, setDatosEdicion] = useState({});
  const [isOpenTimeline, setIsOpenTimeline] = useState(false);
  const [textoFiltroPacientes, setTextoFiltroPacientes] = useState(null);
  const [inputFiltroPacientes, setInputFiltroPacientes] = useState(null);
  const [filtrarPacientes, setFiltrarPacientes] = useState(false);

  const auth = useAuth();
  const globalData = useGlobalDataContext();
  const addToast = useToastContext();

  //console.log(globalData.terminosLocalizados);

  const urlObtenerPacientes = `${API_URL()}/Pacientes/getPacientesCuenta`;

  const [textoBusquedaTimeline, setTextoBusquedaTimeline] = useState("");

  // Copiado desde ClinicFormPage - es necesario generalizar
  const [paciente, setPaciente] = useState({
    estado: {
      cargado: true,
      creando: false,
    },
  });

  // Estos textos deberían obtenerse desde el servidor según el idioma del usuario
  // const textos = {
  //   filtrar: "Filtrar",
  //   cancelarFiltro: "Borrar Filtro",
  //   cabeceraPrimerNombre: "Primer Nombre",
  //   cabeceraSegundoNombre: "Segundo Nombre",
  //   cabeceraPrimerApellido: "Primer Apellido",
  //   cabeceraSegundoApellido: "Segundo Apellido",
  //   cabeceraEstadoEdicion: "Estado de Edición",
  //   cabeceraCambiarEdicion: "Cambiar Edición",
  //   cabeceraAccederTimeline: "Acceder a Timeline",
  //   tituloCambiarEdicion: "Cambiar Edición de Paciente",
  //   estadoActivado: "Activado",
  //   estadoDesactivado: "Desactivado",
  //   activarEdicion: "Activar",
  //   desactivarEdicion: "Desactivar",
  //   cancelar: "Cancelar",
  //   cerrar: "Cerrar",
  //   accederTimeline: "Timeline",
  //   avisoDesactivar:
  //     "Al desactivar la edición no podrá publicar en la timeline " +
  //     "pero podrá seguir viendo el contenido de la misma.",
  //   avisoCambiosRestantes: {
  //     desactivarNoMas:
  //       "Con su suscripción actual no podrá volver a activar la edición",
  //     desactivarUnaMas:
  //       "Con su suscripción actual podrá volver a activar la edición solo una vez más",
  //     desactivarVariable:
  //       "Con su suscripción actual podrá volver a activar la edición {arg1} veces más",
  //     desactivarIlimitadas:
  //       "Su suscripción le permite volver a activar la edición cuando así lo desee",
  //     activarNoMas:
  //       "Con su suscripción actual no podrá desactivar y volver a activar la edición",
  //     activarUnaMas:
  //       "Con su suscripción actual podrá desactivar y volver a activar la edición solo una vez más",
  //     activarVariable:
  //       "Con su suscripción actual podrá desactivar y volver a activar la edición {arg1} veces más",
  //     activarIlimitadas:
  //       "Su suscripción le permite desactivar y volver a activar la edición cuando así lo desee.",
  //     activarProhibido:
  //       "Su suscripción actual ya no le permite activar la edición de este paciente.",
  //   },
  // };

  // Copiado desde ClinicFormPage - es necesario generalizar
  const buscarPaciente = async (guid) => {
    const request = {
      Token: auth.user.token,
      GuidPaciente: guid,
    };
    const url = `${API_URL()}/Pacientes/getPacienteByGuid`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(request),
    };
    try {
      const res = await fetch(url, options);
      if (res.ok) {
        const json = await res.json();
        if (json.resultadoOK) {
          setPaciente((prevState) => ({
            ...prevState,
            estado: { cargado: true, creando: false },
            // En esta instancia, por ahora, no se está devolviendo el documento de identidad
            //paisDocumento: data.CodigoPaisDocumento,
            //tipoDocumento: data.TipoDocumento,
            //numeroDocumento: data.NumeroDocumentoIdentidad,
            nombre: json.nombre,
            nombreCompleto: json.nombreCompleto,
            edad: json.edad,
            ultimaVisita: json.ultimaVisita,
            timeline: json.timeline,
          }));
          setIsOpenTimeline(true);
        } else {
          json.errores.forEach((item) => {
            addToast(item, Severities.error);
          });
        }
      } else {
        addToast("Error de servicio.", Severities.error);
      }
    } catch (err) {
      addToast("Error de conexión", Severities.error);
    }
  };

  // Obtener lista de pacientes
  useEffect(() => {
    (async () => {
      const request = {
        Token: auth.user.token,
        GuidCuentaAdicional: auth.cuentaActiva.guid,
        TextoBusqueda: textoFiltroPacientes,
      };
      try {
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(request),
        };
        const res = await fetch(urlObtenerPacientes, options);
        if (res.ok) {
          const json = await res.json();
          if (json.resultadoOK) {
            let pacientes = [];
            json.lista.forEach((item) => {
              let nuevaFila = {
                guid: item.guid,
                primerNombre: item.datosUsuario.primerNombre,
                segundoNombre: item.datosUsuario.segundoNombre,
                primerApellido: item.datosUsuario.primerApellido,
                segundoApellido: item.datosUsuario.segundoApellido,
                estadoEdicion: item.datosUsuario.permisoEdicionProfesional
                  ? // ? textos.estadoActivado
                    // : textos.estadoDesactivado,
                    globalData.terminosLocalizados.EST001
                  : globalData.terminosLocalizados.EST002,
                permisoEdicionProfesional: item.permisoEdicionProfesional,
                cambiosRestantes: item.cambiosRestantes,
              };
              pacientes.push(nuevaFila);
            });
            setFilas(pacientes);
          } else {
            json.errores.forEach((item) => {
              addToast(item, Severities.error);
            });
          }
          setLoading(false);
          setFiltrarPacientes(false);
        } else {
          addToast("Error de servicio.", Severities.error);
          setLoading(false);
        }
        return;
      } catch (err) {
        addToast("Error de conexión", Severities.error);
      }
    })();
  }, [
    addToast,
    auth.cuentaActiva.guid,
    auth.user.token,
    urlObtenerPacientes,
    filtrarPacientes,
    textoFiltroPacientes,
    // textos.estadoActivado,
    // textos.estadoDesactivado,
    globalData.terminosLocalizados,
  ]);

  // Actualizar los permisos de una fila
  const actualizarPermisoFila = useCallback(
    function actualizarPermisoFila(guid, permiso) {
      const nuevaListaFilas = filas.map((item) => {
        if (item.guid === guid) {
          let cambiosRestantes = permiso
            ? item.cambiosRestantes - 1
            : item.cambiosRestantes;
          let filaCambiada = {
            ...item,
            permisoEdicionProfesional: permiso,
            cambiosRestantes: cambiosRestantes,
          };
          return filaCambiada;
        }
        return item;
      });
      setFilas(nuevaListaFilas);
    },
    [filas]
  );

  // Modificar el permiso de edición de un paciente
  const cambiarEdicion = useCallback(async () => {
    // Aquí se debería preguntar si hay datos o validar
    //if (!datos) return;
    //event.preventDefault();
    try {
      let url = `${API_URL()}/Pacientes/putEdicionPaciente`;
      const nuevoPermiso = !datosEdicion.permisoEdicionProfesional;
      let request = {
        Token: auth.user.token,
        Guid: datosEdicion.guid,
        Permiso: nuevoPermiso,
      };
      const options = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(request),
      };
      const res = await fetch(url, options);
      if (res.ok) {
        const json = await res.json();
        if (json.resultadoOK) {
          //auth.setAdditionalAccounts(json.cuentasAdicionales);
          addToast(
            datosEdicion.permisoEdicionProfesional
              ? "Se ha desactivado la edición del paciente"
              : "Se ha activado la edición del paciente",
            Severities.info
          );
          actualizarPermisoFila(datosEdicion.guid, nuevoPermiso);
          setDatosEdicion((previous) => {
            return { ...previous, permisoEdicionProfesional: nuevoPermiso };
          });
          setIsOpenCambiarEdicion(false);
        } else {
          json.errores.forEach((item) => {
            addToast(item, Severities.error);
          });
        }
      } else {
        addToast("Error de servicio.", Severities.error);
      }
    } catch (err) {
      addToast("Error de conexión", Severities.error);
    }
  }, [
    auth,
    addToast,
    datosEdicion,
    //datosEdicion.guid,
    //datosEdicion.permisoEdicionProfesional,
    actualizarPermisoFila,
  ]);

  function obtenerTextoActivacion(cambiosRestantes, permiso) {
    if (permiso) {
      // Está activado -> se va a desactivar
      if (cambiosRestantes === 0) {
        // No qudan cambios restantes. Si desactiva ya no podrá volver a activar este paciente.
        //return textos.avisoCambiosRestantes.desactivarNoMas;
        return globalData.terminosLocalizados.AVI017;
      } else {
        if (cambiosRestantes === 1) {
          // Si desactiva le queda solo una activación más para este paciente
          // return textos.avisoCambiosRestantes.desactivarUnaMas;
          return globalData.terminosLocalizados.AVI014;
        } else {
          if (cambiosRestantes > 1) {
            // Si desactiva le quedan "n" activaciones más para este paciente
            // return textos.avisoCambiosRestantes.desactivarVariable.replace(
            //   "{arg1}",
            //   cambiosRestantes
            // );
            return globalData.terminosLocalizados.AVI015.replace(
              "{arg1}",
              cambiosRestantes
            );
          } else {
            // Es negativo: no tiene limitaciones
            //return textos.avisoCambiosRestantes.desactivarIlimitadas;
            return globalData.terminosLocalizados.AVI016;
          }
        }
      }
    } else {
      // Está desactivado -> se va a activar
      if (cambiosRestantes === 1) {
        //return textos.avisoCambiosRestantes.activarNoMas;
        return globalData.terminosLocalizados.AVI017;
      } else {
        if (cambiosRestantes === 2) {
          //return textos.avisoCambiosRestantes.activarUnaMas;
          return globalData.terminosLocalizados.AVI018;
        } else {
          if (cambiosRestantes > 2) {
            // return textos.avisoCambiosRestantes.activarVariable.replace(
            //   "{arg1}",
            //   cambiosRestantes - 1
            // );
            return globalData.terminosLocalizados.AVI019.replace(
              "{arg1}",
              cambiosRestantes - 1
            );
          } else {
            if (cambiosRestantes === 0) {
              // return textos.avisoCambiosRestantes.activarProhibido;
              return globalData.terminosLocalizados.AVI021;
            } else {
              // return textos.avisoCambiosRestantes.activarIlimitadas;
              return globalData.terminosLocalizados.AVI020;
            }
          }
        }
      }
    }
  }

  // Estos textos deberían obtenerse desde el servidor según el idioma del usuario
  // Siendo de uso general tal vez deberían estar definidos a nivel global
  const textosDataGrid = {
    // Root
    noRowsLabel: "Sin registros",
    noResultsOverlayLabel: "No se encontraron resultados",
    errorOverlayDefaultLabel: "Ha ocurrido un error",
    // Density selector toolbar button text
    toolbarDensity: "Densidad",
    toolbarDensityLabel: "Densidad",
    toolbarDensityCompact: "Compacto",
    toolbarDensityStandard: "Estándar",
    toolbarDensityComfortable: "Confortable",
    // Columns selector toolbar button text
    toolbarColumns: "Columnas",
    toolbarColumnsLabel: "Seleccionar columnas",
    // Filters toolbar button text
    toolbarFilters: "Filtros",
    toolbarFiltersLabel: "Mostrar filtros",
    toolbarFiltersTooltipHide: "Ocultar filtros",
    toolbarFiltersTooltipShow: "Mostrar filtros",
    toolbarFiltersTooltipActive: (count) =>
      count !== 1 ? `${count} filtros activos` : `${count} filtro activo`,
    // Quick filter toolbar field
    toolbarQuickFilterPlaceholder: "Buscar...",
    toolbarQuickFilterLabel: "Buscar",
    toolbarQuickFilterDeleteIconLabel: "Limpiar",
    // Export selector toolbar button text
    toolbarExport: "Exportar",
    toolbarExportLabel: "Exportar",
    toolbarExportCSV: "Descargar como CSV",
    toolbarExportPrint: "Imprimir",
    toolbarExportExcel: "Descargar como Excel",
    // Columns panel text
    columnsPanelTextFieldLabel: "Encontrar columna",
    columnsPanelTextFieldPlaceholder: "Título de la columna",
    columnsPanelDragIconLabel: "Reordenar columna",
    columnsPanelShowAllButton: "Mostrar todas",
    columnsPanelHideAllButton: "Ocultar todas",
    // Filter panel text
    filterPanelAddFilter: "Agregar filtro",
    filterPanelDeleteIconLabel: "Quitar",
    filterPanelLinkOperator: "Operador lógico",
    filterPanelOperators: "Operador",
    filterPanelOperatorAnd: "y",
    filterPanelOperatorOr: "o",
    filterPanelColumns: "Columnas",
    filterPanelInputLabel: "Valor",
    filterPanelInputPlaceholder: "Valor de filtro",
    // Filter operators text
    filterOperatorContains: "contiene",
    filterOperatorEquals: "es igual a",
    filterOperatorStartsWith: "comienza con",
    filterOperatorEndsWith: "termina con",
    filterOperatorIs: "es",
    filterOperatorNot: "no es",
    filterOperatorAfter: "está después de",
    filterOperatorOnOrAfter: "está en o después de",
    filterOperatorBefore: "está antes de",
    filterOperatorOnOrBefore: "está en o antes de",
    filterOperatorIsEmpty: "está vacío",
    filterOperatorIsNotEmpty: "no está vacío",
    filterOperatorIsAnyOf: "es alguno de",
    // Filter values text
    filterValueAny: "cualquiera",
    filterValueTrue: "verdadero",
    filterValueFalse: "falso",
    // Column menu text
    columnMenuLabel: "Menú",
    columnMenuShowColumns: "Mostrar",
    columnMenuFilter: "Filtrar",
    columnMenuHideColumn: "Ocultar",
    columnMenuUnsort: "Sin ordenar",
    columnMenuSortAsc: "Ascendente",
    columnMenuSortDesc: "Descendente",
    // Column header text
    columnHeaderFiltersTooltipActive: (count) =>
      count !== 1 ? `${count} filtros activos` : `${count} filtro activo`,
    columnHeaderFiltersLabel: "Mostrar filtros",
    columnHeaderSortIconLabel: "Ordenar",
    // Rows selected footer text
    footerRowSelected: (count) =>
      count !== 1
        ? `${count.toLocaleString()} filas seleccionadas`
        : `${count.toLocaleString()} fila seleccionada`,
    footerTotalRows: "Total de filas:",
    // Total visible row amount footer text
    footerTotalVisibleRows: (visibleCount, totalCount) =>
      `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,
    // Checkbox selection text
    checkboxSelectionHeaderName: "Selección",
    checkboxSelectionSelectAllRows: "Seleccionar todas las filas",
    checkboxSelectionUnselectAllRows: "Descartar todas las filas",
    checkboxSelectionSelectRow: "Seleccionar fila",
    checkboxSelectionUnselectRow: "Descartar fila",
    // Boolean cell text
    booleanCellTrueLabel: "sí",
    booleanCellFalseLabel: "no",
    // Actions cell more text
    actionsCellMore: "más",
    // Column pinning text
    pinToLeft: "Fijar a la izquierda",
    pinToRight: "Fijar a la derecha",
    unpin: "No fijar",
    // Tree Data
    treeDataGroupingHeaderName: "Grupo",
    treeDataExpand: "ver descendientes",
    treeDataCollapse: "ocultar descendientes",
    // Grouping columns
    groupingColumnHeaderName: "Agrupar",
    groupColumn: (name) => `Agrupar por ${name}`,
    unGroupColumn: (name) => `Dejar de agrupar por ${name}`,
    // Master/detail
    detailPanelToggle: "Alternar panel",
    expandDetailPanel: "Extender",
    collapseDetailPanel: "Resumir",
    // MuiTablePagination: {
    //   labelDisplayedRows: ({ from, to, count }) =>
    //     `${from}-${to} de ${count}`,
    // },
    // Row reordering text
    rowReorderingHeaderName: "Reordenar filas",
    // Aggregation
    aggregationMenuItemHeader: "Acumulación",
    aggregationFunctionLabelSum: "suma",
    aggregationFunctionLabelAvg: "promedio",
    aggregationFunctionLabelMin: "mínimo",
    aggregationFunctionLabelMax: "máximo",
    aggregationFunctionLabelSize: "tamaño",
  };

  const textosLocalizadosGrilla = {
    // Root
    noRowsLabel: textosDataGrid.noRowsLabel,
    noResultsOverlayLabel: textosDataGrid.noResultsOverlayLabel,
    errorOverlayDefaultLabel: textosDataGrid.errorOverlayDefaultLabel,
    // Density selector toolbar button text
    toolbarDensity: textosDataGrid.toolbarDensity,
    toolbarDensityLabel: textosDataGrid.toolbarDensityLabel,
    toolbarDensityCompact: textosDataGrid.toolbarDensityCompact,
    toolbarDensityStandard: textosDataGrid.toolbarDensityStandard,
    toolbarDensityComfortable: textosDataGrid.toolbarDensityComfortable,
    // Columns selector toolbar button text
    toolbarColumns: textosDataGrid.toolbarColumns,
    toolbarColumnsLabel: textosDataGrid.toolbarColumnsLabel,
    // Filters toolbar button text
    toolbarFilters: textosDataGrid.toolbarFilters,
    toolbarFiltersLabel: textosDataGrid.toolbarFiltersLabel,
    toolbarFiltersTooltipHide: textosDataGrid.toolbarFiltersTooltipHide,
    toolbarFiltersTooltipShow: textosDataGrid.toolbarFiltersTooltipShow,
    toolbarFiltersTooltipActive: textosDataGrid.toolbarFiltersTooltipActive,
    // Quick filter toolbar field
    toolbarQuickFilterPlaceholder: textosDataGrid.toolbarQuickFilterPlaceholder,
    toolbarQuickFilterLabel: textosDataGrid.toolbarQuickFilterLabel,
    toolbarQuickFilterDeleteIconLabel:
      textosDataGrid.toolbarQuickFilterDeleteIconLabel,
    // Export selector toolbar button text
    toolbarExport: textosDataGrid.toolbarExport,
    toolbarExportLabel: textosDataGrid.toolbarExportLabel,
    toolbarExportCSV: textosDataGrid.toolbarExportCSV,
    toolbarExportPrint: textosDataGrid.toolbarExportPrint,
    toolbarExportExcel: textosDataGrid.toolbarExportExcel,
    // Columns panel text
    columnsPanelTextFieldLabel: textosDataGrid.columnsPanelTextFieldLabel,
    columnsPanelTextFieldPlaceholder:
      textosDataGrid.columnsPanelTextFieldPlaceholder,
    columnsPanelDragIconLabel: textosDataGrid.columnsPanelDragIconLabel,
    columnsPanelShowAllButton: textosDataGrid.columnsPanelShowAllButton,
    columnsPanelHideAllButton: textosDataGrid.columnsPanelHideAllButton,
    // Filter panel text
    filterPanelAddFilter: textosDataGrid.filterPanelAddFilter,
    filterPanelDeleteIconLabel: textosDataGrid.filterPanelDeleteIconLabel,
    filterPanelLinkOperator: textosDataGrid.filterPanelLinkOperator,
    filterPanelOperators: textosDataGrid.filterPanelOperators,
    filterPanelOperatorAnd: textosDataGrid.filterPanelOperatorAnd,
    filterPanelOperatorOr: textosDataGrid.filterPanelOperatorOr,
    filterPanelColumns: textosDataGrid.filterPanelColumns,
    filterPanelInputLabel: textosDataGrid.filterPanelInputLabel,
    filterPanelInputPlaceholder: textosDataGrid.filterPanelInputPlaceholder,
    // Filter operators text
    filterOperatorContains: textosDataGrid.filterOperatorContains,
    filterOperatorEquals: textosDataGrid.filterOperatorEquals,
    filterOperatorStartsWith: textosDataGrid.filterOperatorStartsWith,
    filterOperatorEndsWith: textosDataGrid.filterOperatorEndsWith,
    filterOperatorIs: textosDataGrid.filterOperatorIs,
    filterOperatorNot: textosDataGrid.filterOperatorNot,
    filterOperatorAfter: textosDataGrid.filterOperatorAfter,
    filterOperatorOnOrAfter: textosDataGrid.filterOperatorOnOrAfter,
    filterOperatorBefore: textosDataGrid.filterOperatorBefore,
    filterOperatorOnOrBefore: textosDataGrid.filterOperatorOnOrBefore,
    filterOperatorIsEmpty: textosDataGrid.filterOperatorIsEmpty,
    filterOperatorIsNotEmpty: textosDataGrid.filterOperatorIsNotEmpty,
    filterOperatorIsAnyOf: textosDataGrid.filterOperatorIsAnyOf,
    // Filter values text
    filterValueAny: textosDataGrid.filterValueAny,
    filterValueTrue: textosDataGrid.filterValueTrue,
    filterValueFalse: textosDataGrid.filterValueFalse,
    // Column menu text
    columnMenuLabel: textosDataGrid.columnMenuLabel,
    columnMenuShowColumns: textosDataGrid.columnMenuShowColumns,
    columnMenuFilter: textosDataGrid.columnMenuFilter,
    columnMenuHideColumn: textosDataGrid.columnMenuHideColumn,
    columnMenuUnsort: textosDataGrid.columnMenuUnsort,
    columnMenuSortAsc: textosDataGrid.columnMenuSortAsc,
    columnMenuSortDesc: textosDataGrid.columnMenuSortDesc,
    // Column header text
    columnHeaderFiltersTooltipActive:
      textosDataGrid.columnHeaderFiltersTooltipActive,
    columnHeaderFiltersLabel: textosDataGrid.columnHeaderFiltersLabel,
    columnHeaderSortIconLabel: textosDataGrid.columnHeaderSortIconLabel,
    // Rows selected footer text
    footerRowSelected: textosDataGrid.footerRowSelected,
    footerTotalRows: textosDataGrid.footerTotalRows,
    // Total visible row amount footer text
    footerTotalVisibleRows: textosDataGrid.footerTotalVisibleRows,
    // Checkbox selection text
    checkboxSelectionHeaderName: textosDataGrid.checkboxSelectionHeaderName,
    checkboxSelectionSelectAllRows:
      textosDataGrid.checkboxSelectionSelectAllRows,
    checkboxSelectionUnselectAllRows:
      textosDataGrid.checkboxSelectionUnselectAllRows,
    checkboxSelectionSelectRow: textosDataGrid.checkboxSelectionSelectRow,
    checkboxSelectionUnselectRow: textosDataGrid.checkboxSelectionUnselectRow,
    // Boolean cell text
    booleanCellTrueLabel: textosDataGrid.booleanCellTrueLabel,
    booleanCellFalseLabel: textosDataGrid.booleanCellFalseLabel,
    // Actions cell more text
    actionsCellMore: textosDataGrid.actionsCellMore,
    // Column pinning text
    pinToLeft: textosDataGrid.pinToLeft,
    pinToRight: textosDataGrid.pinToRight,
    unpin: textosDataGrid.unpin,
    // Tree Data
    treeDataGroupingHeaderName: textosDataGrid.treeDataGroupingHeaderName,
    treeDataExpand: textosDataGrid.treeDataExpand,
    treeDataCollapse: textosDataGrid.treeDataCollapse,
    // Grouping columns
    groupingColumnHeaderName: textosDataGrid.groupingColumnHeaderName,
    groupColumn: textosDataGrid.groupColumn,
    // Master/detail
    detailPanelToggle: textosDataGrid.detailPanelToggle,
    expandDetailPanel: textosDataGrid.expandDetailPanel,
    collapseDetailPanel: textosDataGrid.collapseDetailPanel,
    // Si utilizo una función, como en el caso de footerRowSelected, ocurre un error.
    // Tal vez sea porque son diferentes componentes, y tal vez TablePagination no acepte
    // esta invocación.
    // Used core components translation keys
    MuiTablePagination: {
      labelDisplayedRows: ({ from, to, count }) => `${from}-${to} de ${count}`,
    },
    // Row reordering text
    rowReorderingHeaderName: textosDataGrid.rowReorderingHeaderName,
    // Aggregation
    aggregationMenuItemHeader: textosDataGrid.aggregationMenuItemHeader,
    aggregationFunctionLabelSum: textosDataGrid.aggregationFunctionLabelSum,
    aggregationFunctionLabelAvg: textosDataGrid.aggregationFunctionLabelAvg,
    aggregationFunctionLabelMin: textosDataGrid.aggregationFunctionLabelMin,
    aggregationFunctionLabelMax: textosDataGrid.aggregationFunctionLabelMax,
    aggregationFunctionLabelSize: textosDataGrid.aggregationFunctionLabelSize,
  };

  const columnas = [
    {
      field: "primerNombre",
      //headerName: textos.cabeceraPrimerNombre,
      headerName: globalData.terminosLocalizados.ETI009,
      width: 150,
      editable: true,
    },
    {
      field: "segundoNombre",
      //headerName: textos.cabeceraSegundoNombre,
      headerName: globalData.terminosLocalizados.ETI010,
      width: 150,
      editable: true,
    },
    {
      field: "primerApellido",
      //headerName: textos.cabeceraPrimerApellido,
      headerName: globalData.terminosLocalizados.ETI011,
      width: 150,
      editable: true,
    },
    {
      field: "segundoApellido",
      //headerName: textos.cabeceraSegundoApellido,
      headerName: globalData.terminosLocalizados.ETI012,
      width: 150,
      editable: true,
    },
    {
      field: "estadoEdicion",
      //headerName: textos.cabeceraEstadoEdicion,
      headerName: globalData.terminosLocalizados.ETI013,
      width: 200,
    },
    {
      field: "cambiarEdicion",
      //headerName: textos.cabeceraCambiarEdicion,
      headerName: globalData.terminosLocalizados.ETI014,
      width: 200,
      renderCell: (cellValues) => {
        var detalle,
          color,
          backgroundColor,
          backgroundColorHover,
          backgroundColorFocus;
        if (cellValues.row.permisoEdicionProfesional) {
          //detalle = textos.desactivarEdicion;
          detalle = globalData.terminosLocalizados.COM009;
          color = theme.palette.secondary.contrastText;
          backgroundColor = theme.palette.secondary.main;
          backgroundColorHover = theme.palette.secondary.dark;
          backgroundColorFocus = theme.palette.secondary.dark;
        } else {
          //detalle = textos.activarEdicion;
          detalle = globalData.terminosLocalizados.COM008;
          color = theme.palette.primary.contrastText;
          backgroundColor = theme.palette.primary.main;
          backgroundColorHover = theme.palette.primary.dark;
          backgroundColorFocus = theme.palette.primary.dark;
        }
        return (
          <Button
            variant="contained"
            //color="secondary"
            onClick={(event) => {
              handleClickAbrirEdicion(event, cellValues);
            }}
            disabled={
              !cellValues.row.permisoEdicionProfesional &&
              cellValues.row.cambiosRestantes === 0
            }
            sx={{
              width: "350px",
              textTransform: "none",
              color: color,
              backgroundColor: backgroundColor,
              ":hover": { backgroundColor: backgroundColorHover },
              ":focus": { backgroundColor: backgroundColorFocus },
            }}
          >
            {detalle}
          </Button>
        );
      },
    },
    {
      field: "timeline",
      //headerName: textos.cabeceraAccederTimeline,
      headerName: globalData.terminosLocalizados.ETI015,
      width: 200,
      renderCell: (cellValues) => {
        return (
          <Button
            variant="contained"
            //color="secondary"
            onClick={(event) => {
              handleClickAbrirTimeline(event, cellValues);
            }}
            // disabled={
            //   !cellValues.row.permisoEdicionProfesional &&
            //   cellValues.row.cambiosRestantes === 0
            // }
            sx={{
              width: "350px",
              textTransform: "none",
              color: theme.palette.primary.contrastText,
              backgroundColor: theme.palette.primary.main,
              ":hover": { backgroundColor: theme.palette.primary.dark },
              ":focus": { backgroundColor: theme.palette.primary.dark },
            }}
          >
            {/* {textos.accederTimeline} */}
            {globalData.terminosLocalizados.COM012}
          </Button>
        );
      },
    },
  ];

  function obtenerNombreCompleto(
    primerNombre,
    segundoNombre,
    primerApellido,
    segundoApellido
  ) {
    var nombreCompleto;
    var nombre = primerNombre;
    if (!nombre) {
      nombre = segundoNombre;
    }
    var apellido = primerApellido;
    if (!apellido) {
      apellido = segundoApellido;
    }
    if (nombre && apellido) {
      nombreCompleto = nombre + " " + apellido;
    } else {
      if (nombre) {
        nombreCompleto = nombre;
      } else {
        nombreCompleto = apellido;
      }
    }
    return nombreCompleto;
  }

  const handleChangeBusqueda = (event) => {
    //setTextoFiltroPacientes(event.target.value);
    setInputFiltroPacientes(event.target.value);
  };

  const handleClickFiltrar = () => {
    setTextoFiltroPacientes(inputFiltroPacientes);
    setFiltrarPacientes(true);
  };

  const handleClickCancelarFiltro = (event, params) => {
    setTextoFiltroPacientes("");
    setFiltrarPacientes(true);
  };

  const handleClickAbrirEdicion = (event, params) => {
    event.stopPropagation();
    //console.log(params);
    var nombreCompleto = obtenerNombreCompleto(
      params.row.primerNombre,
      params.row.segundoNombre,
      params.row.primerApellido,
      params.row.segundoApellido
    );
    setDatosEdicion({
      guid: params.row.guid,
      nombreCompleto: nombreCompleto,
      permisoEdicionProfesional: params.row.permisoEdicionProfesional,
      cambiosRestantes: params.row.cambiosRestantes,
      detallePermisoEdicionProfesional: params.row.permisoEdicionProfesional
        ? // ? textos.estadoActivado
          // : textos.estadoDesactivado,
          globalData.terminosLocalizados.EST001
        : globalData.terminosLocalizados.EST002,
      //avisoDesactivar: textos.avisoDesactivar,
      avisoDesactivar: globalData.terminosLocalizados.AVI012,
      avisoCambiosRestantes: obtenerTextoActivacion(
        params.row.cambiosRestantes,
        params.row.permisoEdicionProfesional
      ),
      // avisoCambiosRestantes: textos.avisoCambiosRestantes(
      //   params.row.cambiosRestantes,
      //   params.row.permisoEdicionProfesional
      // ),
      accionCambiar: params.row.permisoEdicionProfesional
        ? // ? textos.desactivarEdicion
          // : textos.activarEdicion,
          globalData.terminosLocalizados.COM009
        : globalData.terminosLocalizados.COM008,
      //accionCancelar: textos.cancelar,
      accionCancelar: globalData.terminosLocalizados.COM010,
    });
    setIsOpenCambiarEdicion(true);
  };

  const handleCambiarEdicion = () => {
    cambiarEdicion();
  };

  const handleCloseCambiarEdicion = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    setIsOpenCambiarEdicion(false);
  };

  if (loading) {
    return <Loading />;
  }

  const handleClickAbrirTimeline = (event, params) => {
    const guidPaciente = params.row.guid;
    //alert("handleClickAbrirTimeline " + guidPaciente);
    buscarPaciente(guidPaciente);
    //setIsOpenTimeline(true);
  };

  const handleCloseTimeline = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    setIsOpenTimeline(false);
  };

  return (
    <>
      <AppBar position="static" width="100%">
        <Typography variant="h5">Archivo de Pacientes</Typography>
      </AppBar>
      {/* <Box sx={{ height: 400, width: "100%" }}> */}

      <Box className="search" padding={1}>
        <Box display={"flex"} justifyContent={"space-between"} paddingX={1}>
          <Box
            //paddingX={0.5}
            flexGrow={1}
          >
            <input
              width={"100%"}
              className="inputText"
              type="text"
              id="SearchText"
              //placeholder={textos.filtrar}
              placeholder={globalData.terminosLocalizados.ETI041}
              onChange={handleChangeBusqueda}
              value={textoFiltroPacientes}
            />
          </Box>
          <Box
            className="searchButtonContainer"
            paddingLeft={1}
            paddingY={0}
            flexGrow={1}
            display="flex"
            alignItems={"center"}
          >
            <Button
              sx={{ height: "30px", width: "150px" }}
              onClick={handleClickFiltrar}
            >
              {/* {textos.filtrar} */}
              {globalData.terminosLocalizados.COM006}
            </Button>
            <Button
              sx={{ height: "30px", width: "150px", marginLeft: "5px" }}
              onClick={handleClickCancelarFiltro}
            >
              {/* {textos.cancelarFiltro} */}
              {globalData.terminosLocalizados.COM007}
            </Button>
          </Box>
        </Box>
      </Box>

      <DataGrid
        localeText={textosLocalizadosGrilla}
        rows={filas}
        columns={columnas}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
        disableSelecionOnClick
        getRowId={(fila) => fila.guid}
        isCellEditable={(params) => false}
        isRowSelectable={(params) => true}
        editMode="row"
        //disableColumnSelector
        //disableColumnFilter
        //disableColumnMenu
        //disableColumnSelector
      />
      <ModalContainer
        isOpenModal={isOpenCambiarEdicion}
        closeModal={handleCloseCambiarEdicion}
      >
        <Card
          sx={{
            p: 2,
            width: 400,
            borderRadius: "10px",
          }}
        >
          <Box>
            {/* <Typography variant="h5">{textos.tituloCambiarEdicion}</Typography> */}
            <Typography variant="h5">
              {globalData.terminosLocalizados.ETI016}
            </Typography>
          </Box>
          <Divider />
          <Grid container marginY={1}>
            <Grid item md={12}>
              <Stack direction={"row"}>
                {/* <Typography width={"80px"}>Paciente:</Typography> */}
                <Typography width={"80px"}>
                  {globalData.terminosLocalizados.ETI123}:
                </Typography>
                <Typography fontWeight={"bold"}>
                  {datosEdicion.nombreCompleto}
                </Typography>
              </Stack>
            </Grid>
            <Grid item md={12}>
              <Stack direction={"row"}>
                <Typography width={"80px"}>
                  {globalData.terminosLocalizados.ETI124}:
                </Typography>
                <Typography fontWeight={"bold"}>
                  {datosEdicion.detallePermisoEdicionProfesional}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Divider />
          <Grid marginY={1} container rowSpacing={1}>
            <Grid item md={12}>
              <Typography fontStyle={"italic"}>
                {datosEdicion.avisoDesactivar}
              </Typography>
            </Grid>
            <Grid item md={12}>
              <Typography fontStyle={"italic"}>
                {datosEdicion.avisoCambiosRestantes}
              </Typography>
            </Grid>
            <Grid item md={12}></Grid>
          </Grid>
          <Divider />
          <Stack
            marginTop={1}
            direction={"row"}
            spacing={1}
            justifyContent="flex-end"
          >
            <Button variant="CommandBar2" onClick={handleCambiarEdicion}>
              {datosEdicion.accionCambiar}
            </Button>
            <Button variant="CommandBar2" onClick={handleCloseCambiarEdicion}>
              {datosEdicion.accionCancelar}
            </Button>
          </Stack>
        </Card>
      </ModalContainer>
      <ModalContainer
        isOpenModal={isOpenTimeline}
        closeModal={handleCloseTimeline}
      >
        <Box
          id="archivoPageBox1"
          backgroundColor="white"
          sx={{
            p: 2,
            //width: "100%",
            borderRadius: "10px",
          }}
        >
          <Box
            id="archivoPageBox2"
            paddingBottom={2}
            height={500}
            width={500}
            display={"flex"}
            justifyContent={"center"}
          >
            <InfoPaciente
              //categorias={categorias}
              //setCategorias={setCategorias}
              textoBusqueda={textoBusquedaTimeline}
              setTextoBusqueda={setTextoBusquedaTimeline}
              //codigosColores={codigosColores}
              paciente={paciente}
              setPaciente={setPaciente}
              pacienteFijo={true}
              readOnly={true}
            ></InfoPaciente>
          </Box>
          <Divider />
          <Stack
            marginTop={1}
            direction={"row"}
            spacing={1}
            justifyContent="flex-end"
          >
            <Button variant="CommandBar2" onClick={handleCloseTimeline}>
              {/* {textos.cerrar} */}
              {globalData.terminosLocalizados.COM011}
            </Button>
          </Stack>
        </Box>
      </ModalContainer>
    </>
  );
}
