import React, { useCallback } from "react";
import { useForm, Controller } from "react-hook-form";

import { TextField, Card, Typography, Stack, Button } from "@mui/material";

import ErrorSummary from "../Forms/ErrorSummary";

import { API_URL } from "../../utils/constants";
import useToastContext from "../../hooks/useToastContext";
import { Severities } from "../../contexts/ToastContext";

export default function ForgotPassword(props) {
  const { close, terminos } = props;

  const addToast = useToastContext();

  // Atención: los términos localizads todavía no están cargados, porque se cargan después de que el
  // usuario inicia sesión, una vez que se conoce su idioma.
  // Debería implementarse una carga previa de algunos términos según el idioma del navegador.
  const textos = {
    contraseñaEnviada: terminos.AVI037,
    enviar: terminos.COM003,
    cancelar: terminos.COM004,
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const cancelar = () => {
    close();
  };

  // En esta estructura se tienen los nombres de los campos en el idioma del usuario
  // Se recibirán del servidor cuando esté implementado el multi-lenguaje.
  const descripcionCampos = {
    Email: terminos.ETI004,
  };

  // Enviar nueva contraseña
  const enviarPassword = useCallback(
    async (data) => {
      // Aquí se debería preguntar si hay datos o validar
      //if (!datos) return;
      //event.preventDefault();
      let url = `${API_URL()}/Users/forgotPassword`;
      let request = {
        Email: data.Email,
      };
      try {
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(request),
        };
        const res = await fetch(url, options);
        if (res.ok) {
          const json = await res.json();
          if (json.resultadoOK) {
            //addToast("Contraseña enviada", Severities.info);
            addToast(textos.contraseñaEnviada, Severities.info);
            close();
          } else {
            json.errores.forEach((item) => {
              addToast(item, Severities.error);
            });
          }
        } else {
          addToast(terminos.AVI004, Severities.error);
        }
      } catch (err) {
        addToast(terminos.AVI005, Severities.error);
      }
    },
    [
      addToast,
      terminos.AVI004,
      terminos.AVI005,
      textos.contraseñaEnviada,
      close,
    ]
  );

  return (
    <Card
      id="forgotPassContainer"
      sx={{
        p: 2,
        width: 400,
        borderRadius: "10px",
      }}
    >
      <form onSubmit={handleSubmit(enviarPassword)}>
        <Stack id="forgotPassStack" spacing={1}>
          <Typography variant="h5">{terminos.ETI121}</Typography>
          <Controller
            name="Email"
            control={control}
            rules={{
              required: {
                value: true,
              },
              pattern: {
                value:
                  /^([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/, ///^(\[[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.)|(())([a-zA-Z]{2,4}|[0-9]{1,3})$/,
              },
              maxLength: {
                value: 255,
              },
            }}
            render={({ field }) => (
              <TextField
                type="text"
                size="small"
                name="Email"
                label={descripcionCampos.Email}
                sx={{ width: "100%" }}
                {...field}
              />
            )}
          ></Controller>
          <Stack
            id="commandBarStack"
            direction={"row"}
            spacing={1}
            justifyContent="flex-end"
          >
            <Button variant="CommandBar2" type="submit">
              {/* Enviar */}
              {textos.enviar}
            </Button>
            <Button variant="CommandBar2" onClick={cancelar}>
              {/* Cancelar */}
              {textos.cancelar}
            </Button>
          </Stack>
          <ErrorSummary
            errors={errors}
            descripciones={descripcionCampos}
          ></ErrorSummary>
        </Stack>
      </form>
    </Card>
  );
}
