import React from "react";

import { Box, Typography } from "@mui/material";

import theme from "../../../../utils/theme";

export default function CabeceraFormulario(props) {
  const { titulo } = props;

  return (
    <Box
      bgcolor={theme.palette.terciary.dark}
      color={theme.palette.terciary.contrastText}
      borderRadius={"10px 10px 0 0"}
      width={"100%"}
      padding={1}
    >
      <Typography fontSize={14}>{titulo}</Typography>
    </Box>
  );
}
