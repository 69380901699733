import React from "react";
import { Box, Typography } from "@mui/material";

import ComandoEstadoElemento from "./ComandoEstadoElemento";
import { const_tipoPublicacion } from "../../../../../utils/constants";
import theme from "../../../../../utils/theme";

export default function ElementoTimeline(props) {
  const { item, cambiarEstado, sxBox, sxAutor } = props;
  if (item.tipoPublicacion === const_tipoPublicacion.REGISTRO_ANTIGUO) {
    sxAutor.color = theme.palette.timeline.apuntesProfesional;
  }
  return (
    <Box padding={1}>
      <Box
        display="flex"
        paddingLeft={0}
        paddingY={1}
        borderRadius="10px"
        color={theme.palette.primary.contrastText}
        sx={sxBox}
      >
        <ComandoEstadoElemento item={item} cambiarEstado={cambiarEstado} />
        <Box
          sx={{
            color:
              item.tipoPublicacion === const_tipoPublicacion.REGISTRO_ANTIGUO
                ? theme.palette.timeline.apuntesProfesional
                : "inherit",
          }}
        >
          <Typography fontSize={12} fontWeight="bold" sx={sxAutor}>
            {item.autor}
          </Typography>
          <Typography fontSize={12}>{item.fecha}</Typography>
          <Typography fontSize={12}>{item.prescripcion}</Typography>
        </Box>
      </Box>
    </Box>
  );
}
