import React, { useState, useEffect } from "react";

import {
  Avatar,
  Typography,
  IconButton,
  Box,
  Stack,
  Tooltip,
} from "@mui/material";

import useAuth from "../../auth/useAuth";

import SeleccionarCuenta from "./SeleccionarCuenta";

import theme from "../../utils/theme";

import useGlobalDataContext from "../../hooks/useGlobalDataContext";

export default function HelloBar() {
  const auth = useAuth();

  const globalData = useGlobalDataContext();

  const [openCuentas, setOpenCuentas] = React.useState(false);

  const [saludo, setSaludo] = useState(null);
  const [detalleCuenta, setDetalleCuenta] = useState("");

  const [avatarURL, setAvatarURL] = useState(".");
  const [identificador, setIdentificador] = useState(null);

  const handleOpenCuentas = () => {
    setOpenCuentas(true);
  };

  const handleCloseCuentas = () => {
    setOpenCuentas(false);
  };

  useEffect(() => {
    if (auth.cuentaActiva !== null) {
      setIdentificador(auth.cuentaActiva.detalle);
      setAvatarURL(auth.cuentaActiva.avatarURL);
    }
  }, [auth.cuentaActiva]);

  // Cargar saludo
  useEffect(() => {
    if (globalData.loadingTerminosLocalizados) {
      return;
    }
    if (auth.cuentaActiva !== null) {
      let saludo = "";
      if (auth.isProfessional()) {
        saludo = `${globalData.terminosLocalizados.ETI128} ${auth.cuentaActiva.detalle}`;
        setDetalleCuenta(saludo);
      } else {
        if (auth.esInstitucion()) {
          saludo = auth.cuentaActiva.detalle;
          setDetalleCuenta(saludo);
        } else {
          saludo = `${globalData.terminosLocalizados.ETI127}, ${auth.cuentaActiva.detalle}`;
          setDetalleCuenta(auth.cuentaActiva.detalle);
        }
      }
      setSaludo(saludo);
    }
    // eslint-disable-next-line
  }, [
    auth.cuentaActiva,
    auth.isProffesional,
    auth.esInstituion,
    globalData.loadingTerminosLocalizados,
  ]);

  if (!auth.isLogged()) {
    return "NO LOGIN";
  }

  return (
    <Stack
      direction={"row"}
      spacing={1}
      id="helloBar"
      display="flex"
      alignItems="center"
    >
      <Tooltip title={detalleCuenta}>
        <Avatar
          alt={identificador}
          src={avatarURL}
          sx={{
            width: "32px",
            height: "32px",
            padding: "0 !important",
            minWidth: "fit-content",
            backgroundColor: theme.palette.primary.main,
          }}
        />
      </Tooltip>
      <Box
        id="saludo"
        flexGrow={1}
        textAlign="left"
        sx={{ [theme.breakpoints.down("900")]: { display: "none" } }}
      >
        <Typography
          fontSize={18}
          fontWeight="bold"
          width="110px"
          whiteSpace={"nowrap"}
          overflow="hidden"
          textOverflow={"ellipsis"}
        >
          {saludo}
        </Typography>
      </Box>
      <Box
        id="cuentas"
        display={"flex"}
        alignItems="center"
        justifyContent={"center"}
        sx={{ [theme.breakpoints.down("520")]: { display: "none" } }}
      >
        <IconButton
          onClick={handleOpenCuentas}
          sx={{
            width: "32px",
            padding: "0",
            minWidth: "fit-content",
          }}
        >
          <AutorenewConCuentas
            viewBox="0 0 24 24"
            cuentas={auth.user.cuentasAdicionales.cantidad}
          />
        </IconButton>
        <SeleccionarCuenta
          openCuentas={openCuentas}
          handleCloseCuentas={handleCloseCuentas}
        />
      </Box>
    </Stack>
  );
}

const AutorenewConCuentas = ({ cuentas }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28}>
      <path stroke="none" fill="#00677A" d="M 14 0 v 8 l 4 -4" />
      <path stroke="none" fill="#00677A" d="M 14 28 v -8 l -4 4" />
      <path
        stroke="#00677A"
        strokeWidth={2}
        fill="none"
        d="M 14 4 A 10 10 0 0 0 8 22"
      />
      <path
        stroke="#00677A"
        strokeWidth={2}
        fill="none"
        d="M 14 24 A 10 10 0 0 0 20 5"
      />
      <text
        x="15"
        y="15"
        textAnchor="middle"
        alignmentBaseline="middle"
        fontSize="10.5"
        fill="#00677A"
        fontWeight={"bold"}
      >
        {cuentas}
      </text>
    </svg>
  );
};
