import React from "react";

import { Box, Typography, Tabs, Tab, tabsClasses } from "@mui/material";

import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";

import EdicionListaAreasSalud from "./EdicionListaAreasSalud";
import BotonMoverProceso from "./BotonMoverProceso";

import { const_TipoOrdenableFormularioClinico } from "../../../../utils/constants";

import theme from "../../../../utils/theme";

import useGlobalDataContext from "../../../../hooks/useGlobalDataContext";

function TabPanel(props) {
  const { children, value, index } = props;
  return (
    <Box>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function EdicionListaProcesos(props) {
  const {
    procesos,
    quitarElemento,
    subirElemento,
    bajarElemento,
    cambiarAlineacionAreasPreguntas,
  } = props;

  const [procesoSeleccionado, setProcesoSeleccionado] = React.useState(0);
  const [
    desplazamientoProcesoSeleccionado,
    setDesplazamientoProcesoSeleccionado,
  ] = React.useState(0);

  const globalData = useGlobalDataContext();

  const handleChange = (event, newValue) => {
    setProcesoSeleccionado(newValue);
  };

  function handleQuitarPregunta(guid, tipo) {
    quitarElemento(guid, tipo);
  }

  function handleSubirPregunta(guid, tipo) {
    subirElemento(guid, tipo);
    if (tipo === const_TipoOrdenableFormularioClinico.PROCESO) {
      setDesplazamientoProcesoSeleccionado(-1);
    }
  }

  function handleBajarPregunta(guid, tipo) {
    bajarElemento(guid, tipo);
    if (tipo === const_TipoOrdenableFormularioClinico.PROCESO) {
      setDesplazamientoProcesoSeleccionado(+1);
    }
  }

  // Se supone que esto se debe ejecutar después de renderizar
  // pero primero está moviento el cursor del TAB y recién después
  // actualiza las etiquetas de los TABs.
  React.useEffect(() => {
    setProcesoSeleccionado(
      procesoSeleccionado + desplazamientoProcesoSeleccionado
    );
    setDesplazamientoProcesoSeleccionado(0);
  }, [procesoSeleccionado, desplazamientoProcesoSeleccionado]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Tabs
          value={procesoSeleccionado}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              "&.Mui-disabled": { opacity: 0.3 },
            },
          }}
        >
          {procesos.map((item, index) => {
            return (
              <Tab
                key={index}
                label={item.descripcion}
                {...a11yProps(0)}
                sx={{
                  backgroundColor: theme.palette.secondary.main,
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: theme.palette.secondary.dark,
                  borderRadius: "10px 10px 0 0",
                  "&:hover": {
                    backgroundColor: theme.palette.secondary.dark,
                    color: theme.palette.primary.contrastText,
                  },
                  "&.Mui-selected": {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    borderColor: theme.palette.primary.main,
                  },
                  "&.Mui-selected:hover": {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                  },
                }}
              />
            );
          })}
        </Tabs>
      </Box>
      <Box>
        {procesos.map((itemProcesos, indexProcesos) => {
          const indexUltimo = procesos.length - 1;
          return (
            <TabPanel
              key={indexProcesos}
              value={procesoSeleccionado}
              index={indexProcesos}
              id="TabPanel-Id"
            >
              <Box
                sx={{
                  padding: 1,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <BotonMoverProceso
                  guid={itemProcesos.guid}
                  habilitado={indexProcesos !== 0}
                  derecha="false"
                  handleSubir={handleSubirPregunta}
                  handleBajar={handleBajarPregunta}
                >
                  <ArrowBack />
                  {/* Mover proceso a la izquierda */}
                  {globalData.terminosLocalizados.COM046}
                </BotonMoverProceso>
                <BotonMoverProceso
                  guid={itemProcesos.guid}
                  habilitado={indexProcesos < indexUltimo}
                  derecha="true"
                  handleSubir={handleSubirPregunta}
                  handleBajar={handleBajarPregunta}
                >
                  {/* Mover proceso a la derecha */}
                  {globalData.terminosLocalizados.COM047}
                  <ArrowForward />
                </BotonMoverProceso>
              </Box>
              <EdicionListaAreasSalud
                areasSalud={itemProcesos.areasSalud}
                handleQuitarPregunta={handleQuitarPregunta}
                handleSubirPregunta={handleSubirPregunta}
                handleBajarPregunta={handleBajarPregunta}
                cambiarAlineacionAreasPreguntas={
                  cambiarAlineacionAreasPreguntas
                }
              />
            </TabPanel>
          );
        })}
      </Box>
    </Box>
  );
}
