import React from "react";

import { Stack, TextField } from "@mui/material";

import { const_TipoFactorValoracion } from "../../../../utils/constants";

import CajaListaSeleccion from "./CajaListaSeleccion";

import useGlobalDataContext from "../../../../hooks/useGlobalDataContext";

export default function ResultadoValoracion(props) {
  const { factor, titulo, resultado, setResultado } = props;

  const globalData = useGlobalDataContext();

  // Obtener desde el servidor según el idioma del usuario
  const etiquetaValor = globalData.terminosLocalizados.ETI100;
  const etiquetaMinimo = globalData.terminosLocalizados.ETI101;
  const etiquetaMaximo = globalData.terminosLocalizados.ETI102;

  const handleOnBlur = (event) => {
    setResultado({ ...resultado, [event.target.name]: event.target.value });
  };

  const handleOnChange = (event) => {
    setResultado({ ...resultado, [event.target.name]: event.target.value });
  };

  return (
    <CajaListaSeleccion titulo={titulo}>
      <Stack padding={1} spacing={2}>
        {factor !== const_TipoFactorValoracion.RANGO && (
          <TextField
            type="text"
            size="small"
            name="valor"
            label={etiquetaValor}
            value={resultado.valor}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
          ></TextField>
        )}
        {factor === const_TipoFactorValoracion.RANGO && (
          <TextField
            type="text"
            size="small"
            name="minimo"
            label={etiquetaMinimo}
            value={resultado.minimo}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
          ></TextField>
        )}
        {factor === const_TipoFactorValoracion.RANGO && (
          <TextField
            type="text"
            size="small"
            name="maximo"
            label={etiquetaMaximo}
            value={resultado.maximo}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
          ></TextField>
        )}
      </Stack>
    </CajaListaSeleccion>
  );
}
