import React from "react";
import { Box, Stack } from "@mui/material";

import { format } from "date-fns";

import { TextoNivel1, TextoNivel2, TextoNivel3, TextoNivel4 } from "./Textos";
import theme from "../../../../utils/theme";

import useGlobalDataContext from "../../../../hooks/useGlobalDataContext";

export default function MuestraPaciente(props) {
  const { formularioClinico } = props;

  const globalData = useGlobalDataContext();

  const tituloObservaciones = globalData.terminosLocalizados.ETI098;
  const tituloPrescripcion = globalData.terminosLocalizados.ETI093;
  const tituloAdjuntos = globalData.terminosLocalizados.ETI096;

  return (
    <Stack
      id="stackMuestraPaciente"
      spacing={0.5}
      direction="row"
      // Se define este color porque luego se utiliza como borde en los tabs.
      // Si se cambiara el color del fondo debe ser el mismo en ambos lados.
      backgroundColor={theme.palette.primary.background}
      sx={{
        height: "100%",
        [theme.breakpoints.down("md")]: { height: "100%" },
      }}
    >
      <Box
        id="muestraPacienteObservaciones"
        flexGrow={1}
        display="flex"
        flexDirection={"column"}
        height="100%"
      >
        <BarraTituloMuestras texto={tituloObservaciones} />
        <Box
          id="formulario-clínico-vista-paciente-observaciones-contenido"
          borderRadius={"5px"}
          paddingX={0.4}
          paddingY={0.2}
          flexGrow={1}
          sx={{
            border: "solid",
            borderWidth: "1px",
            borderColor: "rgba(0,0,0,0.23)",
          }}
        >
          <RenderElementos formularioClinico={formularioClinico} />
        </Box>
      </Box>
      <Box
        id="formulario-clínico-vista-paciente-otros"
        flexGrow={1}
        display="flex"
        flexDirection={"column"}
      >
        <Box
          id="vista-paciente-prescripcion"
          flexGrow={2}
          display="flex"
          flexDirection={"column"}
        >
          <BarraTituloMuestras texto={tituloPrescripcion} />
          <Box
            flexGrow={1}
            borderRadius={"5px"}
            paddingX={0.5}
            sx={{
              border: "solid",
              borderWidth: "1px",
              borderColor: "rgba(0,0,0,0.23)",
            }}
          >
            <RenderPrescripcion
              valor={
                formularioClinico.prescripcion.compartido
                  ? formularioClinico.prescripcion.texto
                  : ""
              }
              formularioClinico={formularioClinico}
            />
          </Box>
        </Box>
        <Box
          id="vista-paciente-archivos-adjuntos"
          flexGrow={1}
          display="flex"
          flexDirection={"column"}
        >
          <BarraTituloMuestras texto={tituloAdjuntos} />
          <Box
            flexGrow={1}
            borderRadius={"5px"}
            paddingX={0.5}
            sx={{
              border: "solid",
              borderWidth: "1px",
              borderColor: "rgba(0,0,0,0.23)",
            }}
          ></Box>
        </Box>
      </Box>
    </Stack>
  );
}

function RenderElementos(props) {
  const { formularioClinico } = props;
  return (
    <Stack id="muestra-paciente-contenedor" spacing={0.5}>
      {formularioClinico.procesos.map((item, index) => {
        return (
          item.contadorCompartidas > 0 && (
            <RenderProceso key={index} proceso={item} />
          )
        );
      })}
    </Stack>
  );
}

function RenderProceso(props) {
  const { proceso, index } = props;
  return (
    <Box key={index} id="muestra-paciente-proceso-contenedor">
      {proceso.areasSalud.map((item, index) => {
        return (
          item.contadorCompartidas > 0 && (
            <RenderAreaSalud key={index} areaSalud={item} />
          )
        );
      })}
    </Box>
  );
}

function RenderAreaSalud(props) {
  const { areaSalud, index } = props;
  return (
    <Box id="muestra-paciente-area-salud-contenedor" key={index}>
      {areaSalud.especialidades.map((item, index) => {
        return (
          item.contadorCompartidas > 0 && (
            <RenderEspecialidad key={index} especialidad={item} />
          )
        );
      })}
    </Box>
  );
}

function RenderEspecialidad(props) {
  const { especialidad, index } = props;
  return (
    <Box id="muestra-paciente-especialidad-contenedor" key={index}>
      {especialidad.secciones.map((item, index) => {
        return (
          item.contadorCompartidas > 0 && (
            <RenderSeccion key={index} seccion={item} />
          )
        );
      })}
    </Box>
  );
}

function RenderSeccion(props) {
  const { seccion } = props;
  return (
    <Box id="muestra-paciente-seccion-contenedor">
      <TextoNivel4 texto={seccion.descripcion} />
      {seccion.areasPreguntas.map((item, index) => {
        return (
          item.contadorCompartidas > 0 && (
            <RenderAreaPreguntas key={index} areaPreguntas={item} />
          )
        );
      })}
    </Box>
  );
}

function RenderAreaPreguntas(props) {
  const { areaPreguntas } = props;
  return (
    <Stack
      id="muestra-paciente-areaPreguntas-contenedor"
      spacing={0.5}
      width="100%"
    >
      {areaPreguntas.preguntas.map((item, index) => {
        return (
          item.respuestaCompartida && (
            <RenderPregunta key={index} pregunta={item} />
          )
        );
      })}
    </Stack>
  );
}

function RenderPregunta(props) {
  const { pregunta } = props;
  return (
    <Box paddingLeft={2}>
      <TextoNivel3 texto={pregunta.descripcion} />
      <Stack spacing={0.2} paddingLeft={1}>
        {pregunta.respuestaTextoPleno !== "" && (
          <RenderTextoPleno valor={pregunta.respuestaTextoPleno} />
        )}
        {pregunta.respuestaOpciones !== "" && (
          <RenderOpciones
            valor={pregunta.respuestaOpciones}
            informacionAdicional={pregunta.respuestaOpcionesTextoAdicional}
          />
        )}
        {pregunta.respuestaUnidadMedida !== "" && (
          <RenderUnidadMedida
            valor={pregunta.respuestaUnidadMedida}
            unidadMedida={pregunta.unidadMedida}
            valoracion={pregunta.respuestaUnidadMedidaValoracion}
          />
        )}
        {pregunta.respuestaMultimedia.archivo !== null && (
          <RenderMultimedia valor={pregunta.respuestaMultimedia} />
        )}
        {pregunta.respuestaFecha !== null && (
          <RenderFecha valor={pregunta.respuestaFecha} />
        )}
      </Stack>
    </Box>
  );
}

function RenderTextoPleno(props) {
  const { valor } = props;
  return <TextoNivel1 texto={valor} />;
}

function RenderOpciones(props) {
  const { valor, informacionAdicional } = props;
  return (
    <Stack direction={"row"} spacing={1}>
      <Box>
        <TextoNivel1 texto={valor} />
      </Box>
      <Box>
        {informacionAdicional && <TextoNivel1 texto={informacionAdicional} />}
      </Box>
    </Stack>
  );
}

function RenderUnidadMedida(props) {
  const { valor, unidadMedida, valoracion } = props;
  const textoValor = `${valor} ${unidadMedida}`;
  return (
    <Stack direction={"row"} spacing={1}>
      <TextoNivel1 texto={textoValor} />
      {valoracion !== "" && <TextoNivel1 texto={`(${valoracion})`} />}
    </Stack>
  );
}

function RenderMultimedia(props) {
  const { valor } = props;
  return (
    <Box id="boxImagen" height={"30px"}>
      <img height={"100%"} src={valor.preview} alt="preview" />
    </Box>
  );
}

function RenderFecha(props) {
  const { valor } = props;
  try {
    const fechaObj = new Date(valor);
    const fechaString = format(fechaObj, "yyyy-MM-dd");
    return <TextoNivel1 texto={fechaString} />;
  } catch {
    return null; // Si la fecha no es válida no se puede convertir a texto
  }
}

function RenderPrescripcion(props) {
  const { valor } = props;
  return <TextoNivel1 texto={valor} />;
}

function BarraTituloMuestras(props) {
  const { texto } = props;
  return (
    <Box height="20px">
      <TextoNivel2 texto={texto} />
    </Box>
  );
}
