import React from "react";
import { Stack, Grid } from "@mui/material";
import EntradaArea from "./EntradaArea";
import ArchivosAdjuntos from "./ArchivosAdjuntos";
import UsuariosEtiquetados from "./UsuariosEtiquetados";

import { tipos_respuesta } from "../../../../pages/ClinicFormPage";

import useGlobalDataContext from "../../../../hooks/useGlobalDataContext";
import theme from "../../../../utils/theme";

export default function OtrasEntradas(props) {
  const {
    inhabilitado,
    formularioClinico,
    setFormularioClinico,
    actualizarOtrasEntradas,
  } = props;

  const globalData = useGlobalDataContext();

  const titulos = {
    prescripcion: globalData.terminosLocalizados.ETI093,
    prescripcionTest: globalData.terminosLocalizados.ETI094,
    notasProfesionales: globalData.terminosLocalizados.ETI095,
    adjuntos: globalData.terminosLocalizados.ETI096,
    etiquetas: globalData.terminosLocalizados.ETI097,
  };

  // ¿Esto no tendría que ser un estado?
  // Parece que no, porque está funcionando.
  var rows = 2.5;

  return (
    <Stack
      id="stackPrescripciones"
      spacing={0.1}
      sx={{ [theme.breakpoints.down("md")]: { height: "100%" } }}
    >
      <EntradaArea
        titulo={titulos.prescripcion}
        rows={rows}
        uso={tipos_respuesta.prescripcion}
        inhabilitado={inhabilitado}
        formularioClinico={formularioClinico}
        actualizarOtrasEntradas={actualizarOtrasEntradas}
      />
      <EntradaArea
        titulo={titulos.prescripcionTest}
        rows={rows}
        uso={tipos_respuesta.prescripcionTest}
        inhabilitado={inhabilitado}
        formularioClinico={formularioClinico}
        actualizarOtrasEntradas={actualizarOtrasEntradas}
      />
      <Grid
        container
        columnSpacing={0.5}
        // Para sobrescribir el ancho del Grid container de MUI
        // que le agrega 4px al 100%
        width="100%"
      >
        <Grid item xs={12} md={5}>
          <Stack>
            <ArchivosAdjuntos
              titulo={titulos.adjuntos}
              inhabilitado={inhabilitado}
              formularioClinico={formularioClinico}
              setFormularioClinico={setFormularioClinico}
              actualizarOtrasEntradas={actualizarOtrasEntradas}
            />
            <UsuariosEtiquetados
              titulo={titulos.etiquetas}
              inhabilitado={inhabilitado}
              formularioClinico={formularioClinico}
              actualizarOtrasEntradas={actualizarOtrasEntradas}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={7}>
          <EntradaArea
            titulo={titulos.notasProfesionales}
            rows={4}
            uso={tipos_respuesta.notasProfesional}
            inhabilitado={inhabilitado}
            formularioClinico={formularioClinico}
            actualizarOtrasEntradas={actualizarOtrasEntradas}
          ></EntradaArea>
        </Grid>
      </Grid>
    </Stack>
  );
}
