import React from "react";

import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import { Person, PhotoCamera, Settings, Lock } from "@mui/icons-material";

import useGlobalDataContext from "../../../hooks/useGlobalDataContext";

export default function Menu(props) {
  const { pagina, setPagina, listaPaginas } = props;

  const globalData = useGlobalDataContext();

  const sxListItemButton = {
    borderTopRightRadius: "25px",
    borderBottomRightRadius: "25px",
  };

  return (
    <List>
      <ListItemButton
        selected={pagina === listaPaginas.informacionPersonal}
        onClick={() => {
          setPagina(listaPaginas.informacionPersonal);
        }}
        sx={sxListItemButton}
      >
        <ListItemIcon>
          <Person />
        </ListItemIcon>
        <ListItemText primary={globalData.terminosLocalizados.ETI125} />
      </ListItemButton>
      <ListItemButton
        selected={pagina === listaPaginas.fotoPerfil}
        onClick={() => {
          setPagina(listaPaginas.fotoPerfil);
        }}
        sx={sxListItemButton}
      >
        <ListItemIcon>
          <PhotoCamera />
        </ListItemIcon>
        <ListItemText primary={globalData.terminosLocalizados.ETI116} />
      </ListItemButton>
      <ListItemButton
        selected={pagina === listaPaginas.configuracion}
        onClick={() => {
          setPagina(listaPaginas.configuracion);
        }}
        sx={sxListItemButton}
      >
        <ListItemIcon>
          <Settings />
        </ListItemIcon>
        <ListItemText primary={globalData.terminosLocalizados.ETI126} />
      </ListItemButton>
      <ListItemButton
        selected={pagina === listaPaginas.cambiarPassword}
        onClick={() => {
          setPagina(listaPaginas.cambiarPassword);
        }}
        sx={sxListItemButton}
      >
        <ListItemIcon>
          <Lock />
        </ListItemIcon>
        <ListItemText primary={globalData.terminosLocalizados.ETI159} />
      </ListItemButton>
    </List>
  );
}
