import React from "react";

import { Button } from "@mui/material";

import BoxPregunta from "./BoxPregunta";

import { const_TipoOrdenableFormularioClinico } from "../../../../utils/constants";

import theme from "../../../../utils/theme";

import useGlobalDataContext from "../../../../hooks/useGlobalDataContext";

export default function EdicionMultimedia(props) {
  const {
    itemPreguntas,
    handleQuitarPregunta,
    handleSubirPregunta,
    handleBajarPregunta,
  } = props;

  const globalData = useGlobalDataContext();

  return (
    <BoxPregunta
      guid={itemPreguntas.guid}
      tipo={const_TipoOrdenableFormularioClinico.MULTIMEDIA}
      suprimible
      handleQuitarPregunta={handleQuitarPregunta}
      handleSubirPregunta={handleSubirPregunta}
      handleBajarPregunta={handleBajarPregunta}
    >
      <Button
        sx={{
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.secondary.main,
          "&:hover": {
            backgroundColor: theme.palette.secondary.dark,
            color: theme.palette.primary.contrastText,
          },
          paddingX: 2,
          borderRadius: "10px",
        }}
      >
        {/* Cargar archivo multimedia */}
        {globalData.terminosLocalizados.COM048}
      </Button>
    </BoxPregunta>
  );
}
