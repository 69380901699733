import React from "react";
import { Box, Button } from "@mui/material";
import BarraTituloEntradas from "./BarraTituloOtrasEntradas";

import { uso_barra_titulo } from "./Formulario";

export default function UsuariosEtiquetados(props) {
  const { titulo, cargarRespuesta, buscarRespuesta, inhabilitado } = props;
  const handleOnClick = () => {
    alert("falta implementar onClick de etiquetado de usuarios");
  };
  return (
    <Box>
      <BarraTituloEntradas
        texto={titulo}
        cargarRespuesta={cargarRespuesta}
        buscarRespuesta={buscarRespuesta}
        uso={uso_barra_titulo.usuariosEtiquetados}
        omitirCompartir
      />
      <Button
        onClick={handleOnClick}
        sx={{ padding: 0, width: "100%" }}
        // Por ahora se inhabilita siempre, porque no está implementado.
        disabled={inhabilitado || true}
      >
        +
      </Button>
    </Box>
  );
}
