import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import {
  Grid,
  Button,
  Stack,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";

import useAuth from "../../../auth/useAuth";
import ErrorSummary from "../../Forms/ErrorSummary";
import useGlobalDataContext from "../../../hooks/useGlobalDataContext";
import Loading from "../../Feedback/Loading";
import {
  API_URL,
  const_TipoDocumentoIdentidad,
} from "../../../utils/constants";

import useToastContext from "../../../hooks/useToastContext";
import { Severities } from "../../../contexts/ToastContext";

const anchoBotones = "150px";

export default function InformacionProfesional() {
  const [modoEdicion, setModoEdicion] = useState(false);

  const auth = useAuth();
  const globalData = useGlobalDataContext();
  const addToast = useToastContext();

  const textos = {
    tituloIdentificacion: globalData.terminosLocalizados.ETI106,
    tituloInformacionPersonal: globalData.terminosLocalizados.ETI079,
    tituloInformacionContacto: globalData.terminosLocalizados.ETI082,
    etiquetaProfesion: globalData.terminosLocalizados.ETI107,
    etiquetaNombre: globalData.terminosLocalizados.ETI108,
    etiquetaApellido: globalData.terminosLocalizados.ETI109,
    etiquetaEmail: globalData.terminosLocalizados.ETI004,
    etiquetaTelefono: globalData.terminosLocalizados.ETI005,
    etiquetaLugarTrabajo: globalData.terminosLocalizados.ETI110,
    etiquetaCodigoPaisDocumento: globalData.terminosLocalizados.ETI076,
    etiquetaTipoDocumento: globalData.terminosLocalizados.ETI077,
    etiquetaNumeroDocumento: globalData.terminosLocalizados.ETI078,
    textoEditar: globalData.terminosLocalizados.COM040,
    textoGuardar: globalData.terminosLocalizados.COM041,
    textoCancelar: globalData.terminosLocalizados.COM004,
  };

  // En esta estructura se tienen los nombres de los campos en el idioma del usuario
  // Se recibirán del servidor cuando esté implementado el multi-lenguaje.
  const descripcionCampos = {
    Profesion: globalData.terminosLocalizados.ETI107, // "Profesión",
    Nombre: globalData.terminosLocalizados.ETI108, // "Nombre",
    Apellido: globalData.terminosLocalizados.ETI109, // "Apellido",
    Email: globalData.terminosLocalizados.ETI004, // "Email",
    Telefono: globalData.terminosLocalizados.ETI005, // "Teléfono",
    CodigoPaisDocumentoIdentidad: globalData.terminosLocalizados.ETI071, // "Código de país",
    TipoDocumentoIdentidad: globalData.terminosLocalizados.ETI072, // "Tipo de documento",
    NumeroDocumentoIdentidad: globalData.terminosLocalizados.ETI073, //"Número de documento",
    LugarTrabajo: globalData.terminosLocalizados.ETI110, // "Lugar de trabajo",
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      Profesion: "",
      Nombre: "",
      Apellido: "",
      Email: "",
      Telefono: "",
      LugarTrabajo: "",
      CodigoPaisDocumento: "",
      TipoDocumento: "",
      NumeroDocumento: "",
    },
  });

  // Obtener información de la cuenta adicional de profesional
  useEffect(() => {
    (async () => {
      try {
        const request = {
          Token: auth.user.token,
          Guid: auth.cuentaActiva.guid,
        };
        const url = `${API_URL()}/Users/getCuentaProfesional`;
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(request),
        };
        const res = await fetch(url, options);
        if (res.ok) {
          const json = await res.json();
          if (json.resultadoOK) {
            reset({
              Profesion: json.datos.profesion,
              Nombre: json.datos.nombre,
              Apellido: json.datos.apellido,
              Email: json.datos.email,
              Telefono: json.datos.telefono,
              CodigoPaisDocumento: json.datos.codigoPaisDocumento,
              TipoDocumento: json.datos.tipoDocumento,
              NumeroDocumento: json.datos.numeroDocumento,
              LugarTrabajo: json.datos.lugarTrabajo,
            });
          } else {
            json.errores.forEach((item) => {
              addToast(item, Severities.error);
            });
          }
        } else {
          addToast(globalData.terminosLocalizados.AVI004, Severities.error);
        }
      } catch (err) {
        //addToast("Error de conexión", Severities.error);
        addToast(globalData.terminosLocalizados.AVI005, Severities.error);
      }
    })();
    // eslint-disable-next-line
  }, [
    addToast,
    auth.cuentaActiva.guid,
    auth.user.token,
    globalData.terminosLocalizados.AVI004,
    globalData.terminosLocalizados.AVI005,
  ]);

  const actualizarInformacionProfesional = async (data) => {
    const request = {
      Token: auth.user.token,
      Guid: auth.cuentaActiva.guid,
    };
    request.Datos = {
      Profesion: data.Profesion,
      Nombre: data.Nombre,
      Apellido: data.Apellido,
      Email: data.Email,
      Telefono: data.Telefono,
      CodigoPaisDocumento: data.CodigoPaisDocumento,
      TipoDocumento: data.TipoDocumento,
      NumeroDocumento: data.NumeroDocumento,
      LugarTrabajo: data.LugarTrabajo,
    };
    const url = `${API_URL()}/users/putCuentaProfesional`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(request),
    };
    try {
      const res = await fetch(url, options);
      if (res.ok) {
        const json = await res.json();
        if (json.resultadoOK) {
          //auth.updateUser(json.usuario);
        } else {
          json.errores.forEach((item) => {
            addToast(item, Severities.error);
          });
        }
      } else {
        addToast(globalData.terminosLocalizados.AVI004, Severities.error);
      }
    } catch (err) {
      addToast(globalData.terminosLocalizados.AVI005, Severities.error);
    }
  };

  const handleEditar = () => {
    setModoEdicion(true);
  };

  const handleGuardar = (data) => {
    actualizarInformacionProfesional(data);
    setModoEdicion(false);
  };

  const handleCancelar = () => {
    setModoEdicion(false);
  };

  return (
    <form onSubmit={handleSubmit(handleGuardar)}>
      {/* <Box display="flex"> */}
      <Grid
        container
        rowSpacing={1}
        columnSpacing={1}
        maxWidth="700px"
        margin={1}
        width="100%"
      >
        <RenderIdentificacionUsuario
          control={control}
          modoEdicion={modoEdicion}
          textos={textos}
        />
        <RenderDatosProfesional
          control={control}
          inputProps={{ readOnly: !modoEdicion, disabled: !modoEdicion }}
          selectInputProps={{ readOnly: !modoEdicion }}
          textos={textos}
        />
        <Grid item md={12}>
          <RenderCommandBar
            modoEdicion={modoEdicion}
            handleEditar={handleEditar}
            handleCancelar={handleCancelar}
            textos={textos}
          />
        </Grid>
        <Grid item md={12}>
          <ErrorSummary
            errors={errors}
            descripciones={descripcionCampos}
          ></ErrorSummary>
        </Grid>
      </Grid>
      {/* </Box> */}
    </form>
  );
}

function RenderIdentificacionUsuario(props) {
  const { control, modoEdicion, textos } = props;
  const globalData = useGlobalDataContext();

  const descripcionTipoDocumentoIdentidad = {
    CedulaIdentidad: "Cédula de Identidad",
    Pasaporte: "Pasaporte",
    DNI: "DNI",
  };

  return (
    <>
      <Grid item md={12} width="100%">
        <Typography variant="h7" fontStyle="italic">
          {textos.tituloIdentificacion}
        </Typography>
      </Grid>
      <Grid item md={12} width="100%">
        <FormControl size="small">
          <InputLabel>{textos.etiquetaCodigoPaisDocumento}</InputLabel>
          <Controller
            name="CodigoPaisDocumento"
            control={control}
            render={({ field }) => (
              <Select
                // A los <Select> no se les aplica "disabled" porque le cambia el color al contenido
                inputProps={{
                  readOnly: !modoEdicion,
                  //disabled: !modoEdicion
                }}
                label={textos.etiquetaCodigoPaisDocumento}
                MenuProps={{
                  PaperProps: { sx: { maxHeight: 190, width: "" } },
                }}
                {...field}
              >
                {globalData.loadingPaises ? (
                  <Loading />
                ) : (
                  globalData.paises.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.codigo}>
                        {item.nombre}
                      </MenuItem>
                    );
                  })
                )}
              </Select>
            )}
          ></Controller>
        </FormControl>
      </Grid>
      <Grid item md={6} width="100%">
        <FormControl size="small">
          <InputLabel>{textos.etiquetaTipoDocumento}</InputLabel>
          <Controller
            name="TipoDocumento"
            control={control}
            rules={{
              maxLength: {
                value: 40,
              },
            }}
            render={({ field }) => (
              <Select
                // A los <Select> no se les aplica "disabled" porque le cambia el color al contenido
                inputProps={{
                  readOnly: !modoEdicion,
                  //disabled: !modoEdicion
                }}
                label={textos.etiquetaTipoDocumento}
                {...field}
              >
                <MenuItem value={const_TipoDocumentoIdentidad.CEDULA_IDENTIDAD}>
                  {descripcionTipoDocumentoIdentidad.CedulaIdentidad}
                </MenuItem>
                <MenuItem value={const_TipoDocumentoIdentidad.PASAPORTE}>
                  {descripcionTipoDocumentoIdentidad.Pasaporte}
                </MenuItem>
                <MenuItem value={const_TipoDocumentoIdentidad.DNI}>
                  {descripcionTipoDocumentoIdentidad.DNI}
                </MenuItem>
              </Select>
            )}
          ></Controller>
        </FormControl>
      </Grid>
      <Grid item md={6} width="100%">
        <Controller
          name="NumeroDocumento"
          control={control}
          rules={{
            required: {
              value: true,
            },
            maxLength: {
              value: 40,
            },
          }}
          render={({ field }) => (
            <TextField
              type="text"
              name={textos.etiquetaNumeroDocumento}
              label={textos.etiquetaNumeroDocumento}
              size="small"
              inputProps={{ readOnly: !modoEdicion, disabled: !modoEdicion }}
              {...field}
            />
          )}
        ></Controller>
      </Grid>
    </>
  );
}

function RenderDatosProfesional(props) {
  const {
    control,
    // Estas propiedades son para todos los campos excepto los de tipo <Select>
    inputProps,
    // Si hubiera algún campo de tipo <Select> se le debería aplicar estas propiedades
    //selectInputProps,
    textos,
  } = props;

  return (
    <>
      <Grid item md={12} width="100%">
        <Typography variant="h7" fontStyle="italic">
          {textos.tituloInformacionPersonal}
        </Typography>
      </Grid>
      <Grid item md={6}>
        <Controller
          name="Nombre"
          control={control}
          rules={{
            maxLength: {
              value: 40,
            },
          }}
          render={({ field }) => (
            <TextField
              type="text"
              size="small"
              name={textos.etiquetaNombre}
              label={textos.etiquetaNombre}
              inputProps={inputProps}
              {...field}
            />
          )}
        ></Controller>
      </Grid>
      <Grid item md={6}>
        <Controller
          name="Apellido"
          control={control}
          rules={{
            required: {
              value: true,
            },
            maxLength: {
              value: 40,
            },
          }}
          render={({ field }) => (
            <TextField
              type="text"
              name={textos.etiquetaApellido}
              label={textos.etiquetaApellido}
              size="small"
              inputProps={inputProps}
              {...field}
            />
          )}
        ></Controller>
      </Grid>
      <Grid item md={12}>
        <Controller
          name="Profesion"
          control={control}
          rules={{
            required: {
              value: true,
            },
            maxLength: {
              value: 40,
            },
          }}
          render={({ field }) => (
            <TextField
              type="text"
              size="small"
              name={textos.etiqueProfesion}
              label={textos.etiquetaProfesion}
              inputProps={inputProps}
              {...field}
            />
          )}
        ></Controller>
      </Grid>
      <Grid item md={12} width="100%">
        <Typography variant="h7" fontStyle="italic">
          {textos.tituloInformacionContacto}
        </Typography>
      </Grid>
      <Grid item md={12}>
        <Controller
          name="Email"
          control={control}
          rules={{
            required: {
              value: true,
            },
            pattern: {
              value:
                // Este es el patern original, con \. para indicar el punto en la columna 28,
                // pero emite una advertencia diciendo que es escape es innecesario.
                // Lo quito, pero dejo el original en comentarios, por las dudas.
                // /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/,
                /^([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/,
            },
          }}
          render={({ field }) => (
            <TextField
              type="text"
              name={textos.etiquetaEmail}
              label={textos.etiquetaEmail}
              size="small"
              inputProps={inputProps}
              {...field}
            />
          )}
        ></Controller>
      </Grid>
      <Grid item md={12}>
        <Controller
          name="Telefono"
          control={control}
          rules={{
            maxLength: {
              value: 40,
            },
          }}
          render={({ field }) => (
            <TextField
              type="text"
              name={textos.etiquetaTelefono}
              label={textos.etiquetaTelefono}
              size="small"
              inputProps={inputProps}
              {...field}
            />
          )}
        ></Controller>
      </Grid>
      <Grid item md={12}>
        <Controller
          name="LugarTrabajo"
          control={control}
          rules={{
            maxLength: {
              value: 40,
            },
          }}
          render={({ field }) => (
            <TextField
              type="text"
              name={textos.etiquetaLugarTrabajo}
              label={textos.etiquetaLugarTrabajo}
              size="small"
              inputProps={inputProps}
              {...field}
            />
          )}
        ></Controller>
      </Grid>
    </>
  );
}

function RenderCommandBar(props) {
  const { modoEdicion, handleEditar, handleCancelar, textos } = props;
  return (
    <Stack direction={"row"} spacing={1} justifyContent="flex-start">
      {!modoEdicion && (
        <Button
          variant="CommandBar3"
          onClick={handleEditar}
          sx={{
            width: anchoBotones,
          }}
        >
          {textos.textoEditar}
        </Button>
      )}
      {modoEdicion && (
        <Button
          variant="CommandBar3"
          type="submit"
          sx={{
            width: anchoBotones,
          }}
        >
          {textos.textoGuardar}
        </Button>
      )}
      {modoEdicion && (
        <Button
          variant="CommandBar3"
          onClick={handleCancelar}
          sx={{
            width: anchoBotones,
          }}
        >
          {textos.textoCancelar}
        </Button>
      )}
    </Stack>
  );
}
