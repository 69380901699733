import React from "react";
import {
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
} from "@mui/lab";
import ElementoTimeline from "./ElementoTimeline";
import { const_tipoPublicacion } from "../../../../../utils/constants";
import theme from "../../../../../utils/theme";

export default function LineaTimeline(props) {
  const { item, cambiarEstado, sxBox, sxAutor } = props;
  let sxDot = {
    backgroundColor: theme.palette.timeline.prescripciones,
  };
  if (item.publicacionPaciente) {
    sxDot.bgcolor = theme.palette.timeline.notasPaciente;
  } else {
    switch (item.tipoPublicacion) {
      case const_tipoPublicacion.PRESCRIPCION:
        sxDot.bgcolor = theme.palette.timeline.prescripciones;
        break;
      case const_tipoPublicacion.PRESCRIPCION_TEST:
        sxDot.bgcolor = theme.palette.timeline.resultados;
        break;
      case const_tipoPublicacion.CONSULTA:
        sxDot.bgcolor = theme.palette.timeline.apuntesProfesional;
        break;
      case const_tipoPublicacion.REGISTRO_ANTIGUO:
        sxDot.bgcolor = theme.palette.timeline.registrosAntiguos;
        break;
      default:
    }
  }

  return (
    <TimelineItem sx={{ paddingLeft: 0 }}>
      <TimelineOppositeContent sx={{ padding: 0, textAlign: "left" }}>
        {item.publicacionPaciente && (
          <ElementoTimeline
            item={item}
            cambiarEstado={cambiarEstado}
            sxBox={sxBox}
            sxAutor={sxAutor}
          />
        )}
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot sx={sxDot} />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ padding: 0 }}>
        {!item.publicacionPaciente && (
          <ElementoTimeline
            item={item}
            cambiarEstado={cambiarEstado}
            sxBox={sxBox}
            sxAutor={sxAutor}
          />
        )}
      </TimelineContent>
    </TimelineItem>
  );
}
