import React, { useState, useEffect } from "react";

import { Box, Grid, Button, Typography, Stack, Slider } from "@mui/material";

import { RotateLeft, RotateRight } from "@mui/icons-material";

import ReactAvatarEditor from "react-avatar-editor";

import Dropzone from "react-dropzone";

import useAuth from "../../../auth/useAuth";
import useGlobalDataContext from "../../../hooks/useGlobalDataContext";
import useToastContext from "../../../hooks/useToastContext";
import { API_URL } from "../../../utils/constants";
import { Severities } from "../../../contexts/ToastContext";

import theme from "../../../utils/theme";
import Loading from "../../Feedback/Loading";

export default function FotoPerfil() {
  const auth = useAuth();
  const [image, setImage] = useState(null);
  const [cargandoImagen, setCargandoImage] = useState(true);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);

  // *** NO QUITAR ESTAS LÍNEAS ***
  // *** SE CONSERVAN A MODO DE REFERENCIA ***
  //const [allowZoomOut, setAllowZoomOut] = useState(false);
  //const [position, setPosition] = useState({ x: 0.5, y: 0.5 });
  //const [borderRadius, setBorderRadius] = useState(50);
  //const [preview, setPreview] = useState(null);
  //const [width, setWidth] = useState(200);
  //const [height, setHeight] = useState(200);

  const [editor, setEditor] = useState(null);

  const addToast = useToastContext();
  const globalData = useGlobalDataContext();

  const textos = {
    titulo: globalData.terminosLocalizados.ETI116,
    comandoSeleccionar: globalData.terminosLocalizados.COM042,
    comandoGuardar: globalData.terminosLocalizados.COM041,
    instrucciones: globalData.terminosLocalizados.AVI033,
    zoom: globalData.terminosLocalizados.COM043,
    rotar: globalData.terminosLocalizados.ETI117,
    izquierda: globalData.terminosLocalizados.COM044,
    derecha: globalData.terminosLocalizados.COM045,
  };

  // Cargar la imagen del avatar activo
  useEffect(() => {
    if (auth.cuentaActiva !== null) {
      setImage(auth.cuentaActiva.avatarURL);
      setScale(1);
      setRotate(0);
    } else {
      setImage(null);
    }
    setCargandoImage(false);
  }, [auth.cuentaActiva]);

  const handleNewImage = (event) => {
    setImage(event.target.files[0]);
  };

  const handleScale = (event) => {
    const scale = parseFloat(event.target.value);
    setScale(scale);
  };

  const handleRotateLeft = () => {
    setRotate((previous) => {
      return previous - 90;
    });
  };

  const handleRotateRight = () => {
    setRotate((previous) => {
      return previous + 90;
    });
  };

  // *** NO QUITAR - CONSERVAR A MODO DE REFERENCIA ***
  // const handlePositionChange = (position) => {
  //   setPosition({ position });
  // };

  const handleDrop = (dropped) => {
    setImage(dropped[0]);
  };

  const handleSave = () => {
    if (editor) {
      const img = editor.getImageScaledToCanvas();
      img.toBlob((blob) => {
        if (blob) {
          let file = new File([blob], "avatar.png", { type: "image/png" });
          actualizarFotoPerfil(file);
          auth.actualizarAvatarActivo(file);
          //setImage(file);
          // Restaurar el zoom
          setScale(1);
          // Restaurar la rotación
          setRotate(0);
        }
      });
    }
  };

  const actualizarFotoPerfil = async (file) => {
    const formData = new FormData();
    formData.append("Token", auth.user.token);
    if (!auth.isCuentaPrincipal()) {
      // Si la cuenta activa es una cuenta adicional
      // se prepara el request para actualizar el avatar
      // de una cuenta adicional
      formData.append("CuentaAdicionalGuid", auth.cuentaActiva.guid);
    } else {
      // Si la cuenta activa es la cuenta principal del usuario
      // se prepara el request para actualizar el avatar del usuario
      formData.append("UsuarioGuid", auth.user.guid);
    }
    formData.append("Avatar", file);
    var url;
    if (!auth.isCuentaPrincipal()) {
      // Si la cuenta activa es una cuenta adicional
      // se prepara la URL para actualizar el avatar
      // de una cuenta adicional
      url = `${API_URL()}/users/postAvatarCuentaAdicional`;
    } else {
      // Si la cuenta activa es la cuenta principal del usuario
      // se parapra la URL para actualizar el avatar del usuario
      url = `${API_URL()}/users/postAvatar`;
    }
    const options = {
      method: "POST",
      body: formData,
    };
    try {
      const res = await fetch(url, options);
      if (res.ok) {
        const json = await res.json();
        if (json.resultadoOK) {
          auth.refrescarAvatarCuentas();
        } else {
          json.errores.forEach((item) => {
            addToast(item, Severities.error);
          });
        }
      } else {
        addToast(globalData.terminosLocalizados.AVI004, Severities.error);
      }
    } catch (err) {
      addToast(globalData.terminosLocalizados.AVI005, Severities.error);
    }
  };

  const setEditorRef = (editor) => {
    setEditor(editor);
  };

  // Si se agregan controles para modificar estos valores
  // entonces se deben pasar a variables de estado.
  const width = 200;
  const height = 200;
  const botonGuardarWidth = `${width + 50}px`;
  //const rotate = 0;
  const borderRadius = 50;
  const allowZoomOut = false;

  return (
    <Grid
      container
      rowSpacing={1}
      columnSpacing={1}
      maxWidth="700px"
      margin={2}
      width="100%"
    >
      <Dropzone
        border="solid"
        width="100%"
        onDrop={handleDrop}
        noClick
        noKeyboard
      >
        {({ getRootProps, getInputProps }) => (
          <Grid item {...getRootProps()} width="100%">
            <Grid container width="100%">
              <Grid item md={4.8} width="100%" minWidth={width}>
                {cargandoImagen ? (
                  <Loading />
                ) : (
                  <ReactAvatarEditor
                    ref={setEditorRef}
                    scale={parseFloat(scale)}
                    width={width}
                    height={height}
                    rotate={parseFloat(rotate)}
                    borderRadius={width / (100 / borderRadius)}
                    image={image}
                  />
                )}
              </Grid>
              <Grid item md={7.2} width="100%">
                <Stack spacing={3}>
                  <Box width="100%">
                    <Typography fontStyle={"italic"} fontSize={16}>
                      {textos.instrucciones}
                    </Typography>
                  </Box>
                  <Box width="100%">
                    <Button
                      component="label"
                      sx={{
                        width: "308px",
                        bgcolor: theme.palette.secondary.main,
                        color: theme.palette.secondary.contrastText,
                        ":hover": { bgcolor: theme.palette.secondary.dark },
                        ":focus": { bgcolor: theme.palette.secondary.dark },
                      }}
                    >
                      {textos.comandoSeleccionar}
                      <input type="file" hidden onChange={handleNewImage} />
                    </Button>
                  </Box>
                  <Box width="308px">
                    <Typography fontSize={12}>{textos.zoom}:</Typography>
                    <Stack width="100%" direction="row" paddingLeft={1.2}>
                      <Slider
                        value={scale}
                        onChange={handleScale}
                        step={0.01}
                        max={2}
                        min={allowZoomOut ? 0.1 : 1}
                        disabled={image === null}
                      />
                    </Stack>
                  </Box>
                  <Box width="100%">
                    <Typography fontSize={12}>{textos.rotar}:</Typography>
                    <Stack
                      width="100%"
                      direction="row"
                      paddingLeft={0}
                      spacing={1}
                    >
                      <Button
                        startIcon={<RotateLeft />}
                        sx={{ width: "150px", textTransform: "none" }}
                        onClick={handleRotateLeft}
                      >
                        {globalData.terminosLocalizados.COM044}
                      </Button>
                      <Button
                        startIcon={<RotateRight />}
                        sx={{ width: "150px", textTransform: "none" }}
                        onClick={handleRotateRight}
                      >
                        {globalData.terminosLocalizados.COM045}
                      </Button>
                    </Stack>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Dropzone>
      <Grid item md={4.8} width="100%">
        <Button onClick={handleSave} sx={{ width: botonGuardarWidth }}>
          {textos.comandoGuardar}
        </Button>
      </Grid>
    </Grid>
  );
}
