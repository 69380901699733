import { React } from "react";
import { Box, Stack } from "@mui/material";

import Logo_Beemedics from "../../assets/img/Logo_BeeMedics_TRN.png";
import Logo from "../../assets/img/Logo.png";

import SearchBar from "./SearchBar";
import HelloBar from "./HelloBar";
import MenuBar from "./MenuBar";

import theme from "../../utils/theme";

import { const_Paginas } from "../../utils/constants";

export default function Header(props) {
  const { setCurrentPage } = props;

  // Esto no es imprescindible y queda feo, porque cuando el usuario cambia de idioma
  // la cabecera queda en blanco con el componente de Loading.
  // Sin este código, simplemente quedan en blanco los textos hasta que se actualizan.
  // if (globalData.loadingTerminosLocalizados) {
  //   return (
  //     <Box height="50px">
  //       <Loading />
  //     </Box>
  //   );
  // }

  const handleLogoClick = () => {
    setCurrentPage(const_Paginas.DASHBOARD);
  };

  return (
    <>
      <Stack
        direction="row"
        spacing={1}
        id="header"
        display="flex"
        flexDirection="row"
        height="50px"
        bgcolor={theme.palette.secondary.main}
        color={theme.palette.primary.main}
      >
        <Box
          id="logoContainer"
          width="max(10%,160px)"
          display="flex"
          alignItems="center"
          justifyContent={"right"}
          sx={{ [theme.breakpoints.down("430")]: { display: "none" } }}
        >
          <Box
            id="logo"
            component={"img"}
            src={Logo_Beemedics}
            alt="BeeMedics"
            height={"70%"}
            sx={{ cursor: "pointer" }}
            onClick={handleLogoClick}
          ></Box>
        </Box>
        <Box
          id="logoContainer"
          width="max(10%,36px)"
          display="flex"
          alignItems="center"
          justifyContent={"right"}
          sx={{ [theme.breakpoints.up("430")]: { display: "none" } }}
        >
          <Box
            id="logo"
            component={"img"}
            src={Logo}
            alt="BeeMedics"
            height={"70%"}
            sx={{ cursor: "pointer" }}
            onClick={handleLogoClick}
          ></Box>
        </Box>
        <Box id="search" flexGrow={1} display="flex" alignItems={"center"}>
          <SearchBar />
        </Box>
        <Box id="hello" display={"flex"} alignItems="center">
          <HelloBar />
        </Box>
        <Box id="menu">
          <MenuBar setCurrentPage={setCurrentPage} />
        </Box>
      </Stack>
    </>
  );
}
