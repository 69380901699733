import React, { useState } from "react";

import { Typography, AppBar, Grid } from "@mui/material";

import Menu from "../components/Datos/MiCuenta/Menu";
import InformacionPersonal from "../components/Datos/MiCuenta/InformacionPersonal";
import FotoPerfil from "../components/Datos/MiCuenta/FotoPerfil";
import Configuracion from "../components/Datos/MiCuenta/Configuracion";
import InformacionProfesional from "../components/Datos/MiCuenta/InformacionProfesional";
import InformacionInstitucion from "../components/Datos/MiCuenta/InformacionInstitucion";
import CambiarPassword from "../components/Datos/MiCuenta/Seguridad";

import useAuth from "../auth/useAuth";
import useGlobalDataContext from "../hooks/useGlobalDataContext";

import { const_TipoCuentaAdicional } from "../utils/constants";

export default function MyAccountPage() {
  const [pagina, setPagina] = useState("InformacionPersonal");

  const auth = useAuth();
  const globalData = useGlobalDataContext();

  const listaPaginas = {
    informacionPersonal: "InformacionPersonal",
    fotoPerfil: "FotoPerfil",
    configuracion: "Configuración",
    cambiarPassword: "CambiarPassword",
  };

  return (
    <>
      <AppBar position="static" width="100%">
        {/* <Typography variant="h5">Mi Cuenta</Typography> */}
        <Typography variant="h5">
          {globalData.terminosLocalizados.ETI105}
        </Typography>
      </AppBar>
      <Grid container rowSpacing={1} columnSpacing={1} width="100%">
        <Grid item md={3} marginTop={1} width="100%">
          <Menu
            pagina={pagina}
            setPagina={setPagina}
            listaPaginas={listaPaginas}
          />
        </Grid>
        {pagina === listaPaginas.informacionPersonal && (
          <Grid item md={9} width="100%">
            {(() => {
              switch (auth.cuentaActiva.codigoCategoria) {
                case const_TipoCuentaAdicional.PROFESIONAL:
                  return <InformacionProfesional />;
                case const_TipoCuentaAdicional.INSTITUCION:
                  return <InformacionInstitucion />;
                default:
                  return <InformacionPersonal />;
              }
            })()}
          </Grid>
        )}
        {pagina === listaPaginas.fotoPerfil && (
          <Grid item md={9} width="100%">
            <FotoPerfil />
          </Grid>
        )}
        {pagina === listaPaginas.configuracion && (
          <Grid item md={9} width="100%">
            <Configuracion />
          </Grid>
        )}
        {pagina === listaPaginas.cambiarPassword && (
          <Grid item md={9} width="100%">
            <CambiarPassword />
          </Grid>
        )}
      </Grid>
    </>
  );
}
