//export const API_URL = "https://localhost:44377";

export const API_URL = () => {
  switch (process.env.NODE_ENV) {
    case "production":
      //return "https://localhost/beemedics_webapi";
      return "https://webapi.beemedics.com";
    case "development":
      return "https://localhost:7255";
    //return "https://localhost:44377";
    default:
      throw new Error(
        "Error al establecer el enlace de la api: no se pudo establecer el entorno."
      );
  }
};

export const const_CurrentUser = "currentUser";
export const const_CurrentAvatar = "currentAvatar";
export const const_CuentaAdicionalActiva = "cuentaAdicionalActiva";

export const const_TipoCuentaAdicional = {
  PROFESIONAL: "PRF",
  INSTITUCION: "INS",
};

export const const_TipoDocumentoIdentidad = {
  CEDULA_IDENTIDAD: "CI",
  PASAPORTE: "PP",
  DNI: "DN",
};

export const const_TipoVariable = {
  CUALITATIVA: "L",
  CUANTITATIVA: "N",
  MULTIMEDIA: "M",
};

export const const_TipoVariableCualitativa = {
  MULTIMEDIA: "M",
  CAJAS: "C",
  TEXTO_PLENO: "T",
  FECHA: "F",
};

export const const_TipoFactorValoracion = {
  MENOR: "LT",
  MENOR_IGUAL: "LE",
  IGUAL: "EQ",
  MAYOR_IGUAL: "GE",
  MAYOR: "GT",
  RANGO: "BE",
};

export const const_TipoDato = {
  BOOLEANO: "Bool",
  STRING: "String",
};

export const const_TipoSeleccionEdicionFormularioClinico = {
  PROCESO: "Proceso",
  AREA_SALUD: "AreaSalud",
  ESPECIALIDAD: "Especialidad",
  SECCION: "Seccion",
  AREA_PREGUNTAS: "AreaPreguntas",
  PREGUNTA: "Pregunta",
  TIPO_VARIABLE: "TipoVariable",
  OPCION_PREGUNTA: "OpcionPregunta",
  UNIDAD_MEDIDA: "UnidadMedida",
  VALORACION: "Valoracion",
  FACTOR_VALORACION: "FactorValoracion",
};

export const const_TipoOrdenableFormularioClinico = {
  VALORACION: "Valoracion",
  UNIDAD_MEDIDA: "UnidadMedida",
  OPCION: "Opcion",
  TEXTO_PLENO: "TextoPleno",
  FECHA: "Fecha",
  MULTIMEDIA: "Multimedia",
  PREGUNTA: "Pregunta",
  AREA_PREGUNTAS: "AreaPreguntas",
  SECCION: "Seccion",
  PROCESO: "Proceso",
};

export const const_Paginas = {
  DASHBOARD: "Dashboard",
  CLINIC_FORM: "ClinicForm",
  EDIT_CLINIC_FORM: "EditClinicForm",
  ARCHIVO: "Archivo",
  MY_ACCOUNT: "MyAccount",
  // TEST_MULTIMEDIA: "TestMultimedia",
  // TEST_POSICIONAMIENTO: "TestPosicionamiento",
  // TEST_COMPONENTES: "TestComponentes",
  // TEST_PDF: "TestPdf",
  // TEST_HEXAGONOS: "TestHexagonos",
};

export const const_TiposMimeAceptados = [
  "image/jpeg",
  "image/png",
  "image/gif",
];

export const tipoEstadoArchivo = {
  SUBIENDO: 0,
  SUBIDO: 1,
  ERROR: 2,
};

export const const_tipoPublicacion = {
  CONSULTA: "C",
  PRESCRIPCION: "P",
  PRESCRIPCION_TEST: "T",
  RESULTADO: "R",
  CITA: "A",
  REGISTRO_ANTIGUO: "G",
};
