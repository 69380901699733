import React from "react";
import {
  Grid,
  Stack,
  Box,
  Typography,
  TextField,
  Button,
  Icon,
  Tooltip,
  //Divider,
} from "@mui/material";

import theme from "../../../../../utils/theme";
import useGlobalDataContext from "../../../../../hooks/useGlobalDataContext";

export default function ArchivoAntiguo(props) {
  const {
    item,
    index,
    actualizarSeleccion,
    cancelarSeleccion,
    actualizarNombre,
    actualizarNota,
    cancelarArchivo,
  } = props;

  const globalData = useGlobalDataContext();

  const textos = {
    nombre: globalData.terminosLocalizados.ETI108,
    nota: globalData.terminosLocalizados.ETI167,
    archivo: globalData.terminosLocalizados.ETI151,
    seleccionarArchivo: globalData.terminosLocalizados.COM042,
  };

  const handleOnChangeSeleccionarArchivo = (event) => {
    actualizarSeleccion(event, item.guid);
  };

  const handleClickCancelarSeleccion = (event) => {
    cancelarSeleccion(item.guid);
  };

  const handleClickCancelarArchivo = (event) => {
    cancelarArchivo(event, item.guid);
  };

  const handleOnChangeNombre = (event) => {
    actualizarNombre(event, item.guid);
  };

  const handleOnChangeNota = (event) => {
    actualizarNota(event, item.guid);
  };

  return (
    <Stack
      id="archivoContainer"
      spacing={1}
      bgcolor={theme.palette.cuaternary.dark}
      borderRadius="5px"
      padding={1}
      color={theme.palette.primary.main}
    >
      <Box id="cabecera" display={"flex"}>
        <Typography flexGrow={1}>{`${textos.archivo} ${index + 1}`}</Typography>
        <Tooltip title="Descartar este archivo">
          <Icon onClick={handleClickCancelarArchivo}>cancel_circle</Icon>
        </Tooltip>
      </Box>
      <Grid
        container
        id="contenido"
        spacing={1}
        // Estas dos propiedades se establecen para todos los contenedores de tipo <Grid>
        // para que los items que están en los bordes no tengan el espacio que separa a los
        // elementos interiores, pero por algún tema de maquetado de elementos que están más
        // arriba en la cadena de componentes, se están perdiendo. Por lo visto, hay un
        // componente de tipo <Stack> que los borra. Por ahora, los recupero aquí.
        marginTop={"-8px !important"}
        marginLeft={"-8px !important"}
      >
        <Grid item id="gridItemSelección" xs={12} sm={4}>
          {item.estado.cargado ? (
            <Stack
              height={"150px"}
              border="solid"
              borderRadius={"7px"}
              borderColor="rgba(0,0,0,0.23)"
              backgroundColor={theme.palette.cuaternary.ultraDark}
              sx={{ borderWidth: "1px" }}
            >
              <Box
                height={"30px"}
                display="flex"
                flexDirection={"row"}
                justifyContent="space-between"
                paddingLeft={1}
              >
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {item.archivo.name}
                </Typography>
                <Icon onClick={handleClickCancelarSeleccion}>
                  cancel_circle
                </Icon>
              </Box>
              <Box display="flex" justifyContent={"space-around"}>
                <Box height={"120px"}>
                  <img height={"100%"} src={item.preview} alt="preview" />
                </Box>
              </Box>
            </Stack>
          ) : (
            <Box>
              <Button
                variant="contained"
                component="label"
                sx={{
                  fontSize: "12px",
                  textTransform: "none",
                  backgroundColor: theme.palette.cuaternary.ultraDark,
                  color: theme.palette.primary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.cuaternary.ultraDarkPlus,
                  },
                  "&:focus": {
                    backgroundColor: theme.palette.cuaternary.ultraDarkPlus,
                  },
                }}
              >
                {textos.seleccionarArchivo}
                <input
                  type="file"
                  hidden
                  onChange={handleOnChangeSeleccionarArchivo}
                />
              </Button>
            </Box>
          )}
        </Grid>
        <Grid
          item
          id="gridItemInformacion"
          xs={12}
          sm={8}
          display="flex"
          flexDirection={"column"}
          justifyContent={"space-between"}
        >
          <Stack
            id="stackInformacion"
            spacing={1}
            display="flex"
            height={"100%"}
          >
            <Box id="contenedorNombre">
              <TextField
                id="nombre"
                s
                label={textos.nombre}
                size="small"
                onChange={handleOnChangeNombre}
                sx={{
                  backgroundColor: theme.palette.cuaternary.ultraDark,
                }}
              ></TextField>
            </Box>
            <Box id="contanedorNota">
              <TextField
                id="nota"
                label={textos.nota}
                multiline
                rows={3}
                onChange={handleOnChangeNota}
                sx={{ backgroundColor: theme.palette.cuaternary.ultraDark }}
              ></TextField>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}
