import React from "react";

import { CircularProgress, Box } from "@mui/material";

//import "./Loading.scss";

// Si se recibe "size" se utiliza el size proporcionado.
// En caso contrario, se utiliza el size por defecto, que según
// la documentación son 40px.
export default function Loading(props) {
  var { size, marginTop } = props;
  if (!size) {
    size = 40;
  }
  return (
    <Box
      //height={"auto"}
      height={"100%"}
      display="flex"
      alignItems={"center"}
      justifyContent="center"
    >
      {size ? (
        <CircularProgress size={size} sx={{ marginTop: { marginTop } }} />
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
}
