import React from "react";
import { Box, Icon, IconButton } from "@mui/material";
import theme from "../../../../../utils/theme";

export default function ComandoEstadoElemento(props) {
  const { item, cambiarEstado } = props;

  const handleCambiarEstado = () => {
    cambiarEstado(item.guid);
  };

  return (
    <Box
      width={"20px"}
      minWidth="20px"
      display={"flex"}
      alignItems="center"
      overflow={"clip"}
      marginRight={1}
      color={theme.palette.timeline.iconos}
    >
      <IconButton onClick={handleCambiarEstado}>
        <Icon fontSize="small">menu</Icon>
      </IconButton>
    </Box>
  );
}
